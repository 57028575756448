import { computed } from 'mobx';
import getModel from 'shared-between-front-ends/src/decorators/withModel/getModel';
import InputModelBaseClass from 'shared-between-front-ends/src/InputModelBaseClass';

import TeamsModel from './TeamsModel';

export default class TeamSelectInputModel extends InputModelBaseClass {
  dependencies = {};

  constructor(inputModelConfiguration = {}, teamModel = getModel(TeamsModel)) {
    super({ defaultValue: null, ...inputModelConfiguration });

    this.dependencies.teamModel = teamModel;
  }

  @computed get teams() {
    return this.dependencies.teamModel.teams.map(team => ({
      ...team,

      select: () => {
        this.setValue(team);
      },
    }));
  }
}
