import PropTypes from 'prop-types';
import React from 'react';

import Typography, {
  commonPropTypesForAllTypographies,
} from '../../private/Typography/Typography';

function Heading({ size = 'lg', children, ...props }) {
  return (
    <Typography
      {...props}
      font="primary"
      size={size}
      uppercase={false}
      bold={false}
    >
      {children}
    </Typography>
  );
}

export const supportedSizes = ['lg', 'sm', 'xs', 'xxs'];

Heading.propTypes = {
  ...commonPropTypesForAllTypographies,
  size: PropTypes.oneOf(supportedSizes),
};

export default Heading;
