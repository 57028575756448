import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';

export default getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/teams/{teamId}/work-orders/{workOrderId}',
    method: 'GET',
  },
  decoratorConfig: {
    spinnerName: 'getting-work-order',
    showSpinnerInstantly: true,
  },
});
