import { observer } from 'mobx-react';
import React from 'react';

import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import RouteLink from 'shared-between-front-ends/src/components/public/RouteLink/RouteLink';
import Title from 'shared-between-front-ends/src/components/public/Title/Title';

function ResourceRowHeader({
  resource: { id: resourceId, backgroundColor, firstName, lastName },
  teamId,
  districtId,
  userRights,
}) {
  return (
    <Div
      relative
      occupyVerticalSpace
      padding={{ vertical: 'xs' }}
      backgroundColor={backgroundColor}
    >
      <Icon
        color="grey40"
        shown={userRights.reorderResourcesInTeams}
        size="sm"
        name="grip-lines-vertical"
        absolutePosition="my-center-left-to-center-left-of-parent"
      />

      <Flex
        centeredVertically
        occupyVerticalSpace
        padding={{
          horizontal: 'md',
        }}
      >
        <RouteLink
          draggable={false}
          routeName="resource-dashboard"
          pathParameters={{
            resourceId,
          }}
          hideOverflow
          data-update-resource-test
        >
          <Title singleLine>
            {lastName}, {firstName}
          </Title>
        </RouteLink>
      </Flex>
    </Div>
  );
}

export default decorate(observer)(ResourceRowHeader);
