import { observer } from 'mobx-react';
import React from 'react';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import HeavyText from 'shared-between-front-ends/src/components/public/HeavyText/HeavyText';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import MeasurementAmountInput from 'shared-between-front-ends/src/components/public/MeasurementAmountInput/MeasurementAmountInput';
import Panel from 'shared-between-front-ends/src/components/public/Panel/Panel';
import SemanticButton from 'shared-between-front-ends/src/components/public/SemanticButton/SemanticButton';
import TextAreaInput from 'shared-between-front-ends/src/components/public/TextAreaInput/TextAreaInput';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import resourceDashboardTranslations from '../../../../../resourceDashboardTranslations';
import EffortAmountAndDuration from '../../../EffortAmountAndDuration/EffortAmountAndDuration';

const translate = localTranslate(resourceDashboardTranslations);

const Participation = ({
  model: {
    participationId,
    actualEffortInput,
    comment,
    participantNames,
    effortDefinitionCatalogItemId,
    effortEntryHighlightColor,
    participationIsExpanded,
    effortAmount,
    expectedEffort,
    roundedTotalDuration,
  },
}) => (
  <Panel data-participation-id-test={participationId}>
    <SemanticButton
      occupyHorizontalSpace
      onClick={participationIsExpanded.toggle}
      data-toggle-participation-test
    >
      <Panel.Header
        backgroundColor={effortEntryHighlightColor}
        padding={{
          horizontal: 'md',
          vertical: 'xs',
        }}
        data-participation-highlight
      >
        <Flex centeredVertically>
          <Icon
            compact
            size="sm"
            alignTextTo="center"
            style={{ width: 20 }}
            name={
              participationIsExpanded.internalValue
                ? 'chevron-up'
                : 'chevron-down'
            }
          />

          <Gutter size="sm" />

          <BodyText size="3xs" flexItem forceWrap>
            {participantNames}
          </BodyText>

          <Gutter size="sm" />

          <EffortAmountAndDuration
            duration={roundedTotalDuration}
            effort={{
              amount: effortAmount,
              measurementUnitId: expectedEffort.measurementUnitId,
            }}
          />
        </Flex>
      </Panel.Header>
    </SemanticButton>

    <Panel.Content
      shown={participationIsExpanded.internalValue}
      data-participation-content
    >
      <Flex centeredVertically spaceBetween>
        <BodyText>{translate('actualEffortEntries')}</BodyText>

        <Gutter flexItem size="sm" />

        <MeasurementAmountInput
          model={actualEffortInput}
          width="sm"
          autoFocus
          data-measurement-amount-input-test={effortDefinitionCatalogItemId}
          data-effort-amount-input-e2e-test
        />
      </Flex>

      <Gutter size="sm" />

      <Div>
        <HeavyText color="primary">{translate('comment')}</HeavyText>

        <Gutter size="xxs" />

        <TextAreaInput
          model={comment}
          data-comment-input-test={effortDefinitionCatalogItemId}
        />
      </Div>
    </Panel.Content>
  </Panel>
);

export default observer(Participation);
