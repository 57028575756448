import React from 'react';
import Element from '../Element';

function Label({ children, ...props }) {
  return (
    <Element tagName="label" {...props}>
      {children}
    </Element>
  );
}

export default Label;
