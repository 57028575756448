import React from 'react';

import Div from '../../../components/private/Element/Div/Div';
import Span from '../../../components/private/Element/Span/Span';
import BodyText from '../../../components/public/BodyText/BodyText';
import Flex from '../../../components/public/Flex/Flex';
import Gutter from '../../../components/public/Gutter/Gutter';
import Icon from '../../../components/public/Icon/Icon';

function ErrorMessage({ errorMessage, children, ...props }) {
  return (
    <Flex centeredVertically {...props} data-error-message-e2e-test>
      <div>
        <Icon color="error" name="mug-saucer" size="md" />
      </div>

      <Gutter />

      <Div forceWrap>
        <BodyText block singleLine>
          <Span bold>{errorMessage}</Span>
        </BodyText>

        {!!children && <BodyText block>{children}</BodyText>}
      </Div>
    </Flex>
  );
}

export default ErrorMessage;
