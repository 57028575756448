import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import localTranslate from '../../../doings/localTranslate/localTranslate';
import Div from '../../private/Element/Div/Div';
import Element from '../../private/Element/Element';
import BodyText from '../BodyText/BodyText';
import Flex from '../Flex/Flex';
import Gutter from '../Gutter/Gutter';
import Icon from '../Icon/Icon';
import Map from '../Map/Map';
import SecondaryButton from '../SecondaryButton/SecondaryButton';
import TextInput from '../TextInput/TextInput';
import multiTextInputTranslations from './multiTextInputTranslations';

const translate = localTranslate(multiTextInputTranslations);

const MultiTextInput = ({ label, model: { inputs, addEmptyInput } }) => (
  <Div onEnterKey={addEmptyInput} data-add-row-with-enter-test>
    <BodyText size="3xs">{label}</BodyText>

    <Map items={inputs} separator={<Gutter size="xxs" />}>
      {({ inputModel, remove, autoFocus }) => (
        <Flex spaceBetween>
          <TextInput
            model={inputModel}
            autoFocus={autoFocus}
            flexItem
            data-text-input-test
          />

          <Gutter size="sm" />

          <IconButton iconName="xmark" onClick={remove} data-remove-row-test />
        </Flex>
      )}
    </Map>

    <Gutter size="xs" />

    <SecondaryButton size="sm" onClick={addEmptyInput} data-add-row-test>
      {translate('add')}
    </SecondaryButton>
  </Div>
);

const IconButton = ({ iconName, ...props }) => (
  <Element
    tagName="button"
    backgroundColor="transparent"
    type="button"
    {...props}
  >
    <Icon name={iconName} size="sm" />
  </Element>
);

export default decorate(observer)(MultiTextInput);
