import flow from 'lodash/fp/flow';

export default (...decorators) => toBeDecorated => {
  const decorated = flow(...decorators)(toBeDecorated);

  decorated.__decorators = decorators;

  decorated.__decoratedFunction = toBeDecorated;

  return decorated;
};
