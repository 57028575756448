import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';

export default getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/create-resource',
    method: 'POST',
  },
  decoratorConfig: {
    spinnerName: 'creating-resource',
    showSpinnerInstantly: true,
  },
});
