import { action, computed, observable, runInAction } from 'mobx';
import pipeline from 'shared-between-everything/src/doings/pipeline/pipeline';

import {
  fromPairs,
  identity,
  includes,
  keys,
  map,
  sortBy,
  toLower,
  values,
} from 'shared-between-everything/src/functionalProgramming';
import * as userRightConstantsImport from 'shared-between-everything/src/userRightConstants';
import BooleanInputModel from 'shared-between-front-ends/src/components/public/CheckboxInput/BooleanInputModel';
import EmailInputModel from 'shared-between-front-ends/src/components/public/EmailInput/EmailInputModel';
import whenRouteChangesTo from 'shared-between-front-ends/src/decorators/whenRouteChangesTo/whenRouteChangesTo';

import createUserImport from './createUser/createUser';
import getUsersImport from './getUsers/getUsers';
import noDuplicateIdsValidator from './noDuplicateIdsValidator/noDuplicateIdsValidator';
import updateUserImport from './updateUser/updateUser';

export default class UserRightsModel {
  dependencies = {};

  constructor(
    getUsers = getUsersImport,
    userRightConstants = userRightConstantsImport,
    updateUser = updateUserImport,
    createUser = createUserImport,
  ) {
    this.dependencies.getUsers = getUsers;
    this.dependencies.userRightConstants = userRightConstants;
    this.dependencies.updateUser = updateUser;
    this.dependencies.createUser = createUser;

    this._toUserAndUserRightInputModels = toUserAndUserRightInputModelsFor({
      userRightConstants: this.dependencies.userRightConstants,
      updateUser: this.dependencies.updateUser,
    });
  }

  @computed
  get allUserRights() {
    return pipeline(
      this.dependencies.userRightConstants,
      keys,
      sortBy(identity),
    );
  }

  @observable _users = [];

  @computed
  get users() {
    return sortBy('id', this._users);
  }

  @whenRouteChangesTo('user-right-maintenance')
  refresh = async () => {
    const { response: users } = await this.dependencies.getUsers();

    runInAction(() => {
      this._clearUsers();

      users.forEach(user => {
        const userWithInputModels = this._toUserAndUserRightInputModels(user);

        this._users.push(userWithInputModels);
      });
    });
  };

  _clearUsers = () => {
    this._users.length = 0;
  };

  emailAddress = new EmailInputModel({
    required: true,
    validators: [noDuplicateIdsValidator(this._users)],
  });

  @action
  createUser = () => {
    const user = {
      id: toLower(this.emailAddress.value),
      userRights: [],
    };

    this.dependencies.createUser(user);

    const userWithInputModels = this._toUserAndUserRightInputModels(user);

    this._users.unshift(userWithInputModels);

    this.emailAddress.clear();
  };

  @computed
  get isValid() {
    return this.emailAddress.isValid;
  }
}

const getUserRightInputModelsFor = ({
  userRightOptions,
  updateUser,
}) => user => {
  const selectedUserRights = new Set(user.userRights);

  return pipeline(
    userRightOptions,
    values,
    map(userRight => [
      userRight,
      new BooleanInputModel({
        defaultValue: includes(userRight, user.userRights),
        onChange: addOrDeleteUserRightFor({
          selectedUserRights,
          userRight,
          user,
          updateUser,
        }),
      }),
    ]),
    fromPairs,
  );
};

const addOrDeleteUserRightFor = ({
  selectedUserRights,
  userRight,
  user,
  updateUser,
}) => addingUserRight => {
  if (addingUserRight) {
    selectedUserRights.add(userRight);
  } else {
    selectedUserRights.delete(userRight);
  }

  updateUser({
    userId: user.id,
    userRights: [...selectedUserRights.values()],
  });
};

const toUserAndUserRightInputModelsFor = ({
  userRightConstants,
  updateUser,
}) => {
  const getUserRightInputModels = getUserRightInputModelsFor({
    userRightOptions: userRightConstants,
    updateUser,
  });

  return user => ({
    id: user.id,
    userRights: getUserRightInputModels(user),
  });
};
