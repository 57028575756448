import {
  mapValues,
  pipeline,
} from 'shared-between-everything/src/functionalProgramming';

import workOrderTypes from 'shared-between-everything/src/workOrderTypes';

const getShortBalanceHeaders = language =>
  pipeline(
    workOrderTypes,
    mapValues(`translations.${language}.shortBalanceHeader`),
  );

export default {
  en: {
    createTeam: 'Add team member',
    sendNotificationEmails: 'Notify all',
    notificationEmailsGotSent: '{numberOfSentEmails} emails sent',
    noNotificationEmailsGotSent: 'No changes recently, no emails sent',
    scheduler: 'Scheduler',
    week: 'Week',
    weeks: 'Weeks',
    yes: 'Yes',
    showWeekends: 'Weekends',
    no: 'No',
    workOrders: 'Works',
    otherWorkOrders: 'Resources',
    commonWorkOrders: 'Common',
    shortHour: 'h',
    today: 'Today',
    createResource: 'New resource',
    createWorkOrder: 'New work order',
    createTeamResource: 'Create team resource',
    leaveBalances: 'Total Leave balances (grey) / Planned absences (white)',
    shortBalanceHeaders: getShortBalanceHeaders('en'),
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    shortMonths: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    shortWeekdays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    confirmDelete: 'Delete appointment?',
    deleteSuccess: 'Appointment deleted',
    searchWorkOrders: 'Search work orders',
    absenceWorkOrderListHeader: 'Vacations and other absences',
    absenceBudgetHeader: 'Leave budget',
    absenceBudgetSubheader: 'Planned/budgeted',
    cancel: 'Cancel',
    save: 'Save',
    deleteAppointment: 'Delete',
    selectAppointment: 'Show',
    selectWorkOrder: 'Schedule more',
    setAppointmentDuration: 'Appointment duration:',
    changeViewToAbsenceScheduler: 'Absence scheduler',
    changeViewToScheduler: 'Scheduler',
    urgent: 'Urgent',
    done: 'Done',
    info: 'Note',
    openWorkOrder: 'Open work order',
    openWorkOrderLink: 'Open external link',
  },
  fi: {
    createTeam: 'Luo tiimi',
    sendNotificationEmails: 'Lähetä muutokset sähköpostilla',
    notificationEmailsGotSent: '{numberOfSentEmails} sähköpostia lähetettiin.',
    noNotificationEmailsGotSent:
      'Tapaamiset eivät ole muuttuneet lähipäivinä, sähköposteja ei lähetetty.',
    scheduler: 'Työkalenteri',
    week: 'Viikko',
    weeks: 'Viikkoja',
    yes: 'Kyllä',
    no: 'Ei',
    showWeekends: 'Viikonloput',
    workOrders: 'Työt',
    otherWorkOrders: 'Resurssit',
    commonWorkOrders: 'Yleiset',
    shortHour: 'h',
    today: 'Tänään',
    createResource: 'Uusi henkilö',
    createWorkOrder: 'Uusi työtilaus',
    createTeamResource: 'Luo uusi henkilö',
    leaveBalances: 'Lomakertymä (harmaa) / Suunniteltu poissaolot (valkoinen)',
    shortBalanceHeaders: getShortBalanceHeaders('fi'),
    months: [
      'Tammikuu',
      'Helmikuu',
      'Maaliskuu',
      'Huhtikuu',
      'Toukokuu',
      'Kesäkuu',
      'Heinäkuu',
      'Elokuu',
      'Syyskuu',
      'Lokakuu',
      'Marraskuu',
      'Joulukuu',
    ],
    shortMonths: [
      'Tammi',
      'Helmi',
      'Maalis',
      'Huhti',
      'Touko',
      'Kesä',
      'Heinä',
      'Elo',
      'Syys',
      'Loka',
      'Marras',
      'Joulu',
    ],
    shortWeekdays: ['Ma', 'Ti', 'Ke', 'To', 'Pe', 'La', 'Su'],
    confirmDelete: 'Poistetaanko?',
    deleteSuccess: 'Aikataulutus poistettu',
    searchWorkOrders: 'Hae työtilauksia',
    absenceWorkOrderListHeader: 'Lomat ja muut poissaolot',
    absenceBudgetHeader: 'Lomabudjetti',
    absenceBudgetSubheader: 'Suunniteltu/budjetoitu',
    cancel: 'Peruuta',
    save: 'Tallenna',
    deleteAppointment: 'Poista aikataulutus',
    selectAppointment: 'Näytä aikataulutus',
    selectWorkOrder: 'Aikatauluta lisää',
    setAppointmentDuration: 'Aseta työtunnit:',
    changeViewToAbsenceScheduler: 'Lomasuunnittelu',
    changeViewToScheduler: 'Työsuunnittelu',
    urgent: 'Kiireellinen',
    done: 'Valmis',
    info: 'Huomioitavaa',
    openWorkOrder: 'Avaa työtilaus',
    openWorkOrderLink: 'Avaa linkki',
  },
  pl: {
    createTeam: 'Utwórz zespół',
    sendNotificationEmails: 'Powiadom wszystkich',
    notificationEmailsGotSent: '{numberOfSentEmails} email został wysłany',
    noNotificationEmailsGotSent: 'No changes recently, no emails sent',
    scheduler: 'Harmonogram',
    week: 'Tydzień',
    weeks: 'Tygodnie',
    yes: 'Tak',
    showWeekends: 'Weekendy',
    no: 'Nie',
    workOrders: 'Projekty',
    otherWorkOrders: 'Zasoby',
    commonWorkOrders: 'Wspólne',
    shortHour: 'h',
    today: 'Dzisiaj',
    createResource: 'Nowy zasób',
    createWorkOrder: 'Nowe zlecenie pracy',
    createTeamResource: 'Luo uusi henkilö',
    leaveBalances: 'Saldo dni wolnych',
    shortBalanceHeaders: getShortBalanceHeaders('pl'),
    months: [
      'Styczeń',
      'Luty',
      'Marzec',
      'Kwiecień',
      'Maj',
      'Czerwiec',
      'Lipiec',
      'Sierpień',
      'Wrzesień',
      'Październik',
      'Listopad',
      'Grudzień',
    ],
    shortMonths: [
      'Sty',
      'Lut',
      'Mar',
      'Kwi',
      'Maj',
      'Cze',
      'Lip',
      'Sie',
      'Wrz',
      'Paź',
      'Lis',
      'Gru',
    ],
    shortWeekdays: ['Pon', 'Wto', 'Śro', 'Czw', 'Pią', 'Sob', 'Nie'],
    confirmDelete: 'Usunąć spotkanie?',
    deleteSuccess: 'Spotkanie usunięte',
    searchWorkOrders: 'wyszukaj zlecenie pracy',
    absenceWorkOrderListHeader: 'Urlop i inne nieobecności',
    absenceBudgetHeader: 'Nieobecności budżet',
    absenceBudgetSubheader: 'Planowane/budżetowane',
    cancel: 'Anuluj',
    save: 'Zapisz',
    deleteAppointment: 'Usuń',
    selectAppointment: 'Pokaż',
    selectWorkOrder: 'Zaplanuj',
    setAppointmentDuration: 'Czas trwania spotkania:',
    changeViewToAbsenceScheduler: 'Absence scheduler',
    changeViewToScheduler: 'Scheduler',
    urgent: 'Pilne',
    done: 'Gotowe',
    info: 'Uwaga',
    openWorkOrder: 'Open work order',
    openWorkOrderLink: 'Open external link',
  },
  se: {
    createTeam: 'Lägg till team medlem',
    sendNotificationEmails: 'Meddela alla',
    notificationEmailsGotSent: '{numberOfSentEmails} epost skickad',
    noNotificationEmailsGotSent:
      'Inga ändringar är gjorda, ingen epost skickad',
    scheduler: 'Schemaläggare',
    week: 'Vecka',
    weeks: 'Veckor',
    yes: 'Ja',
    showWeekends: 'Helger',
    no: 'Nej',
    workOrders: 'AO',
    otherWorkOrders: 'Resurser',
    commonWorkOrders: 'Frånvaro',
    shortHour: 'h',
    today: 'I dag',
    createResource: 'Ny användare',
    createWorkOrder: 'Ny arbetsorder',
    createTeamResource: 'Luo uusi henkilö',
    leaveBalances: 'Semesterbalans',
    shortBalanceHeaders: getShortBalanceHeaders('se'),
    months: [
      'Januari',
      'Februari',
      'Mars',
      'April',
      'Maj',
      'Juni',
      'Juli',
      'Augusti',
      'September',
      'Oktober',
      'November',
      'December',
    ],
    shortMonths: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    shortWeekdays: ['Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör', 'Sön'],
    confirmDelete: 'Ta bort möte?',
    deleteSuccess: 'Mötet borttaget',
    searchWorkOrders: 'Sök arbetsorder',
    absenceWorkOrderListHeader: 'Semester och annan ledighet',
    absenceBudgetHeader: 'Lämna budget',
    absenceBudgetSubheader: 'Plannerad/budget',
    cancel: 'Avbryt',
    save: 'Spara',
    deleteAppointment: 'Ta bort',
    selectAppointment: 'Visa',
    selectWorkOrder: 'Schemalägg mer',
    setAppointmentDuration: 'Mötets varaktighet:',
    changeViewToAbsenceScheduler: 'Frånvaroschemaläggare',
    changeViewToScheduler: 'Schemaläggare',
    urgent: 'Brådskande',
    done: 'Klar',
    info: 'Notering',
    openWorkOrder: 'Öppen arbetsorder',
    openWorkOrderLink: 'Öppna extern länk',
  },
};
