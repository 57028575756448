import LoginRequired from '../../../App/LoginRequired/LoginRequired';
import LoginRequiredModel from '../../../App/LoginRequired/LoginRequiredModel/LoginRequiredModel';
import NotFound from '../../../App/NotFound/NotFound';
import UserRightRequired from '../../../App/UserRightRequired/UserRightRequired';
import UserRightRequiredModel from '../../../App/UserRightRequired/UserRightRequiredModel/UserRightRequiredModel';

export default [
  {
    name: 'session-start',
    path: '/session-start',
    backRoute: null,
    View: null,
    ViewModel: null,
  },

  {
    name: 'login-required',
    backRoute: null,
    View: LoginRequired,
    ViewModel: LoginRequiredModel,
  },

  {
    name: 'login-pending',
    backRoute: null,
    View: null,
    ViewModel: null,
  },

  {
    name: 'user-right-required',
    backRoute: null,
    View: UserRightRequired,
    ViewModel: UserRightRequiredModel,
  },

  {
    name: 'not-found',
    backRoute: null,
    View: NotFound,
    ViewModel: null,
  },
];
