import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import isTomorrow from 'shared-between-everything/src/date-time-abstractions/isTomorrow/isTomorrow';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import AttentionText from 'shared-between-front-ends/src/components/public/AttentionText/AttentionText';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import Loader from 'shared-between-front-ends/src/components/public/Loader/Loader';
import Map from 'shared-between-front-ends/src/components/public/Map/Map';
import Page from 'shared-between-front-ends/src/components/public/Page/Page';
import Popover from 'shared-between-front-ends/src/components/public/Popover/Popover';
import SecondaryButton from 'shared-between-front-ends/src/components/public/SecondaryButton/SecondaryButton';
import SeparatorLine from 'shared-between-front-ends/src/components/public/SeparatorLine/SeparatorLine';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import SimpleNavigation from '../SimpleNavigation/SimpleNavigation';
import AppointmentBox from './AppointmentBox/AppointmentBox';
import AutoScrollMarker from './AutoScrollMarker/AutoScrollMarker';
import PopoverContentForSelfScheduling from './PopoverContentForSelfScheduling/PopoverContentForSelfScheduling';
import resourceDashboardTranslations from './resourceDashboardTranslations';
import SimplifiedAppointmentBox from './SimplifiedAppointmentBox/SimplifiedAppointmentBox';

const translate = localTranslate(resourceDashboardTranslations);

const ResourceDashboard = ({ model }) => {
  useEffect(() => {
    autorun(() => {
      if (model.dependencies.dataPopulationModel.fullDataIsAvailable) {
        setTimeout(() => {
          model.scrollToSelectedAppointment();
        }, 1000);
      }
    });
  });

  return (
    <Page
      compact
      segmented
      backgroundColor="lightGrey"
      data-resource-dashboard-test={model.currentResourceId}
      data-resource-dashboard-e2e-test
    >
      <Loader unless={() => model.dataIsAvailable} padding={{ size: 'md' }}>
        {() => (
          <>
            <Page.Header>
              <SimpleNavigation
                currentResourceName={model.currentResourceName}
                teamName={model.teamName}
              />
            </Page.Header>

            <Page.Body padding={{ bottom: 'sm' }}>
              <Gutter size="sm" />

              <Page.Row margin={false}>
                <Flex centeredVertically>
                  <SeparatorLine flexItem horizontal />

                  <Gutter size="xs" />

                  <AttentionText color="grey50">
                    {translate('olderAppointmentsAreNotShown')}
                  </AttentionText>

                  <Gutter size="xs" />

                  <SeparatorLine flexItem horizontal />
                </Flex>
              </Page.Row>

              <Gutter size="sm" />

              <Map items={model.days} separator={<Gutter size="sm" />}>
                {day =>
                  day.delimiter ? (
                    <Page.Row margin={false}>
                      <Flex centeredVertically>
                        <SeparatorLine flexItem horizontal />

                        <Gutter size="xs" />

                        <AttentionText color="grey50">
                          {translate('pastFutureDelimiter')}
                        </AttentionText>

                        <Gutter size="xs" />

                        <SeparatorLine flexItem horizontal />
                      </Flex>
                    </Page.Row>
                  ) : (
                    <Div>
                      {day.firstFutureDay && (
                        <AutoScrollMarker
                          active={day.shouldAutoScrollToCurrentDateOnRender}
                          scrollIntoView={day.scrollIntoView}
                          data-current-date
                        />
                      )}

                      <Div
                        backgroundColor="white"
                        sticky="top"
                        padding={{ vertical: 'sm' }}
                        boxShadow
                      >
                        <Page.Row margin={false}>
                          <Flex centeredVertically>
                            <AttentionText
                              width="sm"
                              color="primary"
                              shown={!!day.dateAlias}
                            >
                              {day.dateAlias}
                            </AttentionText>

                            <Gutter shown={!!day.dateAlias} size="xs" />

                            <BodyText>{day.dateString}</BodyText>

                            <Gutter flexItem />

                            <SecondaryButton
                              size="sm"
                              onClick={day.showSelfSchedulingPopOver}
                              data-schedule-appointment-test={day.date}
                              data-schedule-new-appointment-e2e-test={
                                isTomorrow(day.date)
                                  ? 'tomorrow'
                                  : 'not-tomorrow'
                              }
                            >
                              {translate('selfSchedule')}
                            </SecondaryButton>

                            <Popover
                              content={
                                <PopoverContentForSelfScheduling day={day} />
                              }
                              position="vertical center of screen"
                              activateOn="manual"
                              activationModel={
                                model.dependencies.activationModel
                              }
                              activationId={`pop-up-for-self-scheduling-${day.date}`}
                            />
                          </Flex>
                        </Page.Row>
                      </Div>

                      <Gutter size="sm" />

                      <Page.Row margin={false}>
                        <Map
                          items={day.normalizedItems}
                          separator={<Gutter size="sm" />}
                        >
                          {normalizedItem =>
                            normalizedItem.workOrderType
                              .appointmentsAreSimplified ? (
                              <SimplifiedAppointmentBox
                                normalizedItem={normalizedItem}
                              />
                            ) : (
                              <AppointmentBox normalizedItem={normalizedItem} />
                            )
                          }
                        </Map>
                      </Page.Row>
                    </Div>
                  )
                }
              </Map>
            </Page.Body>

            <Page.Footer backgroundColor="white" padding={{ size: 'sm' }}>
              <Flex centered>
                <SecondaryButton
                  width="md"
                  onClick={model.scrollToFutureAppointments}
                  data-scroll-to-future-appointments-test
                >
                  {translate('scrollToFutureAppointments')}
                </SecondaryButton>
              </Flex>
            </Page.Footer>
          </>
        )}
      </Loader>
    </Page>
  );
};

export default decorate(observer)(ResourceDashboard);
