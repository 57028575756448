import { observer } from 'mobx-react';
import React from 'react';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import HeavyText from 'shared-between-front-ends/src/components/public/HeavyText/HeavyText';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import Map from 'shared-between-front-ends/src/components/public/Map/Map';
import Panel from 'shared-between-front-ends/src/components/public/Panel/Panel';
import SecondaryButton from 'shared-between-front-ends/src/components/public/SecondaryButton/SecondaryButton';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import resourceDashboardTranslations from '../../../../resourceDashboardTranslations';
import Participation from './Participation/Participation';

const translate = localTranslate(resourceDashboardTranslations);

const Participations = ({
  effortDefinitionCatalogItem,
  showAddParticipationPopover,
  participations,
}) => (
  <Panel transparent>
    <Panel.Header color="primary" backgroundColor="lightGrey">
      <Flex>
        <Flex centeredVertically>
          <Icon name="users" size="sm" />

          <Gutter size="sm" />

          <HeavyText>{translate('participations')}</HeavyText>
        </Flex>

        <Gutter flexItem />

        <SecondaryButton
          onClick={showAddParticipationPopover}
          size="sm"
          data-add-participation-test={effortDefinitionCatalogItem.id}
        >
          <Flex centered>
            <Icon name="plus" size="xs" />

            <Gutter size="xs" />

            <Icon name="users" size="sm" />
          </Flex>
        </SecondaryButton>
      </Flex>
    </Panel.Header>

    <Map items={participations} separator={<Gutter size="xxs" />}>
      {participationModel => <Participation model={participationModel} />}
    </Map>
  </Panel>
);

export default observer(Participations);
