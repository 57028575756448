import React from 'react';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Panel from 'shared-between-front-ends/src/components/public/Panel/Panel';

import styles from './StripedBox.module.scss';

const StripedBox = ({ children, color, ...props }) => (
  <Panel {...props} occupyVerticalSpace>
    <Flex alignBaseline stretchToSameHeight occupyVerticalSpace>
      <Div className={styles.stripedBox_stripe} backgroundColor={color} />

      <Div flexItem occupyVerticalSpace>
        {children}
      </Div>
    </Flex>
  </Panel>
);

export default StripedBox;
