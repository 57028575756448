import { computed, runInAction } from 'mobx';
import moment from 'moment-timezone';

import {
  filter,
  groupBy,
  isNull,
  map,
  matches,
  pipeline,
  reject,
  flatMap,
  sum,
} from 'shared-between-everything/src/functionalProgramming';

import getWorkOrderTypeValueObject from 'shared-between-everything/src/getWorkOrderTypeValueObject';
import getModel from 'shared-between-front-ends/src/decorators/withModel/getModel';
import RoutingModel from 'shared-between-front-ends/src/models/RoutingModel/RoutingModel';
import withLaziness from '../../../team/UpdateTeam/withLaziness/withLaziness';
import callForResourceDashboardDtoImport from '../callForResourceDashboardDto/callForResourceDashboardDto';
import formatToDateTime from 'shared-between-everything/src/date-time-abstractions/formatToDateTime/formatToDateTime';

// total API calls for the resource dashboard
const API_CALLS = 4;

export default class DataPopulationModel {
  dependencies = {};

  constructor({
    routingModel = getModel(RoutingModel),
    callForResourceDashboardDto = callForResourceDashboardDtoImport,
  } = {}) {
    this.dependencies.routingModel = routingModel;
    this.dependencies.callForResourceDashboardDto = callForResourceDashboardDto;
  }

  @computed
  get currentResourceId() {
    return this.dependencies.routingModel.pathParameters.resourceId;
  }

  @computed
  get currentResource() {
    return this.data.currentResource;
  }

  @computed
  get currentResourceName() {
    const { lastName, firstName } = this.currentResource;

    return `${firstName} ${lastName}`;
  }

  @computed
  get team() {
    return this.data.team;
  }

  @computed
  get teamResources() {
    return this.data.teamResources;
  }

  @computed
  get teamWorkOrders() {
    return this.data.teamWorkOrders;
  }

  @computed
  get currentResourceNormalizedItems() {
    return filter(
      matches({
        resource: {
          id: this.currentResourceId,
        },
      }),
      this.normalizedItems,
    );
  }

  @computed
  get normalizedItems() {
    return pipeline(
      this.data.normalizedItems,

      map(({ efforts = [], ...normalizedItem }) => {
        const effortsAndTotals = efforts.map(
          toEffortsWithTotalsFor(normalizedItem.appointment),
        );

        const groupedEfforts = groupBy(
          'expectedEffortDefinition.type',
          effortsAndTotals,
        );

        const workOrderType = getWorkOrderTypeValueObject(
          normalizedItem.workOrder.type,
        );

        return {
          ...normalizedItem,

          workOrderType,

          issuedEfforts: groupedEfforts.issued || [],

          manualEfforts: groupedEfforts.manual || [],
        };
      }),
    );
  }

  @computed
  get dataIsAvailable() {
    return this._resourceDashboardDto.promiseStatus.fulfilled;
  }

  // there are separate calls for each week, this to indicate when all calls are done
  @computed
  get fullDataIsAvailable() {
    return (
      this._resourceDashboardDto.promiseStatus.fulfilled &&
      this._resourceDashboardDto.promiseStatus.value.count === API_CALLS
    );
  }

  // update the normalized items with latest from server
  _updateByStartTime = async startTime => {
    const data = this._resourceDashboardDto.promiseStatus.value;

    const { response } = await this.dependencies.callForResourceDashboardDto({
      resourceId: this.currentResourceId,
      startTime: formatToDateTime(startTime),
    });

    runInAction(() => {
      response.normalizedItems.forEach(item => {
        for (let i = 0; i < data.normalizedItems.length; i++) {
          if (
            data.normalizedItems[i].date === item.date &&
            data.normalizedItems[i].appointment.id === item.appointment.id
          ) {
            data.normalizedItems[i] = item;
          }
        }
      });
    });

    return response;
  };

  _requestWithStartTime = async startTime => {
    const { response } = await this.dependencies.callForResourceDashboardDto({
      resourceId: this.currentResourceId,
      startTime: formatToDateTime(startTime),
    });

    this._merge(response);
  };

  _resourceDashboardDto = withLaziness(async () => {
    const startTime = formatToDateTime(moment());

    const { response } = await this.dependencies.callForResourceDashboardDto({
      resourceId: this.currentResourceId,
      startTime,
    });

    // request for 2 weeks ahead (one week ahead already done) and 2 weeks behind
    this._requestWithStartTime(moment().add(8, 'days'));
    this._requestWithStartTime(moment().subtract(8, 'days'));
    this._requestWithStartTime(moment().subtract(16, 'days'));

    return response;
  });

  _merge = response => {
    const data = this._resourceDashboardDto.promiseStatus.value;

    runInAction(() => {
      data.normalizedItems = [
        ...data.normalizedItems,
        ...response.normalizedItems,
      ];

      data.count = data.count || 1;
      data.count++;
    });
  };

  get data() {
    return this._resourceDashboardDto.promiseStatus.value;
  }

  refresh = async () => {
    await this._resourceDashboardDto.promiseStatus.refresh();
  };
}

const toEffortsWithTotalsFor = appointment => effort => {
  const effortAmountTotal = pipeline(
    effort.actualEffortEntries,
    flatMap('participations'),
    map('effort.amount'),
    reject(isNull),
    sum,
  );

  return {
    appointment,

    effortAmountTotal,

    roundedDurationPerUnit: {
      amount:
        Math.round(effort.effortDefinitionCatalogItem.duration.amount * 100) /
        100,
      measurementUnitId: 'hour',
    },

    roundedTotalDuration: {
      amount:
        Math.round(
          effort.effortDefinitionCatalogItem.duration.amount *
            effortAmountTotal *
            100,
        ) / 100,
      measurementUnitId: 'hour',
    },

    ...effort,
  };
};
