import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';
import pathParametersInputMapper from 'shared-between-front-ends/src/doings/pathParametersInputMapper/pathParametersInputMapper';

export default getAuthenticatedCall({
  apiCallConfig: {
    path:
      '/api/effort-definition-catalog-maintenance/delete-effort-definition-catalog/{catalogId}',

    method: 'POST',

    inputMapper: pathParametersInputMapper,
  },
});
