export default {
  en: {
    placeholder: 'Type here',
  },
  fi: {
    placeholder: 'Kirjoita tähän',
  },
  pl: {
    placeholder: 'Pisz tutaj',
  },
  se: {
    placeholder: 'Skriv här',
  },
};
