export default {
  meter: {
    id: 'meter',
    translations: {
      en: {
        metric: 'm',
      },
      fi: {
        metric: 'm',
      },
      pl: {
        metric: 'm',
      },
      se: {
        metric: 'm',
      },
    },
  },
  kilometer: {
    id: 'kilometer',
    translations: {
      en: {
        metric: 'km',
      },
      fi: {
        metric: 'km',
      },
      pl: {
        metric: 'km',
      },
      se: {
        metric: 'km',
      },
    },
  },
  'square-meter': {
    id: 'squareMeter',
    translations: {
      en: {
        metric: 'm2',
      },
      fi: {
        metric: 'm2',
      },
      pl: {
        metric: 'm2',
      },
      se: {
        metric: 'm2',
      },
    },
  },
  hour: {
    id: 'hour',
    translations: {
      en: {
        metric: 'h',
      },
      fi: {
        metric: 'h',
      },
      pl: {
        metric: 'h',
      },
      se: {
        metric: 'h',
      },
    },
  },
  piece: {
    id: 'piece',
    translations: {
      en: {
        metric: 'pcs',
      },
      fi: {
        metric: 'kpl',
      },
      pl: {
        metric: 'pcs',
      },
      se: {
        metric: 'bit',
      },
    },
  },
};
