import upperFirst from 'lodash/fp/upperFirst';
import PropTypes from 'prop-types';
import React from 'react';

import Element from '../Element/Element';
import styles from './Typography.module.scss';

function Typography({
  paragraph,
  tagName = !!paragraph ? 'p' : 'span',
  font = 'primary',
  size,
  children,
  singleLine,
  uppercase,
  strikeThrough,
  useDefaultBottomMargin,
  margin = {},
  noLineHeight,
  className,
  ...props
}) {
  const classNameForFont = `typography__${font}`;
  const classNameForSize = `typography__size${upperFirst(size)}`;

  const classNames = [
    styles.typography,
    styles[classNameForFont],
    styles[classNameForSize],
    {
      [styles.typography__singleLine]: !!singleLine,
      [styles.typography__uppercase]: !!uppercase,
      [styles.typography__strikeThrough]: !!strikeThrough,
      [styles.typography__noLineHeight]: !!noLineHeight,
      [styles.typography__paragraph]: !!paragraph,
    },
    className,
  ];

  const marginWithDefaultBottom = useDefaultBottomMargin
    ? { ...margin, bottom: defaultBottomMarginBySize[size] }
    : margin;

  return (
    <Element
      tagName={tagName}
      className={classNames}
      margin={marginWithDefaultBottom}
      {...props}
    >
      {children}
    </Element>
  );
}

export const supportedSizes = ['lg', 'md', 'sm', 'xs', 'xxs', '3xs', '4xs'];
export const supportedTagNames = [
  'span',
  'div',
  'p',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'pre',
];

const defaultBottomMarginBySize = {
  lg: 'md',
  md: 'md',
  sm: 'sm',
  xs: 'sm',
  xxs: 'sm',
  '3xs': 'xs',
  '4xs': 'xs',
};

export const commonPropTypesForAllTypographies = {
  tagName: PropTypes.oneOf(supportedTagNames),
  singleLine: PropTypes.bool,
  strikeThrough: PropTypes.bool,
  useDefaultBottomMargin: PropTypes.bool,
};

Typography.propTypes = {
  ...commonPropTypesForAllTypographies,
  font: PropTypes.oneOf(['primary']),
  size: PropTypes.oneOf(supportedSizes).isRequired,
};

export default Typography;
