import isMap from 'lodash/fp/isMap';
import join from 'lodash/fp/join';
import split from 'lodash/fp/split';
import overSome from 'lodash/fp/overSome';
import { isObservableMap } from 'mobx';

const getForMapsAndObjects = (currentValue, keysString) => {
  const [currentKey, ...nextKeys] = split('.', keysString);
  const nextKeysString = join('.', nextKeys);

  const nextValue = getForMapOrObject(currentKey, currentValue);

  if (nextValue === undefined || nextKeys.length === 0) {
    return nextValue;
  }

  return getForMapsAndObjects(nextValue, nextKeysString);
};

export default getForMapsAndObjects;

const isAnyMap = overSome([isMap, isObservableMap]);

const getForMapOrObject = (key, value) =>
  isAnyMap(value) ? value.get(key) : value[key];
