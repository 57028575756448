import { computed } from 'mobx';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import NotificationsModel from 'shared-between-front-ends/src/components/public/Notifications/NotificationsModel';
import TextInputModel from 'shared-between-front-ends/src/components/public/TextInput/TextInputModel';
import whenRouteChangesTo from 'shared-between-front-ends/src/decorators/whenRouteChangesTo/whenRouteChangesTo';
import getModel from 'shared-between-front-ends/src/decorators/withModel/getModel';
import RoutingModel from 'shared-between-front-ends/src/models/RoutingModel/RoutingModel';
import updateDistrictTranslations from './updateDistrictTranslations';

const translate = localTranslate(updateDistrictTranslations);

export default class CreateDistrictModel {
  dependencies = {};

  constructor(
    getEveryInputModelIsValid = require('shared-between-front-ends/src/doings/getEveryInputModelIsValid/getEveryInputModelIsValid')
      .default,
    submitUpdateDistrict = require('./submitUpdateDistrict/submitUpdateDistrict')
      .default,
    routingModel = getModel(RoutingModel),
    notificationsModel = getModel(NotificationsModel),
    getDistrict = require('./getDistrict/getDistrict').default,
  ) {
    this.dependencies.getEveryInputModelIsValid = getEveryInputModelIsValid;
    this.dependencies.submitUpdateDistrict = submitUpdateDistrict;
    this.dependencies.routingModel = routingModel;
    this.dependencies.notificationsModel = notificationsModel;
    this.dependencies.getDistrict = getDistrict;
  }

  get id() {
    return this.dependencies.routingModel.pathParameters.districtId;
  }

  name = new TextInputModel({ required: true });
  nameOfManager = new TextInputModel();

  @whenRouteChangesTo('update-district')
  async loadDistrict() {
    const {
      response: { name, nameOfManager },
    } = await this.dependencies.getDistrict({
      districtId: this.id,
    });

    this.name.setValue(name);
    this.nameOfManager.setValue(nameOfManager);
  }

  submit = async () => {
    await this.dependencies.submitUpdateDistrict({
      id: this.id,
      name: this.name.value,
      nameOfManager: this.nameOfManager.value,
    });

    this.dependencies.routingModel.goBack();

    this.dependencies.notificationsModel.setSuccess(translate('updateSuccess'));
  };

  @computed
  get isValid() {
    return this.dependencies.getEveryInputModelIsValid(this.name);
  }
}
