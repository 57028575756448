import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import range from 'lodash/fp/range';
import moment from 'moment-timezone';
import isOneOf from 'shared-between-everything/src/doings/isOneOf/isOneOf';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';

import schedulerTranslations from '../../../schedulerTranslations';

const translate = localTranslate(schedulerTranslations);

export default getDayIsNationalHoliday => ({
  selectedDateRange: { start, end },
  weekendsAreShown,
}) => {
  const numberOfDays = moment(end).diff(start, 'days') + 1;

  return flow(
    range(0),
    map(toDayOfWeekObjectFor(start, weekendsAreShown, getDayIsNationalHoliday)),
  )(numberOfDays);
};

const toDayOfWeekObjectFor = (
  originDateTime,
  weekendsAreShown,
  getDayIsNationalHoliday,
) => daysFromOrigin => {
  const day = moment(originDateTime).add(daysFromOrigin, 'days').format();

  const dayOfWeekNumber = moment(day).isoWeekday();

  const isWeekend = isOneOf([6, 7], dayOfWeekNumber);

  const date = moment(day).format(moment.HTML5_FMT.DATE);

  const today = moment().format('YYYY-MM-DD');

  const isToday = moment(today).isSame(date);

  const isFirstDayOfWeek = dayOfWeekNumber === 1;

  const isFirstDayOfMonth = moment(day).format('D') === '1';

  const isLastDayOfMonth =
    moment(day).format('D') === moment(day).endOf('month').format('D');

  const weekNumber = moment(day).isoWeek();

  return {
    date,
    isToday,
    isWeekend,
    isFirstDayOfWeek,
    weekNumber,
    compact: isWeekend && !weekendsAreShown,
    dateTimeRange: {
      start: moment(day).startOf('day').format(),
      end: moment(day).endOf('day').format(),
    },
    dayOfWeekString: translate(`shortWeekdays[${dayOfWeekNumber - 1}]`),
    dayOfMonthString: moment(day).format('D'),
    isNationalHoliday: getDayIsNationalHoliday(date),
    isFirstDayOfMonth,
    isLastDayOfMonth,
  };
};
