import { createBrowserHistory } from 'history';
import { syncHistoryWithStore } from 'mobx-react-router';

import autoScrollToTopOnPush from './autoScrollToTopOnPush';

export const dependencies = {
  createBrowserHistory,
  syncHistoryWithStore,
  autoScrollToTopOnPush,
};

export default routerStore => {
  const browserHistory = dependencies.createBrowserHistory();
  const history = dependencies.syncHistoryWithStore(
    browserHistory,
    routerStore,
  );

  history.subscribe(dependencies.autoScrollToTopOnPush);

  return history;
};
