import { action, observable } from 'mobx';

export default class ApplicationModel {
  @observable
  started = false;

  @action.bound
  start = () => {
    this.started = true;
  };
}
