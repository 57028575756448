import React from 'react';

import Td from '../../../private/Element/Td/Td';
import styles from '../Table.module.scss';

function TableCell({
  className,
  padding = { vertical: 'sm', horizontal: 'md' },
  children,
  ...props
}) {
  return (
    <Td padding={padding} className={[styles.table_cell, className]} {...props}>
      {children}
    </Td>
  );
}

export default TableCell;
