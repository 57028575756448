import assignAll from 'lodash/fp/assignAll';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import { observer, Observer } from 'mobx-react';
import React from 'react';
import withExposedConfiguration from 'shared-between-everything/src/test-utils/withExposedConfiguration';

import getModel from './getModel';

export const dependencies = { getModel };

export default withExposedConfiguration((...models) => {
  const decorator = function withModel(ToBeDecorated) {
    const ObserverToBeDecorated = observer(ToBeDecorated);

    const Decorated = ({ ...explicitProps }) => {
      const modelInstancesAndPropConfigurations = map(
        toModelInstanceAndProps,
        models,
      );

      return (
        <Observer>
          {() => {
            const modelProps = getModelProps(
              modelInstancesAndPropConfigurations,
            );

            return <ObserverToBeDecorated {...modelProps} {...explicitProps} />;
          }}
        </Observer>
      );
    };

    Decorated.displayName = `withModel(${
      ToBeDecorated.displayName || ToBeDecorated.name
    })`;

    return Decorated;
  };

  decorator.__decoratorParameters = { models };

  return decorator;
});

const toPropsWithValue = ([model, props]) =>
  flow(
    map(({ name, modelPath }) => ({
      [name]: modelPath ? get(modelPath, model) : model,
    })),
    assignAll,
  )(props);

const toModelInstanceAndProps = ({ Model, props }) => [
  dependencies.getModel(Model),
  props,
];

const getModelProps = flow(map(toPropsWithValue), assignAll);
