import defaultEmptyTo from 'shared-between-everything/src/doings/defaultEmptyTo/defaultEmptyTo';

import InputModelBaseClass from '../../../InputModelBaseClass';
import internationalPrefixValidator from '../../../validators/internationalPrefix/internationalPrefixValidator';
import maxLengthValidator from '../../../validators/maxLength/maxLengthValidator';
import minLengthValidator from '../../../validators/minLength/minLengthValidator';
import sanitizePhoneNumberFormatter from './sanitizePhoneNumberFormatter';

export default class PhoneNumberInputModel extends InputModelBaseClass {
  constructor({ validators = [], ...rest } = {}) {
    super({
      inboundFormatters: [
        defaultEmptyTo(''),
        sanitizePhoneNumberFormatter,
        defaultEmptyTo(null),
      ],
      outboundFormatters: [defaultEmptyTo('')],
      validators: [
        internationalPrefixValidator,
        minLengthValidator(8),
        maxLengthValidator(13),
        ...validators,
      ],
      ...rest,
    });
  }
}
