import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import upperFirst from 'lodash/fp/upperFirst';
import { PropTypes } from 'prop-types';
import React from 'react';

import Span from '../../private/Element/Span/Span';
import { availableIconNames } from './availableIcons';
import registerIcons from './registerIcons';

// Note: Order of imports is important
import '@fortawesome/fontawesome-svg-core/styles.css';
import styles from './Icon.module.scss';

registerIcons();

function Icon({
  name,
  size,
  spinning,
  rotationDegrees,
  alt,
  className,
  compact,
  ...props
}) {
  const classNames = [styles.icon, className];

  return (
    <Span inlineBlock className={classNames} {...props}>
      <FontAwesomeIcon
        className={styles[`icon__size${upperFirst(size)}`]}
        icon={name}
        spin={spinning}
        rotation={rotationDegrees}
        fixedWidth={!compact}
      />

      {alt && <Span visuallyHidden>{alt}</Span>}
    </Span>
  );
}

export const availableSizes = ['xs', 'sm', 'md', 'lg'];

Icon.propTypes = {
  name: PropTypes.oneOf(availableIconNames),
  size: PropTypes.oneOf(availableSizes),
  rotationDegrees: PropTypes.oneOf([90, 180, 270]),
};

export default Icon;
