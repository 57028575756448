import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import getWorkOrderTypeValueObject from 'shared-between-everything/src/getWorkOrderTypeValueObject';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import DateInput from 'shared-between-front-ends/src/components/public/DateInput/DateInput';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Form from 'shared-between-front-ends/src/components/public/Form/Form';
import GoBackButton from 'shared-between-front-ends/src/components/public/GoBackButton/GoBackButton';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import MainHeading from 'shared-between-front-ends/src/components/public/MainHeading/MainHeading';
import NumberInput from 'shared-between-front-ends/src/components/public/NumberInput/NumberInput';
import Page from 'shared-between-front-ends/src/components/public/Page/Page';
import PrimaryButton from 'shared-between-front-ends/src/components/public/PrimaryButton/PrimaryButton';
import RadioButtonGroup from 'shared-between-front-ends/src/components/public/RadioButtonGroup/RadioButtonGroup';
import RouteToggleGroup from 'shared-between-front-ends/src/components/public/RouteToggleGroup/RouteToggleGroup';
import SecondaryButton from 'shared-between-front-ends/src/components/public/SecondaryButton/SecondaryButton';
import Subheading from 'shared-between-front-ends/src/components/public/Subheading/Subheading';
import TextAreaInput from 'shared-between-front-ends/src/components/public/TextAreaInput/TextAreaInput';
import TextInput from 'shared-between-front-ends/src/components/public/TextInput/TextInput';
import TileBag from 'shared-between-front-ends/src/components/public/TileBag/TileBag';
import SpinnerTentative from 'shared-between-front-ends/src/decorators/withSpinnerFor/SpinnerTentative/SpinnerTentative';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import Navigation from '../../Navigation/Navigation';
import WorkOrderColorInput from '../WorkOrderColorInput/WorkOrderColorInput';
import workOrderTranslations from '../workOrderTranslations';

const translate = localTranslate(workOrderTranslations);

const UpdateWorkOrder = ({
  model: {
    customerName,
    name,
    erpId,
    link,
    description,
    address,
    postalCode,
    city,
    deadlineDate,
    billableHours,
    color,
    colorInputIsVisible,
    type,
    department,
    handledByIntegration,
    canBeDeleted,
    deleteWorkOrder,
    submit,
    isValid,
    navigationTabsAreShown,
    tabRoutes,
    dependencies: { routingModel },
  },
}) => {
  return (
    <Page
      data-work-order-details-page-test
      data-update-work-order-page-e2e-test
    >
      <Navigation />

      <Page.Row alignWithPanel margin={{ bottom: 'md' }}>
        <Flex>
          <MainHeading flexItem>
            {translate('workOrder')}

            {erpId && ` - ${erpId}`}
          </MainHeading>

          {canBeDeleted && (
            <Flex centeredVertically>
              <SecondaryButton
                size="sm"
                onClick={deleteWorkOrder}
                data-delete-work-order-test
                data-delete-e2e-test
              >
                {translate('delete')}
              </SecondaryButton>
            </Flex>
          )}
        </Flex>
      </Page.Row>

      <Page.Row shown={navigationTabsAreShown} alignWithPanel>
        <RouteToggleGroup
          routes={tabRoutes}
          routingModel={routingModel}
          inline
        />
      </Page.Row>

      <SpinnerTentative
        spinnerName={['getting-work-order', 'updating-work-order']}
        data-update-work-order-spinner-test
      >
        {handledByIntegration && (
          <Page.Row alignWithPanel>
            <BodyText paragraph>
              {translate('sharedWorkOrderDisclaimer', { department })}
            </BodyText>
          </Page.Row>
        )}

        <Form onSubmit={submit}>
          <Page.Row alignWithPanel>
            {handledByIntegration && (
              <Subheading>{translate('basicInformationIFS')}</Subheading>
            )}

            {!handledByIntegration && (
              <Subheading>{translate('basicInformation')}</Subheading>
            )}
          </Page.Row>

          <Page.Row withPanel>
            <TileBag
              tiles={[
                <TextInput
                  label={translate('customerName')}
                  model={customerName}
                  autoFocus
                  data-work-order-customer-name-input-test
                  data-name-of-customer-e2e-test={customerName.outboundValue}
                />,
                <TextInput
                  label={translate('workOrderName')}
                  model={name}
                  data-work-order-name-input-test
                  data-name-of-work-order-e2e-test={name.outboundValue}
                />,

                <TextInput
                  label={translate('address')}
                  model={address}
                  data-address-input-test
                  data-address-e2e-test={address.outboundValue}
                />,

                <TextInput
                  label={translate('postalCode')}
                  model={postalCode}
                  data-postal-code-input-test
                  data-postal-code-e2e-test={postalCode.outboundValue}
                />,

                <TextInput
                  label={translate('city')}
                  model={city}
                  data-city-input-test
                  data-city-e2e-test={city.outboundValue}
                />,

                <NumberInput
                  label={translate('billableHours')}
                  model={billableHours}
                  data-billable-hours-input-test
                  data-billable-hours-e2e-test={billableHours.outboundValue}
                />,

                <WorkOrderColorInput
                  label={translate('color')}
                  model={color}
                  shown={colorInputIsVisible}
                  data-color-input-test
                  data-color-e2e-test={color.outboundValue}
                />,
              ]}
            />

            {getWorkOrderTypeValueObject(type.value).typeCanBeChanged && (
              <Div>
                <Gutter />

                <RadioButtonGroup
                  label={translate('workOrderType')}
                  model={type}
                  data-type-select-input-test
                />
              </Div>
            )}
          </Page.Row>

          <Page.Row alignWithPanel>
            <Subheading useDefaultBottomMargin={!!handledByIntegration}>
              {translate('otherInformation')}
            </Subheading>

            {handledByIntegration && (
              <BodyText paragraph>
                {translate('sharedOtherInformationDisclaimer')}
              </BodyText>
            )}
          </Page.Row>

          <Page.Row withPanel>
            <DateInput
              label={translate('deadlineDate')}
              model={deadlineDate}
              data-deadline-date-input-test
            />

            <Gutter />

            <TextInput
              label={translate('link')}
              model={link}
              data-link-input-test
            />

            <Gutter />

            <TextAreaInput
              label={translate('description')}
              model={description}
              data-description-input-test
            />
          </Page.Row>

          <Page.Row alignWithPanel margin={false}>
            <Flex spaceBetween>
              <GoBackButton>{translate('backButton')}</GoBackButton>

              <PrimaryButton
                data-submit-button-test
                data-submit-e2e-test
                type="submit"
                enabled={isValid}
              >
                {translate('updateWorkOrderButton')}
              </PrimaryButton>
            </Flex>
          </Page.Row>
        </Form>
      </SpinnerTentative>
    </Page>
  );
};

export default decorate(observer)(UpdateWorkOrder);
