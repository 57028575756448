import React from 'react';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import CheckboxInput from 'shared-between-front-ends/src/components/public/CheckboxInput/CheckboxInput';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import HeavyText from 'shared-between-front-ends/src/components/public/HeavyText/HeavyText';
import HourInput from 'shared-between-front-ends/src/components/public/HourInput/HourInput';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import Link from 'shared-between-front-ends/src/components/public/Link/Link';
import Panel from 'shared-between-front-ends/src/components/public/Panel/Panel';
import SeparatorLine from 'shared-between-front-ends/src/components/public/SeparatorLine/SeparatorLine';
import TextAreaInput from 'shared-between-front-ends/src/components/public/TextAreaInput/TextAreaInput';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import schedulerTranslations from '../../../../../schedulerTranslations';
import IconAndLabel from './IconAndLabel/IconAndLabel';

const translate = localTranslate(schedulerTranslations);

const AppointmentPopoverContent = ({
  appointment: {
    delete: deleteAppointment,
    selectWorkOrder,
    duration,
    backgroundColor,
    workOrderName,
    workOrderErpId,
    info,
    workOrder: { urgent, done, type },
    workOrderDoneStatusCanBeUpdated,
    workOrderUrgencyCanBeUpdated,
    navigateToUpdateWorkOrder,
    workOrderLink,
  },
  userRights,
  ...props
}) => {
  return (
    <Panel width="lg" {...props}>
      <Panel.Content padding={{ size: 'sm' }} backgroundColor={backgroundColor}>
        <Flex centeredVertically spaceBetween color="white">
          <HeavyText flexItem>{workOrderName}</HeavyText>

          <Gutter />

          <BodyText flexItem>{workOrderErpId}</BodyText>
        </Flex>
      </Panel.Content>

      <Panel.Content padding={{ size: 'md' }}>
        <Flex centeredVertically>
          <Div flexItem>
            <Flex centered>
              <BodyText>{translate('setAppointmentDuration')}</BodyText>
            </Flex>

            <Gutter size="sm" />

            <Flex centered>
              <HourInput data-appointment-duration-test model={duration} />
            </Flex>
          </Div>

          <SeparatorLine size="sm" />

          <Div flexItem>
            <Link
              onClick={deleteAppointment}
              defaultColors
              data-delete-appointment-test
              shown={!!userRights.doScheduling}
            >
              <IconAndLabel iconName="calendar-xmark">
                {translate('deleteAppointment')}
              </IconAndLabel>
            </Link>

            <SeparatorLine
              size="xs"
              horizontal
              shown={userRights.doScheduling}
            />

            {(type === 'workOrder' ||
              userRights.createWorkOrdersOfAllTypes) && (
              <Div>
                <Link
                  onClick={navigateToUpdateWorkOrder}
                  defaultColors
                  data-navigate-to-update-work-order-test
                  shown={!!userRights.maintainWorkOrders}
                >
                  <IconAndLabel iconName="rectangle-list">
                    {translate('openWorkOrder')}
                  </IconAndLabel>
                </Link>

                <SeparatorLine
                  size="xs"
                  horizontal
                  shown={
                    userRights.maintainWorkOrders && userRights.doScheduling
                  }
                />
              </Div>
            )}

            <Link
              onClick={selectWorkOrder}
              defaultColors
              data-select-work-order-test
              shown={!!userRights.doScheduling}
            >
              <IconAndLabel iconName="copy">
                {translate('selectWorkOrder')}
              </IconAndLabel>
            </Link>

            {workOrderLink && (
              <Div>
                <SeparatorLine size="xs" horizontal />

                <Link
                  href={workOrderLink}
                  defaultColors
                  openInNewWindow
                  data-work-order-specific-link-test
                >
                  <IconAndLabel iconName="up-right-from-square">
                    {translate('openWorkOrderLink')}
                  </IconAndLabel>
                </Link>
              </Div>
            )}
          </Div>
        </Flex>

        <Div shown={userRights.doScheduling || info.outboundValue}>
          <Gutter />

          <TextAreaInput
            label={translate('info')}
            labelExtra={
              <Icon
                name="file-lines"
                size="sm"
                color="grey90"
                margin={{ left: 'xs' }}
              />
            }
            model={info}
            data-info-test
          />
        </Div>

        <Div
          shown={
            workOrderDoneStatusCanBeUpdated || workOrderUrgencyCanBeUpdated
          }
        >
          <Gutter />

          <Flex>
            <CheckboxInput
              label={translate('done')}
              model={done}
              data-done-test
              shown={workOrderDoneStatusCanBeUpdated}
            />

            <Gutter size="sm" />

            <CheckboxInput
              label={translate('urgent')}
              model={urgent}
              data-urgent-test
              shown={workOrderUrgencyCanBeUpdated}
            />
          </Flex>
        </Div>
      </Panel.Content>
    </Panel>
  );
};

export default AppointmentPopoverContent;
