import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';

import InputWrapper from '../../private/InputWrapper/InputWrapper';
import TextArea from '../../private/TextArea/TextArea';

function TextAreaInput({
  label,
  labelExtra,
  model = {},
  value = model.outboundValue,
  onChange = model.setValue,
  readOnly = model.readOnly,
  errorText = model.validationTranslationKey,
  flexItem,
  ...props
}) {
  return (
    <InputWrapper
      label={label}
      labelExtra={labelExtra}
      errorText={errorText}
      flexItem={flexItem}
    >
      <TextArea
        type="text"
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        {...props}
      />
    </InputWrapper>
  );
}

export const NotObserverTextAreaInput = TextAreaInput;
export default decorate(observer)(TextAreaInput);
