/* istanbul ignore file */

export const seeTeamAbsenceScheduler = 'seeTeamAbsenceScheduler';
export const maintainDistricts = 'maintainDistricts';
export const maintainTeams = 'maintainTeams';
export const removeTeams = 'removeTeams';
export const maintainResources = 'maintainResources';
export const maintainWorkOrders = 'maintainWorkOrders';
export const createWorkOrdersOfAllTypes = 'createWorkOrdersOfAllTypes';
export const scheduleWorkOrdersOfAllTypes = 'scheduleWorkOrdersOfAllTypes';
export const doScheduling = 'doScheduling';
export const seeObfuscatedAppointments = 'seeObfuscatedAppointments';
export const seeDistantAppointments = 'seeDistantAppointments';
export const maintainUsers = 'maintainUsers';
export const reorderResourcesInTeams = 'reorderResourcesInTeams';
export const maintainEffortDefinitionCatalogs =
  'maintainEffortDefinitionCatalogs';
export const maintainWorkOrderExpectedEfforts =
  'maintainWorkOrderExpectedEfforts';
