import React from 'react';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import SemanticButton from 'shared-between-front-ends/src/components/public/SemanticButton/SemanticButton';

const ExpandableContent = ({
  stripeColor = 'primary',
  title,
  rightTitleArea,
  children,
  open,
  toggleOpen,
  clickAreaProps,
  ...props
}) => {
  return (
    <Div {...props} data-value-e2e-test={open ? 'expanded' : 'collapsed'}>
      <Div
        onClick={toggleOpen}
        color="action"
        hoverColor="actionHover"
        clickableAppearance
        data-toggle-content-test
        {...clickAreaProps}
      >
        <Flex stretchToSameHeight backgroundColor="grey3">
          <Div backgroundColor={stripeColor}>&nbsp;</Div>

          <Div flexItem block padding={{ size: 'sm' }}>
            <Flex centeredVertically spaceBetween>
              <BodyText color="fontColor" flexItem>
                {title}
              </BodyText>

              <Gutter />

              {rightTitleArea && rightTitleArea}

              {rightTitleArea && <Gutter />}

              <Div>
                <SemanticButton color="inherit">
                  <Icon name={open ? 'chevron-up' : 'chevron-down'} size="md" />
                </SemanticButton>
              </Div>
            </Flex>
          </Div>
        </Flex>
      </Div>

      <Div hidden={!open}>{children}</Div>
    </Div>
  );
};

export default ExpandableContent;
