export default [
  { value: '#003269', default: true },
  { value: '#7b37b5' },
  { value: '#424297' },
  { value: '#225cca' },
  { value: '#418de0' },
  { value: '#7b7dd5' },
  { value: '#6794b2' },
  { value: '#717296' },
  { value: '#b165a2' },
  { value: '#db4a84' },
  { value: '#b27b61' },
  { value: '#c96b4d' },
  { value: '#985040' },
  { value: '#a72d59' },
  { value: '#642b48' },
  { value: '#4d604a' },
  { value: '#4c8130' },
  { value: '#657704' },
  { value: '#95b237' },
  { value: '#c6991d' },
  { value: '#de7505' },
  { value: '#d13e12' },
  { value: '#901a00' },
  { value: '#045240' },
];
