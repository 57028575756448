import { action, autorun, observable } from 'mobx';
import InputModelBaseClass from '../../../InputModelBaseClass';

export default class RadioButtonGroupModel extends InputModelBaseClass {
  @observable values = [];
  @action
  setValues = values => {
    this.values = values;
  };

  constructor({ values, observeValues, defaultValue = null, ...rest }) {
    super({
      defaultValue,
      ...rest,
    });

    if (values) {
      this.setValues(values);
    }

    if (observeValues) {
      autorun(() => {
        this.setValues(observeValues());
      });
    }
  }
}
