import { flow as mobxFlow, observable } from 'mobx';
import whenRouteChangesTo from 'shared-between-front-ends/src/decorators/whenRouteChangesTo/whenRouteChangesTo';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import deleteBusinessObjectImport from 'shared-between-front-ends/src/doings/deleteBusinessObject/deleteBusinessObject';
import allResourcesTranslations from './allResourcesTranslations';

const translate = localTranslate(allResourcesTranslations);

export default class AllResourcesModel {
  dependencies = {};

  constructor(
    getResources = require('./getResources/getResources').default,
    deleteBusinessObject = deleteBusinessObjectImport,
  ) {
    this.dependencies.getResources = getResources;
    this.dependencies.deleteBusinessObject = deleteBusinessObject;
  }

  @observable resources = [];

  @whenRouteChangesTo('all-resources')
  refresh = mobxFlow(function* () {
    const { response: resources } = yield this.dependencies.getResources();

    const toResourceWithAbilityToDelete = toResourceWithAbilityToDeleteFor({
      deleteBusinessObject: this.dependencies.deleteBusinessObject,
      onDeletionDone: () => this.refresh(),
    });

    this.resources = resources.map(toResourceWithAbilityToDelete);
  });
}

const toResourceWithAbilityToDeleteFor = ({
  deleteBusinessObject,
  onDeletionDone,
}) => ({ id, ...restResource }) => ({
  id,
  ...restResource,
  delete: async () => {
    const { deletionWasDone } = await deleteBusinessObject({
      path: `/api/resources/${id}`,
      spinnerName: 'deleting-resource',
      confirmationMessage: translate('deleteConfirmation'),
      successMessage: translate('deleteSuccess'),
    });

    if (deletionWasDone) {
      onDeletionDone();
    }
  },
});
