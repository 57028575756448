import NotificationsModel from '../../components/public/Notifications/NotificationsModel';
import App from '../App';
import RoutePageModel from '../RoutePageModel/RoutePageModel';
import LoginModel from '../SessionStart/LoginModel';
import ErrorStateModel from '../ErrorTentative/ErrorStateModel';
import getModel from '../../decorators/withModel/getModel';
import ApplicationModel from '../../models/ApplicationModel/ApplicationModel';
import RoutingModel from '../../models/RoutingModel/RoutingModel';
import setupRoutingModel from '../../models/RoutingModel/setupRoutingModel/setupRoutingModel';

import { config as fontAwesomeConfig } from '@fortawesome/fontawesome-svg-core';

export const dependencies = {
  applicationModel: getModel(ApplicationModel),
  loginModel: getModel(LoginModel),
  renderFeature: require('../../doings/renderFeatures/renderFeature').default,
  routePageModel: getModel(RoutePageModel),
  errorStateModel: getModel(ErrorStateModel),
  routingModel: getModel(RoutingModel),
  notificationsModel: getModel(NotificationsModel),
  setupRoutingModel,
  fontAwesomeConfig,
};

export default routes => {
  dependencies.fontAwesomeConfig.autoAddCss = false;

  dependencies.setupRoutingModel({
    routingModel: dependencies.routingModel,
    routes,
  });

  dependencies.applicationModel.start();

  dependencies.renderFeature(App, 'application', {
    history: dependencies.routingModel.history,
    routePageModel: dependencies.routePageModel,
    errorStateModel: dependencies.errorStateModel,
    notificationsModel: dependencies.notificationsModel,
  });
};
