export default {
  en: {
    appTitle: 'Resource management',
    calendar: 'Work calendar',
    resources: 'Resources',
    districts: 'Districts',
    districtReport: 'Reporting',
    logIn: 'Log in',
    logOut: 'Log out',
    routes: {
      districts: 'Districts',
      'all-resources': 'Resources',
      'district-report': 'Reporting',
      'user-right-maintenance': 'Users',
      'effort-definition-catalogs': 'Catalogs',
    },
  },
  fi: {
    appTitle: 'Töiden hallinta',
    calendar: 'Työkalenteri',
    resources: 'Henkilöstö',
    districts: 'Alueet',
    districtReport: 'Raportointi',
    logIn: 'Kirjaudu',
    logOut: 'Kirjaudu ulos',
    routes: {
      districts: 'Alueet',
      'all-resources': 'Henkilöstö',
      'district-report': 'Raportointi',
      'user-right-maintenance': 'Käyttäjät',
      'effort-definition-catalogs': 'Yksikkölistat',
    },
  },
  pl: {
    appTitle: 'Zarządzanie zasobami',
    calendar: 'Kalendarz pracy',
    resources: 'Zasoby',
    districts: 'Działy',
    districtReport: 'Raporty',
    logIn: 'Zaloguj się',
    logOut: 'Wyloguj',
    routes: {
      districts: 'Dział',
      'all-resources': 'Zasoby',
      'district-report': 'Raporty',
      'user-right-maintenance': 'Użytkownicy',
      'effort-definition-catalogs': 'Catalogs',
    },
  },
  se: {
    appTitle: 'Personalplanering',
    calendar: 'Arbetskalender',
    resources: 'Personal',
    districts: 'Distrikt',
    districtReport: 'Rapportering',
    logIn: 'Logga in',
    logOut: 'Logga ut',
    routes: {
      districts: 'Distrikts',
      'all-resources': 'Personal',
      'district-report': 'Rapportering',
      'user-right-maintenance': 'Rättigheter',
      'effort-definition-catalogs': 'Arbetsmoment',
    },
  },
};
