import camelCase from 'lodash/fp/camelCase';
import PropTypes from 'prop-types';
import React from 'react';

import AttentionText from '../../public/AttentionText/AttentionText';
import Flex from '../../public/Flex/Flex';
import Gutter from '../../public/Gutter/Gutter';
import Icon from '../../public/Icon/Icon';
import Element from '../Element/Element';
import styles from './Button.module.scss';

const iconSizeByButtonSize = {
  md: 'sm',
  sm: 'xs',
};

const verticalPaddingSizeByButtonSize = {
  sm: '3xs',
  md: 'xs',
};

const horizontalPaddingSizeByButtonSize = {
  sm: 'sm',
  md: 'lg',
};

const fontSizeByButtonSize = {
  sm: '3xs',
  md: 'xxs',
};

function Button({
  children,
  iconName,
  className,
  variant,
  size = 'md',
  enabled,
  disabled = enabled === undefined ? undefined : !enabled,
  type = 'button',
  ...props
}) {
  const classNames = [
    styles.button,
    styles[`button__${camelCase(variant)}`],
    {
      [styles.button__disabled]: disabled,
    },
    className,
  ];

  return (
    <Element
      tagName="button"
      className={classNames}
      enabled={enabled}
      disabled={disabled}
      type={type}
      clickableAppearance
      border={{
        size: 'md',
        color: 'transparent',
        radius: { size: 'rounded' },
      }}
      inlineBlock
      padding={{
        vertical: verticalPaddingSizeByButtonSize[size],
        horizontal: horizontalPaddingSizeByButtonSize[size],
      }}
      data-indicators-e2e-test={disabled ? 'disabled' : 'enabled'}
      {...props}
    >
      <Flex centered>
        <AttentionText block singleLine size={fontSizeByButtonSize[size]}>
          {children}
        </AttentionText>

        {iconName && <Gutter quarter />}

        {iconName && (
          <Icon
            name={iconName}
            size={iconSizeByButtonSize[size]}
            className={styles.button__icon}
          />
        )}
      </Flex>
    </Element>
  );
}

export const availableSizes = ['sm', 'md'];
export const variants = ['primary', 'secondary', 'additional', 'navigation'];

Button.propTypes = {
  variant: PropTypes.oneOf(variants),
  size: PropTypes.oneOf(availableSizes),
};

export default Button;
