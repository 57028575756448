/* istanbul ignore file */
import { computed, untracked } from 'mobx';
import React from 'react';
import getModelImport from '../../decorators/withModel/getModel';
import RoutingModel from '../../models/RoutingModel/RoutingModel';

export default class RoutePageModel {
  dependencies = {};

  constructor({
    getModel = getModelImport,
    routingModel = getModel(RoutingModel),
  } = {}) {
    this.dependencies.routingModel = routingModel;
    this.dependencies.getModel = getModel;
  }

  @computed
  get page() {
    const { View, ViewModel } = this.dependencies.routingModel.externalRoute;

    if (!View) {
      return null;
    }

    if (!ViewModel) {
      return <View />;
    }

    const viewModel = untracked(() => this.dependencies.getModel(ViewModel));

    return <View model={viewModel} />;
  }
}
