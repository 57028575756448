import React from 'react';

import Button from '../../private/Button/Button';

function SecondaryButton({ children, ...props }) {
  return (
    <Button variant="secondary" {...props}>
      {children}
    </Button>
  );
}

export default SecondaryButton;
