import React from 'react';
import styles from './ConfirmationDialog.module.scss';
import Page from '../../components/public/Page/Page';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import withModel from '../../decorators/withModel/withModel';
import ConfirmationDialogModel from './ConfirmationDialogModel';
import PrimaryButton from '../../components/public/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../components/public/SecondaryButton/SecondaryButton';
import Flex from '../../components/public/Flex/Flex';
import Gutter from '../../components/public/Gutter/Gutter';
import Subheading from '../../components/public/Subheading/Subheading';
import Panel from '../../components/public/Panel/Panel';
import localTranslate from '../../doings/localTranslate/localTranslate';
import confirmationDialogTranslations from './confirmationDialogTranslations';

const translate = localTranslate(confirmationDialogTranslations);

function ConfirmationDialog({ confirmations }) {
  if (confirmations.length === 0) {
    return null;
  }

  const [{ message, accept, reject }] = confirmations;

  return (
    <Page
      className={styles.confirmationDialog}
      backgroundColor="grey80"
      onClick={reject}
      clickableAppearance={false}
      padding={{ vertical: 'md' }}
      data-shroud-test
    >
      <Page.Row occupyVerticalSpace data-confirmation-popup-e2e-test>
        <Flex occupyVerticalSpace centered>
          <Panel>
            <Panel.Content>
              <Subheading useDefaultBottomMargin>{message}</Subheading>

              <Flex spaceBetween>
                <SecondaryButton onClick={reject} data-reject-test>
                  {translate('cancel')}
                </SecondaryButton>

                <Gutter />

                <PrimaryButton
                  autoFocus
                  onClick={accept}
                  data-accept-test
                  data-accept-e2e-test
                >
                  {translate('ok')}
                </PrimaryButton>
              </Flex>
            </Panel.Content>
          </Panel>
        </Flex>
      </Page.Row>
    </Page>
  );
}

export default decorate(
  withModel({
    Model: ConfirmationDialogModel,
    props: [
      {
        name: 'confirmations',
        modelPath: 'confirmations',
      },
    ],
  }),
)(ConfirmationDialog);
