import { action, computed, observable, reaction } from 'mobx';
import moment from 'moment-timezone';
import getRandomIdImport from 'shared-between-everything/src/doings/getRandomId/getRandomId';
import pipeline from 'shared-between-everything/src/doings/pipeline/pipeline';
import { map } from 'shared-between-everything/src/functionalProgramming';
import NotificationsModel from 'shared-between-front-ends/src/components/public/Notifications/NotificationsModel';
import ActivationModel from 'shared-between-front-ends/src/components/public/Popover/ActivationModel/ActivationModel';
import whenRouteChangesTo from 'shared-between-front-ends/src/decorators/whenRouteChangesTo/whenRouteChangesTo';
import getModel from 'shared-between-front-ends/src/decorators/withModel/getModel';
import injectInstance from 'shared-between-front-ends/src/decorators/withModel/injectInstance';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import getForMapsAndObjects from 'shared-between-front-ends/src/doings/nested-map-and-object/getForMapsAndObjects/getForMapsAndObjects';
import setForMapsAndObjects from 'shared-between-front-ends/src/doings/nested-map-and-object/setForMapsAndObjects/setForMapsAndObjects';
import RoutingModel from 'shared-between-front-ends/src/models/RoutingModel/RoutingModel';
import SessionModel from 'shared-between-front-ends/src/models/SessionModel/SessionModel';
import schedulerTranslations from '../../schedulerTranslations';
import getWorkOrdersImport from '../getWorkOrders/getWorkOrders';
import GridCellModel from '../GridCellModel/GridCellModel';
import AppointmentModel from './AppointmentModel/AppointmentModel';
import callForCancelAppointmentImport from './AppointmentModel/callForCancelAppointment/callForCancelAppointment';
import callForModifyAppointmentImport from './AppointmentModel/callForModifyAppointment/callForModifyAppointment';
import callForModifyingDoneStatusOfWorkOrderImport from './callForModifyingDoneStatusOfWorkOrder/callForModifyingDoneStatusOfWorkOrder';
import callForModifyingUrgencyOfWorkOrderImport from './callForModifyingUrgencyOfWorkOrder/callForModifyingUrgencyOfWorkOrder';
import callForReorderingOfResourcesImport from './callForReorderingOfResources/callForReorderingOfResources';
import callForResourcesInTeamImport from './callForResourcesInTeam/callForResourcesInTeam';
import callForSendingOfNotificationEmailsImport from './callForSendingOfNotificationEmails/callForSendingOfNotificationEmails';
import createAppointmentImport from './createAppointment/createAppointment';
import DataPopulationModel from './DataPopulationModel/DataPopulationModel';
import DateScopeModel from './DateScopeModel/DateScopeModel';
import getAppointmentsImport from './getAppointments/getAppointments';
import getDayIsNationalHolidayImport from './getDayIsNationalHoliday/getDayIsNationalHoliday';
import { reorderAbove, reorderBelow } from './reorder/reorder';
import ResourceModel from './ResourceModel/ResourceModel';
import WorkOrderListModel from './WorkOrderListModel/WorkOrderListModel';
import WorkOrderModel from './WorkOrderModel/WorkOrderModel';

const translate = localTranslate(schedulerTranslations);

export default class SchedulerModel {
  dependencies = {};

  constructor({
    activationModel = getModel(ActivationModel),
    callForCancelAppointment = callForCancelAppointmentImport,
    callForModifyAppointment = callForModifyAppointmentImport,
    callForModifyingDoneStatusOfWorkOrder = callForModifyingDoneStatusOfWorkOrderImport,
    callForModifyingUrgencyOfWorkOrder = callForModifyingUrgencyOfWorkOrderImport,
    callForReorderingOfResources = callForReorderingOfResourcesImport,
    callForResourcesInTeam = callForResourcesInTeamImport,
    callForSendingOfNotificationEmails = callForSendingOfNotificationEmailsImport,
    createAppointment = createAppointmentImport,
    getAppointments = getAppointmentsImport,
    getDayIsNationalHoliday = getDayIsNationalHolidayImport,
    getRandomId = getRandomIdImport,
    getWorkOrders = getWorkOrdersImport,
    maximumAmountOfListedWorkOrders = 100,
    notificationsModel = getModel(NotificationsModel),
    routingModel = getModel(RoutingModel),
    sessionModel = getModel(SessionModel),
  } = {}) {
    this.dependencies.activationModel = activationModel;
    this.dependencies.callForCancelAppointment = callForCancelAppointment;
    this.dependencies.callForReorderingOfResources = callForReorderingOfResources;
    this.dependencies.callForModifyAppointment = callForModifyAppointment;
    this.dependencies.callForResourcesInTeam = callForResourcesInTeam;
    this.dependencies.callForSendingOfNotificationEmails = callForSendingOfNotificationEmails;
    this.dependencies.createAppointment = createAppointment;
    this.dependencies.getAppointments = getAppointments;
    this.dependencies.getDayIsNationalHoliday = getDayIsNationalHoliday;
    this.dependencies.getRandomId = getRandomId;
    this.dependencies.getWorkOrders = getWorkOrders;
    this.dependencies.maximumAmountOfListedWorkOrders = maximumAmountOfListedWorkOrders;
    this.dependencies.notificationsModel = notificationsModel;
    this.dependencies.routingModel = routingModel;
    this.dependencies.sessionModel = sessionModel;
    this.dependencies.callForModifyingUrgencyOfWorkOrder = callForModifyingUrgencyOfWorkOrder;
    this.dependencies.callForModifyingDoneStatusOfWorkOrder = callForModifyingDoneStatusOfWorkOrder;

    this.dataPopulationModel = new DataPopulationModel(
      getAppointments,
      callForResourcesInTeam,
      getWorkOrders,
      this.createWorkOrder,
      this.createAppointment,
      this.createResource,
      this.dependencies.sessionModel,
    );

    this.workOrderListModel = new WorkOrderListModel(
      routingModel,
      sessionModel,
      this.workOrdersById,
      this.dependencies.maximumAmountOfListedWorkOrders,
    );

    this.dateScopeModel = new DateScopeModel(
      routingModel,
      getDayIsNationalHoliday,
    );
  }

  @computed
  get showWeekNumberLabels() {
    return this.dateScopeModel.showWeekNumberLabels;
  }

  @computed
  get showDayOfWeekString() {
    return this.dateScopeModel.showDayOfWeekString;
  }

  @computed
  get showDayOfMonthString() {
    return this.dateScopeModel.showDayOfMonthString;
  }

  @computed
  get allowChangingViewToAbsenceScheduler() {
    return !!this.dependencies.sessionModel.userRights.seeTeamAbsenceScheduler;
  }

  changeViewToAbsenceScheduler = () => {
    this.dependencies.routingModel.setRouteTo({
      name: 'district-team-absence-scheduler',
      pathParameters: {
        districtId: this.districtId,
        teamId: this.teamId,
      },
      queryParameters: this.dependencies.routingModel.queryParameters,
    });
  };

  @computed
  get showWeekendAsSelectable() {
    return this.dateScopeModel.showWeekendAsSelectable;
  }

  sendNotificationEmails = async () => {
    const {
      response: outcomeOfSentEmails,
    } = await this.dependencies.callForSendingOfNotificationEmails({
      teamId: this.teamId,
    });

    const numberOfSentEmails = outcomeOfSentEmails.successes.length;

    if (numberOfSentEmails) {
      this.dependencies.notificationsModel.setSuccess(
        translate('notificationEmailsGotSent', {
          numberOfSentEmails,
        }),

        { 'data-number-of-sent-emails-e2e-test': numberOfSentEmails },
      );
    } else {
      this.dependencies.notificationsModel.setSuccess(
        translate('noNotificationEmailsGotSent'),

        { 'data-notification-for-no-sent-emails-e2e-test': true },
      );
    }
  };

  @computed
  get teamId() {
    return this.dependencies.routingModel.pathParameters.teamId;
  }

  @computed
  get weekendsAreShown() {
    return this.dateScopeModel.weekendsAreShown;
  }

  @computed
  get weeksInView() {
    return this.dateScopeModel.weeksInView;
  }

  @computed
  get showNumberOfWeeksOptions() {
    return this.dateScopeModel.showNumberOfWeeksOptions;
  }

  @computed
  get showWeekendsOptions() {
    return this.dateScopeModel.showWeekendsOptions;
  }

  setNumberOfWeeksShown = weeks => {
    this.dateScopeModel.setNumberOfWeeksShown(weeks);
  };

  @computed
  get selectedWorkOrderCategory() {
    return this.workOrderListModel.selectedWorkOrderCategory;
  }

  @computed
  get workOrderListFilterInput() {
    return this.workOrderListModel.filterStringInput;
  }

  @computed
  get listedWorkOrders() {
    return this.workOrderListModel.listedWorkOrders;
  }

  @computed
  get daysInView() {
    return this.dateScopeModel.daysInView;
  }

  showWeekends = show => {
    this.dateScopeModel.showWeekends(show);
  };

  changeListedWorkOrders = type => {
    this.workOrderListModel.changeListedWorkOrders(type);
  };

  @computed
  get daysOfSelectedDateRange() {
    return this.dateScopeModel.daysOfSelectedDateRange;
  }

  @computed
  get monthsOfSelectedDateRange() {
    return this.dateScopeModel.monthsOfSelectedDateRange;
  }

  @computed
  get yearStringOfSelectedDateRange() {
    return this.dateScopeModel.yearStringOfSelectedDateRange;
  }

  @computed
  get selectedDateRange() {
    return this.dateScopeModel.selectedDateRange;
  }

  @computed
  get _resources() {
    return [...this.resourcesById.values()];
  }

  @computed
  get resourceRows() {
    return pipeline(
      this._resources,
      map(resource =>
        observable({
          resourceId: resource.id,
          days: this.daysOfSelectedDateRange.map(({ ...day }) => ({
            ...day,
            resourceId: resource.id,
            resourceIdAndDate: `${resource.id}/${day.date}`,
            gridCell: this.createGridCell({ resource, day }),
          })),

          dropAbove: this._dropFor(reorderAbove),
          dropBelow: this._dropFor(reorderBelow),

          orientationOfStripe: null,

          setOrientationOfStripe: action(function (dragOrientation) {
            this.orientationOfStripe = dragOrientation;
          }),
        }),
      ),
    );
  }

  _dropFor = reorder => async ({
    resourceIdToBeMoved,
    resourceIdForReference,
  }) => {
    const resourceIdsInOrder = pipeline(
      this._resources,
      map('id'),
      reorder(resourceIdForReference, resourceIdToBeMoved),
    );

    await this.dependencies.callForReorderingOfResources({
      teamId: this.teamId,
      resourceIdsInOrder: resourceIdsInOrder,
    });

    this.refresh({
      teamId: this.teamId,
    });
  };

  goToThisWeek = () => {
    this.dateScopeModel.goToThisWeek();
  };

  goToNextWeek = () => {
    this.dateScopeModel.goForwardWeeks(1);
  };

  goToPreviousWeek = () => {
    this.dateScopeModel.goForwardWeeks(-1);
  };

  @observable selectedWorkOrderId = null;

  @action
  setSelectedWorkOrderId = workOrderId => {
    this.selectedWorkOrderId = workOrderId;
  };

  @action
  clearWorkOrderSelection = () => {
    this.selectedWorkOrderId = null;
    this.selectedHours = null;
  };

  @observable selectedHours = null;

  @action
  setSelectedHours = hours => {
    this.selectedHours = hours;
  };

  @computed
  get buttonForClearingWorkOrderSelectionIsEnabled() {
    return !!this.selectedWorkOrderId;
  }

  @whenRouteChangesTo('district-team-scheduler')
  refresh = () => {
    this.dataPopulationModel.refresh({
      teamId: this.teamId,
    });
  };

  createWorkOrder = ({ workOrderData }) =>
    new WorkOrderModel({
      workOrderData,
      schedulerModel: this,
      setSelectedWorkOrderId: this.setSelectedWorkOrderId,
      callForModifyingUrgencyOfWorkOrder: this.dependencies
        .callForModifyingUrgencyOfWorkOrder,
      callForModifyingDoneStatusOfWorkOrder: this.dependencies
        .callForModifyingDoneStatusOfWorkOrder,
    });

  createGridCell = ({ resource, day }) =>
    new GridCellModel(
      this.scheduleWorkOrderToGridCell,
      this,
      resource,
      day,
      this.gridData,
      this.appointmentsById,
    );

  createAppointment = appointmentData => {
    const appointmentModel = injectInstance(
      AppointmentModel,
      this.dependencies.sessionModel.userRights,
      this.dependencies.createAppointment,
      this.dependencies.callForModifyAppointment,
      this.workOrdersById,
      this,
      this.dependencies.activationModel,
      this.dependencies.routingModel,
      this.dependencies.callForCancelAppointment,
      appointmentData,
    );

    const startReactiveIndexing = startReactiveIndexingFor(
      this.gridData,
      this.appointmentsById,
    );

    startReactiveIndexing(appointmentModel);

    return appointmentModel;
  };

  createResource = ({ resourceData }) => new ResourceModel(resourceData);

  @computed
  get districtId() {
    return this.dependencies.routingModel.pathParameters.districtId;
  }

  @computed
  get userRights() {
    return this.dependencies.sessionModel.userRights;
  }

  @action
  scheduleWorkOrderToGridCell = ({
    workOrderId,
    resourceId,
    date,
    durationHours,
  }) => {
    durationHours = durationHours || 8; // techniquely there shouldn't be zero hours
    const startHour = 8;
    const endHour = startHour + Math.floor(durationHours); // duration hours can be 7.5
    const endMinute = 60 * (durationHours % 1);
    const appointmentData = {
      id: this.dependencies.getRandomId(),
      resourceId,
      teamId: this.teamId,
      workOrderId,
      info: '',
      startDateTime: moment(date)
        .hour(startHour)
        .minute(0)
        .second(0)
        .utc()
        .format(),
      endDateTime: moment(date)
        .hour(endHour)
        .minute(endMinute)
        .second(0)
        .utc()
        .format(),
    };

    const appointment = this.createAppointment(appointmentData);

    appointment.createInBackend();
  };

  @computed get resourcesById() {
    return this.dataPopulationModel.resourcesById;
  }

  @computed get workOrdersById() {
    return this.dataPopulationModel.workOrdersById;
  }

  @computed get appointmentsById() {
    return this.dataPopulationModel.appointmentsById;
  }

  @observable
  gridData = new Map();
}

const startReactiveIndexingFor = (
  gridData,
  appointmentsById,
) => appointmentModel => {
  let oldResourceId;
  let oldDate;

  reaction(
    () => ({
      newResourceId: appointmentModel.resourceId,
      newDate: appointmentModel.date,
    }),
    ({ newResourceId, newDate }) => {
      const appointmentIsBeingDeleted = !newResourceId || !newDate;
      const appointmentIsBeingRelocated = oldResourceId && oldDate;

      if (appointmentIsBeingRelocated || appointmentIsBeingDeleted) {
        getForMapsAndObjects(
          gridData,
          `resources.${oldResourceId}.days.${oldDate}.appointments`,
        ).delete(appointmentModel.id);
      }

      if (appointmentIsBeingDeleted) {
        appointmentsById.delete(appointmentModel.id, appointmentModel);
      } else {
        setForMapsAndObjects(
          gridData,
          `resources.${newResourceId}.days.${newDate}.appointments.${appointmentModel.id}`,
          appointmentModel,
        );

        appointmentsById.set(appointmentModel.id, appointmentModel);
      }

      oldResourceId = newResourceId;
      oldDate = newDate;
    },
    { fireImmediately: true },
  );
};
