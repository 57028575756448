import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import withModel from '../../../../decorators/withModel/withModel';

import ActivationModel from '../ActivationModel/ActivationModel';

const Activatable = ({ activationId, isActivated, children }) =>
  children({ activationStatus: isActivated(activationId) });

export default decorate(
  withModel({
    Model: ActivationModel,
    props: [
      {
        name: 'isActivated',
        modelPath: 'isActivated',
      },
    ],
  }),
)(Activatable);
