import getClassNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Element from '../../../private/Element/Element';

import styles from '../Table.module.scss';

function TableRow({ className, children, ...props }) {
  const classNames = getClassNames(styles.table_row, className);

  return (
    <Element tagName="tr" className={classNames} {...props}>
      {children}
    </Element>
  );
}

export default decorate(observer)(TableRow);
