import add from 'lodash/fp/add';
import assign from 'lodash/fp/assign';
import assignAll from 'lodash/fp/assignAll';
import camelCase from 'lodash/fp/camelCase';
import castArray from 'lodash/fp/castArray';
import chunk from 'lodash/fp/chunk';
import compact from 'lodash/fp/compact';
import cond from 'lodash/fp/cond';
import conforms from 'lodash/fp/conforms';
import constant from 'lodash/fp/constant';
import countBy from 'lodash/fp/countBy';
import curry from 'lodash/fp/curry';
import concat from 'lodash/fp/concat';
import defaultTo from 'lodash/fp/defaultTo';
import defaults from 'lodash/fp/defaults';
import difference from 'lodash/fp/difference';
import dropRight from 'lodash/fp/dropRight';
import dropRightWhile from 'lodash/fp/dropRightWhile';
import endsWith from 'lodash/fp/endsWith';
import entries from 'lodash/fp/entries';
import every from 'lodash/fp/every';
import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import findIndex from 'lodash/fp/findIndex';
import first from 'lodash/fp/first';
import flatMap from 'lodash/fp/flatMap';
import flatten from 'lodash/fp/flatten';
import flip from 'lodash/fp/flip';
import floor from 'lodash/fp/floor';
import forEach from 'lodash/fp/forEach';
import fromPairs from 'lodash/fp/fromPairs';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import groupBy from 'lodash/fp/groupBy';
import gt from 'lodash/fp/gt';
import has from 'lodash/fp/has';
import head from 'lodash/fp/head';
import identity from 'lodash/fp/identity';
import includes from 'lodash/fp/includes';
import intersection from 'lodash/fp/intersection';
import invoke from 'lodash/fp/invoke';
import isArray from 'lodash/fp/isArray';
import isBoolean from 'lodash/fp/isBoolean';
import isEmpty from 'lodash/fp/isEmpty';
import isEqual from 'lodash/fp/isEqual';
import isFunction from 'lodash/fp/isFunction';
import isNull from 'lodash/fp/isNull';
import isNumber from 'lodash/fp/isNumber';
import isObject from 'lodash/fp/isObject';
import isPlainObject from 'lodash/fp/isPlainObject';
import isString from 'lodash/fp/isString';
import isSymbol from 'lodash/fp/isSymbol';
import isUndefined from 'lodash/fp/isUndefined';
import join from 'lodash/fp/join';
import kebabCase from 'lodash/fp/kebabCase';
import keys from 'lodash/fp/keys';
import last from 'lodash/fp/last';
import lowerCase from 'lodash/fp/lowerCase';
import map from 'lodash/fp/map';
import mapKeys from 'lodash/fp/mapKeys';
import mapValues from 'lodash/fp/mapValues';
import matches from 'lodash/fp/matches';
import max from 'lodash/fp/max';
import maxBy from 'lodash/fp/maxBy';
import min from 'lodash/fp/min';
import negate from 'lodash/fp/negate';
import noop from 'lodash/fp/noop';
import nth from 'lodash/fp/nth';
import omit from 'lodash/fp/omit';
import once from 'lodash/fp/once';
import orderBy from 'lodash/fp/orderBy';
import over from 'lodash/fp/over';
import overArgs from 'lodash/fp/overArgs';
import overEvery from 'lodash/fp/overEvery';
import overSome from 'lodash/fp/overSome';
import partition from 'lodash/fp/partition';
import pick from 'lodash/fp/pick';
import pickBy from 'lodash/fp/pickBy';
import random from 'lodash/fp/random';
import range from 'lodash/fp/range';
import rearg from 'lodash/fp/rearg';
import reduce from 'lodash/fp/reduce';
import reject from 'lodash/fp/reject';
import replace from 'lodash/fp/replace';
import rest from 'lodash/fp/rest';
import reverse from 'lodash/fp/reverse';
import sample from 'lodash/fp/sample';
import set from 'lodash/fp/set';
import size from 'lodash/fp/size';
import snakeCase from 'lodash/fp/snakeCase';
import some from 'lodash/fp/some';
import sortBy from 'lodash/fp/sortBy';
import split from 'lodash/fp/split';
import spread from 'lodash/fp/spread';
import startsWith from 'lodash/fp/startsWith';
import stubTrue from 'lodash/fp/stubTrue';
import sum from 'lodash/fp/sum';
import sumBy from 'lodash/fp/sumBy';
import tail from 'lodash/fp/tail';
import take from 'lodash/fp/take';
import takeWhile from 'lodash/fp/takeWhile';
import tap from 'lodash/fp/tap';
import times from 'lodash/fp/times';
import toLower from 'lodash/fp/toLower';
import toNumber from 'lodash/fp/toNumber';
import toPairs from 'lodash/fp/toPairs';
import toString from 'lodash/fp/toString';
import toUpper from 'lodash/fp/toUpper';
import trim from 'lodash/fp/trim';
import unionBy from 'lodash/fp/unionBy';
import uniq from 'lodash/fp/uniq';
import uniqBy from 'lodash/fp/uniqBy';
import upperFirst from 'lodash/fp/upperFirst';
import values from 'lodash/fp/values';

import dropRightUntil from './doings/dropRightUntil/dropRightUntil';
import flow, { pipelineBreak } from './doings/flow/flow';
import isOneOf from './doings/isOneOf/isOneOf';
import memoize from './doings/memoize/memoize';
import pipeline from './doings/pipeline/pipeline';
import mapValuesDeep from './doings/mapValuesDeep/mapValuesDeep';
import mergeAll from './doings/mergeAll/mergeAll';

const none = curry((x, y) => !some(x, y));
const upperSnakeCase = flow(snakeCase, toUpper);
const pipelineRight = flip(pipeline);
const flowRight = flip(flow);

const getFrom = get.convert({ rearg: false });
const isPrimitive = negate(overSome([isPlainObject, isArray]));
const nonCappedMap = map.convert({ cap: false });
const nonCappedForEach = forEach.convert({ cap: false });
const not = negate;

const awaitAll = Promise.all.bind(Promise);

export {
  add,
  assign,
  assignAll,
  awaitAll,
  camelCase,
  castArray,
  chunk,
  compact,
  cond,
  conforms,
  constant,
  countBy,
  curry,
  concat,
  defaultTo,
  defaults,
  difference,
  dropRight,
  dropRightUntil,
  dropRightWhile,
  endsWith,
  entries,
  every,
  filter,
  find,
  findIndex,
  first,
  flatMap,
  flatten,
  flip,
  floor,
  flow,
  flowRight,
  forEach,
  fromPairs,
  get,
  getFrom,
  getOr,
  groupBy,
  gt,
  has,
  head,
  identity,
  includes,
  intersection,
  invoke,
  isArray,
  isBoolean,
  isEmpty,
  isEqual,
  isFunction,
  isNull,
  isNumber,
  isObject,
  isOneOf,
  isPlainObject,
  isPrimitive,
  isString,
  isSymbol,
  isUndefined,
  join,
  kebabCase,
  keys,
  last,
  lowerCase,
  map,
  mapKeys,
  mapValues,
  mapValuesDeep,
  matches,
  max,
  maxBy,
  memoize,
  mergeAll,
  min,
  negate,
  nonCappedForEach,
  nonCappedMap,
  none,
  noop,
  not,
  nth,
  omit,
  once,
  orderBy,
  over,
  overArgs,
  overEvery,
  overSome,
  partition,
  pick,
  pickBy,
  pipeline,
  pipelineBreak,
  pipelineRight,
  random,
  range,
  rearg,
  reduce,
  reject,
  replace,
  rest,
  reverse,
  sample,
  set,
  size,
  snakeCase,
  some,
  sortBy,
  split,
  spread,
  startsWith,
  stubTrue,
  sum,
  sumBy,
  tail,
  take,
  takeWhile,
  tap,
  times,
  toLower,
  toNumber,
  toPairs,
  toString,
  toUpper,
  trim,
  unionBy,
  uniq,
  uniqBy,
  upperFirst,
  upperSnakeCase,
  values,
};
