import withExposedConfiguration from 'shared-between-everything/src/test-utils/withExposedConfiguration';
import getModel from '../withModel/getModel';
import SpinningModel from './SpinnerTentative/SpinningModel';

export const dependencies = {
  spinningModel: getModel(SpinningModel),
};

export default withExposedConfiguration(
  (spinnerName, { showInstantly = true } = {}) =>
    withSpinnerForSpinnerName(spinnerName, showInstantly),
);

const withSpinnerForSpinnerName = (spinnerName, showInstantly) =>
  function withSpinnerFor(toBeDecorated) {
    return async (...parameters) =>
      showInstantly
        ? showSpinnerInstantly(toBeDecorated, parameters, spinnerName)
        : showSpinnerAfterWhile(toBeDecorated, parameters, spinnerName);
  };

const showSpinnerInstantly = async (toBeDecorated, parameters, spinnerName) => {
  dependencies.spinningModel.setNamedSpinnerToSpin(spinnerName);

  try {
    return await toBeDecorated(...parameters);
  } finally {
    dependencies.spinningModel.setNamedSpinnerToNotSpin(spinnerName);
  }
};

const showSpinnerAfterWhile = async (
  toBeDecorated,
  parameters,
  spinnerName,
) => {
  let callTookLongEnoughToShowSpinner = false;

  const timeoutId = setTimeout(() => {
    callTookLongEnoughToShowSpinner = true;
    dependencies.spinningModel.setNamedSpinnerToSpin(spinnerName);
  }, 500);

  try {
    return await toBeDecorated(...parameters);
  } finally {
    clearTimeout(timeoutId);

    if (callTookLongEnoughToShowSpinner) {
      dependencies.spinningModel.setNamedSpinnerToNotSpin(spinnerName);
    }
  }
};
