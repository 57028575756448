import compact from 'lodash/fp/compact';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import join from 'lodash/fp/join';
import map from 'lodash/fp/map';

export default error =>
  get('message', error) ||
  get('operationError.message', error) ||
  flow(getOr([], 'operationError'), map('code'), compact, join(', '))(error) ||
  (typeof error === 'string' ? error : 'Unknown error');
