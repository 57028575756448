import { observer } from 'mobx-react';
import React from 'react';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import EffortAmountAndDuration from '../EffortAmountAndDuration/EffortAmountAndDuration';

const EffortHeader = ({
  model: {
    effortDefinitionCatalogItem,
    expectedEffortDefinition,
    expectedEffortEntryIsExpanded,
    effortAmount,
    roundedTotalDuration,
  },
}) => (
  <Flex centeredVertically>
    <Icon
      compact
      name={
        expectedEffortEntryIsExpanded.internalValue
          ? 'chevron-up'
          : 'chevron-down'
      }
      size="md"
    />

    <Gutter size="sm" />

    <BodyText flexItem forceWrap>
      {effortDefinitionCatalogItem.name}
    </BodyText>

    <Gutter size="sm" />

    <EffortAmountAndDuration
      duration={roundedTotalDuration}
      effort={{
        amount: effortAmount,
        measurementUnitId: expectedEffortDefinition.effort.measurementUnitId,
      }}
    />
  </Flex>
);

export default observer(EffortHeader);
