import React from 'react';
import Div from '../../../private/Element/Div/Div';

const PanelHeader = ({ children, ...props }) => (
  <Div
    color="white"
    backgroundColor="modalHeader"
    padding={{ horizontal: 'md', vertical: 'sm' }}
    {...props}
  >
    {children}
  </Div>
);

export default PanelHeader;
