import { computed } from 'mobx';
import { RouterStore } from 'mobx-react-router';

import getModel from '../../../decorators/withModel/getModel';

import syncWithBrowserHistoryImport from './syncWithBrowserHistory';

export default class RouterStoreModel {
  dependencies = {};

  constructor(
    routerStore = getModel(RouterStore),
    syncWithBrowserHistory = syncWithBrowserHistoryImport,
  ) {
    this.dependencies.routerStore = routerStore;
    this.dependencies.syncWithBrowserHistory = syncWithBrowserHistory;

    syncWithBrowserHistory(routerStore);
  }

  @computed get history() {
    return this.dependencies.routerStore.history;
  }

  @computed get push() {
    return this.dependencies.routerStore.push;
  }

  @computed get replace() {
    return this.dependencies.routerStore.replace;
  }

  @computed get go() {
    return this.dependencies.routerStore.go;
  }

  @computed get goBack() {
    return this.dependencies.routerStore.goBack;
  }

  @computed get goForward() {
    return this.dependencies.routerStore.goForward;
  }

  @computed get location() {
    return this.dependencies.routerStore.location;
  }

  get path() {
    return this.location.pathname + this.location.search;
  }
}
