import React from 'react';
import { noop } from 'shared-between-everything/src/functionalProgramming';
import MainHeading from '../../components/public/MainHeading/MainHeading';
import Page from '../../components/public/Page/Page';
import PrimaryButton from '../../components/public/PrimaryButton/PrimaryButton';
import localTranslate from '../../doings/localTranslate/localTranslate';
import userRightRequiredTranslations from './userRightRequiredTranslations';

const translate = localTranslate(userRightRequiredTranslations);

const UserRightRequired = ({ getNavigation = noop, model, ...props }) => (
  <Page data-user-right-required-test {...props}>
    {getNavigation()}

    <Page.Row alignWithPanel>
      <MainHeading>
        {translate('userRightRequired', {
          missingUserRight: model.missingUserRight,
        })}
      </MainHeading>
    </Page.Row>

    <Page.Row alignWithPanel margin={{ bottom: 'zero' }}>
      <PrimaryButton
        onClick={model.navigateToFrontPage}
        data-go-to-front-page-test
      >
        {translate('goToFrontPage')}
      </PrimaryButton>
    </Page.Row>
  </Page>
);

export default UserRightRequired;
