import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import { baseline } from 'shared-between-front-ends/src/components/public/constants';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import Link from 'shared-between-front-ends/src/components/public/Link/Link';
import Panel from 'shared-between-front-ends/src/components/public/Panel/Panel';
import PrimaryButton from 'shared-between-front-ends/src/components/public/PrimaryButton/PrimaryButton';
import RadioButton from 'shared-between-front-ends/src/components/public/RadioButton/RadioButton';
import RouteLink from 'shared-between-front-ends/src/components/public/RouteLink/RouteLink';
import Subheading from 'shared-between-front-ends/src/components/public/Subheading/Subheading';
import Table from 'shared-between-front-ends/src/components/public/Table/Table';
import SpinnerTentative from 'shared-between-front-ends/src/decorators/withSpinnerFor/SpinnerTentative/SpinnerTentative';

import teamTranslations from '../teamTranslations';

const translate = localTranslate(teamTranslations);

function TeamSelectInput({
  model: { clear, teams = [], value: selectedTeam } = {},
  districtId,
}) {
  return (
    <SpinnerTentative
      spinnerName={['loading-teams', 'deleting-team']}
      data-team-selecter-test
    >
      <Panel transparent>
        <Panel.Content>
          <Subheading useDefaultBottomMargin>{translate('team')}</Subheading>
        </Panel.Content>
      </Panel>

      <Panel>
        <Table
          striped
          cols={[
            {
              content: (
                <Table.HeadingCell>
                  <RadioButton
                    checked={false}
                    onChange={clear}
                    data-unselect-team-test
                  />
                </Table.HeadingCell>
              ),
              width: 5 * baseline,
            },
            {
              content: (
                <Table.HeadingCell>{translate('teamName')}</Table.HeadingCell>
              ),
            },
            {
              content: (
                <Table.HeadingCell alignTextTo="right">
                  {districtId && (
                    <RouteLink
                      routeName="district-create-team"
                      pathParameters={{ districtId }}
                      data-create-team-test
                    >
                      <PrimaryButton data-create-team-button-e2e-test>
                        {translate('createTeamButton')}
                      </PrimaryButton>
                    </RouteLink>
                  )}
                </Table.HeadingCell>
              ),
            },
          ]}
          rows={teams}
        >
          {({ name, id, select, update, delete: doDelete }) => (
            <Table.Row data-team-roster-row-e2e-test={name}>
              <Table.Cell>
                <RadioButton
                  checked={!!selectedTeam && id === selectedTeam.id}
                  onChange={select}
                  data-select-team-test
                />
              </Table.Cell>

              <Table.Cell>{name}</Table.Cell>

              <Table.Cell alignTextTo="right">
                <Link onClick={update} data-update-team-test>
                  <Icon size="md" name="pen" color="secondary" />
                </Link>

                <Gutter inlineBlock />

                <Link
                  onClick={doDelete}
                  data-delete-team-test
                  data-delete-roster-row-e2e-test={name}
                >
                  <Icon size="md" name="circle-minus" color="danger" />
                </Link>
              </Table.Cell>
            </Table.Row>
          )}
        </Table>
      </Panel>
    </SpinnerTentative>
  );
}

export default decorate(observer)(TeamSelectInput);
