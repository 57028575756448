import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import AttentionText from 'shared-between-front-ends/src/components/public/AttentionText/AttentionText';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import Picture from 'shared-between-front-ends/src/components/public/Picture/Picture';
import Popover from 'shared-between-front-ends/src/components/public/Popover/Popover';
import RouteLink from 'shared-between-front-ends/src/components/public/RouteLink/RouteLink';
import districtsTranslations from '../../districtsTranslations';
import DropdownList from 'shared-between-front-ends/src/components/public/DropdownList/DropdownList';
import { NameAndValue } from '../../NameAndValue/NameAndValue';
import EllipsisLink from './EllipsisLink/EllipsisLink';
import styles from './TeamCard.module.scss';

import teamPictureHref from './teamPicture.jpg';

const translate = localTranslate(districtsTranslations);

const TeamCard = ({
  team: {
    id: teamId,
    districtId,
    name,
    nameOfManager,
    formattedCostCentres,
    formattedDepartments,
    formattedNamesOfForemen,
    actionMenuIsVisible,
    actionMenuItems,
  },
}) => {
  return (
    <Div className={[styles.teamCard]} relative>
      {actionMenuIsVisible && (
        <Popover
          activationId={`team-popover-for-${teamId}`}
          activateOn="click"
          content={
            <TeamActionsMenu items={actionMenuItems} data-team-actions-test />
          }
          data-team-card-popover-test={teamId}
          data-district-maintenance-test
          absolutePosition="my top right to top right of parent"
          margin={{
            right: 'xs',
            top: 'xs',
          }}
          bringForward
          defaultColors
        >
          <EllipsisLink />
        </Popover>
      )}

      <RouteLink
        routeName="district-team-scheduler"
        pathParameters={{ districtId, teamId }}
        className={styles.teamCard_link}
        hoverColor="fontColor"
        backgroundColor="white"
        backgroundHoverColor="lightGrey"
        border={{ radius: { size: 'lg' } }}
        block
        hideOverflow
        data-open-scheduler-test
        data-open-scheduler-e2e-test={teamId}
      >
        <Picture src={teamPictureHref} />

        <Div padding={{ size: 'sm' }}>
          <AttentionText color="action" singleLine>
            {name}
          </AttentionText>

          <Gutter size="sm" />

          <NameAndValue
            name={translate('costCentres')}
            value={formattedCostCentres}
          />

          <Gutter size="sm" />

          <NameAndValue
            name={translate('departments')}
            value={formattedDepartments}
          />

          <Gutter size="sm" />

          <NameAndValue
            name={translate('foremen')}
            value={formattedNamesOfForemen}
          />

          <Gutter size="sm" />

          <NameAndValue name={translate('teamManager')} value={nameOfManager} />
        </Div>
      </RouteLink>
    </Div>
  );
};

const TeamActionsMenu = ({ actionMenuItems, ...props }) => {
  return (
    <DropdownList items={actionMenuItems} {...props}>
      {({ action, translationKey }) => (
        <DropdownList.Link onClick={action} data-teams-action-menu-item-test>
          {translate(translationKey)}
        </DropdownList.Link>
      )}
    </DropdownList>
  );
};

export default decorate(observer)(TeamCard);
