import { observable, reaction, runInAction } from 'mobx';
import formatToLocaleDateTime from 'shared-between-everything/src/date-time-abstractions/formatToLocaleDateTime/formatToLocaleDateTime';
import {
  map,
  pipeline,
  replace,
  sortBy,
  toNumber,
} from 'shared-between-everything/src/functionalProgramming';
import ConfirmationDialogModel from 'shared-between-front-ends/src/App/ConfirmationDialog/ConfirmationDialogModel';
import NotificationsModel from 'shared-between-front-ends/src/components/public/Notifications/NotificationsModel';
import ActivationModel from 'shared-between-front-ends/src/components/public/Popover/ActivationModel/ActivationModel';
import fileExtensionValidator from 'shared-between-front-ends/src/components/public/SelectFile/fileExtensionValidator/fileExtensionValidator';
import FileInputModel from 'shared-between-front-ends/src/components/public/SelectFile/FileInputModel';
import TextInputModel from 'shared-between-front-ends/src/components/public/TextInput/TextInputModel';
import getModel from 'shared-between-front-ends/src/decorators/withModel/getModel';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import RoutingModel from 'shared-between-front-ends/src/models/RoutingModel/RoutingModel';
import effortDefinitionCatalogsTranslations from '../effortDefinitionCatalogsTranslations';
import getRowsFromCsvImport from '../getRowsFromCsv/getRowsFromCsv';
import callForCreateEffortDefinitionCatalogImport from './callForCreateEffortDefinitionCatalog/callForCreateEffortDefinitionCatalog';
import callForDeleteCatalogImport from './callForDeleteCatalog/callForDeleteCatalog';
import callForEffortDefinitionCatalogsImport from './callForEffortDefinitionCatalogs/callForEffortDefinitionCatalogs';

const translate = localTranslate(effortDefinitionCatalogsTranslations);

export default class EffortDefinitionCatalogsModel {
  dependencies = {};

  constructor({
    routingModel = getModel(RoutingModel),
    activationModel = getModel(ActivationModel),
    getRowsFromCsv = getRowsFromCsvImport,
    callForCreateEffortDefinitionCatalog = callForCreateEffortDefinitionCatalogImport,
    callForEffortDefinitionCatalogs = callForEffortDefinitionCatalogsImport,

    confirmationDialogModel = getModel(ConfirmationDialogModel),
    notificationsModel = getModel(NotificationsModel),
    callForDeleteCatalog = callForDeleteCatalogImport,
  } = {}) {
    this.dependencies.routingModel = routingModel;
    this.dependencies.activationModel = activationModel;
    this.dependencies.getRowsFromCsv = getRowsFromCsv;
    this.dependencies.callForEffortDefinitionCatalogs = callForEffortDefinitionCatalogs;
    this.dependencies.callForCreateEffortDefinitionCatalog = callForCreateEffortDefinitionCatalog;

    this.dependencies.confirmationDialogModel = confirmationDialogModel;
    this.dependencies.notificationsModel = notificationsModel;
    this.dependencies.callForDeleteCatalog = callForDeleteCatalog;

    reaction(
      () => routingModel.routeName === 'effort-definition-catalogs',
      routeIsRelevant => routeIsRelevant && this.refresh(),
      { fireImmediately: true },
    );
  }

  catalogCsv = new FileInputModel({
    validators: [fileExtensionValidator({ allowedFileExtensions: ['csv'] })],
    required: true,
  });

  description = new TextInputModel();

  get catalogCanBeSubmitted() {
    return this.catalogCsv.isValid && this.description.isValid;
  }

  openPopoverForAddingCatalog = () => {
    this.dependencies.activationModel.activate(
      'add-effort-definition-catalog',
      { showOverlay: true },
    );
  };

  closePopoverForAddingCatalog = () => {
    this.dependencies.activationModel.deactivate(
      'add-effort-definition-catalog',
    );
  };

  @observable catalogs;

  refresh = async () => {
    const {
      response: catalogs,
    } = await this.dependencies.callForEffortDefinitionCatalogs();

    runInAction(() => {
      this.catalogs = pipeline(
        catalogs,
        sortBy('name'),
        map(({ id, createdDateTime, description, name }) => ({
          id,
          name,
          description,
          createdAt: formatToLocaleDateTime(createdDateTime),
          delete: this._deleteFor(id),
        })),
      );
    });
  };

  _deleteFor = catalogId => async () => {
    const deleteIsConfirmed = await this.dependencies.confirmationDialogModel.getConfirmation(
      translate('confirmDelete'),
    );

    if (!deleteIsConfirmed) {
      return;
    }

    await this.dependencies.callForDeleteCatalog({ catalogId });

    this.dependencies.notificationsModel.setSuccess(
      translate('deleteSuccess'),

      {
        'data-notification-for-deleted-catalog-e2e-test': true,
      },
    );

    this.refresh();
  };

  @observable submitting;

  submit = async () => {
    runInAction(() => {
      this.submitting = true;
    });

    const { fileName, contentAsBase64 } = this.catalogCsv.internalValue;

    const catalogItems = await this._getCatalogItemsFromCsv(contentAsBase64);

    await this.dependencies.callForCreateEffortDefinitionCatalog({
      name: removeFileExtension(fileName),
      description: this.description.internalValue,
      catalogItems,
    });

    runInAction(() => {
      this.submitting = false;
    });

    this.dependencies.activationModel.deactivate(
      'add-effort-definition-catalog',
    );

    this.refresh();

    this.catalogCsv.clear();
    this.description.clear();
  };

  _getCatalogItemsFromCsv = async contentAsBase64 =>
    await pipeline(
      contentAsBase64,
      atob,
      this.dependencies.getRowsFromCsv,
      map(
        ([
          identificationCode,
          incentiveCode,
          standardJobCode,
          name,
          effortMeasurementUnit,
          description,
          including,
          excluding,
          __,
          durationAmount,
          incentiveAmount,
        ]) => ({
          identificationCode,
          incentiveCode,
          standardJobCode,
          name,
          description,
          including,
          excluding,

          effort: {
            amount: 0,

            measurementUnitId:
              measurementUnitIdsByUnitInCsv[effortMeasurementUnit],
          },

          durationAmount: toDecimalNumber(durationAmount),
          incentiveAmount: toDecimalNumber(incentiveAmount),
        }),
      ),
    );
}

const toDecimalNumber = number => pipeline(number, replace(',', '.'), toNumber);

const measurementUnitIdsByUnitInCsv = {
  h: 'hour',
  kpl: 'piece',
  m: 'meter',
  m2: 'square-meter',
  km: 'kilometer',
};

const removeFileExtension = replace(/\.[^.]*$/, '');
