import queryString from 'query-string';

import getModel from '../../decorators/withModel/getModel';
import SpinningModel from '../../decorators/withSpinnerFor/SpinnerTentative/SpinningModel';

export const dependencies = {
  spinningModel: getModel(SpinningModel),
  assignWindowLocation: window.location.assign.bind(window.location),
};

export default ({ url, queryParameters }) => {
  dependencies.spinningModel.setNamedSpinnerToSpin('redirecting');

  const parameters = queryString.stringify(queryParameters);

  if (parameters) {
    dependencies.assignWindowLocation(`${url}?${parameters}`);
  } else {
    dependencies.assignWindowLocation(url);
  }
};
