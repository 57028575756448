import {
  unionBy,
  pipeline,
} from 'shared-between-everything/src/functionalProgramming';

import sharedRoutes from './sharedRoutes';

export default ({ routingModel, routes: appSpecificRoutes }) =>
  void pipeline(sharedRoutes, unionBy('name', appSpecificRoutes), routes =>
    routingModel.setRoutes(routes),
  );
