import { action, computed, observable } from 'mobx';

export default class NotificationsModel {
  @observable
  successesMap = new Map();
  @computed get successes() {
    return [...this.successesMap.values()];
  }

  @action
  deprecateSuccess(key) {
    this.successesMap.delete(key);
  }

  @action
  setSuccess(message, props = {}) {
    const key = Math.random();

    this.successesMap.set(key, { message, props });

    setTimeout(() => this.deprecateSuccess(key), 1000);
  }
}
