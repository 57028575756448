import { observer } from 'mobx-react';
import React from 'react';

import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import {
  getFrom,
  pipeline,
} from 'shared-between-everything/src/functionalProgramming';

import Page from 'shared-between-front-ends/src/components/public/Page/Page';
import Panel from 'shared-between-front-ends/src/components/public/Panel/Panel';
import SemanticButton from 'shared-between-front-ends/src/components/public/SemanticButton/SemanticButton';
import EffortContent from './EffortContent/EffortContent';
import EffortHeader from './EffortHeader/EffortHeader';

const InteractiveEffort = ({ model, type }) => (
  <Panel
    transparent
    data-interactive-effort-test={model.effortDefinitionCatalogItem.id}
  >
    <SemanticButton
      occupyHorizontalSpace
      onClick={model.expectedEffortEntryIsExpanded.toggle}
      color="white"
      data-toggle-expected-effort-definition-test={
        model.effortDefinitionCatalogItem.id
      }
      {...pipeline(
        type,

        getFrom({
          issued: {
            'data-issued-effort-e2e-test':
              model.effortDefinitionCatalogItem.name,

            'data-expected-amount-e2e-test':
              model.expectedEffortDefinition.effort.amount,
          },

          manual: {},
        }),
      )}
    >
      <Panel.Header
        backgroundColor={model.effortEntryHighlightColor}
        data-expected-effort-background-color-test={
          model.effortDefinitionCatalogItem.id
        }
      >
        <Page.Row margin={false}>
          <EffortHeader model={model} type={type} />
        </Page.Row>
      </Panel.Header>
    </SemanticButton>

    <Page.Row margin={false}>
      <EffortContent
        shown={model.expectedEffortEntryIsExpanded.internalValue}
        model={model}
        type={type}
        data-catalog-item-id-test={model.effortDefinitionCatalogItem.id}
        data-expected-effort-definition-test={
          model.effortDefinitionCatalogItem.id
        }
        {...pipeline(
          type,

          getFrom({
            issued: {},

            manual: {
              'data-added-effort-entry-e2e-test':
                model.effortDefinitionCatalogItem.name,
            },
          }),
        )}
      />
    </Page.Row>
  </Panel>
);

export default decorate(observer)(InteractiveEffort);
