import lodashMemoize from 'lodash/fp/memoize';

const memoize = (...args) => {
  const memoized = lodashMemoize(...args);

  memoize._caches.push(memoized.cache);

  return memoized;
};

memoize._caches = [];

memoize.purgeCaches = () => {
  memoize._caches.forEach(cache => {
    cache.clear();
  });
};

export default memoize;
