import flow from 'lodash/fp/flow';
import head from 'lodash/fp/head';
import isNull from 'lodash/fp/isNull';
import over from 'lodash/fp/over';
import some from 'lodash/fp/some';
import equals from 'lodash/fp/equals';
import { action, computed, observable } from 'mobx';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import { noop } from 'shared-between-everything/src/functionalProgramming';
import withDebounce from './decorators/withDebounce/withDebounce';
import withObservabilityFromPromise from './decorators/withObservabilityFromPromise/withObservabilityFromPromise';
import requiredValidator from './validators/required/requiredValidator';

export default class InputModelBaseClass {
  __isInputModel = true;

  validators;
  inboundFormatters;
  outboundFormatters;
  defaultValue;

  constructor({
    validators = [],
    outboundFormatters = [],
    inboundFormatters = [],
    readOnly = false,
    required = false,
    defaultValue = null,
    clearedInternalValue = defaultValue,
    initialInboundValue = defaultValue,
    initialInternalValue = defaultValue,
    onChange = noop,
  }) {
    const undecoratedValidators = required
      ? [requiredValidator, ...validators]
      : validators;

    const decoratedValidators = undecoratedValidators.map(
      decorate(withObservabilityFromPromise),
    );

    this._clearedInternalValue = clearedInternalValue;
    this._onChange = onChange;
    this.validators = decoratedValidators;
    this.outboundFormatters = outboundFormatters;
    this.inboundFormatters = inboundFormatters;
    this.setReadOnly(readOnly);
    this.setRequired(required);

    if (initialInboundValue != null) {
      this.setInboundValue(initialInboundValue, true);
    } else {
      this.setInternalValue(initialInternalValue, true);
    }

    this.commitCurrentValue();
  }

  @observable debouncedInternalValue = null;

  @withDebounce(300)
  @action
  setDebouncedInternalValue = debouncedInternalValue => {
    this.debouncedInternalValue = debouncedInternalValue;
  };

  @computed
  get value() {
    return this.internalValue;
  }

  get cleared() {
    return equals(this.internalValue, this._clearedInternalValue);
  }

  setValue = value => {
    this.setInboundValue(value);
  };

  @observable
  _internalValue;

  @computed
  get internalValue() {
    return this._internalValue;
  }

  @action
  setInternalValue = (value, valueIsInitial = false) => {
    defendFromUndefinedValue(value);

    if (!valueIsInitial) {
      this._onChange(value);
    }

    this._internalValue = value;
    this.setDebouncedInternalValue(value);
  };

  setInboundValue = (value, valueIsInitial = false) => {
    defendFromUndefinedValue(value);

    const formattedValue = flow(...this.inboundFormatters)(value);

    this.setInternalValue(formattedValue, valueIsInitial);
  };

  @computed
  get outboundValue() {
    return flow(...this.outboundFormatters)(this.internalValue);
  }

  @computed
  get asyncValidations() {
    return over(this.validators)(this.internalValue);
  }

  @computed
  get validationTranslationKeys() {
    return this.asyncValidations
      .map(observablePromise => observablePromise.value)
      .filter(errorText => !isNull(errorText));
  }

  @computed
  get asyncValidationIsPending() {
    return some('pending')(this.asyncValidations);
  }

  @computed
  get validationTranslationKey() {
    return head(this.validationTranslationKeys);
  }

  @computed
  get isValid() {
    return this.validationTranslationKeys.length === 0;
  }

  commitCurrentValue = () => {
    this._committedInternalValue = this.internalValue;
  };

  commit = () => {
    this.commitCurrentValue();
  };

  resetToCommittedValue = () => {
    this.setInternalValue(this._committedInternalValue);
  };

  clear = () => {
    this.resetToCommittedValue();
  };

  @observable
  readOnly;
  @action.bound
  setReadOnly(readOnly) {
    this.readOnly = readOnly;
  }

  @observable
  required;
  @action.bound
  setRequired(required) {
    this.required = required;
  }
}

const defendFromUndefinedValue = value => {
  if (value === undefined) {
    throw new Error('Tried to set an undefined value in an InputModel');
  }
};
