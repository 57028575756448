import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';
import parameterInputMapperFor from 'shared-between-everything/src/doings/parameterInputMapperFor/parameterInputMapperFor';

export default getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/work-order/{workOrderId}/modify-urgency',
    method: 'POST',
    inputMapper: parameterInputMapperFor({
      pathParameters: {
        workOrderId: 'workOrderId',
      },

      bodyParameters: {
        urgent: 'urgent',
      },
    }),
  },
  decoratorConfig: {
    spinnerName: 'modifying-urgency-of-work-order',
    showSpinnerInstantly: false,
  },
});
