import noop from 'lodash/fp/noop';
import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';
import pathParametersInputMapper from 'shared-between-front-ends/src/doings/pathParametersInputMapper/pathParametersInputMapper';
import getAbsenceBudgetsOutputMapper from './getAbsenceBudgetsOutputMapper/getAbsenceBudgetsOutputMapper';

export default getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/teams/{teamId}/absence-budgets',
    method: 'GET',
    inputMapper: pathParametersInputMapper,
    outputMapper: getAbsenceBudgetsOutputMapper,
  },
  decoratorConfig: {
    spinnerName: 'getting-absence-budgets',
    showSpinnerInstantly: false,
    stubificationFunction: noop,
  },
});
