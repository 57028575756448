import localTranslate from '../../doings/localTranslate/localTranslate';
import validatorTranslations from '../validatorTranslations';

const translate = localTranslate(validatorTranslations);

export default value => {
  if (!value || value.length === 0 || !!value.match(/^\+\d+/)) {
    return null;
  }

  return translate('noInternationalPrefix');
};
