import React from 'react';

import { baseline } from '../constants';
import Flex from '../Flex/Flex';

const Circle = ({ children, sizeBaselines = 5, ...props }) => (
  <Flex
    centered
    border={{
      size: 'md',
      color: 'action',
      radius: { size: 'elliptical' },
    }}
    style={{
      width: sizeBaselines * baseline,
      height: sizeBaselines * baseline,
    }}
    {...props}
  >
    {children}
  </Flex>
);

export default Circle;
