import {
  isOneOf,
  pipeline,
  split,
  last,
} from 'shared-between-everything/src/functionalProgramming';

import withExposedConfiguration from 'shared-between-everything/src/test-utils/withExposedConfiguration';

import localTranslate from '../../../../doings/localTranslate/localTranslate';
import validatorTranslations from '../../../../validators/validatorTranslations';

const translate = localTranslate(validatorTranslations);

export default withExposedConfiguration(
  ({ allowedFileExtensions }) => value => {
    if (!value) {
      return null;
    }

    const fileExtension = pipeline(value.fileName, split('.'), last);

    if (isOneOf(allowedFileExtensions, fileExtension)) {
      return null;
    }

    return translate('disallowedFileExtension', {
      allowedFileExtensions: allowedFileExtensions.join(', '),
      fileExtension: fileExtension,
    });
  },
);
