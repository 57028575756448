import { computed } from 'mobx';
import bodyParametersInputMapper from 'shared-between-everything/src/doings/bodyParametersInputMapper/bodyParametersInputMapper';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import NotificationsModel from 'shared-between-front-ends/src/components/public/Notifications/NotificationsModel';
import TextInputModel from 'shared-between-front-ends/src/components/public/TextInput/TextInputModel';
import getModel from 'shared-between-front-ends/src/decorators/withModel/getModel';
import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';
import RoutingModel from 'shared-between-front-ends/src/models/RoutingModel/RoutingModel';
import MultiTextInputModel from 'shared-between-front-ends/src/components/public/MultiTextInput/MultiTextInputModel';

import teamTranslations from '../teamTranslations';

const translate = localTranslate(teamTranslations);

export default class CreateTeamModel {
  static isSingleton = false;

  dependencies = {};

  constructor(
    getEveryInputModelIsValid = require('shared-between-front-ends/src/doings/getEveryInputModelIsValid/getEveryInputModelIsValid')
      .default,
    routingModel = getModel(RoutingModel),
    submitCreateTeam = getAuthenticatedCall({
      apiCallConfig: {
        path: '/api/create-team',
        method: 'POST',
        inputMapper: bodyParametersInputMapper,
      },
      decoratorConfig: {
        spinnerName: 'creating-team',
        showSpinnerInstantly: true,
      },
    }),
    notificationsModel = getModel(NotificationsModel),
  ) {
    this.dependencies.getEveryInputModelIsValid = getEveryInputModelIsValid;
    this.dependencies.routingModel = routingModel;
    this.dependencies.submitCreateTeam = submitCreateTeam;
    this.dependencies.notificationsModel = notificationsModel;
  }

  name = new TextInputModel({ required: true });
  nameOfManager = new TextInputModel();

  costCentres = new MultiTextInputModel();
  departments = new MultiTextInputModel();
  namesOfForemen = new MultiTextInputModel();

  submit = async () => {
    await this.dependencies.submitCreateTeam({
      districtId: this.dependencies.routingModel.pathParameters.districtId,
      name: this.name.value,
      nameOfManager: this.nameOfManager.value,
      costCentres: this.costCentres.internalValue,
      departments: this.departments.internalValue,
      namesOfForemen: this.namesOfForemen.internalValue,
    });

    this.dependencies.notificationsModel.setSuccess(translate('createSuccess'));

    this.dependencies.routingModel.setRouteTo({ name: 'districts' });
  };

  @computed
  get isValid() {
    return this.dependencies.getEveryInputModelIsValid(this.name);
  }
}
