import defaultEmptyTo from 'shared-between-everything/src/doings/defaultEmptyTo/defaultEmptyTo';

import InputModelBaseClass from '../../../InputModelBaseClass';
import postalCodeValidator from '../../../validators/postalCode/postalCodeValidator';
import sanitizePostalCodeFormatter from './sanitizePostalCodeFormatter';

export default class PostalCodeInputModel extends InputModelBaseClass {
  constructor({ validators = [], ...rest } = {}) {
    super({
      inboundFormatters: [
        defaultEmptyTo(''),
        sanitizePostalCodeFormatter,
        defaultEmptyTo(null),
      ],
      outboundFormatters: [defaultEmptyTo('')],
      validators: [postalCodeValidator, ...validators],
      ...rest,
    });
  }
}
