import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';
import noop from 'lodash/fp/noop';

export default getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/users',
    method: 'GET',
  },
  decoratorConfig: {
    spinnerName: 'getting-users',
    showSpinnerInstantly: false,
    stubificationFunction: noop,
  },
});
