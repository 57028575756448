import PropTypes from 'prop-types';
import React from 'react';

import Typography, {
  commonPropTypesForAllTypographies,
} from '../../private/Typography/Typography';

function Title({ size = 'xxs', children, ...props }) {
  return (
    <Typography
      {...props}
      font="primary"
      size={size}
      uppercase={false}
      bold={true}
    >
      {children}
    </Typography>
  );
}

export const supportedSizes = ['xxs'];

Title.propTypes = {
  ...commonPropTypesForAllTypographies,
  size: PropTypes.oneOf(supportedSizes),
};

export default Title;
