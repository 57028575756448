import { reaction } from 'mobx';

export default model => {
  if (model.__when) {
    model.__when.forEach(({ methodName, triggerWhen, fireImmediately }) => {
      reaction(
        () => triggerWhen(model),
        shouldTrigger => {
          if (shouldTrigger) {
            model[methodName](model);
          }
        },
        { fireImmediately },
      );
    });
  }
};
