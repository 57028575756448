import toNumber from 'lodash/fp/toNumber';

import {
  curry,
  fromPairs,
  isArray,
  isPrimitive,
  map,
  nth,
  pipeline,
  toPairs,
  isUndefined,
  flow,
  reject,
} from '../../functionalProgramming';

const mapEntries = how => thing =>
  pipeline(
    thing,
    toPairs,
    map(how),
    reject(valueIsUndefined),
    isArray(thing) ? map(nth(1)) : fromPairs,
  );

export default curry((how, thing) =>
  recursiveMapValuesDeep({ how, thing, rootThing: thing, referencePath: '' }),
);

const recursiveMapValuesDeep = ({ how, thing, rootThing, referencePath }) =>
  pipeline(thing, mapEntries(toEntriesFor(how, referencePath, rootThing)));

const getNewReferencePath = (key, oldReferencePath) =>
  !isNaN(toNumber(key))
    ? `${oldReferencePath}[${key}]`
    : `${oldReferencePath ? `${oldReferencePath}.` : ''}${key}`;

const toEntriesFor = (how, oldReferencePath, rootThing) => ([key, value]) => {
  const newReferencePath = getNewReferencePath(key, oldReferencePath);

  const newThing = how(value, newReferencePath, rootThing);

  return [
    key,
    isPrimitive(newThing)
      ? newThing
      : recursiveMapValuesDeep({
          how,
          referencePath: newReferencePath,
          thing: newThing,
          rootThing,
        }),
  ];
};

const valueIsUndefined = flow(nth(1), isUndefined);
