import React from 'react';

import Button from '../../private/Button/Button';

function PrimaryButton({ children, ...props }) {
  return (
    <Button variant="primary" {...props}>
      {children}
    </Button>
  );
}

export default PrimaryButton;
