import getModel from '../../../decorators/withModel/getModel';
import ApplicationModel from '../../../models/ApplicationModel/ApplicationModel';

export const dependencies = {
  applicationModel: getModel(ApplicationModel),
};

export default function applicationIsStarted() {
  return dependencies.applicationModel.started;
}
