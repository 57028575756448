import React from 'react';

import BodyText from '../../public/BodyText/BodyText';
import Div from '../Element/Div/Div';
import Label from '../Element/Label/Label';

function InputWrapper({ label, labelExtra, errorText, children, ...props }) {
  return (
    <Label block {...props}>
      {label && (
        <Div margin={{ bottom: 'xs' }}>
          <BodyText size="3xs">{label}</BodyText>

          {labelExtra}
        </Div>
      )}

      {children}

      {errorText && (
        <BodyText block size="3xs" color="error">
          {errorText}
        </BodyText>
      )}
    </Label>
  );
}

export default InputWrapper;
