export default {
  en: {
    updateDistrictButton: 'Update district',
    backButton: 'Cancel',
    districtName: 'Name',
    districtManager: 'District manager',
    updateDistrict: 'Update district',
    updateSuccess: 'Updated successfully',
  },
  fi: {
    updateDistrictButton: 'Tallenna',
    backButton: 'Peruuta',
    districtName: 'Nimi',
    districtManager: 'Aluepäällikkö',
    updateDistrict: 'Päivitä aluetta',
    updateSuccess: 'Tallennettu onnistuneesti',
  },
  pl: {
    updateDistrictButton: 'Zaktualizuj dział',
    backButton: 'Anuluj',
    districtName: 'Nazwa',
    districtManager: 'Kierownik działu',
    updateDistrict: 'Zaktualizuj dział',
    updateSuccess: 'Aktualizacja działu zakończona sukcesem',
  },
  se: {
    updateDistrictButton: 'Uppdatera distrikt',
    backButton: 'Avbryt',
    districtName: 'Namn',
    districtManager: 'Distriktschef',
    updateDistrict: 'Uppdatera distrikt',
    updateSuccess: 'Uppdaterad!',
  },
};
