import { action, reaction, ObservableMap, computed } from 'mobx';
import getModel from '../../../../decorators/withModel/getModel';
import RoutingModel from '../../../../models/RoutingModel/RoutingModel';

export default class ActivationModel {
  dependencies = {};

  constructor(routingModel = getModel(RoutingModel)) {
    this.dependencies.routingModel = routingModel;

    reaction(
      () => routingModel.externalRoute,
      () => {
        this.deactivateAll();
      },
      {
        fireImmediately: false,
      },
    );
  }

  _activationMap = new ObservableMap();

  @action
  activate = (activationId, context) => {
    this._activationMap.set(activationId, context);
  };

  @action
  deactivate = activationId => {
    this._activationMap.delete(activationId);
  };

  @action
  deactivateAll = () => {
    this._activationMap.clear();
  };

  isActivated = activationId => this._activationMap.has(activationId);

  @action
  toggle = (activationId, context) => {
    if (!this.isActivated(activationId)) {
      this.activate(activationId, context);
    } else {
      this.deactivate(activationId);
    }
  };

  @computed
  get activations() {
    return [...this._activationMap.entries()].map(
      ([activationId, context]) => ({
        activationId,
        context,
      }),
    );
  }
}
