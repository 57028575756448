import React from 'react';
import Table from 'shared-between-front-ends/src/components/public/Table/Table';

import styles from '../Scheduler.module.scss';

export default ({ children, ...props }) => (
  <Table.Cell
    padding={false}
    backgroundColor="lightGrey"
    className={styles.scheduler_cell}
    {...props}
  >
    {children}
  </Table.Cell>
);
