import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Form from 'shared-between-front-ends/src/components/public/Form/Form';
import GoBackButton from 'shared-between-front-ends/src/components/public/GoBackButton/GoBackButton';
import MainHeading from 'shared-between-front-ends/src/components/public/MainHeading/MainHeading';
import Page from 'shared-between-front-ends/src/components/public/Page/Page';
import PrimaryButton from 'shared-between-front-ends/src/components/public/PrimaryButton/PrimaryButton';
import TextInput from 'shared-between-front-ends/src/components/public/TextInput/TextInput';
import TileBag from 'shared-between-front-ends/src/components/public/TileBag/TileBag';
import withModel from 'shared-between-front-ends/src/decorators/withModel/withModel';
import SpinnerTentative from 'shared-between-front-ends/src/decorators/withSpinnerFor/SpinnerTentative/SpinnerTentative';
import MultiTextInput from 'shared-between-front-ends/src/components/public/MultiTextInput/MultiTextInput';
import Navigation from '../../Navigation/Navigation';
import teamTranslations from '../teamTranslations';
import CreateTeamModel from './CreateTeamModel';

const translate = localTranslate(teamTranslations);

function CreateTeam({
  name,
  nameOfManager,
  costCentres,
  departments,
  namesOfForemen,
  submit,
  isValid,
}) {
  return (
    <Page data-create-team-page-e2e-test>
      <Navigation />

      <Page.Row alignWithPanel margin={{ bottom: 'md' }}>
        <MainHeading>{translate('createTeam')}</MainHeading>
      </Page.Row>

      <SpinnerTentative spinnerName="creating-team">
        <Form onSubmit={submit}>
          <Page.Row withPanel>
            <TileBag
              tiles={[
                <TextInput
                  label={translate('teamName')}
                  model={name}
                  autoFocus
                  data-team-name-input-test
                  data-team-name-e2e-test
                />,

                <TextInput
                  label={translate('teamManager')}
                  model={nameOfManager}
                  data-name-of-manager-input-test
                />,
              ]}
            />

            <TileBag
              tiles={[
                <MultiTextInput
                  label={translate('costCentres')}
                  model={costCentres}
                  data-cost-centres-input-test
                />,

                <MultiTextInput
                  label={translate('departments')}
                  model={departments}
                  data-departments-input-test
                />,
                <MultiTextInput
                  label={translate('foremen')}
                  model={namesOfForemen}
                  data-names-of-foremen-input-test
                />,
              ]}
            />
          </Page.Row>

          <Page.Row alignWithPanel margin={false}>
            <Flex spaceBetween>
              <GoBackButton>{translate('backButton')}</GoBackButton>

              <PrimaryButton
                data-submit-button-test
                data-submit-button-e2e-test
                type="submit"
                enabled={isValid}
              >
                {translate('createTeamButton')}
              </PrimaryButton>
            </Flex>
          </Page.Row>
        </Form>
      </SpinnerTentative>
    </Page>
  );
}

export default decorate(
  withModel({
    Model: CreateTeamModel,
    props: [
      {
        name: 'name',
        modelPath: 'name',
      },
      {
        name: 'nameOfManager',
        modelPath: 'nameOfManager',
      },
      {
        name: 'costCentres',
        modelPath: 'costCentres',
      },
      {
        name: 'departments',
        modelPath: 'departments',
      },
      {
        name: 'namesOfForemen',
        modelPath: 'namesOfForemen',
      },
      {
        name: 'submit',
        modelPath: 'submit',
      },
      {
        name: 'isValid',
        modelPath: 'isValid',
      },
    ],
  }),
)(CreateTeam);
