import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Div from '../../private/Element/Div/Div';
import MarginSpace from '../../private/MarginSpace/MarginSpace';
import BodyText from '../BodyText/BodyText';

import Flex from '../Flex/Flex';
import Gutter from '../Gutter/Gutter';
import Icon from '../Icon/Icon';
import Map from '../Map/Map';
import styles from './Notifications.module.scss';

const Notifications = ({ model: { successes } }) =>
  successes.length > 0 ? (
    <Div backgroundColor="white" className={styles.notifications}>
      <MarginSpace padding={{ vertical: 'xlg' }}>
        <Map items={successes} separator={<Gutter />}>
          {success => (
            <Flex centeredVertically {...success.props}>
              <Icon name="check" size="lg" color="primary" />

              <Gutter />

              <BodyText color="primary">{success.message}</BodyText>
            </Flex>
          )}
        </Map>
      </MarginSpace>
    </Div>
  ) : null;

export default decorate(observer)(Notifications);
