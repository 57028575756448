import { computed, flow as mobxFlow, observable } from 'mobx';
import getWorkOrderTypeValueObject from 'shared-between-everything/src/getWorkOrderTypeValueObject';
import workOrderTypes from 'shared-between-everything/src/workOrderTypes';
import ColorInputModel from 'shared-between-front-ends/src/components/public/ColorInput/ColorInputModel';
import NotificationsModel from 'shared-between-front-ends/src/components/public/Notifications/NotificationsModel';
import NumberInputModel from 'shared-between-front-ends/src/components/public/NumberInput/NumberInputModel';
import RadioButtonGroupModel from 'shared-between-front-ends/src/components/public/RadioButtonGroup/RadioButtonGroupModel';
import PostalCodeInputModel from 'shared-between-front-ends/src/components/public/TextInput/PostalCodeInputModel';
import TextInputModel from 'shared-between-front-ends/src/components/public/TextInput/TextInputModel';
import whenRouteChangesTo from 'shared-between-front-ends/src/decorators/whenRouteChangesTo/whenRouteChangesTo';
import getModel from 'shared-between-front-ends/src/decorators/withModel/getModel';
import deleteBusinessObjectImport from 'shared-between-front-ends/src/doings/deleteBusinessObject/deleteBusinessObject';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import RoutingModel from 'shared-between-front-ends/src/models/RoutingModel/RoutingModel';
import SessionModel from 'shared-between-front-ends/src/models/SessionModel/SessionModel';
import isPositiveNumber from 'shared-between-front-ends/src/validators/isPositiveNumber/isPositiveNumber';
import isUrl from 'shared-between-front-ends/src/validators/isUrl/isUrl';
import callForModifyWorkOrderImport from '../callForModifyWorkOrder';
import getWorkOrderTypeValues from '../getWorkOrderTypeValues/getWorkOrderTypeValues';
import getWorkOrderUpdateTabNavigationRoutes from '../getWorkOrderUpdateTabNavigationRoutes';
import workOrderTranslations from '../workOrderTranslations';
import getWorkOrderImport from './getWorkOrder/getWorkOrder';

const translate = localTranslate(workOrderTranslations);

export default class UpdateWorkOrderModel {
  static isSingleton = false;

  dependencies = {};

  constructor({
    getEveryInputModelIsValid = require('shared-between-front-ends/src/doings/getEveryInputModelIsValid/getEveryInputModelIsValid')
      .default,
    routingModel = getModel(RoutingModel),
    callForModifyWorkOrder = callForModifyWorkOrderImport,
    notificationsModel = getModel(NotificationsModel),
    getWorkOrder = getWorkOrderImport,
    deleteBusinessObject = deleteBusinessObjectImport,
    sessionModel = getModel(SessionModel),
  } = {}) {
    this.dependencies.getEveryInputModelIsValid = getEveryInputModelIsValid;
    this.dependencies.routingModel = routingModel;
    this.dependencies.callForModifyWorkOrder = callForModifyWorkOrder;
    this.dependencies.notificationsModel = notificationsModel;
    this.dependencies.getWorkOrder = getWorkOrder;
    this.dependencies.deleteBusinessObject = deleteBusinessObject;
    this.dependencies.sessionModel = sessionModel;

    this.type = new RadioButtonGroupModel({
      readOnly: false,
      required: false,
      defaultValue: workOrderTypes.workOrder.type,
      observeValues: () =>
        getWorkOrderTypeValues(
          sessionModel.userRights.createWorkOrdersOfAllTypes,
        ),
    });
  }

  customerName = new TextInputModel({ required: true });
  name = new TextInputModel({ required: true });

  link = new TextInputModel({ required: false, validators: [isUrl] });
  description = new TextInputModel({ required: false });
  address = new TextInputModel({ required: false });

  postalCode = new PostalCodeInputModel({
    required: false,
  });

  city = new TextInputModel({ required: false });
  deadlineDate = new TextInputModel({ required: false });
  billableHours = new NumberInputModel({
    required: false,
    validators: [isPositiveNumber],
  });

  color = new ColorInputModel({ required: false });

  @observable erpId = null;
  @observable department = null;

  @observable
  handledByIntegration = false;

  @computed
  get canBeDeleted() {
    return (
      getWorkOrderTypeValueObject(this.type.value).canBeDeleted &&
      !this.handledByIntegration
    );
  }

  @whenRouteChangesTo(
    'district-team-update-work-order-from-scheduler',
    'district-team-update-work-order-from-work-orders',
    'district-team-update-work-order-from-absence-scheduler',
  )
  getWorkOrder = mobxFlow(function* () {
    const {
      workOrderId,
      teamId,
    } = this.dependencies.routingModel.pathParameters;

    const {
      response: {
        origin,
        customerName,
        name,
        link,
        description,
        address,
        postalCode,
        city,
        deadlineDate,
        billableHours,
        erpId,
        type,
        department,
        color,
      },
    } = yield this.dependencies.getWorkOrder({
      pathParameters: {
        teamId,
        workOrderId,
      },
    });

    if (origin === 'integration') {
      this.customerName.setReadOnly(true);
      this.name.setReadOnly(true);
      this.address.setReadOnly(true);
      this.postalCode.setReadOnly(true);
      this.city.setReadOnly(true);
      this.billableHours.setReadOnly(true);
      this.type.setReadOnly(true);

      this.handledByIntegration = true;
    }

    this.customerName.setValue(customerName);
    this.name.setValue(name);
    this.link.setValue(link);
    this.description.setValue(description);
    this.address.setValue(address);
    this.postalCode.setValue(postalCode);
    this.city.setValue(city);
    this.deadlineDate.setValue(deadlineDate);
    this.billableHours.setValue(billableHours);
    this.color.setValue(color);
    this.type.setValue(type);

    this.erpId = erpId;
    this.department = department;
  });

  submit = async () => {
    const workOrderType = getWorkOrderTypeValueObject(this.type.value);

    await this.dependencies.callForModifyWorkOrder({
      id: this.dependencies.routingModel.pathParameters.workOrderId,
      customerName: this.customerName.value,
      name: this.name.value,
      link: this.link.value,
      description: this.description.value,
      address: this.address.value,
      postalCode: this.postalCode.value,
      city: this.city.value,
      deadlineDate: this.deadlineDate.value,
      billableHours: this.billableHours.value,
      type: this.type.value,
      color: this.color.value,
      teamId: workOrderType.isTeamSpecific
        ? this.dependencies.routingModel.pathParameters.teamId
        : 'null',
    });

    this.dependencies.notificationsModel.setSuccess(translate('updateSuccess'));

    this.dependencies.routingModel.goBack();
  };

  deleteWorkOrder = async () => {
    const { deletionWasDone } = await this.dependencies.deleteBusinessObject({
      path: `/api/teams/${this.dependencies.routingModel.pathParameters.teamId}/work-orders/${this.dependencies.routingModel.pathParameters.workOrderId}`,
      spinnerName: 'deleting-work-order',
      confirmationMessage: translate('deleteConfirmation'),
      successMessage: translate('deleteSuccess'),
    });

    if (deletionWasDone) {
      this.dependencies.routingModel.goBack();
    }
  };

  @computed
  get tabRoutes() {
    return getWorkOrderUpdateTabNavigationRoutes(
      this.dependencies.routingModel,
    );
  }

  @computed
  get isValid() {
    return this.dependencies.getEveryInputModelIsValid(
      this.customerName,
      this.name,
      this.link,
      this.description,
      this.address,
      this.postalCode,
      this.city,
      this.deadlineDate,
      this.billableHours,
      this.type,
      this.color,
    );
  }

  @computed
  get colorInputIsVisible() {
    return getWorkOrderTypeValueObject(this.type.internalValue)
      .colorIsUserSelectable;
  }

  @computed
  get navigationTabsAreShown() {
    return !!this.dependencies.sessionModel.userRights
      .maintainWorkOrderExpectedEfforts;
  }

  @computed
  get userRights() {
    return this.dependencies.sessionModel.userRights;
  }
}
