export default {
  en: {
    shortWeekdays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  },
  fi: {
    shortWeekdays: ['Ma', 'Ti', 'Ke', 'To', 'Pe', 'La', 'Su'],
  },
  pl: {
    shortWeekdays: ['Pon', 'Wto', 'Śro', 'Czw', 'Pią', 'Sob', 'Nie'],
  },
  se: {
    shortWeekdays: ['Mo', 'Ti', 'On', 'To', 'Fr', 'Lö', 'Sö'],
  },
};
