import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';

import {
  filter,
  isEmpty,
  map,
  over,
  pipeline,
} from 'shared-between-everything/src/functionalProgramming';

import withModel from '../../../../decorators/withModel/withModel';
import Div from '../../../private/Element/Div/Div';
import Link from '../../Link/Link';
import ActivationModel from '../ActivationModel/ActivationModel';

const Activate = ({
  activate,
  deactivate,
  toggle,
  activations,
  children,
  ...props
}) => {
  const hoverActivations = pipeline(
    activations,
    filter({ activateOn: 'hover' }),
  );

  const doOnMouseEnterActivations = pipeline(
    hoverActivations,
    map(({ activationId }) => () => activate(activationId)),
    over,
  );

  const doOnMouseLeaveDeactivations = pipeline(
    hoverActivations,
    map(({ activationId }) => () => deactivate(activationId)),
    over,
  );

  const clickActivations = pipeline(
    activations,
    filter({ activateOn: 'click' }),
  );

  const doActivationToggles = pipeline(
    clickActivations,

    map(({ activationId }) => () =>
      toggle(activationId, { showOverlay: true }),
    ),

    over,
  );

  const Tag = isEmpty(clickActivations) ? Div : Link;

  return (
    <Tag
      block
      onMouseEnter={doOnMouseEnterActivations}
      onMouseLeave={doOnMouseLeaveDeactivations}
      onDragStart={doOnMouseLeaveDeactivations}
      onClick={doActivationToggles}
      {...props}
    >
      {children}
    </Tag>
  );
};

export default decorate(
  withModel({
    Model: ActivationModel,

    props: [
      {
        name: 'activate',
        modelPath: 'activate',
      },

      {
        name: 'deactivate',
        modelPath: 'deactivate',
      },

      {
        name: 'toggle',
        modelPath: 'toggle',
      },
    ],
  }),
)(Activate);
