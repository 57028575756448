import PropTypes from 'prop-types';
import React from 'react';

import Div from '../../private/Element/Div/Div';
import styles from './Flex.module.scss';

// TODO: This should be moved to "private" directory because it makes things more complicated.
// We should think about creating wrappers for aligning contents in predefined way.
function Flex({
  className,
  children,
  centered,
  centeredVertically = centered,
  centeredHorizontally = centered,
  alignTop,
  alignBottom,
  alignBaseline,
  alignRight,
  spaceBetween,
  wrapContent,
  pileVertically,
  pileVerticallyTo,
  occupyVerticalSpace,
  stretchToSameHeight,
  reverseDirection,
  inline,
  ...props
}) {
  const classNames = [
    styles.flex,
    {
      [styles.flex__centeredVertically]: centeredVertically,
      [styles.flex__centeredHorizontally]: centeredHorizontally,
      [styles.flex__alignTop]: alignTop,
      [styles.flex__alignBottom]: alignBottom,
      [styles.flex__alignBaseline]: alignBaseline,
      [styles.flex__alignRight]: alignRight,
      [styles.flex__spaceBetween]: spaceBetween,
      [styles.flex__wrapContent]: wrapContent,
      [styles.flex__stretchToSameHeight]: stretchToSameHeight,
      [styles.flex__pileVerticallyToXsScreen]: pileVerticallyTo === 'xs',
      [styles.flex__pileVerticallyToSmScreen]: pileVerticallyTo === 'sm',
      [styles.flex__pileVerticallyToMdScreen]: pileVerticallyTo === 'md',
      [styles.flex__pileVerticallyToLgScreen]: pileVerticallyTo === 'lg',
      [styles.flex__pileVertically]: pileVertically,
      [styles.flex__occupyVerticalSpace]: occupyVerticalSpace,
      [styles.flex__reverseDirection]: reverseDirection,
      [styles.flex__inlineFlex]: inline,
    },
    className,
  ];

  return (
    <Div className={classNames} {...props}>
      {children}
    </Div>
  );
}

Flex.propTypes = {
  pileVerticallyTo: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
};

export default Flex;
