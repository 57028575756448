import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import Span from 'shared-between-front-ends/src/components/private/Element/Span/Span';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import DropdownList from 'shared-between-front-ends/src/components/public/DropdownList/DropdownList';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import GutterBag from 'shared-between-front-ends/src/components/public/GutterBag/GutterBag';
import Popover from 'shared-between-front-ends/src/components/public/Popover/Popover';
import districtsTranslations from '../districtsTranslations';
import ExpandableContent from '../ExpandableContent/ExpandableContent';
import EllipsisLink from './TeamCard/EllipsisLink/EllipsisLink';
import TeamCard from './TeamCard/TeamCard';

const translate = localTranslate(districtsTranslations);

const District = ({
  district: {
    id: districtId,
    name: districtName,
    nameOfManager,
    teams,
    navigateToUpdateDistrict,
    navigateToCreateTeam,
    delete: doDelete,
    expanded,
    toggleExpanded,
  },
  userRights,
  ...props
}) => (
  <ExpandableContent
    clickAreaProps={{
      'data-collapse-district-e2e-test': districtId,
      'data-expand-district-e2e-test': districtId,
    }}
    data-teams-e2e-test={districtId}
    data-indicators-e2e-test={expanded ? 'expanded' : 'collapsed'}
    open={expanded}
    toggleOpen={toggleExpanded}
    {...props}
    title={
      <Flex>
        <Div flexItem>{districtName}</Div>

        <Div flexItem>
          <Flex>
            <Span>{translate('districtManager')}:</Span>

            <Gutter size="sm" />

            <Span>{nameOfManager}</Span>
          </Flex>
        </Div>

        <Gutter flexItem />

        <Gutter flexItem />

        <Gutter flexItem />
      </Flex>
    }
    rightTitleArea={
      userRights.maintainDistricts && (
        <Popover
          activationId={`district-popover-for-${districtId}`}
          activateOn="click"
          data-district-pop-over-test={districtId}
          content={
            <DistrictActionsMenu
              navigateToCreateTeam={navigateToCreateTeam}
              navigateToUpdateDistrict={navigateToUpdateDistrict}
              delete={doDelete}
              userRights={userRights}
              data-district-actions-test={districtId}
            />
          }
        >
          <EllipsisLink
            color="action"
            hoverColor="actionHover"
            data-district-pop-over-e2e-test={districtId}
          />
        </Popover>
      )
    }
  >
    {teams.length > 0 ? (
      <GutterBag
        padding={{ top: 'md' }}
        contents={teams.map(team => (
          <TeamCard team={team} data-team-card-test={team.id} />
        ))}
      />
    ) : (
      <BodyText>{translate('districtHasNoTeams')}</BodyText>
    )}
  </ExpandableContent>
);

const DistrictActionsMenu = ({
  navigateToUpdateDistrict,
  navigateToCreateTeam,
  delete: doDelete,
  userRights,
  ...props
}) => {
  return (
    <DropdownList
      items={[
        {
          onClick: navigateToUpdateDistrict,
          children: translate('edit'),
          'data-update-district-test': true,
          'data-edit-district-e2e-test': true,
        },
        {
          onClick: navigateToCreateTeam,
          children: translate('addTeam'),
          'data-create-team-for-district-test': true,
        },
        {
          onClick: doDelete,
          children: translate('delete'),
          'data-delete-district-test': true,
          'data-delete-district-e2e-test': true,
        },
      ]}
      {...props}
    >
      {itemProps => <DropdownList.Link {...itemProps} />}
    </DropdownList>
  );
};

export default decorate(observer)(District);
