import React from 'react';
import { noop } from 'shared-between-everything/src/functionalProgramming';
import MainHeading from '../../components/public/MainHeading/MainHeading';
import Page from '../../components/public/Page/Page';
import PrimaryButton from '../../components/public/PrimaryButton/PrimaryButton';
import localTranslate from '../../doings/localTranslate/localTranslate';
import loginRequiredTranslations from './loginRequiredTranslations';

const translate = localTranslate(loginRequiredTranslations);

const LoginRequired = ({ getNavigation = noop, model, ...props }) => (
  <Page data-login-is-required-test data-login-is-required-e2e-test {...props}>
    {getNavigation()}

    <Page.Row alignWithPanel>
      <MainHeading>{translate('loginRequired')}</MainHeading>
    </Page.Row>

    <Page.Row alignWithPanel margin={{ bottom: 'zero' }}>
      <PrimaryButton onClick={model.leaveToLogin} data-leave-to-login-test>
        {translate('login')}
      </PrimaryButton>
    </Page.Row>
  </Page>
);

export default LoginRequired;
