import React from 'react';

import { noop } from 'shared-between-everything/src/functionalProgramming';
import Div from '../../components/private/Element/Div/Div';
import Span from '../../components/private/Element/Span/Span';
import BodyText from '../../components/public/BodyText/BodyText';
import Flex from '../../components/public/Flex/Flex';
import Gutter from '../../components/public/Gutter/Gutter';
import Icon from '../../components/public/Icon/Icon';
import MainHeading from '../../components/public/MainHeading/MainHeading';
import Page from '../../components/public/Page/Page';

const NotFound = ({ getNavigation = noop, ...props }) => (
  <Page {...props}>
    {getNavigation()}

    <Page.Row alignWithPanel>
      <MainHeading color="error">Page not found</MainHeading>
    </Page.Row>

    <Page.Row withPanel margin={false}>
      <Flex centeredVertically>
        <div>
          <Icon color="error" name="mug-saucer" size="md" />
        </div>

        <Gutter />

        <Div forceWrap>
          <BodyText block color="black" singleLine>
            <Span bold>You are trying to access page that doesn't exist.</Span>
          </BodyText>
        </Div>
      </Flex>
    </Page.Row>
  </Page>
);

export default NotFound;
