import LoginModel from '../../SessionStart/LoginModel';
import getModel from '../../../decorators/withModel/getModel';

export default class LoginRequiredModel {
  dependencies = {};

  constructor({ loginModel = getModel(LoginModel) } = {}) {
    this.dependencies.loginModel = loginModel;
  }

  leaveToLogin = () => {
    this.dependencies.loginModel.leaveToLogin();
  };
}
