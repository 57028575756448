import { action, computed, untracked } from 'mobx';
import {
  get,
  pipeline,
} from 'shared-between-everything/src/functionalProgramming';
import MultiTextInputModel from 'shared-between-front-ends/src/components/public/MultiTextInput/MultiTextInputModel';
import NotificationsModel from 'shared-between-front-ends/src/components/public/Notifications/NotificationsModel';
import TextInputModel from 'shared-between-front-ends/src/components/public/TextInput/TextInputModel';
import getModel from 'shared-between-front-ends/src/decorators/withModel/getModel';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import RoutingModel from 'shared-between-front-ends/src/models/RoutingModel/RoutingModel';
import teamTranslations from '../teamTranslations';
import withLaziness from './withLaziness/withLaziness';

const translate = localTranslate(teamTranslations);

export default class UpdateTeamModel {
  static isSingleton = false;

  dependencies = {};

  constructor({
    getEveryInputModelIsValid = require('shared-between-front-ends/src/doings/getEveryInputModelIsValid/getEveryInputModelIsValid')
      .default,
    routingModel = getModel(RoutingModel),
    submitUpdateTeam = require('./submitUpdateTeam/submitUpdateTeam').default,
    notificationsModel = getModel(NotificationsModel),
    getTeam = require('./getTeam/getTeam').default,
  } = {}) {
    this.dependencies.getEveryInputModelIsValid = getEveryInputModelIsValid;
    this.dependencies.routingModel = routingModel;
    this.dependencies.submitUpdateTeam = submitUpdateTeam;
    this.dependencies.notificationsModel = notificationsModel;
    this.dependencies.getTeam = getTeam;
  }

  team = withLaziness(() =>
    pipeline(
      this.dependencies.getTeam({
        teamId: this.dependencies.routingModel.pathParameters.teamId,
      }),

      get('response'),
    ),
  );

  @computed
  get name() {
    return untracked(
      () =>
        new TextInputModel({
          required: true,
          initialInternalValue: this.team.promiseStatus.value.name,
        }),
    );
  }

  @computed
  get nameOfManager() {
    return untracked(
      () =>
        new TextInputModel({
          initialInternalValue: this.team.promiseStatus.value.nameOfManager,
        }),
    );
  }

  @computed
  get costCentres() {
    return untracked(
      () =>
        new MultiTextInputModel({
          initialInternalValue: this.team.promiseStatus.value.costCentres,
        }),
    );
  }

  @computed
  get departments() {
    return untracked(
      () =>
        new MultiTextInputModel({
          initialInternalValue: this.team.promiseStatus.value.departments,
        }),
    );
  }

  @computed
  get namesOfForemen() {
    return untracked(
      () =>
        new MultiTextInputModel({
          initialInternalValue: this.team.promiseStatus.value.namesOfForemen,
        }),
    );
  }

  @action
  submit = async () => {
    const {
      teamId,
      districtId,
    } = this.dependencies.routingModel.pathParameters;

    await this.dependencies.submitUpdateTeam({
      id: teamId,
      districtId,
      name: this.name.value,
      nameOfManager: this.nameOfManager.value,
      costCentres: this.costCentres.internalValue,
      departments: this.departments.internalValue,
      namesOfForemen: this.namesOfForemen.internalValue,
    });

    this.dependencies.notificationsModel.setSuccess(
      translate('updateSuccess'),
      { 'data-team-update-success-notification-test': true },
    );

    this.dependencies.routingModel.goBack();
  };

  openTeamEffortCatalogMaintenance = () => {
    const routeName = `${this.dependencies.routingModel.routeName}/effort-catalogs`;

    this.dependencies.routingModel.setRouteTo({
      name: routeName,
    });
  };

  @computed
  get isValid() {
    return this.dependencies.getEveryInputModelIsValid(this.name);
  }
}
