import defaultEmptyTo from 'shared-between-everything/src/doings/defaultEmptyTo/defaultEmptyTo';

import {
  toNumber,
  toString,
} from 'shared-between-everything/src/functionalProgramming';

import InputModelBaseClass from '../../../InputModelBaseClass';

export default class NumberInputModel extends InputModelBaseClass {
  constructor(parameterObject) {
    super({
      inboundFormatters: [defaultEmptyTo(null), nullSafeToNumber],
      outboundFormatters: [toString],
      ...parameterObject,
    });
  }
}

const nullSafeToNumber = value => (value === null ? null : toNumber(value));
