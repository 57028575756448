import React from 'react';
import BodyText from '../../BodyText/BodyText';
import Flex from '../../Flex/Flex';
import Gutter from '../../Gutter/Gutter';
import Icon from '../../Icon/Icon';

const PanelHeading = ({ headerText, iconName }) => (
  <Flex
    centeredVertically
    color="white"
    backgroundColor="modalHeader"
    padding={{ size: 'sm' }}
  >
    <Icon name={iconName} compact />

    <Gutter size="xs" />

    <BodyText>{headerText}</BodyText>
  </Flex>
);

export default PanelHeading;
