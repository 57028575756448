export default {
  en: {
    costCentres: 'Billing units',
    departments: 'Departments',
    districtManager: 'District manager',
    teamManager: 'Team manager',
    foremen: 'Foremen',
    menu: 'Menu',
    edit: 'Edit',
    createNewDistrict: 'Create new district',
    navigateToTeamAbsenceScheduler: 'Absence scheduler',
    navigateToUpdateTeam: 'Update team',
    navigateToTeamResources: 'Resources',
    navigateToTeamWorkOrders: 'Work orders',
    addTeam: 'Add new team',
    delete: 'Delete',
    deleteSuccess: 'District has been deleted',
    deleteConfirmation: 'Confirm delete?',
    deleteTeamSuccess: 'Team has been deleted',
    deleteTeamConfirmation: 'Confirm delete?',
    districtHasNoTeams: 'District has no teams',
  },
  fi: {
    costCentres: 'Kustannuspaikat',
    departments: 'Osastot',
    teamManager: 'Tiimipäällikkö',
    districtManager: 'Aluepäällikkö',
    foremen: 'Työpäälliköt',
    menu: 'Valikko',
    edit: 'Muokkaa',
    createNewDistrict: 'Luo uusi alue',
    navigateToTeamAbsenceScheduler: 'Lomasuunnittelu',
    navigateToUpdateTeam: 'Muokkaa tiimiä',
    navigateToTeamResources: 'Näytä henkilöstö',
    navigateToTeamWorkOrders: 'Näytä työtilaukset',
    addTeam: 'Lisää uusi tiimi',
    delete: 'Poista',
    deleteSuccess: 'Alue poistettu',
    deleteConfirmation: 'Poistetaanko?',
    deleteTeamSuccess: 'Tiimi poistettu',
    deleteTeamConfirmation: 'Poistetaanko?',
    districtHasNoTeams: 'Alueella ei ole tiimejä',
  },
  pl: {
    costCentres: 'Jednostki rozliczeniowe',
    departments: 'Dział',
    districtManager: 'Kierownik działu',
    teamManager: 'Kierownik zespołu',
    foremen: 'Brygadzista',
    menu: 'Menu',
    edit: 'Edytuj',
    createNewDistrict: 'Utwórz nowy dział',
    navigateToTeamAbsenceScheduler: 'Harmonogram nieobecności',
    navigateToUpdateTeam: 'Zaktualizuj zespół',
    navigateToTeamResources: 'Zasoby',
    navigateToTeamWorkOrders: 'Zlecenia pracy',
    addTeam: 'Dodaj nowy zespół',
    delete: 'Kasuj',
    deleteSuccess: 'Dział został usunięty',
    deleteConfirmation: 'Potwierdź usunięcie?',
    deleteTeamSuccess: 'Zespół została usunięta',
    deleteTeamConfirmation: 'Potwierdź usunięcie?',
    districtHasNoTeams: 'Dział nie ma przypisanego zespołu',
  },
  se: {
    costCentres: 'Avdelning',
    departments: 'CA',
    districtManager: 'Distriktschef',
    teamManager: 'Teamchef',
    foremen: 'Teamledare',
    menu: 'Meny',
    edit: 'Ändra',
    createNewDistrict: 'Skapa nytt distrikt',
    navigateToTeamAbsenceScheduler: 'Ledighetsplanerare',
    navigateToUpdateTeam: 'Uppdatera team',
    navigateToTeamResources: 'Personal',
    navigateToTeamWorkOrders: 'Arbetsordrar',
    addTeam: 'Lägg till nytt team',
    delete: 'Ta bort',
    deleteSuccess: 'Distrikt har tagits bort',
    deleteConfirmation: 'Bekräfta radering?',
    deleteTeamSuccess: 'Team har tagits bort',
    deleteTeamConfirmation: 'Bekräfta borttagning?',
    districtHasNoTeams: 'Distrikt har inget team',
  },
};
