import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Form from 'shared-between-front-ends/src/components/public/Form/Form';
import GoBackButton from 'shared-between-front-ends/src/components/public/GoBackButton/GoBackButton';
import MainHeading from 'shared-between-front-ends/src/components/public/MainHeading/MainHeading';
import Page from 'shared-between-front-ends/src/components/public/Page/Page';
import PrimaryButton from 'shared-between-front-ends/src/components/public/PrimaryButton/PrimaryButton';
import TextInput from 'shared-between-front-ends/src/components/public/TextInput/TextInput';
import TileBag from 'shared-between-front-ends/src/components/public/TileBag/TileBag';
import withModel from 'shared-between-front-ends/src/decorators/withModel/withModel';
import SpinnerTentative from 'shared-between-front-ends/src/decorators/withSpinnerFor/SpinnerTentative/SpinnerTentative';
import Navigation from '../../Navigation/Navigation';
import CreateDistrictModel from './CreateDistrictModel';
import createDistrictTranslations from './createDistrictTranslations';

const translate = localTranslate(createDistrictTranslations);

const CreateDistrict = ({ submit, name, nameOfManager, isValid }) => {
  return (
    <Page data-create-district-page-e2e-test>
      <Navigation />

      <Page.Row alignWithPanel margin={{ bottom: 'md' }}>
        <MainHeading>{translate('createDistrict')}</MainHeading>
      </Page.Row>

      <SpinnerTentative spinnerName="creating-district">
        <Form onSubmit={submit}>
          <Page.Row withPanel>
            <TileBag
              tiles={[
                <TextInput
                  label={translate('districtName')}
                  model={name}
                  autoFocus
                  data-district-name-input-test
                  data-name-of-district-e2e-test
                />,

                <TextInput
                  label={translate('districtManager')}
                  model={nameOfManager}
                  data-name-of-manager-input-test
                  data-name-of-district-manager-e2e-test
                />,
              ]}
            />
          </Page.Row>

          <Page.Row alignWithPanel margin={false}>
            <Flex spaceBetween>
              <GoBackButton>{translate('backButton')}</GoBackButton>

              <PrimaryButton
                data-submit-button-test
                data-submit-e2e-test
                type="submit"
                enabled={isValid}
              >
                {translate('createDistrictButton')}
              </PrimaryButton>
            </Flex>
          </Page.Row>
        </Form>
      </SpinnerTentative>
    </Page>
  );
};

export default decorate(
  withModel({
    Model: CreateDistrictModel,
    props: [
      {
        name: 'submit',
        modelPath: 'submit',
      },
      {
        name: 'name',
        modelPath: 'name',
      },
      {
        name: 'nameOfManager',
        modelPath: 'nameOfManager',
      },
      {
        name: 'isValid',
        modelPath: 'isValid',
      },
    ],
  }),
)(CreateDistrict);
