import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import isOneOf from 'shared-between-everything/src/doings/isOneOf/isOneOf';
import localTranslate from '../../../doings/localTranslate/localTranslate';
import LoginModel from '../../../App/SessionStart/LoginModel';
import withModel from '../../../decorators/withModel/withModel';
import doLogout from '../../../doings/doLogout/doLogout';
import RoutingModel from '../../../models/RoutingModel/RoutingModel';
import SessionModel from '../../../models/SessionModel/SessionModel';
import SecondaryButton from '../SecondaryButton/SecondaryButton';
import logInOrLogOutTranslations from './logInOrLogOutTranslations';

const translate = localTranslate(logInOrLogOutTranslations);

function LogInOrLogOutButton({ userIsLoggedIn, routeName, leaveToLogin }) {
  return (
    <div data-user-is-logged-in-e2e-test={userIsLoggedIn}>
      {!userIsLoggedIn &&
        !isOneOf(['login', 'login-required', 'login-pending'], routeName) && (
          <SecondaryButton onClick={leaveToLogin} data-login-test>
            {translate('logIn')}
          </SecondaryButton>
        )}

      {userIsLoggedIn && (
        <SecondaryButton onClick={doLogout} data-logout-test>
          {translate('logOut')}
        </SecondaryButton>
      )}
    </div>
  );
}

export default decorate(
  withModel(
    {
      Model: SessionModel,
      props: [{ name: 'userIsLoggedIn', modelPath: 'userIsLoggedIn' }],
    },
    {
      Model: RoutingModel,
      props: [
        {
          name: 'routeName',
          modelPath: 'routeName',
        },
      ],
    },
    {
      Model: LoginModel,
      props: [
        {
          name: 'leaveToLogin',
          modelPath: 'leaveToLogin',
        },
      ],
    },
  ),
)(LogInOrLogOutButton);
