import React from 'react';
import workOrderCategories from 'shared-between-everything/src/workOrderCategories';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import CircleButton from 'shared-between-front-ends/src/components/public/CircleButton/CircleButton';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import RouteLink from 'shared-between-front-ends/src/components/public/RouteLink/RouteLink';
import SemanticButton from 'shared-between-front-ends/src/components/public/SemanticButton/SemanticButton';
import TextInput from 'shared-between-front-ends/src/components/public/TextInput/TextInput';
import ToggleGroup from 'shared-between-front-ends/src/components/public/ToggleGroup/ToggleGroup';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import schedulerTranslations from '../../schedulerTranslations';
import WorkOrderList from './WorkOrderList/WorkOrderList';

const translate = localTranslate(schedulerTranslations);

const SchedulerWorkOrders = ({
  selectedWorkOrderCategory,
  listedWorkOrders,
  changeListedWorkOrders,
  teamId,
  districtId,
  clearWorkOrderSelection,
  buttonForClearingWorkOrderSelectionIsEnabled,
  workOrderListFilterInput,
  userRights,
  ...props
}) => {
  return (
    <Div
      style={{
        width: '240px',
        height: '100vh',
        overflowY: 'scroll',
        position: 'sticky',
        top: 0,
      }}
      backgroundColor="grey3"
      {...props}
    >
      <Div
        style={{
          position: 'sticky',
          top: 0,
          bottom: 0,
          zIndex: 1,
        }}
        padding={{ size: 'xxs' }}
        backgroundColor="grey3"
      >
        <ToggleGroup
          values={[
            {
              label: translate('workOrders'),
              value: workOrderCategories.work.id,
            },
            {
              label: translate('commonWorkOrders'),
              value: workOrderCategories.sharedBetweenEveryone.id,
            },
            {
              label: translate('otherWorkOrders'),
              value: workOrderCategories.sharedBetweenTeamMembers.id,
            },
          ]}
          value={selectedWorkOrderCategory}
          onChange={changeListedWorkOrders}
          data-listed-work-orders-test
        />

        <Gutter size="xxs" />

        <TextInput
          model={workOrderListFilterInput}
          placeholder={translate('searchWorkOrders')}
          data-work-order-list-filter-input-test
        />

        <Flex
          padding={{ vertical: 'xs', horizontal: 'sm' }}
          centeredVertically
          spaceBetween
        >
          <Div>
            <SemanticButton
              onClick={clearWorkOrderSelection}
              color="action"
              hoverColor="actionHover"
              enabled={buttonForClearingWorkOrderSelectionIsEnabled}
              data-clearing-work-order-selection-test
            >
              <Icon name="calendar-check" size="md" />
            </SemanticButton>
          </Div>

          <Gutter />

          <RouteLink
            routeName="district-team-create-work-order"
            pathParameters={{
              districtId,
              teamId,
            }}
            block
            data-create-work-order-test
            data-create-work-order-link-e2e-test
          >
            <CircleButton>
              <Icon block name="plus" size="sm" />
            </CircleButton>
          </RouteLink>
        </Flex>

        <WorkOrderList
          userRights={userRights}
          workOrders={listedWorkOrders}
          districtId={districtId}
          teamId={teamId}
        />
      </Div>
    </Div>
  );
};

export default SchedulerWorkOrders;
