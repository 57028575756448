import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import CheckboxInput from 'shared-between-front-ends/src/components/public/CheckboxInput/CheckboxInput';
import { baseline } from 'shared-between-front-ends/src/components/public/constants';
import EmailInput from 'shared-between-front-ends/src/components/public/EmailInput/EmailInput';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Form from 'shared-between-front-ends/src/components/public/Form/Form';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import HeavyText from 'shared-between-front-ends/src/components/public/HeavyText/HeavyText';
import Map from 'shared-between-front-ends/src/components/public/Map/Map';
import Page from 'shared-between-front-ends/src/components/public/Page/Page';
import SecondaryButton from 'shared-between-front-ends/src/components/public/SecondaryButton/SecondaryButton';
import StickyTable from 'shared-between-front-ends/src/components/public/Table/StickyTable';
import Table from 'shared-between-front-ends/src/components/public/Table/Table';
import withModel from 'shared-between-front-ends/src/decorators/withModel/withModel';
import Navigation from '../Navigation/Navigation';
import UserRightsModel from './UserRightsModel/UserRightsModel';
import userRightTranslations from './userRightTranslations';

const translate = localTranslate(userRightTranslations);

const UserRights = ({
  users,
  emailAddress,
  createUser,
  allUserRights,
  isValid,
}) => (
  <Page>
    <Navigation />

    <Page.Row withPanel>
      <Form onSubmit={createUser} data-create-user-test>
        <Flex alignBaseline>
          <BodyText>{translate('emailAddress')}</BodyText>

          <Gutter />

          <Div flexItem>
            <EmailInput model={emailAddress} data-email-address-test />
          </Div>

          <Gutter />

          <SecondaryButton
            type="submit"
            enabled={isValid}
            data-submit-create-user-test
          >
            {translate('createNewUser')}
          </SecondaryButton>

          <Gutter flexItem />

          <Gutter flexItem />

          <Gutter flexItem />
        </Flex>
      </Form>
    </Page.Row>

    <StickyTable
      backgroundColor="white"
      striped
      cols={[
        {
          width: 42 * baseline,
        },
        ...allUserRights.map(() => ({})),
      ]}
      headers={[
        <Table.Row backgroundColor="white">
          <Table.HeadingCell>{translate('emailAddress')}</Table.HeadingCell>

          <Map items={allUserRights}>
            {userRight => (
              <Table.HeadingCell alignTextTo="center">
                <HeavyText style={{ hyphens: 'auto' }}>
                  {translate(`userRights.${userRight}`)}
                </HeavyText>
              </Table.HeadingCell>
            )}
          </Map>
        </Table.Row>,
      ]}
      rows={users}
    >
      {({ id: userId, userRights }) => (
        <Table.Row key={userId} data-user-row-test={userId}>
          <Table.Cell>{userId}</Table.Cell>

          <Map items={allUserRights}>
            {userRight => (
              <Table.Cell>
                <Flex centeredHorizontally>
                  <CheckboxInput
                    model={userRights[userRight]}
                    data-user-right-test={userRight}
                  />
                </Flex>
              </Table.Cell>
            )}
          </Map>
        </Table.Row>
      )}
    </StickyTable>
  </Page>
);
export default decorate(
  withModel({
    Model: UserRightsModel,
    props: [
      {
        name: 'users',
        modelPath: 'users',
      },
      {
        name: 'allUserRights',
        modelPath: 'allUserRights',
      },
      {
        name: 'emailAddress',
        modelPath: 'emailAddress',
      },
      {
        name: 'createUser',
        modelPath: 'createUser',
      },
      {
        name: 'isValid',
        modelPath: 'isValid',
      },
    ],
  }),
)(UserRights);
