import {
  isPlainObject,
  get,
  pipeline,
  mapValues,
  isArray,
  curry,
} from '../../functionalProgramming';

import withExposedConfiguration from '../../test-utils/withExposedConfiguration';

const parameterInputMapper = curry((configuration, parameters) => {
  const getParameterOrThrow = getParameterOrThrowFor(parameters);

  return pipeline(
    configuration,
    mapValues(pathToValue => {
      if (isArray(pathToValue)) {
        return pathToValue.map(getParameterOrThrow);
      }

      if (isPlainObject(pathToValue)) {
        return parameterInputMapper(pathToValue, parameters);
      }

      return getParameterOrThrow(pathToValue);
    }),
  );
});

const getParameterOrThrowFor = parameters => pathToValue => {
  const targetValue = get(pathToValue, parameters);

  if (targetValue === undefined) {
    throw new Error(`Parameter for "${pathToValue}" does not exist`);
  }

  return targetValue;
};

export default withExposedConfiguration(parameterInputMapper);
