import { isNumber } from 'shared-between-everything/src/functionalProgramming';
import localTranslate from '../../doings/localTranslate/localTranslate';
import validatorTranslations from '../validatorTranslations';

const translate = localTranslate(validatorTranslations);

export default url => {
  if (!url) {
    return null;
  }

  if (isNumber(url)) {
    return translate('invalidUrl');
  }

  return url.match(/^(https:\/\/|http:\/\/).+$/)
    ? null
    : translate('invalidUrl');
};
