import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import Link from 'shared-between-front-ends/src/components/public/Link/Link';

const AppointmentBox = ({
  appointment: {
    disabled,
    id: appointmentId,
    select: selectAppointment,
    titleForAbsenceScheduler,
    backgroundColor,
  },
}) => (
  <Link block onClick={selectAppointment}>
    <Div
      data-appointment-box-test={appointmentId}
      highlight={disabled}
      draggable
      onDragStart={event => {
        event.dataTransfer.setData(
          'appointmentOrWorkOrder',
          JSON.stringify({
            appointmentId,
          }),
        );
      }}
      backgroundColor={backgroundColor}
      padding={{
        horizontal: 'xxs',
        vertical: 'xs',
      }}
    >
      <BodyText singleLine color="white">
        {titleForAbsenceScheduler ? (
          titleForAbsenceScheduler
        ) : (
          <span>&nbsp;</span>
        )}
      </BodyText>
    </Div>
  </Link>
);

export default decorate(observer)(AppointmentBox);
