export default {
  en: {
    heading: 'An error took place',
    message: 'Something went wrong, please try again.',
    body: '',
  },
  fi: {
    heading: 'Tapahtui virhe',
    message: 'Ole hyvä ja yritä uudelleen.',
    body: '',
  },
  pl: {
    heading: 'Wystąpił błąd',
    message: 'Coś poszło nie tak, spróbuj ponownie.',
    body: '',
  },
  se: {
    heading: 'Ett fel inträffade',
    message: 'Något gick fel, försök igen',
    body: '',
  },
};
