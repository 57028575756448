import { get, join, map, pipeline, toPairs } from '../../functionalProgramming';
import withExposedConfiguration from '../../test-utils/withExposedConfiguration';
import replaceTagsWithValues from '../replaceTagsWithValues/replaceTagsWithValues';

export const dependencies = {
  getEnvironmentVariable: require('../getEnvironmentVariable/getEnvironmentVariable')
    .default,
};

export default withExposedConfiguration(
  ({ getCurrentLanguage }) => translations => (key, translationValues = {}) => {
    const translationKey = `${getCurrentLanguage()}.${key}`;

    const translationWithVariables = get(translationKey, translations);

    if (translationWithVariables === undefined) {
      throw new Error(`Translation for "${translationKey}" is not defined`);
    }

    const inTestEnvironment =
      dependencies.getEnvironmentVariable('NODE_ENV') === 'test';

    if (inTestEnvironment) {
      const variableString = getVariableString(translationValues);

      return variableString ? `${key} with ${variableString}` : key;
    }

    return replaceTagsWithValues(translationWithVariables, translationValues);
  },
);

const getVariableString = translationValues =>
  pipeline(
    translationValues,
    toPairs,
    map(
      ([variableName, variableValue]) => `${variableName}: "${variableValue}"`,
    ),
    join(', '),
  );
