import { isEmpty } from 'shared-between-everything/src/functionalProgramming';

const isTruthy = x => !!x;

export default class TeamModel {
  constructor(
    {
      id,
      name,
      nameOfManager,
      districtId,
      costCentres,
      departments,
      namesOfForemen,
    },
    routingModel,
    deleteBusinessObject,
    onDeletionFinished,
    sessionModel,
    confirmAndDeleteTeam,
  ) {
    this.id = id;
    this.name = name;
    this.nameOfManager = nameOfManager;
    this.districtId = districtId;
    this.costCentres = costCentres;
    this.departments = departments;
    this.namesOfForemen = namesOfForemen;

    this.navigateTo = name => {
      routingModel.setRouteTo({
        name,
        pathParameters: { districtId, teamId: id },
      });
    };

    this.confirmAndDeleteTeam = confirmAndDeleteTeam;
    this.onDeletionFinished = onDeletionFinished;
    this.sessionModel = sessionModel;
  }

  get formattedCostCentres() {
    return this.costCentres.join(', ');
  }

  get formattedDepartments() {
    return this.departments.join(', ');
  }

  get formattedNamesOfForemen() {
    return this.namesOfForemen.join(', ');
  }

  get actionMenuItems() {
    const userRights = this.sessionModel.userRights;

    return [
      userRights.seeTeamAbsenceScheduler && {
        action: this.navigateToTeamAbsenceScheduler,
        translationKey: 'navigateToTeamAbsenceScheduler',
      },

      userRights.maintainTeams && {
        action: this.navigateToUpdateTeam,
        translationKey: 'navigateToUpdateTeam',
      },

      userRights.maintainResources && {
        action: this.navigateToTeamResources,
        translationKey: 'navigateToTeamResources',
      },

      userRights.maintainWorkOrders && {
        action: this.navigateToTeamWorkOrders,
        translationKey: 'navigateToTeamWorkOrders',
      },

      userRights.removeTeams && {
        action: this.delete,
        translationKey: 'delete',
      },
    ].filter(isTruthy);
  }

  get actionMenuIsVisible() {
    return !isEmpty(this.actionMenuItems);
  }

  navigateToUpdateTeam = () => {
    this.navigateTo('district-update-team');
  };

  navigateToTeamResources = () => {
    this.navigateTo('district-team-resources');
  };

  navigateToTeamWorkOrders = () => {
    this.navigateTo('district-team-work-orders');
  };

  navigateToTeamAbsenceScheduler = () => {
    this.navigateTo('district-team-absence-scheduler');
  };

  delete = async () => {
    const { callWasDone } = await this.confirmAndDeleteTeam({
      teamId: this.id,
    });

    if (callWasDone) {
      this.onDeletionFinished();
    }
  };
}
