import React from 'react';
import Element from '../../private/Element/Element';

const SemanticButton = ({
  children,
  inlineBlock,
  block = !inlineBlock,
  defaultColors,
  selected,
  ...props
}) => {
  const colorProps = defaultColors
    ? { color: 'action', hoverColor: 'actionHover' }
    : {};

  const border = selected
    ? { size: 'md', color: 'primary', radius: true }
    : { size: 'zero', color: 'transparent', radius: false };

  return (
    <Element
      tagName="button"
      type="button"
      backgroundColor="transparent"
      border={border}
      alignTextTo="left"
      block={block}
      inlineBlock={inlineBlock}
      {...colorProps}
      {...props}
    >
      {children}
    </Element>
  );
};

export default SemanticButton;
