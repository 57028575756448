import { get } from 'shared-between-everything/src/functionalProgramming';
import enableAutorunWhileDecorator from '../autorunWhile/enableAutorunWhileDecorator';
import enableAutorunWhileObservedDecorator from '../autorunWhileObserved/enableAutorunWhileObservedDecorator';
import enableWhenDecorator from '../when/enableWhenDecorator';
import enableWhenObservedDecorator from '../whenObserved/enableWhenObservedDecorator';

const alreadyInjectedModels = new Set();

const injectInstance = (Model, ...args) => {
  if (alreadyInjectedModels.has(Model)) {
    const errorMessage = getCircularErrorMessage(Model);
    throw new Error(errorMessage);
  }

  alreadyInjectedModels.add(Model);

  const model = new Model(...args);

  enableWhenDecorator(model);
  enableWhenObservedDecorator(model);
  enableAutorunWhileObservedDecorator(model);
  enableAutorunWhileDecorator(model);

  model.__injected = true;

  alreadyInjectedModels.delete(Model);

  return model;
};

const getCircularErrorMessage = Model => {
  const circularModelPath = [...alreadyInjectedModels.values(), Model]
    .map(get('name'))
    .join(' -> ');

  return `Circular dependency encountered: ${circularModelPath}`;
};

export default injectInstance;
