// All icons: https://fontawesome.com/icons?d=gallery&m=free

import {
  faAddressCard,
  faAllergies,
  faAmericanSignLanguageInterpreting,
  faBars,
  faBell,
  faBook,
  faCalendar,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarTimes,
  faCheck,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleExclamation,
  faCircleInfo,
  faCircleMinus,
  faCircleNotch,
  faClipboardList,
  faCopy,
  faEllipsis,
  faEnvelope,
  faFileAlt,
  faFileImport,
  faFileInvoice,
  faFileLines,
  faFolderOpen,
  faGlobe,
  faGripLines,
  faGripLinesVertical,
  faGripVertical,
  faListAlt,
  faMinus,
  faMoon,
  faMugSaucer,
  faPen,
  faPlus,
  faPlusCircle,
  faRectangleList,
  faScrewdriverWrench,
  faShoePrints,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faUpRightFromSquare,
  faUser,
  faUsers,
  faWrench,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

const availableIcons = [
  faAddressCard,
  faAllergies,
  faAmericanSignLanguageInterpreting,
  faBars,
  faBell,
  faBook,
  faCalendar,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarTimes,
  faCheck,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleNotch,
  faClipboardList,
  faMugSaucer,
  faCopy,
  faEllipsis,
  faRectangleList,
  faEnvelope,
  faCircleExclamation,
  faUpRightFromSquare,
  faFileAlt,
  faFileLines,
  faFileImport,
  faFileInvoice,
  faFolderOpen,
  faGlobe,
  faGripLines,
  faGripLinesVertical,
  faGripVertical,
  faCircleInfo,
  faListAlt,
  faMinus,
  faCircleMinus,
  faPen,
  faPlus,
  faPlusCircle,
  faShoePrints,
  faTimes,
  faTimesCircle,
  faScrewdriverWrench,
  faTrash,
  faTrashAlt,
  faUsers,
  faXmark,
  faUser,
  faWrench,
  faMoon,
];

export const availableIconNames = availableIcons.map(icon => icon.iconName);

export default availableIcons;
