import { action, computed, observable } from 'mobx';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import ConfirmationDialogModel from 'shared-between-front-ends/src/App/ConfirmationDialog/ConfirmationDialogModel';
import whenRouteChangesTo from 'shared-between-front-ends/src/decorators/whenRouteChangesTo/whenRouteChangesTo';
import getModel from 'shared-between-front-ends/src/decorators/withModel/getModel';
import deleteBusinessObjectImport from 'shared-between-front-ends/src/doings/deleteBusinessObject/deleteBusinessObject';
import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';
import RoutingModel from 'shared-between-front-ends/src/models/RoutingModel/RoutingModel';

import workOrderTranslations from './workOrderTranslations';

const translate = localTranslate(workOrderTranslations);

export default class WorkOrderModel {
  dependencies = {};

  constructor(
    getWorkOrders = getAuthenticatedCall({
      apiCallConfig: {
        path: '/api/teams/{teamId}/work-orders',
        method: 'GET',
      },
      decoratorConfig: {
        spinnerName: 'loading-work-orders',
      },
    }),
    routingModel = getModel(RoutingModel),
    confirmationDialogModel = getModel(ConfirmationDialogModel),
    deleteBusinessObject = deleteBusinessObjectImport,
  ) {
    this.dependencies.getWorkOrders = getWorkOrders;
    this.dependencies.routingModel = routingModel;
    this.dependencies.confirmationDialogModel = confirmationDialogModel;
    this.dependencies.deleteBusinessObject = deleteBusinessObject;
  }

  @observable
  rawWorkOrders = [];

  @action
  setRawWorkOrders = rawWorkOrders => {
    this.rawWorkOrders = rawWorkOrders;
  };

  @computed
  get teamId() {
    return this.dependencies.routingModel.pathParameters.teamId;
  }

  @computed
  get workOrders() {
    return this.rawWorkOrders.map(workOrder => ({
      ...workOrder,

      update: () => {
        this.dependencies.routingModel.setRouteTo({
          name: {
            'district-team-scheduler':
              'district-team-update-work-order-from-scheduler',
            'district-team-absence-scheduler':
              'district-team-update-work-order-from-absence-scheduler',
            'district-team-work-orders':
              'district-team-update-work-order-from-work-orders',
          }[this.dependencies.routingModel.routeName],
          pathParameters: {
            districtId: this.dependencies.routingModel.pathParameters
              .districtId,
            teamId: this.teamId,
            workOrderId: workOrder.id,
          },
        });
      },

      delete: async () => {
        const {
          deletionWasDone,
        } = await this.dependencies.deleteBusinessObject({
          path: `/api/teams/${this.teamId}/work-orders/${workOrder.id}`,
          spinnerName: 'deleting-work-order',
          confirmationMessage: translate('deleteConfirmation'),
          successMessage: translate('deleteSuccess'),
        });

        if (deletionWasDone) {
          this.refresh();
        }
      },
    }));
  }

  @whenRouteChangesTo('district-team-work-orders')
  refresh = async () => {
    const { response: workOrders } = await this.dependencies.getWorkOrders({
      pathParameters: { teamId: this.teamId },
    });

    this.setRawWorkOrders(workOrders);
  };
}
