import { computed } from 'mobx';
import getModel from '../../../decorators/withModel/getModel';
import RoutingModel from '../../../models/RoutingModel/RoutingModel';

export default class UserRightRequiredModel {
  dependencies = {};

  constructor({ routingModel = getModel(RoutingModel) } = {}) {
    this.dependencies.routingModel = routingModel;
  }

  @computed
  get missingUserRight() {
    return this.dependencies.routingModel.pathParameters.missingUserRight;
  }

  navigateToFrontPage = () => {
    this.dependencies.routingModel.setRouteToFrontPage();
  };
}
