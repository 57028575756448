import parameterInputMapperFor from 'shared-between-everything/src/doings/parameterInputMapperFor/parameterInputMapperFor';
import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';

export default getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/modify-district/{districtId}',
    method: 'POST',
    inputMapper: parameterInputMapperFor({
      pathParameters: {
        districtId: 'id',
      },
      bodyParameters: {
        name: 'name',
        nameOfManager: 'nameOfManager',
      },
    }),
  },
  decoratorConfig: {
    spinnerName: 'updating-district',
    showSpinnerInstantly: true,
  },
});
