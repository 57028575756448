import getClassNames from 'classnames';
import React from 'react';

import Th from '../../../private/Element/Th/Th';
import styles from '../Table.module.scss';

function TableHeadingCell({
  verticalAlignToBottom,
  className,
  children,
  ...props
}) {
  const classNames = getClassNames(
    styles.table_cell,
    { [styles.table_cell__verticalAlignToBottom]: !!verticalAlignToBottom },
    className,
  );

  return (
    <Th
      className={classNames}
      alignTextTo="left"
      padding={{ vertical: 'sm', horizontal: 'md' }}
      {...props}
    >
      {children}
    </Th>
  );
}

export default TableHeadingCell;
