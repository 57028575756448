import defaultEmptyTo from 'shared-between-everything/src/doings/defaultEmptyTo/defaultEmptyTo';

import {
  toNumber,
  toString,
} from 'shared-between-everything/src/functionalProgramming';
import localTranslate from '../../../doings/localTranslate/localTranslate';

import InputModelBaseClass from '../../../InputModelBaseClass';
import measurementUnits from './measurementUnits';

export default class MeasurementAmountInputModel extends InputModelBaseClass {
  constructor({ measurementUnitId, ...rest }) {
    super({
      initialInternalValue: null,
      clearedInternalValue: null,
      inboundFormatters: [
        defaultEmptyTo(null),
        nullSafeToNumber,
        number => (number < 0 ? 0 : number),
        number => (isNaN(number) ? null : number),
      ],
      outboundFormatters: [toString],
      ...rest,
    });

    this.measurementUnitMetric = localTranslate(
      measurementUnits[measurementUnitId].translations,
    )('metric');
  }
}

const nullSafeToNumber = value => (value === null ? null : toNumber(value));
