import { observer } from 'mobx-react';
import React from 'react';
import { negate } from 'shared-between-everything/src/functionalProgramming';
import Flex from '../Flex/Flex';
import Spinner from '../Spinner/Spinner';

const Loader = ({
  when,
  unless = negate(when),
  spinnerIsShown = true,
  children,
  ...props
}) => {
  return unless() ? (
    children()
  ) : spinnerIsShown ? (
    <Flex padding={{ size: 'md' }} centered {...props}>
      <Spinner />
    </Flex>
  ) : null;
};

export default observer(Loader);
