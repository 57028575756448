import debounce from 'lodash/fp/debounce';

const withDebounce = milliseconds => (Class, methodName, oldDescriptor) => {
  const { value, initializer, ...newDescriptor } = oldDescriptor;

  newDescriptor.initializer = function () {
    const decorated = debounce(milliseconds, initializer.call(this));

    decorated.__configuration = [milliseconds];
    decorated.__unconfiguredFunction = withDebounce;

    return decorated;
  };

  return newDescriptor;
};

export default withDebounce;
