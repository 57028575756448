import flow from 'lodash/fp/flow';
import reduce from 'lodash/fp/reduce';
import replace from 'lodash/fp/replace';
import toPairs from 'lodash/fp/toPairs';
import tap from 'lodash/fp/tap';
import map from 'lodash/fp/map';
import join from 'lodash/fp/join';

const replacePathParameterInPath = (key, value, result) =>
  replace(`:${key}`, value, result);

const reducePathToHavePathParameters = reduce((path, [key, value]) =>
  replacePathParameterInPath(key, value, path),
);

const throwOnMissingPathParameters = pathWithPathParameters => {
  const missingPathParameters = pathWithPathParameters.match(/:.*(\/)?/g);

  if (missingPathParameters) {
    const missingPathParameterNames = getMissingPathParameterNames(
      missingPathParameters,
    );

    throw new Error(
      `An attempt was made to set route with missing path parameters "${missingPathParameterNames}"`,
    );
  }
};

export default (variables, initialPath) =>
  flow(
    toPairs,
    reducePathToHavePathParameters(initialPath),
    tap(throwOnMissingPathParameters),
  )(variables);

const getMissingPathParameterNames = flow(map(replace(':', '')), join('", "'));
