import PropTypes from 'prop-types';
import React from 'react';

import Div from '../../private/Element/Div/Div';
import styles from './Gutter.module.scss';

function Gutter({
  className,
  half,
  quarter,
  size = (half && 'half') || (quarter && 'quarter'),
  ...props
}) {
  const classNames = [
    styles.gutter,
    {
      [styles.gutter__half]: size === 'half',
      [styles.gutter__quarter]: size === 'quarter',
      [styles.gutter__size3xs]: size === '3xs',
      [styles.gutter__sizeXxs]: size === 'xxs',
      [styles.gutter__sizeXs]: size === 'xs',
      [styles.gutter__sizeSm]: size === 'sm',
      [styles.gutter__sizeMd]: size === 'md',
      [styles.gutter__sizeLg]: size === 'lg',
      [styles.gutter__sizeXlg]: size === 'xlg',
    },
    className,
  ];

  return <Div className={classNames} {...props} />;
}

Gutter.propTypes = {
  half: PropTypes.bool,
  quarter: PropTypes.bool,
  size: PropTypes.oneOf([
    '3xs',
    'xxs',
    'xs',
    'sm',
    'md',
    'lg',
    'xlg',
    'half',
    'quarter',
  ]),
};

export default Gutter;
