import React from 'react';
import Table from 'shared-between-front-ends/src/components/public/Table/Table';

import styles from '../Scheduler.module.scss';

const SchedulerTableCell = ({ children, ...props }) => {
  return (
    <Table.Cell
      padding={false}
      backgroundColor="lightGrey"
      className={styles.scheduler_cell}
      {...props}
    >
      {children}
    </Table.Cell>
  );
};

export default SchedulerTableCell;
