import flow from 'lodash/fp/flow';
import { get } from '../../functionalProgramming';

export const pipelineBreak = Symbol('pipelineBreak');

export default (...functions) =>
  flow(...functions.map(toTentativeAsyncWrapper));

const toTentativeAsyncWrapper = f => arg =>
  withTentativeAsync(withSkippingForPipelineBreakFor(f), arg);

const isAsync = x => !!get('then', x);

const withTentativeAsync = (f, arg) =>
  isAsync(arg) ? Promise.resolve(arg).then(f) : f(arg);

const withSkippingForPipelineBreakFor = f => arg => {
  if (arg === pipelineBreak) {
    return pipelineBreak;
  }

  return f(arg);
};
