import noop from 'lodash/fp/noop';
import { autorun, reaction, when } from 'mobx';

import setTimeoutWithThis from '../../doings/setTimeoutWithThis/setTimeoutWithThis';
import ApplicationModel from '../../models/ApplicationModel/ApplicationModel';
import getModel from '../withModel/getModel';

export const dependencies = {
  setTimeoutWithThis,
};

export default model => {
  if (model.__autorunWhile) {
    const applicationModel = getModel(ApplicationModel);

    when(
      () => applicationModel.started,
      () => {
        model.__autorunWhile.forEach(
          ({ methodName, getObservationIsActive }) => {
            let stopAutorun = noop;

            reaction(
              getObservationIsActive,
              observationIsActive => {
                if (observationIsActive) {
                  stopAutorun = autorun(model[methodName].bind(model));
                } else {
                  stopAutorun();
                }
              },
              { fireImmediately: true },
            );
          },
        );
      },
    );
  }
};
