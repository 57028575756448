import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import { baseline } from 'shared-between-front-ends/src/components/public/constants';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import GoBackButton from 'shared-between-front-ends/src/components/public/GoBackButton/GoBackButton';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import MainHeading from 'shared-between-front-ends/src/components/public/MainHeading/MainHeading';
import Page from 'shared-between-front-ends/src/components/public/Page/Page';
import Panel from 'shared-between-front-ends/src/components/public/Panel/Panel';
import PrimaryButton from 'shared-between-front-ends/src/components/public/PrimaryButton/PrimaryButton';
import RouteLink from 'shared-between-front-ends/src/components/public/RouteLink/RouteLink';
import Table from 'shared-between-front-ends/src/components/public/Table/Table';
import withModel from 'shared-between-front-ends/src/decorators/withModel/withModel';
import SpinnerTentative from 'shared-between-front-ends/src/decorators/withSpinnerFor/SpinnerTentative/SpinnerTentative';
import Link from 'shared-between-front-ends/src/components/public/Link/Link';
import Navigation from '../../Navigation/Navigation';
import AllResourcesModel from './AllResourcesModel';
import allResourcesTranslations from './allResourcesTranslations';

const translate = localTranslate(allResourcesTranslations);

const AllResources = ({ resources }) => (
  <Page>
    <Navigation />

    <Page.Row alignWithPanel>
      <MainHeading>{translate('resources')}</MainHeading>
    </Page.Row>

    <Page.Row>
      <SpinnerTentative
        spinnerName={['loading-resources', 'deleting-resource']}
      >
        <Panel>
          <Table
            striped
            cols={[
              {
                content: (
                  <Table.HeadingCell>{translate('erpId')}</Table.HeadingCell>
                ),
                width: '100%',
              },
              {
                content: (
                  <Table.HeadingCell>
                    {translate('firstName')}
                  </Table.HeadingCell>
                ),
                width: '100%',
              },
              {
                content: (
                  <Table.HeadingCell>{translate('lastName')}</Table.HeadingCell>
                ),
                width: '100%',
              },
              {
                content: (
                  <Table.HeadingCell>{translate('role')}</Table.HeadingCell>
                ),
                width: '100%',
              },
              {
                content: (
                  <Table.HeadingCell>
                    {translate('phoneNumber')}
                  </Table.HeadingCell>
                ),
                width: '100%',
              },
              {
                content: (
                  <Table.HeadingCell>
                    {translate('emailAddress')}
                  </Table.HeadingCell>
                ),
                width: '200%',
              },
              {
                content: <Table.HeadingCell />,
                width: 22 * baseline,
              },
            ]}
            rows={resources}
          >
            {({
              id,
              erpId,
              firstName,
              lastName,
              role,
              phoneNumber,
              emailAddress,
              team,
              delete: doDelete,
            }) => (
              <Table.Row data-resource-row-test>
                <Table.Cell>{erpId}</Table.Cell>

                <Table.Cell>{firstName}</Table.Cell>

                <Table.Cell>{lastName}</Table.Cell>

                <Table.Cell>
                  {role
                    ? translate(`roles.${role}`)
                    : translate('roles.notDefined')}
                </Table.Cell>

                <Table.Cell>{phoneNumber}</Table.Cell>

                <Table.Cell>{emailAddress}</Table.Cell>

                <Table.Cell alignTextTo="right">
                  <Flex centered>
                    {team && (
                      <RouteLink
                        routeName="district-team-resource-plan"
                        pathParameters={{
                          districtId: team.districtId,
                          resourceId: id,
                          teamId: team.id,
                        }}
                        data-resource-plan-test
                      >
                        <Icon size="lg" name="clipboard-list" color="primary" />
                      </RouteLink>
                    )}

                    {team && <Gutter size="sm" />}

                    <RouteLink
                      routeName="update-resource"
                      pathParameters={{ resourceId: id }}
                      data-update-resource-test
                    >
                      <Icon size="md" name="pen" color="secondary" />
                    </RouteLink>

                    <Gutter size="sm" />

                    <Link onClick={doDelete} data-delete-resource-test>
                      <Icon size="md" name="circle-minus" color="danger" />
                    </Link>
                  </Flex>
                </Table.Cell>
              </Table.Row>
            )}
          </Table>
        </Panel>
      </SpinnerTentative>
    </Page.Row>

    <Page.Row alignWithPanel margin={false}>
      <Flex spaceBetween>
        <GoBackButton>{translate('backButton')}</GoBackButton>

        <Gutter />

        <RouteLink routeName="create-resource" data-create-resource-test>
          <PrimaryButton>{translate('createResourceButton')}</PrimaryButton>
        </RouteLink>
      </Flex>
    </Page.Row>
  </Page>
);

export default decorate(
  withModel({
    Model: AllResourcesModel,
    props: [{ name: 'resources', modelPath: 'resources' }],
  }),
)(AllResources);
