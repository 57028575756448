import bodyParametersInputMapper from 'shared-between-everything/src/doings/bodyParametersInputMapper/bodyParametersInputMapper';
import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';

export default getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/create-district',
    method: 'POST',
    inputMapper: bodyParametersInputMapper,
  },
  decoratorConfig: {
    spinnerName: 'creating-district',
    showSpinnerInstantly: true,
  },
});
