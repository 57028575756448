import React from 'react';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import HeavyText from 'shared-between-front-ends/src/components/public/HeavyText/HeavyText';
import MeasurementAmount from '../../../MeasurementAmount/MeasurementAmount';
import MeasurementUnit from '../../../MeasurementUnit/MeasurementUnit';

const EffortAmountAndDuration = ({
  effort: { amount: effortAmount, measurementUnitId },
  duration,
  ...props
}) => (
  <Flex alignRight minWidth="sm" alignTextTo="right" {...props}>
    <Div flexItem>
      <BodyText>{effortAmount}</BodyText>

      <HeavyText>
        &nbsp;
        <MeasurementUnit measurementUnitId={measurementUnitId} />
      </HeavyText>
    </Div>

    <Gutter size="xs" />

    <HeavyText flexItem block>
      <MeasurementAmount value={duration} />
    </HeavyText>
  </Flex>
);
export default EffortAmountAndDuration;
