import { onBecomeObserved, when } from 'mobx';

import setTimeoutWithThis from '../../doings/setTimeoutWithThis/setTimeoutWithThis';
import ApplicationModel from '../../models/ApplicationModel/ApplicationModel';
import getModel from '../withModel/getModel';

export const dependencies = {
  setTimeoutWithThis,
};

export default model => {
  if (model.__whenObserved) {
    const applicationModel = getModel(ApplicationModel);
    when(
      () => applicationModel.started,
      () => {
        model.__whenObserved.forEach(
          ({ methodName, triggerWhenPropertyBecomesObserved }) => {
            onBecomeObserved(model, triggerWhenPropertyBecomesObserved, () =>
              dependencies.setTimeoutWithThis(model[methodName].bind(model)),
            );
          },
        );
      },
    );
  }
};
