import React from 'react';

import Element from '../Element';

function Th({ children, ...props }) {
  return (
    <Element tagName="th" {...props}>
      {children}
    </Element>
  );
}

export default Th;
