import countBy from 'lodash/fp/countBy';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import range from 'lodash/fp/range';
import toPairs from 'lodash/fp/toPairs';
import moment from 'moment-timezone';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';

import schedulerTranslations from '../../../schedulerTranslations';

const translate = localTranslate(schedulerTranslations);

export default ({ start, end }) => {
  const numberOfDaysInRange = moment(end).diff(start, 'days') + 1;

  return flow(
    range(0),
    map(numberOfDaysFromStart =>
      moment(start).add(numberOfDaysFromStart, 'days').format(),
    ),
    map(dayFromStart => ({
      yearAndMonth: moment(dayFromStart).startOf('month').format(),
    })),
    countBy('yearAndMonth'),
    toPairs,
    map(([yearAndMonth, numberOfDaysInMonth]) => {
      const yearAndMonthMoment = moment(yearAndMonth);

      const month = translate(`months[${yearAndMonthMoment.month()}]`);

      return {
        year: yearAndMonthMoment.year(),
        month,
        shortMonth: translate(`shortMonths[${yearAndMonthMoment.month()}]`),
        numberOfDays: numberOfDaysInMonth,
      };
    }),
  )(numberOfDaysInRange);
};
