import React from 'react';

import Typography from '../../private/Typography/Typography';

function HeavyText({ size = 'xxs', children, ...props }) {
  return (
    <Typography
      {...props}
      font="primary"
      size={size}
      uppercase={false}
      bold={true}
    >
      {children}
    </Typography>
  );
}

export default HeavyText;
