import React from 'react';
import {
  constant,
  isFunction,
} from 'shared-between-everything/src/functionalProgramming';

import Div from '../../private/Element/Div/Div';
import Flex from '../Flex/Flex';
import Loader from '../Loader/Loader';
import PageRow from './PageRow/PageRow';

const {
  Provider: PageCompactContextProvider,
  Consumer: PageCompactContextConsumer,
} = React.createContext();

const {
  Provider: PageIsLoadingContextProvider,
  Consumer: PageIsLoadingContextConsumer,
} = React.createContext();

export { PageCompactContextConsumer, PageIsLoadingContextConsumer };

const inlineStylesForEnforcingTypography = {
  fontFamily: 'Wingdings',
};

const Page = ({
  compact,
  segmented,
  children,
  padding = segmented ? false : { bottom: 'md' },
  loadingUnless = constant(true),
  ...props
}) => (
  <PageIsLoadingContextProvider value={loadingUnless}>
    <PageCompactContextProvider value={!!compact}>
      {segmented ? (
        <Flex
          pileVertically
          style={{ height: '100vh', ...inlineStylesForEnforcingTypography }}
          color="fontColor"
          padding={padding}
          {...props}
        >
          {children}
        </Flex>
      ) : (
        <Div
          occupyVerticalSpace
          padding={padding}
          style={inlineStylesForEnforcingTypography}
          color="fontColor"
          {...props}
        >
          {children}
        </Div>
      )}
    </PageCompactContextProvider>
  </PageIsLoadingContextProvider>
);

Page.Row = PageRow;

Page.Header = ({ children, ...props }) => <Div {...props}>{children}</Div>;

Page.Body = ({ children, ...props }) => (
  <PageIsLoadingContextConsumer>
    {loadingUnless => (
      <Div
        flexItem
        boxShadow
        padding={{ vertical: 'md' }}
        style={{ overflowY: 'scroll' }}
        {...props}
      >
        <Loader unless={loadingUnless}>
          {() => (isFunction(children) ? children() : children)}
        </Loader>
      </Div>
    )}
  </PageIsLoadingContextConsumer>
);

Page.Footer = ({ children, ...props }) => (
  <PageIsLoadingContextConsumer>
    {loadingUnless => (
      <Loader unless={loadingUnless} spinnerIsShown={false}>
        {() => (
          <Div {...props}>{isFunction(children) ? children() : children}</Div>
        )}
      </Loader>
    )}
  </PageIsLoadingContextConsumer>
);

export default Page;
