import React from 'react';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';

export const NameAndValue = ({ name, value }) => (
  <Div>
    <BodyText color="eltelGrey" block>
      {name}
    </BodyText>

    <BodyText>{value}</BodyText>
  </Div>
);
