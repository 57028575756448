import getModel from '../../decorators/withModel/getModel';
import SessionModel from '../../models/SessionModel/SessionModel';

export const dependencies = {
  sessionModel: getModel(SessionModel),
};

export default ({
  bodyParameters,
  queryParameters,
  pathParameters,
  headers,
}) => {
  const { accessToken } = dependencies.sessionModel;

  return {
    bodyParameters,
    queryParameters,
    pathParameters,
    headers: {
      ...headers,
      Authorization: `Bearer ${accessToken.value}`,
    },
  };
};
