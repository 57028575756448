import upperFirst from 'lodash/fp/upperFirst';

import styles from './Padding.module.scss';

export default ({
  size,
  vertical = size,
  horizontal = size,
  left = horizontal,
  right = horizontal,
  top = vertical,
  bottom = vertical,
}) => {
  const classes = {};

  if (left) classes[styles[`padding__size${upperFirst(left)}Left`]] = true;
  if (right) classes[styles[`padding__size${upperFirst(right)}Right`]] = true;
  if (top) classes[styles[`padding__size${upperFirst(top)}Top`]] = true;
  if (bottom)
    classes[styles[`padding__size${upperFirst(bottom)}Bottom`]] = true;

  return classes;
};
