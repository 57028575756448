/* istanbul ignore file */

export default {
  work: {
    id: 'work',
  },

  sharedBetweenEveryone: {
    id: 'shared-between-everyone',
  },

  sharedBetweenTeamMembers: {
    id: 'shared-between-team-members',
  },
};
