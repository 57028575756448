import { flow as mobxFlow, observable } from 'mobx';
import whenRouteChangesTo from 'shared-between-front-ends/src/decorators/whenRouteChangesTo/whenRouteChangesTo';
import getModel from 'shared-between-front-ends/src/decorators/withModel/getModel';
import RoutingModel from 'shared-between-front-ends/src/models/RoutingModel/RoutingModel';
import getTeamImport from './getTeam/getTeam';

export default class TeamModel {
  dependencies = {};

  constructor(routingModel = getModel(RoutingModel), getTeam = getTeamImport) {
    this.dependencies.routingModel = routingModel;
    this.dependencies.getTeam = getTeam;
  }

  @whenRouteChangesTo(
    'district-team-scheduler',
    'district-team-absence-scheduler',
  )
  refresh = mobxFlow(function* () {
    const { teamId } = this.dependencies.routingModel.pathParameters;

    if (teamId === undefined) {
      this.name = null;
    } else {
      const { response: team } = yield this.dependencies.getTeam({ teamId });
      this.name = team.name;
    }
  });

  @observable
  name = null;
}
