import getCurrentDateTime from 'shared-between-everything/src/date-time-abstractions/getCurrentDateTime/getCurrentDateTime';
import InputModelBaseClass from '../../../../InputModelBaseClass';

export default class CompletionInputModel extends InputModelBaseClass {
  constructor({ ...configuration } = {}) {
    super({
      outboundFormatters: [value => !!value],
      inboundFormatters: [value => (value ? getCurrentDateTime() : null)],
      clearedInternalValue: null,
      ...configuration,
    });
  }

  toggleCompletion = () => {
    if (!this.internalValue) {
      this.setCompleted();
    } else {
      this.setUncompleted();
    }
  };

  setCompleted = () => {
    this.setInternalValue(getCurrentDateTime());
  };

  setUncompleted = () => {
    this.setInternalValue(null);
  };
}
