import React from 'react';

import Div from '../../../private/Element/Div/Div';
import styles from '../TileBag.module.scss';

const Tile = ({ children }) => (
  <Div className={styles.tileBag_tile}>{children}</Div>
);

export default Tile;
