import { Observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import MarginSpace from 'shared-between-front-ends/src/components/private/MarginSpace/MarginSpace';
import DropdownList from 'shared-between-front-ends/src/components/public/DropdownList/DropdownList';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import LanguageSelector from 'shared-between-front-ends/src/components/public/LanguageSelector/LanguageSelector';
import LogInOrLogOutButton from 'shared-between-front-ends/src/components/public/LogInOrLogOutButton/LogInOrLogOutButton';
import Panel from 'shared-between-front-ends/src/components/public/Panel/Panel';
import Popover from 'shared-between-front-ends/src/components/public/Popover/Popover';
import RouteLink from 'shared-between-front-ends/src/components/public/RouteLink/RouteLink';
import Subheading from 'shared-between-front-ends/src/components/public/Subheading/Subheading';
import withModel from 'shared-between-front-ends/src/decorators/withModel/withModel';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import eltelLogo from './eltel-logo.svg';
import styles from './Navigation.module.scss';
import NavigationModel from './NavigationModel';
import navigationTranslations from './navigationTranslations';

const translate = localTranslate(navigationTranslations);

const Navigation = ({
  navigationItems,
  margin = { bottom: 'md' },
  ...props
}) => (
  <Div backgroundColor="white" margin={margin} {...props}>
    <MarginSpace padding={{ vertical: 'md' }}>
      <Panel transparent showOverflow>
        <Panel.Content>
          <Flex spaceBetween centeredVertically>
            <RouteLink routeName="districts">
              <img className={styles.logo} src={eltelLogo} alt="Eltel logo" />
            </RouteLink>

            <Gutter />

            <Subheading padding={{ bottom: 'xs' }}>
              {translate('appTitle')}
            </Subheading>

            <Gutter />

            <Observer>
              {() => (
                <>
                  <Popover
                    activationId="navigation-menu"
                    activateOn="click"
                    content={
                      <DropdownList items={navigationItems}>
                        {navigationItem => (
                          <DropdownList.Link
                            onClick={navigationItem.navigateToRoute}
                            data-navigation-link-test
                          >
                            {navigationItem.text}
                          </DropdownList.Link>
                        )}
                      </DropdownList>
                    }
                    data-navigation-popover-test
                  >
                    <Icon name="bars" size="md" color="action" />
                  </Popover>
                </>
              )}
            </Observer>
            <Gutter flexItem />

            <LanguageSelector />

            <Gutter />

            <LogInOrLogOutButton />
          </Flex>
        </Panel.Content>
      </Panel>
    </MarginSpace>
  </Div>
);

export default decorate(
  withModel({
    Model: NavigationModel,
    props: [
      {
        name: 'navigationItems',
        modelPath: 'navigationItems',
      },
    ],
  }),
)(Navigation);
