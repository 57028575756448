import filter from 'lodash/fp/filter';
import map from 'lodash/fp/map';
import { computed } from 'mobx';
import pipeline from 'shared-between-everything/src/doings/pipeline/pipeline';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import getModel from 'shared-between-front-ends/src/decorators/withModel/getModel';
import RoutingModel from 'shared-between-front-ends/src/models/RoutingModel/RoutingModel';
import navigationTranslations from './navigationTranslations';

const translate = localTranslate(navigationTranslations);

export default class NavigationModel {
  dependencies = {};

  constructor(
    routingModel = getModel(RoutingModel),
    isAllowedToAccessRoute = require('shared-between-front-ends/src/doings/isAllowedToAccessRoute/isAllowedToAccessRoute')
      .default,
  ) {
    this.dependencies.routingModel = routingModel;
    this.dependencies.isAllowedToAccessRoute = isAllowedToAccessRoute;
  }

  @computed
  get navigationItems() {
    return this._getNavigationItems([
      'districts',
      'all-resources',
      'user-right-maintenance',
      'effort-definition-catalogs',
    ]);
  }

  _getNavigationItems = routeNames => {
    const toAllowedRoutes = toAllowedRoutesFor(
      this.dependencies.isAllowedToAccessRoute,
    );

    const toNavigationItems = toNavigationItemsFor(
      this.dependencies.routingModel,
    );

    return pipeline(
      routeNames,
      filter(toAllowedRoutes),
      map(toNavigationItems),
    );
  };
}

const toAllowedRoutesFor = isAllowedToAccessRoute => routeName =>
  isAllowedToAccessRoute(routeName);

const toNavigationItemsFor = routingModel => routeName => ({
  navigateToRoute: () => {
    routingModel.setRouteTo({ name: routeName });
  },
  text: translate(`routes.${routeName}`),
});
