import React from 'react';
import MarginSpace from 'shared-between-front-ends/src/components/private/MarginSpace/MarginSpace';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Form from 'shared-between-front-ends/src/components/public/Form/Form';
import Loader from 'shared-between-front-ends/src/components/public/Loader/Loader';
import Panel from 'shared-between-front-ends/src/components/public/Panel/Panel';
import SecondaryButton from 'shared-between-front-ends/src/components/public/SecondaryButton/SecondaryButton';
import TextAreaInput from 'shared-between-front-ends/src/components/public/TextAreaInput/TextAreaInput';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import PrimaryButton from 'shared-between-front-ends/src/components/public/PrimaryButton/PrimaryButton';
import resourceDashboardTranslations from '../../resourceDashboardTranslations';

const translate = localTranslate(resourceDashboardTranslations);

export default ({ normalizedItem }) => (
  <MarginSpace compact data-pop-up-for-editing-work-order-description-e2e-test>
    <Panel>
      <Panel.Heading
        iconName="tools"
        headerText={normalizedItem.workOrder.name}
      />

      <Panel.Content>
        <Loader when={() => normalizedItem.resourceDashboardModel.isLoading}>
          {() => (
            <Form
              onSubmit={normalizedItem.saveWorkOrderDescription}
              data-description-form-test
            >
              <TextAreaInput
                rows={8}
                model={normalizedItem.workOrderDescriptionInput}
                autoFocus
                label={translate('description')}
                data-description-input-test
                data-work-order-description-e2e-test
              />

              <Gutter size="xs" />

              <Flex centeredVertically>
                <Gutter flexItem />

                <SecondaryButton
                  onClick={normalizedItem.cancelEditingOfWorkOrderDescription}
                  data-cancel-test
                >
                  {translate('cancel')}
                </SecondaryButton>

                <Gutter size="md" />

                <PrimaryButton
                  type="submit"
                  data-submit-test
                  data-save-e2e-test
                >
                  {translate('save')}
                </PrimaryButton>
              </Flex>
            </Form>
          )}
        </Loader>
      </Panel.Content>
    </Panel>
  </MarginSpace>
);
