import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';

import Input from '../../private/Input/Input';
import InputWrapper from '../../private/InputWrapper/InputWrapper';
import Flex from '../Flex/Flex';
import Gutter from '../Gutter/Gutter';
import HeavyText from '../HeavyText/HeavyText';

function MeasurementAmountInput({
  label,
  model,
  flexItem,
  autoFocus,
  ...props
}) {
  return (
    <InputWrapper
      label={label}
      errorText={model.validationTranslationKey}
      flexItem={flexItem}
    >
      <Flex centeredVertically alignBaseline>
        <Input
          alignTextTo="right"
          border={{
            size: 'sm',
            color: 'grey5',
            radius: { size: 'zero' },
          }}
          type="number"
          value={model.outboundValue}
          onChange={model.setValue}
          readOnly={model.readOnly}
          autoFocus={autoFocus}
          {...props}
        />

        <Gutter size="sm" />

        <HeavyText>{model.measurementUnitMetric}</HeavyText>
      </Flex>
    </InputWrapper>
  );
}

export default decorate(observer)(MeasurementAmountInput);
