import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import sortBy from 'lodash/fp/sortBy';
import { action, observable, runInAction } from 'mobx';

import TeamModel from './TeamModel';

export default class DistrictModel {
  constructor(
    { id, name, nameOfManager, teams },
    routingModel,
    confirmAndDeleteDistrict,
    onDeletionFinished,
    sessionModel,
    browserStorage,
    confirmAndDeleteTeam,
  ) {
    this.id = id;
    this.name = name;
    this.nameOfManager = nameOfManager;

    const toTeamModel = toTeamModelFor({
      routingModel,
      confirmAndDeleteDistrict,
      onDeletionFinished,
      sessionModel,
      confirmAndDeleteTeam,
    });

    this.teams = flow(map(toTeamModel), sortBy('name'))(teams);

    this.confirmAndDeleteDistrict = confirmAndDeleteDistrict;
    this.onDeletionFinished = onDeletionFinished;
    this.browserStorage = browserStorage;

    const alreadyExpanded = !!browserStorage.get(this._storeExpandKey);

    if (alreadyExpanded) {
      runInAction(() => {
        this.expanded = true;
      });
    }

    this.navigateTo = name => {
      routingModel.setRouteTo({
        name,
        pathParameters: { districtId: this.id },
      });
    };
  }

  navigateToUpdateDistrict = () => {
    this.navigateTo('update-district');
  };

  navigateToCreateTeam = () => {
    this.navigateTo('district-create-team');
  };

  delete = async () => {
    const { callWasDone } = await this.confirmAndDeleteDistrict({
      districtId: this.id,
    });

    if (callWasDone) {
      this.onDeletionFinished();
    }
  };

  @observable expanded = false;
  @action
  toggleExpanded = () => {
    this.expanded = !this.expanded;

    if (this.expanded) {
      this.browserStorage.set(this._storeExpandKey, true);
    } else {
      this.browserStorage.remove(this._storeExpandKey);
    }
  };

  get _storeExpandKey() {
    return `district-${this.id}-is-expanded`;
  }
}

const toTeamModelFor = ({
  routingModel,
  deleteBusinessObject,
  onDeletionFinished,
  sessionModel,
  confirmAndDeleteTeam,
}) => teamData =>
  new TeamModel(
    teamData,
    routingModel,
    deleteBusinessObject,
    onDeletionFinished,
    sessionModel,
    confirmAndDeleteTeam,
  );
