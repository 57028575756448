import React from 'react';
import measurementUnits from 'shared-between-front-ends/src/components/public/MeasurementAmountInput/measurementUnits';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';

const MeasurementAmount = ({
  value: { amount, measurementUnitId },
  metricIsShown = true,
}) => (
  <span>
    <span>{amount}</span>

    {metricIsShown && (
      <span>
        {' '}
        {localTranslate(measurementUnits[measurementUnitId].translations)(
          'metric',
        )}
      </span>
    )}
  </span>
);

export default MeasurementAmount;
