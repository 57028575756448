export default {
  en: {
    workOrders: 'Work orders',
    workOrder: 'Work order',
    customerName: 'Customer name',
    workOrderName: 'Description (shown in calendar)',
    erpId: 'Erp Id',
    link: 'Link',
    description: 'Additional info',
    address: 'Address',
    postalCode: 'Postal code',
    city: 'City',
    deadlineDate: 'Deadline date',
    billableHours: 'Billable hours',
    color: 'Color',
    createWorkOrder: 'Create new work order',
    createWorkOrderButton: 'Create new work order',
    createSuccess: 'Created',
    createMultipleSuccess: '{workOrderAmount} Work orders created',
    importWorkOrders: 'Import csv',
    backButton: 'Back',
    updateWorkOrderButton: 'Update',
    updateSuccess: 'Work order updated',
    delete: 'Delete',
    deleteConfirmation: 'Confirm delete?',
    deleteSuccess: 'Work order deleted',
    done: 'Done',
    notDone: 'In progress',
    urgent: 'Urgent',
    notUrgent: 'Not urgent',
    workOrderType: 'Work order type',
    basicInformation: 'Basic information',
    basicInformationIFS: 'Basic information (IFS)',
    otherInformation: 'Shared information',
    sharedWorkOrderDisclaimer:
      'This work order is shared between all teams having department "{department}".',
    sharedOtherInformationDisclaimer:
      'Please remember that details stored are shared.',
    expectedEfforts: 'Effort',
    maintenancePage: 'Details',
  },
  fi: {
    workOrders: 'Työtilaukset',
    workOrder: 'Työtilaus',
    customerName: 'Asiakasnimi',
    workOrderName: 'Kuvaus (näkyy kalenterissa)',
    erpId: 'Työnumero',
    link: 'Linkki',
    description: 'Lisätietoja',
    address: 'Osoite',
    postalCode: 'Postinumero',
    city: 'Kaupunki',
    deadlineDate: 'Valmis viimeistään',
    billableHours: 'Käytettävissä olevat tunnit',
    color: 'Väri',
    createWorkOrder: 'Luo uusi työtilaus',
    createWorkOrderButton: 'Luo uusi työtilaus',
    createSuccess: 'Luotu',
    createMultipleSuccess: '{workOrderAmount} Työtilausta luotu',
    importWorkOrders: 'Tuo csv',
    backButton: 'Takaisin',
    updateWorkOrderButton: 'Päivitä',
    updateSuccess: 'Työtilaus päivitetty',
    delete: 'Poista',
    deleteConfirmation: 'Poistetaanko?',
    deleteSuccess: 'Työtilaus poistettu',
    done: 'Valmis',
    notDone: 'Kesken',
    urgent: 'Kiireellinen',
    notUrgent: 'Ei kiireellinen',
    workOrderType: 'Työtilauksen tyyppi',
    basicInformation: 'Perustiedot',
    basicInformationIFS: 'Perustiedot (IFS)',
    otherInformation: 'Muut tiedot',
    sharedWorkOrderDisclaimer:
      'Tämä työtilaus näytetään kaikille tiimeille joille on määritetty osasto "{department}".',
    sharedOtherInformationDisclaimer:
      'Muistathan että kaikki työtilauksen tiedot ovat yhteisiä kaikille tiimeille.',
    expectedEfforts: 'Suoritteet',
    maintenancePage: 'Tiedot',
  },
  pl: {
    workOrders: 'Zamówione prace',
    workOrder: 'Zamówiona praca',
    customerName: 'Nazwa klienta',
    workOrderName: 'Opis (widoczny w kalendarzu)',
    erpId: 'Nr IFS',
    link: 'Łącze',
    description: 'Dodatkowe informacje',
    address: 'Adres',
    postalCode: 'Kod pocztowy',
    city: 'Miasto',
    deadlineDate: 'Termin ostateczny',
    billableHours: 'Rozliczane godziny',
    color: 'Kolor',
    createWorkOrder: 'Utwórz nowe zlecenie pracy',
    createWorkOrderButton: 'Utwórz nowe zlecenie pracy',
    createSuccess: 'Utworzono',
    createMultipleSuccess: '{workOrderAmount} Utworzono zlecenia pracy',
    importWorkOrders: 'Importuj csv',
    backButton: 'Wstecz',
    updateWorkOrderButton: 'Aktualizuj',
    updateSuccess: 'Zaktualizowano zlecenie pracy',
    delete: 'Usuń',
    deleteConfirmation: 'Potwierdzić usunięcie?',
    deleteSuccess: 'Zlecenie pracy usunięte',
    done: 'Gotowe',
    notDone: 'W toku',
    urgent: 'Pilne',
    notUrgent: 'Nie pilne',
    workOrderType: 'Typ zlecenia pracy',
    basicInformation: 'Podstawowe informacje',
    basicInformationIFS: 'Podstawowe informacje (IFS)',
    otherInformation: 'Informacje udostępnione',
    sharedWorkOrderDisclaimer:
      'O zlecenie pracy jest dzielone między wszystkie zespoły posiadające dział "{department}".',
    sharedOtherInformationDisclaimer:
      'Pamiętaj, że przechowywane dane są udostępniane.',
    expectedEfforts: 'Effort',
    maintenancePage: 'Details',
  },
  se: {
    workOrders: 'Arbetsordrar',
    workOrder: 'Arbetsorder',
    customerName: 'Kundnamn',
    workOrderName: 'Beskrivning (visas i kalender)',
    erpId: 'Användarid',
    link: 'Länk',
    description: 'Extra info',
    address: 'Adress',
    postalCode: 'Postkod',
    city: 'Stad',
    deadlineDate: 'Leveransdatum',
    billableHours: 'Fakturerbara timmar',
    color: 'Färg',
    createWorkOrder: 'Skapa ny arbetsorder',
    createWorkOrderButton: 'Skapa ny arbetsorder',
    createSuccess: 'Skapad',
    createMultipleSuccess: '{workOrderAmount} Arbetsorder skapad',
    importWorkOrders: 'Importera Excel(csv)',
    backButton: 'Tillbaka',
    updateWorkOrderButton: 'Uppdatera',
    updateSuccess: 'Arbetsorder uppdaterad',
    delete: 'Ta bort',
    deleteConfirmation: 'Bekräfta borttagning?',
    deleteSuccess: 'Arbetsorder borttagen',
    done: 'Klar',
    notDone: 'Pågående',
    urgent: 'Bråskande',
    notUrgent: 'Inte bråskande',
    workOrderType: 'Arbetsorder typ',
    basicInformation: 'Grundläggande information',
    basicInformationIFS: 'Grundläggande information (IFS)',
    otherInformation: 'Delad information',
    sharedWorkOrderDisclaimer:
      'Denna arbetsorder delas mellan alla team som har CA "{department}".',
    sharedOtherInformationDisclaimer: 'Kom ihåg att lagrade uppgifter delas.',
    expectedEfforts: 'Beläggning',
    maintenancePage: 'Detaljer',
  },
};
