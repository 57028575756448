import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import AdditionalButton from 'shared-between-front-ends/src/components/public/AdditionalButton/AdditionalButton';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import Link from 'shared-between-front-ends/src/components/public/Link/Link';
import withModel from 'shared-between-front-ends/src/decorators/withModel/withModel';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';

import SchedulerModel from '../../SchedulerModel/SchedulerModel';
import schedulerTranslations from '../../../schedulerTranslations';

const translate = localTranslate(schedulerTranslations);

function SchedulerDateRangeActions({
  goToThisWeek,
  goToNextWeek,
  goToPreviousWeek,
}) {
  return (
    <Flex spaceBetween centeredVertically>
      <AdditionalButton
        onClick={goToThisWeek}
        size="sm"
        data-this-week-test
        data-go-to-current-week-e2e-test
      >
        {translate('today')}
      </AdditionalButton>

      <Gutter size="sm" />

      <Link
        onClick={goToPreviousWeek}
        defaultColors
        data-previous-week-test
        data-go-to-previous-week-e2e-test
      >
        <Icon block name="chevron-left" size="md" />
      </Link>

      <Gutter size="xs" />

      <Link
        onClick={goToNextWeek}
        defaultColors
        data-next-week-test
        data-go-to-next-week-e2e-test
      >
        <Icon block name="chevron-right" size="md" />
      </Link>

      <Div flexItem />
    </Flex>
  );
}

export default decorate(
  withModel({
    Model: SchedulerModel,
    props: [
      { name: 'goToPreviousWeek', modelPath: 'goToPreviousWeek' },
      { name: 'goToThisWeek', modelPath: 'goToThisWeek' },
      { name: 'goToNextWeek', modelPath: 'goToNextWeek' },
    ],
  }),
)(SchedulerDateRangeActions);
