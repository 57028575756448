import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import RouteLink from 'shared-between-front-ends/src/components/public/RouteLink/RouteLink';
import SemanticButton from 'shared-between-front-ends/src/components/public/SemanticButton/SemanticButton';
import SensuelContentBox from '../../../../shared/SensuelContentBox/SensuelContentBox';

const AbsenceWorkOrder = ({
  districtId,
  teamId,
  userRights,
  absenceWorkOrder: {
    backgroundColor,
    name,
    id: workOrderId,
    highlight,
    selectForScheduling,
  },
}) => {
  return (
    <SensuelContentBox
      draggable={true}
      onDragStart={event =>
        event.dataTransfer.setData(
          'appointmentOrWorkOrder',
          JSON.stringify({
            workOrderId,
          }),
        )
      }
      data-work-order-box-test
      leftArea={{
        backgroundColor,
        highlight,
        'data-absence-work-order-box-left-area-test': true,
        padding: { size: 'zero' },
        content: (
          <SemanticButton
            block
            onClick={selectForScheduling}
            padding={{ horizontal: 'sm' }}
            draggable={false}
            occupyVerticalSpace
            color="white"
            hoverColor="grey2"
            data-select-work-order-for-scheduling-test={workOrderId}
          >
            <Flex centered occupyVerticalSpace>
              <Icon
                name={highlight ? 'calendar-check' : 'calendar'}
                size="md"
                data-select-absence-work-order-for-scheduling-test
              />
            </Flex>
          </SemanticButton>
        ),
      }}
      rightArea={{
        backgroundColor: 'white',
        padding: { size: 'zero' },
        content: (
          <Div>
            {userRights.createWorkOrdersOfAllTypes ? (
              <RouteLink
                block
                draggable={false}
                routeName="district-team-update-work-order-from-absence-scheduler"
                pathParameters={{
                  districtId,
                  workOrderId,
                  teamId,
                }}
                padding={{
                  vertical: 'xxs',
                  horizontal: 'sm',
                }}
                data-update-absence-work-order-test={workOrderId}
              >
                <Flex
                  centeredVertically
                  occupyVerticalSpace
                  block
                  padding={{ vertical: 'xs' }}
                >
                  <BodyText>{name}</BodyText>
                </Flex>
              </RouteLink>
            ) : (
              <Div
                padding={{
                  vertical: 'xxs',
                  horizontal: 'sm',
                }}
                style={{ cursor: 'grab' }}
                data-not-update-absence-work-order-test={workOrderId}
              >
                <BodyText
                  occupyVerticalSpace
                  block
                  padding={{ vertical: 'xs' }}
                >
                  <Flex centeredVertically>{name}</Flex>
                </BodyText>
              </Div>
            )}
          </Div>
        ),
      }}
    />
  );
};

export default decorate(observer)(AbsenceWorkOrder);
