import getOr from 'lodash/fp/getOr';
import { computed } from 'mobx';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import EmailInputModel from 'shared-between-front-ends/src/components/public/EmailInput/EmailInputModel';
import NotificationsModel from 'shared-between-front-ends/src/components/public/Notifications/NotificationsModel';
import PhoneNumberInputModel from 'shared-between-front-ends/src/components/public/PhoneNumberInput/PhoneNumberInputModel';
import RadioButtonGroupModel from 'shared-between-front-ends/src/components/public/RadioButtonGroup/RadioButtonGroupModel';
import TextInputModel from 'shared-between-front-ends/src/components/public/TextInput/TextInputModel';
import whenRouteChangesTo from 'shared-between-front-ends/src/decorators/whenRouteChangesTo/whenRouteChangesTo';
import getModel from 'shared-between-front-ends/src/decorators/withModel/getModel';
import injectInstance from 'shared-between-front-ends/src/decorators/withModel/injectInstance';
import RoutingModel from 'shared-between-front-ends/src/models/RoutingModel/RoutingModel';
import TeamSelectInputModel from '../../team/TeamSelectInput/TeamSelectInputModel';
import resourceTranslations from '../resourceTranslations';

const translate = localTranslate(resourceTranslations);

export default class UpdateResourceModel {
  dependencies = {};

  constructor(
    getEveryInputModelIsValid = require('shared-between-front-ends/src/doings/getEveryInputModelIsValid/getEveryInputModelIsValid')
      .default,
    routingModel = getModel(RoutingModel),
    submitUpdateResource = require('./submitUpdateResource/submitUpdateResource')
      .default,
    notificationsModel = getModel(NotificationsModel),
    getResource = require('./getResource/getResource').default,
  ) {
    this.dependencies.getEveryInputModelIsValid = getEveryInputModelIsValid;
    this.dependencies.routingModel = routingModel;
    this.dependencies.submitUpdateResource = submitUpdateResource;
    this.dependencies.notificationsModel = notificationsModel;
    this.dependencies.getResource = getResource;
  }

  erpId = new TextInputModel({ required: true });

  firstName = new TextInputModel({ required: true });

  lastName = new TextInputModel({ required: true });

  phoneNumber = new PhoneNumberInputModel({ required: true });

  emailAddress = new EmailInputModel({ required: true });

  role = new RadioButtonGroupModel({
    readOnly: false,
    required: true,
    values: [
      {
        label: translate('roles.mechanic'),
        value: 'mechanic',
      },

      {
        label: translate('roles.designer'),
        value: 'designer',
      },

      {
        label: translate('roles.foreman'),
        value: 'foreman',
      },

      {
        label: translate('roles.coordinator'),
        value: 'coordinator',
      },

      {
        label: translate('roles.subcontractor'),
        value: 'subcontractor',
      },
    ],
  });

  team = injectInstance(TeamSelectInputModel);

  @whenRouteChangesTo('update-resource')
  getResource = async () => {
    const {
      response: {
        erpId,
        firstName,
        lastName,
        phoneNumber,
        emailAddress,
        role,
        team,
      },
    } = await this.dependencies.getResource({
      pathParameters: {
        resourceId: this.dependencies.routingModel.pathParameters.resourceId,
      },
    });

    this.erpId.setValue(erpId);
    this.firstName.setValue(firstName);
    this.lastName.setValue(lastName);
    this.phoneNumber.setValue(phoneNumber);
    this.emailAddress.setValue(emailAddress);
    this.role.setValue(role);
    this.team.setValue(team);
  };

  submit = async () => {
    await this.dependencies.submitUpdateResource({
      pathParameters: {
        resourceId: this.dependencies.routingModel.pathParameters.resourceId,
      },
      bodyParameters: {
        erpId: this.erpId.value,
        firstName: this.firstName.value,
        lastName: this.lastName.value,
        phoneNumber: this.phoneNumber.value,
        emailAddress: this.emailAddress.value,
        role: this.role.value,
        teamId: getOr(null, 'team.value.id', this),
      },
    });

    this.dependencies.notificationsModel.setSuccess(translate('updateSuccess'));

    this.dependencies.routingModel.goBack();
  };

  @computed
  get isValid() {
    return this.dependencies.getEveryInputModelIsValid(
      this.erpId,
      this.firstName,
      this.lastName,
      this.phoneNumber,
      this.emailAddress,
      this.role,
      this.team,
    );
  }
}
