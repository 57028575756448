import getClassNames from 'classnames';
import React from 'react';

import Div from '../../private/Element/Div/Div';
import Label from '../../private/Element/Label/Label';
import BodyText from '../BodyText/BodyText';
import Flex from '../Flex/Flex';
import Gutter from '../Gutter/Gutter';
import styles from '../RadioButtonGroup/RadioButtonGroup.module.scss';

function RadioButton({
  value,
  checked,
  onChange,
  label,
  name,
  readOnly,
  className,
  centeredHorizontally,
  ...props
}) {
  const classNames = [
    styles.radioButtonGroup_button,
    { [styles.radioButtonGroup_button__readOnly]: !!readOnly },
    className,
  ];

  const classNamesForHiddenButton = getClassNames(
    styles.radioButtonGroup_hiddenButton,
    'radio-button-input-test',
  );

  return (
    <Label className={classNames} {...props}>
      <Flex centeredHorizontally={centeredHorizontally} centeredVertically>
        <div>
          <input
            name={name}
            value={value}
            type="radio"
            onChange={() => {
              !readOnly && onChange();
            }}
            className={classNamesForHiddenButton}
            checked={checked}
            readOnly={readOnly}
          />

          <Div
            className={styles.radioButtonGroup_fakeButton}
            tabIndex="-1"
            border={{ radius: { size: 'rounded' } }}
            backgroundColor={readOnly ? 'black' : 'white'}
          >
            {checked && (
              <Div
                className={styles.radioButtonGroup_fakeButtonIcon}
                backgroundColor={readOnly ? 'black' : 'primary'}
                border={{ radius: { size: 'rounded' } }}
              />
            )}
          </Div>
        </div>

        <Gutter size="xs" />

        <BodyText
          size="3xs"
          block
          className={styles.radioButtonGroup_buttonLabel}
        >
          {label}
        </BodyText>
      </Flex>
    </Label>
  );
}

export default RadioButton;
