import React from 'react';
import MarginSpace from 'shared-between-front-ends/src/components/private/MarginSpace/MarginSpace';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import CheckboxInput from 'shared-between-front-ends/src/components/public/CheckboxInput/CheckboxInput';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Form from 'shared-between-front-ends/src/components/public/Form/Form';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import GutterBag from 'shared-between-front-ends/src/components/public/GutterBag/GutterBag';
import HeavyText from 'shared-between-front-ends/src/components/public/HeavyText/HeavyText';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import Panel from 'shared-between-front-ends/src/components/public/Panel/Panel';
import PrimaryButton from 'shared-between-front-ends/src/components/public/PrimaryButton/PrimaryButton';
import SecondaryButton from 'shared-between-front-ends/src/components/public/SecondaryButton/SecondaryButton';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import resourceDashboardTranslations from '../../../../resourceDashboardTranslations';

const translate = localTranslate(resourceDashboardTranslations);

const AddParticipationPopover = ({
  cancelSubmittingParticipation,
  submitAddingParticipation,
  participantInputs,
  participationCanBeAdded,
  isParticipationNewDefault,
}) => (
  <MarginSpace
    compact
    data-popover-for-self-scheduling-test
    data-pop-up-for-self-scheduling-e2e-test
  >
    <Form
      onSubmit={submitAddingParticipation}
      data-adding-participation-form-test
    >
      <Panel>
        <Flex pileVertically height="xlgOrViewport">
          <Panel.Header>
            <Flex centeredVertically>
              <Icon name="users" size="sm" />

              <Gutter size="sm" />

              <BodyText>{translate('addNewParticipation')}</BodyText>
            </Flex>
          </Panel.Header>

          <Panel.Content flexItem scrollOverflow>
            <HeavyText block>{translate('selectParticipants')}</HeavyText>

            <Gutter size="xs" />

            <GutterBag contents={participantInputs} gutterSize="xxs">
              {({ resource, isParticipating }) => (
                <CheckboxInput
                  padding={{ size: 'xs' }}
                  backgroundColor="grey10"
                  label={`${resource.firstName} ${resource.lastName}`}
                  model={isParticipating}
                  data-add-participant-resource-id-test={resource.id}
                />
              )}
            </GutterBag>

            <Gutter />

            <CheckboxInput
              label={translate('setParticipantsAsDefault')}
              model={isParticipationNewDefault}
              data-make-participation-as-default-test
            />
          </Panel.Content>

          <Panel.Footer>
            <Flex centered>
              <SecondaryButton
                onClick={cancelSubmittingParticipation}
                type="button"
                data-cancel-add-participation-test
              >
                {translate('cancelNewParticipation')}
              </SecondaryButton>

              <Gutter flexItem />

              <PrimaryButton
                type="submit"
                enabled={participationCanBeAdded}
                data-submit-add-participation-test
              >
                {translate('submitNewParticipation')}
              </PrimaryButton>
            </Flex>
          </Panel.Footer>
        </Flex>
      </Panel>
    </Form>
  </MarginSpace>
);

export default AddParticipationPopover;
