import getModel from '../../decorators/withModel/getModel';
import RoutingModel from '../../models/RoutingModel/RoutingModel';
import redirectTo from '../redirectTo/redirectTo';

export const dependencies = {
  redirectTo,
  routingModel: getModel(RoutingModel),
  environmentVariables: process.env,
};

export default () => {
  dependencies.redirectTo({
    url: dependencies.environmentVariables.REACT_APP_OAUTH_LOGOUT_URL,
    queryParameters: {
      client_id: dependencies.environmentVariables.REACT_APP_OAUTH_CLIENT_ID,
      post_logout_redirect_uri: dependencies.routingModel.origin,
      returnTo: dependencies.routingModel.origin,
    },
  });
};
