import React from 'react';

import {
  upperFirst,
  identity,
} from 'shared-between-everything/src/functionalProgramming';

import Div from '../../private/Element/Div/Div';
import Flex from '../Flex/Flex';
import Map from '../Map/Map';
import styles from './GutterBag.module.scss';

const GutterBag = ({
  contents,
  children = identity,
  gutterSize = 'sm',
  className,
  ...props
}) => (
  <Flex
    wrapContent
    className={[
      styles.gutterBag,
      styles[`gutterBag__gutterSize${upperFirst(gutterSize)}`],
      className,
    ]}
    {...props}
  >
    <Map items={contents}>
      {content => (
        <Div className={styles.gutterBag_item}>{children(content)}</Div>
      )}
    </Map>
  </Flex>
);

export default GutterBag;
