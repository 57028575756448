import React from 'react';
import AdditionalButton from '../AdditionalButton/AdditionalButton';
import Circle from '../Circle/Circle';

const CircleButton = ({ children, sizeBaselines = 3, ...props }) => (
  <AdditionalButton
    clickableAppearance
    border={{ size: 'sm', color: 'action', radius: { size: 'rounded' } }}
    padding={{ size: 'zero' }}
    {...props}
  >
    <Circle border={undefined} sizeBaselines={sizeBaselines}>
      {children}
    </Circle>
  </AdditionalButton>
);

export default CircleButton;
