import has from 'lodash/fp/has';
import isMap from 'lodash/fp/isMap';
import join from 'lodash/fp/join';
import overSome from 'lodash/fp/overSome';
import split from 'lodash/fp/split';
import { isObservableMap, observable } from 'mobx';

const setForMapsAndObjects = (object, keysString, newValue) => {
  const [key, ...nextKeys] = split('.', keysString);
  const nextKeysString = join('.', nextKeys);

  if (nextKeys.length === 0) {
    setForMapOrObject(object, key, newValue);
    return object;
  }

  if (hasForMapOrObject(object, key)) {
    const existingObject = getForMapOrObject(object, key);
    setForMapsAndObjects(existingObject, nextKeysString, newValue);
    return object;
  }

  const newMap = observable(new Map());
  setForMapOrObject(object, key, newMap);

  setForMapsAndObjects(newMap, nextKeysString, newValue);
  return object;
};

export default setForMapsAndObjects;

const isAnyMap = overSome([isMap, isObservableMap]);

const getForMapOrObject = (object, key) =>
  isAnyMap(object) ? object.get(key) : object[key];

const hasForMapOrObject = (object, key) =>
  isAnyMap(object) ? object.has(key) : has(key, object);

const setForMapOrObject = (object, key, value) => {
  if (isAnyMap(object)) {
    object.set(key, value);
  } else {
    object[key] = value;
  }
};
