import React from 'react';

import {
  isEmpty,
  map,
  pipeline,
  reject,
  constant,
} from 'shared-between-everything/src/functionalProgramming';
import getModel from '../../../decorators/withModel/getModel';

import Position from '../Position/Position';
import ActivationModel from './ActivationModel/ActivationModel';
import ShowWhenActivated from './ShowWhenActivated/ShowWhenActivated';
import Activate from './Activate/Activate';

function Popover({
  content,
  getContent = constant(content),
  children,
  activationModel = getModel(ActivationModel),
  activationId,
  activateOn = 'hover',
  disabled,
  enabled = !disabled,
  position,
  popovers = [{ getContent, position, activationId, enabled, activateOn }],
  ...props
}) {
  const enabledPopovers = pipeline(
    popovers,
    reject(popover => popover.enabled === false || popover.disabled === true),
  );

  const activations = enabledPopovers.map(popover => ({
    activateOn: popover.activateOn,
    activationId: popover.activationId,
  }));

  return isEmpty(enabledPopovers) ? (
    children
  ) : (
    <Position
      data-popover-test
      whats={pipeline(
        enabledPopovers,
        map(
          ({
            content,
            getContent = constant(content),
            position: how = 'left top to right top of children',
            activationId: activationId2,
          }) => ({
            what: ({ ref, style, placement }) => (
              <ShowWhenActivated
                activationModel={activationModel}
                activationId={activationId2}
                forwardedRef={ref}
                style={style}
                data-placement={placement}
                getContent={getContent}
              />
            ),

            how,
          }),
        ),
      )}
      how={position}
    >
      {({ ref }) => (
        <Activate forwardedRef={ref} activations={activations} {...props}>
          {children}
        </Activate>
      )}
    </Position>
  );
}

export default Popover;
