import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';

import {
  find,
  pipeline,
} from 'shared-between-everything/src/functionalProgramming';

import DropdownList from '../DropdownList/DropdownList';
import withModel from '../../../decorators/withModel/withModel';
import Div from '../../private/Element/Div/Div';
import Icon from '../Icon/Icon';
import Popover from '../Popover/Popover';
import LanguageSelectorModel from './LanguageSelectorModel';

function LanguageSelector({ languages, ...props }) {
  const selectedLanguage = pipeline(languages, find({ disabled: true }));

  return (
    <Div {...props}>
      <Popover
        activationId="language-menu"
        activateOn="click"
        content={
          <DropdownList items={languages}>
            {language => (
              <DropdownList.Link
                onClick={language.select}
                color={language.color}
                disabled={language.disabled}
                data-select-language-link-test={language.id}
                data-select-language-e2e-test={language.id}
              >
                {language.description}
              </DropdownList.Link>
            )}
          </DropdownList>
        }
        data-language-popover-test
      >
        <Icon
          name="globe"
          size="md"
          color="action"
          data-open-language-selector-e2e-test
          data-selected-language-e2e-test={selectedLanguage.id}
        />
      </Popover>
    </Div>
  );
}

export default decorate(
  withModel({
    Model: LanguageSelectorModel,
    props: [
      {
        name: 'languages',
        modelPath: 'languages',
      },
    ],
  }),
)(LanguageSelector);
