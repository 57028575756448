import isOneOf from 'shared-between-everything/src/doings/isOneOf/isOneOf';
import withExposedConfiguration from 'shared-between-everything/src/test-utils/withExposedConfiguration';

import ApplicationModel from '../../models/ApplicationModel/ApplicationModel';
import RoutingModel from '../../models/RoutingModel/RoutingModel';
import getModel from '../withModel/getModel';

export const dependencies = {
  routingModel: getModel(RoutingModel),
  applicationModel: getModel(ApplicationModel),
};

export default withExposedConfiguration((...routeNames) => {
  const routeChangesTo = function routeChangesTo() {
    return (
      dependencies.applicationModel.started &&
      isOneOf(routeNames, dependencies.routingModel.routeName)
    );
  };

  routeChangesTo.__parameters = { routeNames };
  return routeChangesTo;
});
