import bodyParametersInputMapper from 'shared-between-everything/src/doings/bodyParametersInputMapper/bodyParametersInputMapper';
import getCall from '../../../doings/getCall/getCall';
import errorIsAboutInvalidAuthorizationCode from './errorIsAboutInvalidAuthorizationCode/errorIsAboutInvalidAuthorizationCode';

export default getCall({
  apiCallConfig: {
    path: '/api/session/start',
    method: 'POST',
    inputMapper: bodyParametersInputMapper,
  },
  decoratorConfig: {
    spinnerName: 'starting-session',
    showSpinnerInstantly: false,
    reportErrorsUnless: errorIsAboutInvalidAuthorizationCode,
  },
});
