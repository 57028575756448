import { action, computed, observable } from 'mobx';
import {
  fromPairs,
  getOr,
  isUndefined,
  keys,
  map,
  pipeline,
} from 'shared-between-everything/src/functionalProgramming';
import * as allUserRightsImport from 'shared-between-everything/src/userRightConstants';

export default class SessionModel {
  dependencies = {};

  constructor(allUserRights = allUserRightsImport) {
    this.dependencies.allUserRights = allUserRights;
  }

  @computed
  get userIsLoggedIn() {
    return !!this.accessToken;
  }

  @computed
  get loginStatusIsKnown() {
    return !isUndefined(this._session);
  }

  @observable _session;

  @action
  setTokens = tokens => {
    this._session = {
      ...this._session,
      ...tokens,
    };
  };

  @action
  setSession = session => {
    this._session = session;
  };

  @computed
  get accessToken() {
    return getOr(null, '_session.accessToken', this);
  }

  @computed
  get userRights() {
    const allRightsAsFalse = pipeline(
      this.dependencies.allUserRights,
      keys,
      map(userRight => [userRight, false]),
      fromPairs,
    );

    const userSpecificRightsAsTrue = pipeline(
      this,
      getOr(null, '_session.user.userRights'),
      map(userRight => [userRight, true]),
      fromPairs,
    );

    return {
      ...allRightsAsFalse,
      ...userSpecificRightsAsTrue,
    };
  }
}
