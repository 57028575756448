export default {
  en: {
    expectedEffortsPageHeader: 'Efforts',
    catalog: 'Catalog',
    issue: 'Issue',
    issued: 'Issued',
    manual: 'Issued by field',
    nonIssued: 'Non-issued',
    deissue: 'Deissue',
    submit: 'Submit',
    updateDone: 'Efforts were updated',
    none: 'None',
    searchExpectedEfforts: 'Search efforts',
    expectedEfforts: 'Effort',
    maintenancePage: 'Details',
  },
  fi: {
    expectedEffortsPageHeader: 'Suoritteet',
    catalog: 'Hinnasto',
    issue: 'Tilaa',
    issued: 'Tilattu',
    manual: 'Kentällä tilattu',
    nonIssued: 'Tilaamatta',
    deissue: 'Poista',
    submit: 'Tallenna',
    updateDone: 'Suoritteet päivitetty',
    none: 'Tyhjä',
    searchExpectedEfforts: 'Etsi suoritteita',
    expectedEfforts: 'Suoritteet',
    maintenancePage: 'Tiedot',
  },
  pl: {
    expectedEffortsPageHeader: 'Efforts',
    catalog: 'Catalog',
    issue: 'Issue',
    issued: 'Issued',
    manual: 'Issued by field',
    nonIssued: 'Non-issued',
    deissue: 'Deissue',
    submit: 'Submit',
    updateDone: 'Efforts were updated',
    none: 'None',
    searchExpectedEfforts: 'Search efforts',
    expectedEfforts: 'Effort',
    maintenancePage: 'Details',
  },
  se: {
    expectedEffortsPageHeader: 'Beläggning',
    catalog: 'Arbetsmoment',
    issue: 'Problem',
    issued: 'Utfärdad',
    manual: 'Utgiven per fält',
    nonIssued: 'Ej utfärdat',
    deissue: 'Avsluta',
    submit: 'Skicka in',
    updateDone: 'Insatser uppdaterades',
    none: 'Ingen',
    searchExpectedEfforts: 'Sökinsatser',
    expectedEfforts: 'Beläggning',
    maintenancePage: 'Detaljer',
  },
};
