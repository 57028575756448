import { action, computed, ObservableMap, untracked } from 'mobx';
import {
  compact,
  map,
  pipeline,
} from 'shared-between-everything/src/functionalProgramming';
import TextInputModel from '../TextInput/TextInputModel';

export default class MultiTextInputModel {
  _inputMap = new ObservableMap();

  constructor({ initialInternalValue = [] } = {}) {
    // Todo: get rid of.
    this.__isInputModel = true;
    this.setInternalValue(initialInternalValue);
  }

  @action
  setInternalValue = ([firstValue = '', ...restValues]) => {
    this._inputMap.clear();

    [firstValue, ...restValues].forEach(value =>
      this._addInput({
        value: value,
        autoFocus: false,
      }),
    );
  };

  @computed
  get inputs() {
    return [...this._inputMap.values()];
  }

  @computed
  get internalValue() {
    return pipeline(this.inputs, map('inputModel.internalValue'), compact);
  }

  addEmptyInput = () => {
    this._addInput({
      value: '',
      autoFocus: true,
    });
  };

  _addInput = ({ value, autoFocus }) => {
    this._createTextInput({
      value,
      autoFocus,
    });
  };

  @action
  _createTextInput({ value, autoFocus }) {
    const inputModel = untracked(
      () =>
        new TextInputModel({
          initialInternalValue: value,
        }),
    );

    const inputObject = {
      inputModel,

      remove: action(() => {
        if (this._inputMap.size > 1) {
          this._inputMap.delete(inputModel);
        } else {
          inputModel.setInternalValue(null);
        }
      }),

      autoFocus,
    };

    this._inputMap.set(inputModel, inputObject);

    return inputObject;
  }
}
