import React from 'react';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import MarginSpace from 'shared-between-front-ends/src/components/private/MarginSpace/MarginSpace';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import { baseline } from 'shared-between-front-ends/src/components/public/constants';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import eltelLogo from '../Navigation/eltel-logo.svg';

const SimpleNavigation = ({ currentResourceName, teamName, ...props }) => (
  <Div backgroundColor="white" {...props}>
    <MarginSpace compact>
      <Flex centeredVertically spaceBetween padding={{ vertical: 'sm' }}>
        <img
          style={{ height: 4 * baseline }}
          src={eltelLogo}
          alt="Eltel logo"
        />

        <Gutter />

        <Div alignTextTo="right">
          <BodyText size="3xs" block>
            {currentResourceName}
          </BodyText>

          <BodyText size="3xs">{teamName}</BodyText>
        </Div>
      </Flex>
    </MarginSpace>
  </Div>
);

export default SimpleNavigation;
