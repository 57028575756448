export default {
  en: {
    loginRequired: 'Login is required',
    login: 'Login',
  },
  fi: {
    loginRequired: 'Kirjautuminen vaaditaan',
    login: 'Kirjaudu',
  },
  pl: {
    loginRequired: 'Zalogowanie jest wymagane',
    login: 'Zaloguj się',
  },
  se: {
    loginRequired: 'Inloggning behövs',
    login: 'Logga in',
  },
};
