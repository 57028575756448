import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';

import Input from '../../private/Input/Input';
import InputWrapper from '../../private/InputWrapper/InputWrapper';

function NumberInput({
  label,
  model = {},
  value = model.outboundValue,
  onChange = model.setValue,
  readOnly = model.readOnly,
  errorText = model.validationTranslationKey,
  flexItem,
  ...props
}) {
  return (
    <InputWrapper label={label} errorText={errorText} flexItem={flexItem}>
      <Input
        border={{ size: 'sm', color: 'grey5', radius: { size: 'zero' } }}
        type="number"
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        {...props}
      />
    </InputWrapper>
  );
}

export const NotObserverNumberInput = NumberInput;
export default decorate(observer)(NumberInput);
