import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';

const SensuelContentBox = ({
  leftArea: { content: leftAreaContent, ...leftAreaProps },
  rightArea,
  ...props
}) => {
  const {
    backgroundColor: rightAreaBackgroundColor,
    content: rightAreaContent,
    ...rightAreaProps
  } = rightArea;

  const rightAreaIsShown = rightAreaContent;

  return (
    <Flex
      backgroundColor={rightAreaBackgroundColor}
      stretchToSameHeight
      {...props}
    >
      <Div
        occupyHorizontalSpace={!rightAreaIsShown}
        padding={{ horizontal: 'sm' }}
        {...leftAreaProps}
      >
        {leftAreaContent}
      </Div>

      {rightAreaIsShown && (
        <Div
          padding={{
            vertical: 'xxs',
            horizontal: 'sm',
          }}
          flexItem
          {...rightAreaProps}
        >
          {rightAreaContent}
        </Div>
      )}
    </Flex>
  );
};

export default decorate(observer)(SensuelContentBox);
