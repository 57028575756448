import React from 'react';

import A from '../../private/Element/A/A';
import styles from './Link.module.scss';

const Link = ({
  to = '#', // eslint-disable-line
  children,
  openInNewWindow = false,
  defaultColors,
  className,
  ...props
}) => {
  const colorProps = defaultColors
    ? { color: 'action', hoverColor: 'actionHover' }
    : { color: 'fontColor', hoverColor: 'primary' };

  return (
    <A
      href={to}
      target={openInNewWindow ? '_blank' : null}
      rel="noopener noreferrer"
      className={[styles.link, className]}
      {...colorProps}
      {...props}
    >
      {children}
    </A>
  );
};

export default Link;
