import React from 'react';

import MarginSpace from '../../../private/MarginSpace/MarginSpace';
import Panel from '../../Panel/Panel';

import { PageCompactContextConsumer } from '../Page';

const PageRow = ({
  children,
  margin = { bottom: 'md' },
  responsiveFullWidth,
  withPanel,
  alignWithPanel,
  ...props
}) => (
  <PageCompactContextConsumer>
    {compact => (
      <MarginSpace
        compact={compact}
        margin={margin}
        noPaddingForSmallerThanSm={responsiveFullWidth}
        {...props}
      >
        {withPanel && (
          <Panel>
            <Panel.Content>{children}</Panel.Content>
          </Panel>
        )}

        {alignWithPanel && (
          <Panel transparent>
            <Panel.Content>{children}</Panel.Content>
          </Panel>
        )}

        {!withPanel && !alignWithPanel && children}
      </MarginSpace>
    )}
  </PageCompactContextConsumer>
);

export default PageRow;
