import overSome from 'lodash/fp/overSome';
import upperFirst from 'lodash/fp/upperFirst';

import styles from './Element.module.scss';

export default input => {
  const sizeIsSpecifiedWithoutDirection =
    !input.left && !input.right && !input.top && !input.bottom && input.size;
  const parameterIsJustBoolean = input === true;
  const parametersAreIncomplete =
    parameterIsJustBoolean || sizeIsSpecifiedWithoutDirection;

  const {
    left,
    right,
    top,
    bottom,
    size = input.size || 'sm',
  } = parametersAreIncomplete
    ? { left: true, right: true, top: true, bottom: true }
    : input;

  const radiusSize = upperFirst(size);

  const directions = { left, right, top, bottom };

  return {
    [styles[`element_borderRadius__topLeft${radiusSize}`]]: hasTopLeftRadius(
      directions,
    ),
    [styles[`element_borderRadius__topRight${radiusSize}`]]: hasTopRightRadius(
      directions,
    ),
    [styles[
      `element_borderRadius__bottomRight${radiusSize}`
    ]]: hasBottomRightRadius(directions),
    [styles[
      `element_borderRadius__bottomLeft${radiusSize}`
    ]]: hasBottomLeftRadius(directions),
  };
};

const hasTopLeftRadius = overSome([
  ({ left, right, top, bottom }) => top && !left && !right,
  ({ left, right, top, bottom }) => !top && left && !bottom,
  ({ left, right, top, bottom }) => top && left,
]);

const hasTopRightRadius = overSome([
  ({ left, right, top, bottom }) => top && !right && !left,
  ({ left, right, top, bottom }) => !top && right && !bottom,
  ({ left, right, top, bottom }) => top && right,
]);

const hasBottomLeftRadius = overSome([
  ({ left, right, top, bottom }) => bottom && !left && !right,
  ({ left, right, top, bottom }) => !bottom && left && !top,
  ({ left, right, top, bottom }) => bottom && left,
]);

const hasBottomRightRadius = overSome([
  ({ left, right, top, bottom }) => bottom && !right && !left,
  ({ left, right, top, bottom }) => !bottom && right && !top,
  ({ left, right, top, bottom }) => bottom && right,
]);
