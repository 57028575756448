import React from 'react';
import Element from '../Element';

const Option = ({ children, value, ...props }) => (
  <Element tagName="option" value={value === null ? '' : value} {...props}>
    {children}
  </Element>
);

export default Option;
