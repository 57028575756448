import moment from 'moment-timezone';

export default dateTime => {
  if (dateTime === null || dateTime === undefined) {
    return null;
  }

  if (dateTime === 0) {
    return moment(dateTime).format('YYYY-MM');
  }

  if (dateTime.match(/^\d{4}-\d$/)) {
    return moment(dateTime, 'YYYY-M').format('YYYY-MM');
  }

  return moment(dateTime).format('YYYY-MM');
};
