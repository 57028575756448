import React from 'react';

import Div from '../../private/Element/Div/Div';
import PanelContent from './PanelContent/PanelContent';
import PanelHeader from './PanelHeader/PanelHeader';
import PanelHeading from './PanelHeading/PanelHeading';

const {
  Provider: PanelTransparencyContextProvider,
  Consumer: PanelTransparencyContextConsumer,
} = React.createContext();

export { PanelTransparencyContextConsumer };

const Panel = ({
  children,
  transparent,
  backgroundColor = transparent ? 'transparent' : 'white',
  showOverflow = true,
  ...props
}) => (
  <PanelTransparencyContextProvider value={{ panelIsTransparent: transparent }}>
    <Div
      backgroundColor={backgroundColor}
      boxShadow={!transparent}
      hideOverflow={!showOverflow}
      showOverflow={showOverflow}
      {...props}
    >
      {children}
    </Div>
  </PanelTransparencyContextProvider>
);

Panel.Heading = PanelHeading;
Panel.Header = PanelHeader;
Panel.Content = PanelContent;
Panel.Footer = PanelContent;

export default Panel;
