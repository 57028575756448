import { find, get, pipeline } from './functionalProgramming';

import {
  doScheduling,
  scheduleWorkOrdersOfAllTypes,
} from './userRightConstants';
import workOrderCategories from './workOrderCategories';

import workOrderColors from './workOrderColors';

const defaultWorkOrderColor = pipeline(
  workOrderColors,
  find({ default: true }),
  get('value'),
);

export default {
  workOrder: {
    type: 'workOrder',
    relevantForResourceCoOperation: true,
    category: workOrderCategories.work.id,
    isObfuscatedWithoutUserRight: false,
    canBeScheduledWithUserRight: doScheduling,
    absenceScheduling: null,
    canBeDone: true,
    canBeUrgent: true,
    isTeamSpecific: true,
    isCountedInAbsenceBudget: false,
    canBeDeleted: true,
    typeCanBeChanged: true,
    appointmentsAreSimplified: false,
    colorIsUserSelectable: true,
    color: defaultWorkOrderColor,
    translations: {
      en: { name: 'Work order' },
      fi: { name: 'Työtilaus' },
      pl: { name: 'Zlecenie pracy' },
      se: { name: 'Arbetsorder' },
    },
  },

  teamActivity: {
    type: 'teamCommon',
    relevantForResourceCoOperation: true,
    category: workOrderCategories.sharedBetweenTeamMembers.id,
    canBeScheduledWithUserRight: doScheduling,
    isObfuscatedWithoutUserRight: false,
    absenceScheduling: null,
    canBeDone: true,
    canBeUrgent: true,
    isTeamSpecific: true,
    isCountedInAbsenceBudget: false,
    canBeDeleted: true,
    typeCanBeChanged: true,
    appointmentsAreSimplified: true,
    colorIsUserSelectable: true,
    color: '#424297',

    translations: {
      en: { name: 'Resource' },
      fi: { name: 'Resurssi' },
      pl: { name: 'Zasób' },
      se: { name: 'Resurs' },
    },
  },

  sharedActivity: {
    type: 'commonWork',
    relevantForResourceCoOperation: true,
    category: workOrderCategories.sharedBetweenEveryone.id,
    canBeScheduledWithUserRight: scheduleWorkOrdersOfAllTypes,
    isObfuscatedWithoutUserRight: false,
    absenceScheduling: null,
    canBeDone: false,
    canBeUrgent: false,
    isTeamSpecific: false,
    isCountedInAbsenceBudget: false,
    canBeDeleted: false,
    typeCanBeChanged: false,
    appointmentsAreSimplified: true,
    colorIsUserSelectable: true,
    color: '#717296',

    translations: {
      en: { name: 'Common work' },
      fi: { name: 'Yleinen työ (kaikille tiimeille yhteinen)' },
      pl: { name: 'Wspólna praca' },
      se: { name: 'Gemensamt arbete' },
    },
  },

  annualLeave: {
    type: 'annualLeave',
    relevantForResourceCoOperation: false,
    category: workOrderCategories.sharedBetweenEveryone.id,
    canBeScheduledWithUserRight: scheduleWorkOrdersOfAllTypes,
    isObfuscatedWithoutUserRight: true,

    absenceScheduling: {
      sortOrder: 0,
      countValueAs: 'balance',
      balancePathInIntegration: 'VuosilomaPäivissä',
      valueBackgroundColor: 'grey5',
    },

    canBeDone: false,
    canBeUrgent: false,
    isTeamSpecific: false,
    isCountedInAbsenceBudget: true,
    canBeDeleted: false,
    typeCanBeChanged: false,
    appointmentsAreSimplified: true,
    colorIsUserSelectable: false,
    color: '#008000',

    translations: {
      en: {
        shortBalanceHeader: 'Annual',
        name: 'Annual leave',
      },

      fi: {
        shortBalanceHeader: 'Vuosi',
        name: 'Vuosiloma',
      },

      pl: {
        shortBalanceHeader: 'Ann.',
        name: 'Annual leave',
      },
      se: {
        shortBalanceHeader: 'Sem.',
        name: 'Semester',
      },
    },
  },

  annualLeavePlanned: {
    type: 'annualLeavePlanned',
    relevantForResourceCoOperation: false,
    category: workOrderCategories.sharedBetweenEveryone.id,
    canBeScheduledWithUserRight: scheduleWorkOrdersOfAllTypes,
    isObfuscatedWithoutUserRight: true,

    absenceScheduling: {
      sortOrder: 1,
      countValueAs: 'balance',
      balancePathInIntegration: 'VuosilomaPäivissä',
      valueBackgroundColor: 'white',
    },

    canBeDone: false,
    canBeUrgent: false,
    isTeamSpecific: false,
    isCountedInAbsenceBudget: true,
    canBeDeleted: false,
    typeCanBeChanged: false,
    appointmentsAreSimplified: true,
    colorIsUserSelectable: false,
    color: '#008000',

    translations: {
      en: {
        shortBalanceHeader: 'leave',
        name: 'Planned',
      },

      fi: {
        shortBalanceHeader: 'loma',
        name: 'Suunniteltu',
      },

      pl: {
        shortBalanceHeader: 'Ann.',
        name: 'Annual leave',
      },
      se: {
        shortBalanceHeader: 'Sem.',
        name: 'Semester',
      },
    },
  },

  pekkanenLeave: {
    type: 'pekkanenLeave',
    relevantForResourceCoOperation: false,
    category: workOrderCategories.sharedBetweenEveryone.id,
    canBeScheduledWithUserRight: scheduleWorkOrdersOfAllTypes,
    isObfuscatedWithoutUserRight: true,

    absenceScheduling: {
      sortOrder: 2,
      countValueAs: 'balance',
      balancePathInIntegration: 'PekkasetPäivissä',
      valueBackgroundColor: 'grey5',
    },

    canBeDone: false,
    canBeUrgent: false,
    isTeamSpecific: false,
    isCountedInAbsenceBudget: true,
    canBeDeleted: false,
    typeCanBeChanged: false,
    appointmentsAreSimplified: true,
    colorIsUserSelectable: false,
    color: '#00ccff',

    translations: {
      en: {
        shortBalanceHeader: 'Pekka',
        name: 'Pekkanen',
      },

      fi: {
        shortBalanceHeader: 'Pekka',
        name: 'Pekkanen',
      },

      pl: {
        shortBalanceHeader: 'Pekk.',
        name: 'Pekkanen',
      },
      se: {
        shortBalanceHeader: 'ATK',
        name: 'ATK',
      },
    },
  },

  pekkanenLeavePlanned: {
    type: 'pekkanenLeavePlanned',
    relevantForResourceCoOperation: false,
    category: workOrderCategories.sharedBetweenEveryone.id,
    canBeScheduledWithUserRight: scheduleWorkOrdersOfAllTypes,
    isObfuscatedWithoutUserRight: true,

    absenceScheduling: {
      sortOrder: 3,
      countValueAs: 'balance',
      balancePathInIntegration: 'PekkasetPäivissä',
      valueBackgroundColor: 'white',
    },

    canBeDone: false,
    canBeUrgent: false,
    isTeamSpecific: false,
    isCountedInAbsenceBudget: true,
    canBeDeleted: false,
    typeCanBeChanged: false,
    appointmentsAreSimplified: true,
    colorIsUserSelectable: false,
    color: '#00ccff',

    translations: {
      en: {
        shortBalanceHeader: 'nen',
        name: 'Planned',
      },

      fi: {
        shortBalanceHeader: 'nen',
        name: 'Suunniteltu',
      },

      pl: {
        shortBalanceHeader: 'Pekk.',
        name: 'Pekkanen',
      },
      se: {
        shortBalanceHeader: 'ATK',
        name: 'ATK',
      },
    },
  },

  flexibleLeave: {
    type: 'flexibleLeave',
    relevantForResourceCoOperation: false,
    category: workOrderCategories.sharedBetweenEveryone.id,
    canBeScheduledWithUserRight: scheduleWorkOrdersOfAllTypes,
    isObfuscatedWithoutUserRight: true,

    absenceScheduling: {
      sortOrder: 4,
      countValueAs: 'balance',
      balancePathInIntegration: 'JoustovapaaPäivissä',
      valueBackgroundColor: 'grey5',
    },

    canBeDone: false,
    canBeUrgent: false,
    isTeamSpecific: false,
    isCountedInAbsenceBudget: true,
    canBeDeleted: false,
    typeCanBeChanged: false,
    appointmentsAreSimplified: true,
    colorIsUserSelectable: false,
    color: '#ffcc00',

    translations: {
      en: {
        shortBalanceHeader: 'Flexib',
        name: 'Flexible leave',
      },

      fi: {
        shortBalanceHeader: 'Jous',
        name: 'Joustovapaa',
      },

      pl: {
        shortBalanceHeader: 'Flex.',
        name: 'Elastyczny urlop',
      },
      se: {
        shortBalanceHeader: 'Komp',
        name: 'Komp',
      },
    },
  },

  flexibleLeavePlanned: {
    type: 'flexibleLeavePlanned',
    relevantForResourceCoOperation: false,
    category: workOrderCategories.sharedBetweenEveryone.id,
    canBeScheduledWithUserRight: scheduleWorkOrdersOfAllTypes,
    isObfuscatedWithoutUserRight: true,

    absenceScheduling: {
      sortOrder: 5,
      countValueAs: 'balance',
      balancePathInIntegration: 'JoustovapaaPäivissä',
      valueBackgroundColor: 'white',
    },

    canBeDone: false,
    canBeUrgent: false,
    isTeamSpecific: false,
    isCountedInAbsenceBudget: true,
    canBeDeleted: false,
    typeCanBeChanged: false,
    appointmentsAreSimplified: true,
    colorIsUserSelectable: false,
    color: '#ffcc00',

    translations: {
      en: {
        shortBalanceHeader: 'Leave',
        name: 'Planned',
      },

      fi: {
        shortBalanceHeader: 'tovap',
        name: 'Suunniteltu',
      },

      pl: {
        shortBalanceHeader: 'Flex.',
        name: 'Elastyczny urlop',
      },
      se: {
        shortBalanceHeader: 'Komp',
        name: 'Komp',
      },
    },
  },

  holidayBonusLeave: {
    type: 'holidayBonusLeave',
    relevantForResourceCoOperation: false,
    category: workOrderCategories.sharedBetweenEveryone.id,
    canBeScheduledWithUserRight: scheduleWorkOrdersOfAllTypes,
    isObfuscatedWithoutUserRight: true,

    absenceScheduling: {
      sortOrder: 6,
      countValueAs: 'balance',
      balancePathInIntegration: 'LomarahavapaatPäivissä',
      valueBackgroundColor: 'grey5',
    },

    canBeDone: false,
    canBeUrgent: false,
    isTeamSpecific: false,
    isCountedInAbsenceBudget: true,
    canBeDeleted: false,
    typeCanBeChanged: false,
    appointmentsAreSimplified: true,
    colorIsUserSelectable: false,
    color: '#35d010',

    translations: {
      en: {
        shortBalanceHeader: 'Holiday',
        name: 'Holiday bonus leave',
      },

      fi: {
        shortBalanceHeader: 'Loma',
        name: 'Lomarahavapaa',
      },

      pl: {
        shortBalanceHeader: 'Hol.',
        name: 'Dodatkowy urlop wakacyjny',
      },
      se: {
        shortBalanceHeader: 'Sem-Led',
        name: 'Semesternobus ledighet',
      },
    },
  },
  holidayBonusLeavePlanned: {
    type: 'holidayBonusLeavePlanned',
    relevantForResourceCoOperation: false,
    category: workOrderCategories.sharedBetweenEveryone.id,
    canBeScheduledWithUserRight: scheduleWorkOrdersOfAllTypes,
    isObfuscatedWithoutUserRight: true,

    absenceScheduling: {
      sortOrder: 7,
      countValueAs: 'balance',
      balancePathInIntegration: 'LomarahavapaatPäivissä',
      valueBackgroundColor: 'white',
    },

    canBeDone: false,
    canBeUrgent: false,
    isTeamSpecific: false,
    isCountedInAbsenceBudget: true,
    canBeDeleted: false,
    typeCanBeChanged: false,
    appointmentsAreSimplified: true,
    colorIsUserSelectable: false,
    color: '#35d010',

    translations: {
      en: {
        shortBalanceHeader: 'bonus',
        name: 'Planned',
      },

      fi: {
        shortBalanceHeader: 'rahav',
        name: 'Suunniteltu',
      },

      pl: {
        shortBalanceHeader: 'Hol.',
        name: 'Dodatkowy urlop wakacyjny',
      },
      se: {
        shortBalanceHeader: 'Sem-Led',
        name: 'Semesternobus ledighet',
      },
    },
  },

  balanceLeave: {
    type: 'balanceLeave',
    relevantForResourceCoOperation: false,
    category: workOrderCategories.sharedBetweenEveryone.id,
    canBeScheduledWithUserRight: scheduleWorkOrdersOfAllTypes,
    isObfuscatedWithoutUserRight: true,

    absenceScheduling: {
      sortOrder: 8,
      countValueAs: 'total-count',
      valueBackgroundColor: 'white',
    },

    canBeDone: false,
    canBeUrgent: false,
    isTeamSpecific: false,
    isCountedInAbsenceBudget: true,
    canBeDeleted: false,
    typeCanBeChanged: false,
    appointmentsAreSimplified: true,
    colorIsUserSelectable: false,
    color: '#e27220',

    translations: {
      en: {
        shortBalanceHeader: 'Bal.',
        name: 'Balance leave',
      },

      fi: {
        shortBalanceHeader: 'Saldo',
        name: 'Saldovapaa',
      },

      pl: {
        shortBalanceHeader: 'Bal.',
        name: 'Balance leave',
      },
      se: {
        shortBalanceHeader: 'Vab',
        name: 'Vab',
      },
    },
  },

  common: {
    type: 'common',
    relevantForResourceCoOperation: false,
    category: workOrderCategories.sharedBetweenEveryone.id,
    canBeScheduledWithUserRight: scheduleWorkOrdersOfAllTypes,
    isObfuscatedWithoutUserRight: true,

    absenceScheduling: {
      sortOrder: 9,
      countValueAs: 'total-count',
      valueBackgroundColor: 'white',
    },

    canBeDone: true,
    canBeUrgent: true,
    isTeamSpecific: false,
    isCountedInAbsenceBudget: true,
    canBeDeleted: false,
    typeCanBeChanged: false,
    appointmentsAreSimplified: true,
    colorIsUserSelectable: false,
    color: '#b200ff',

    translations: {
      en: {
        shortBalanceHeader: 'Abs.',
        name: 'Common leave',
      },

      fi: {
        shortBalanceHeader: 'Muut',
        name: 'Poissaolo (kaikille tiimeille yhteinen)',
      },

      pl: {
        shortBalanceHeader: 'Nieobec.',
        name: 'Urlop wspólny',
      },
      se: {
        shortBalanceHeader: 'Sjuk',
        name: 'Gemensam ledighet',
      },
    },
  },

  layOff: {
    type: 'layOff',
    relevantForResourceCoOperation: false,
    category: workOrderCategories.sharedBetweenEveryone.id,
    canBeScheduledWithUserRight: scheduleWorkOrdersOfAllTypes,
    isObfuscatedWithoutUserRight: true,

    absenceScheduling: {
      sortOrder: 10,
      countValueAs: 'total-count',
      valueBackgroundColor: 'white',
    },

    canBeDone: false,
    canBeUrgent: false,
    isTeamSpecific: false,
    isCountedInAbsenceBudget: true,
    canBeDeleted: false,
    typeCanBeChanged: false,
    appointmentsAreSimplified: true,
    colorIsUserSelectable: false,
    color: '#ff0000',

    translations: {
      en: {
        shortBalanceHeader: 'Lay-off',
        name: 'Lay off',
      },

      fi: {
        shortBalanceHeader: 'Lom.',
        name: 'Lomautus',
      },

      pl: {
        shortBalanceHeader: 'Zwoln.',
        name: 'Zwolnienie',
      },
      se: {
        shortBalanceHeader: 'Fris.',
        name: 'Friställning',
      },
    },
  },
};
