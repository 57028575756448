import { action, computed } from 'mobx';
import queryString from 'query-string';

import {
  filter,
  matches,
  conforms,
  find,
  flow,
  get,
  identity,
  isUndefined,
  join,
  map,
  not,
  pickBy,
  toPairs,
} from 'shared-between-everything/src/functionalProgramming';

import withExposedConfiguration from 'shared-between-everything/src/test-utils/withExposedConfiguration';
import getModel from '../../decorators/withModel/getModel';
import RouterStoreModel from './RouterStoreModel/RouterStoreModel';
import SessionModel from '../SessionModel/SessionModel';
import inlinePathParametersInPath from './inlinePathParametersInPath';
import pickRouteFor from './pickRouteFor';

export default class RoutingModel {
  dependencies = {};
  routeStack = [];

  constructor({
    windowLocation = window.location,
    sessionModel = getModel(SessionModel),
    routerStoreModel = getModel(RouterStoreModel),
  } = {}) {
    this.dependencies.routerStoreModel = routerStoreModel;
    this.dependencies.windowLocation = windowLocation;
    this.dependencies.sessionModel = sessionModel;

    this.routeStack.push(
      get('location.pathname', this.dependencies.routerStoreModel),
    );
  }

  routes = [];
  pickRoute = pickRouteFor([]);

  setRoutes = routes => {
    const routesWithoutBackRoute = getRoutesWithoutBackRoute(routes);

    if (routesWithoutBackRoute.length > 0) {
      throw new Error(
        `Back route is not defined for routes "${routesWithoutBackRoute
          .map(get('name'))
          .join('", "')}"`,
      );
    }

    this.routes = routes;

    this.pickRoute = pickRouteFor(this.routes);
  };

  get origin() {
    return this.dependencies.windowLocation.origin;
  }

  get path() {
    return this.dependencies.routerStoreModel.path;
  }

  get history() {
    return this.dependencies.routerStoreModel.history;
  }

  @computed
  get queryParameters() {
    return queryString.parse(
      this.dependencies.routerStoreModel.location.search,
    );
  }

  @computed
  get routeName() {
    return get('name', this.externalRoute);
  }

  @computed
  get pathParameters() {
    return get('pathParameters', this.externalRoute);
  }

  @computed
  get _route() {
    return this._getRouteFromPath(this.path);
  }

  @computed
  get internalRoute() {
    const { name, pathParameters } = this._route;

    return { name, pathParameters };
  }

  @computed
  get externalRoute() {
    const {
      name,
      pathParameters,
      loginIsRequired,
      requiredUserRight,
      View,
      ViewModel,
    } = this._route;

    if (
      name === 'session-start' &&
      !this.dependencies.sessionModel.loginStatusIsKnown
    ) {
      const sessionStart = this.routes.find(matches({ name: 'session-start' }));

      return {
        name: sessionStart.name,
        pathParameters: {},
        View: sessionStart.View,
        ViewModel: sessionStart.ViewModel,
      };
    }

    if (!this.dependencies.sessionModel.loginStatusIsKnown) {
      const loginPending = this.routes.find(matches({ name: 'login-pending' }));

      return {
        name: loginPending.name,
        pathParameters: {},
        View: loginPending.View,
        ViewModel: loginPending.ViewModel,
      };
    }

    if (loginIsRequired && !this.dependencies.sessionModel.userIsLoggedIn) {
      const loginRequired = this.routes.find(
        matches({ name: 'login-required' }),
      );

      return {
        name: loginRequired.name,
        pathParameters: {},
        View: loginRequired.View,
        ViewModel: loginRequired.ViewModel,
      };
    }

    if (
      requiredUserRight &&
      !this.dependencies.sessionModel.userRights[requiredUserRight]
    ) {
      const userRightIsRequired = this.routes.find(
        matches({ name: 'user-right-required' }),
      );

      return {
        name: userRightIsRequired.name,

        pathParameters: {
          missingUserRight: requiredUserRight,
        },

        View: userRightIsRequired.View,
        ViewModel: userRightIsRequired.ViewModel,
      };
    }

    return { name, pathParameters, View, ViewModel };
  }

  getPath = name => {
    const { path } = find({ name }, this.routes);

    return path;
  };

  _getRouteFromPath = path =>
    this.pickRoute(path) || {
      ...this.routes.find(matches({ name: 'not-found' })),
      pathParameters: {},
    };

  setRouteByPath = path => {
    this.routeStack.push(path);
    this.dependencies.routerStoreModel.push(path);
  };

  @action
  setRouteTo = ({ name, pathParameters = {}, queryParameters = {} }) => {
    const url = this.getUrl(name, pathParameters, queryParameters);

    this.setRouteByPath(url);
  };

  getUrl = (routeName, pathParameters, queryParameters) => {
    const path = this.getPath(routeName);

    if (!path) {
      return null;
    }

    const bothPathParameters = {
      ...this.internalRoute.pathParameters,
      ...pathParameters,
    };

    const route = inlinePathParametersInPath(bothPathParameters, path);

    const queryParameterString = getQueryParameterString(queryParameters);
    const urlWithQueryParameterString = queryParameterString
      ? `${route}?${queryParameterString}`
      : route;
    return urlWithQueryParameterString;
  };

  setRouteFor = withExposedConfiguration(route => () => {
    this.setRouteTo(route);
  });

  setRouteToFrontPage = () => {
    this.setRouteByPath('/');
  };

  mergeQueryParameters = newQueryParameters => {
    const queryParameters = pickBy(identity, {
      ...this.queryParameters,
      ...newQueryParameters,
    });

    this.setRouteTo({
      name: this.routeName,
      pathParameters: this.pathParameters,
      queryParameters,
    });
  };

  goBack = () => {
    this.setRouteTo({ name: this._route.backRoute });
  };

  get goingBackIsPossible() {
    return !!this._route.backRoute;
  }
}

const getQueryParameterString = flow(toPairs, map(join('=')), join('&'));

const routeHasBackRoute = conforms({ backRoute: not(isUndefined) });

const getRoutesWithoutBackRoute = filter(not(routeHasBackRoute));
