import { observer } from 'mobx-react';
import React from 'react';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import Popover from 'shared-between-front-ends/src/components/public/Popover/Popover';
import EffortDescription from '../../EffortDescription/EffortDescription';
import AddParticipationPopover from './AddParticipationPopover/AddParticipationPopover';
import Participations from './Participations/Participations';

const EffortContent = ({
  model: {
    activationModel,
    descriptionIsShown,
    effortDefinitionCatalogItem,
    effortEntryHighlightColor,
    excludingIsShown,
    expectedEffortEntryDescriptionIsExpanded,
    includingIsShown,
    roundedDurationPerUnit,
    showAddParticipationPopover,
    participations,
    participantInputs,
    submitAddingParticipation,
    cancelSubmittingParticipation,
    participationCanBeAdded,
    isParticipationNewDefault,
  },

  type,
  ...props
}) => (
  <Div {...props}>
    <Div
      border={{ size: 'sm', color: effortEntryHighlightColor }}
      style={{ borderTop: 0 }}
      backgroundColor="white"
    >
      <Popover
        activationModel={activationModel}
        activateOn="manual"
        activationId={`add-participation-popover-for-${effortDefinitionCatalogItem.id}`}
        position="vertical center of screen"
        getContent={() => (
          <AddParticipationPopover
            participationCanBeAdded={participationCanBeAdded}
            participantInputs={participantInputs}
            cancelSubmittingParticipation={cancelSubmittingParticipation}
            submitAddingParticipation={submitAddingParticipation}
            isParticipationNewDefault={isParticipationNewDefault}
          />
        )}
      />

      <EffortDescription
        isExpanded={expectedEffortEntryDescriptionIsExpanded}
        effortDefinitionCatalogItem={effortDefinitionCatalogItem}
        descriptionIsShown={descriptionIsShown}
        includingIsShown={includingIsShown}
        excludingIsShown={excludingIsShown}
        roundedDurationPerUnit={roundedDurationPerUnit}
        data-toggle-expected-effort-definition-description-test={
          effortDefinitionCatalogItem.id
        }
      />

      <Participations
        effortDefinitionCatalogItem={effortDefinitionCatalogItem}
        showAddParticipationPopover={showAddParticipationPopover}
        participations={participations}
      />
    </Div>
  </Div>
);

export default observer(EffortContent);
