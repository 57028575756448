import { computed } from 'mobx';
import { get, some } from 'shared-between-everything/src/functionalProgramming';
import ActivationModel from '../../components/public/Popover/ActivationModel/ActivationModel';
import getModel from '../../decorators/withModel/getModel';

export default class OverlayModel {
  dependencies = {};

  constructor(activationModel = getModel(ActivationModel)) {
    this.dependencies.activationModel = activationModel;
  }

  close = () => {
    this.dependencies.activationModel.deactivateAll();
  };

  @computed get visible() {
    return someHasOverlay(this.dependencies.activationModel.activations);
  }
}

const someHasOverlay = some(get('context.showOverlay'));
