import { observer } from 'mobx-react';
import { lifecycle } from 'recompose';

export const dependencies = {
  setError: require('../../App/ErrorTentative/setError').default,
};

export default function withReactErrorBoundary(ToBeDecorated) {
  const Decorated = lifecycle({
    componentDidCatch(error) {
      dependencies.setError(error);
    },
  })(observer(ToBeDecorated));

  Decorated.displayName = `withReactErrorBoundary(${
    ToBeDecorated.displayName || ToBeDecorated.name
  })`;

  return Decorated;
}
