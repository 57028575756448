import React from 'react';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import MarginSpace from 'shared-between-front-ends/src/components/private/MarginSpace/MarginSpace';
import AdditionalButton from 'shared-between-front-ends/src/components/public/AdditionalButton/AdditionalButton';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import { baseline } from 'shared-between-front-ends/src/components/public/constants';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Form from 'shared-between-front-ends/src/components/public/Form/Form';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import Loader from 'shared-between-front-ends/src/components/public/Loader/Loader';
import MainHeading from 'shared-between-front-ends/src/components/public/MainHeading/MainHeading';
import Page from 'shared-between-front-ends/src/components/public/Page/Page';
import Panel from 'shared-between-front-ends/src/components/public/Panel/Panel';
import Popover from 'shared-between-front-ends/src/components/public/Popover/Popover';
import PrimaryButton from 'shared-between-front-ends/src/components/public/PrimaryButton/PrimaryButton';
import SecondaryButton from 'shared-between-front-ends/src/components/public/SecondaryButton/SecondaryButton';
import SelectFile from 'shared-between-front-ends/src/components/public/SelectFile/SelectFile';
import SemanticButton from 'shared-between-front-ends/src/components/public/SemanticButton/SemanticButton';
import Table from 'shared-between-front-ends/src/components/public/Table/Table';
import TextAreaInput from 'shared-between-front-ends/src/components/public/TextAreaInput/TextAreaInput';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import Navigation from '../Navigation/Navigation';
import effortDefinitionCatalogsTranslations from './effortDefinitionCatalogsTranslations';

const translate = localTranslate(effortDefinitionCatalogsTranslations);

const EffortDefinitionCatalogs = ({ model }) => (
  <Page backgroundColor="white" data-effort-definition-catalogs-e2e-test>
    <Navigation />

    <Loader unless={() => !!model.catalogs}>
      {() => (
        <Div>
          <Page.Row alignWithPanel>
            <MainHeading>{translate('catalogs')}</MainHeading>
          </Page.Row>

          <Page.Row alignWithPanel>
            <Flex alignRight>
              <Popover
                activationId="add-effort-definition-catalog"
                activateOn="manual"
                position="vertical center of screen"
                content={
                  <MarginSpace compact data-add-catalog-popup-e2e-test>
                    <Panel>
                      <Flex
                        centeredVertically
                        color="white"
                        backgroundColor="modalHeader"
                        padding={{ size: 'sm' }}
                      >
                        <Icon name="plus" compact />

                        <Gutter size="xs" />

                        <BodyText>{translate('addNewCatalog')}</BodyText>
                      </Flex>

                      <Loader
                        when={() => model.submitting}
                        padding={{ size: 'lg' }}
                      >
                        {() => (
                          <Panel.Content padding={{ size: 'lg' }}>
                            <Form
                              onSubmit={model.submit}
                              data-add-effort-definition-catalog-test
                            >
                              <Flex>
                                <Div flexItem>
                                  <SelectFile
                                    label={translate('selectFile')}
                                    model={model.catalogCsv}
                                    data-select-file-as-catalog-test
                                    inputProps={{
                                      'data-catalog-csv-input-e2e-test': true,
                                    }}
                                  >
                                    {translate('browse')}
                                  </SelectFile>
                                </Div>

                                <Gutter />

                                <Div flexItem>
                                  <TextAreaInput
                                    label={translate('writeDescription')}
                                    model={model.description}
                                    data-description-test
                                    data-description-e2e-test
                                  />
                                </Div>
                              </Flex>

                              <Gutter />

                              <Flex>
                                <Div flexItem />

                                <AdditionalButton
                                  onClick={model.closePopoverForAddingCatalog}
                                  type="reset"
                                  data-cancel-adding-effort-definition-test
                                >
                                  {translate('cancel')}
                                </AdditionalButton>

                                <Gutter size="md" />

                                <PrimaryButton
                                  enabled={model.catalogCanBeSubmitted}
                                  data-submit-button-test
                                  data-submit-e2e-test
                                  type="submit"
                                >
                                  {translate('add')}
                                </PrimaryButton>
                              </Flex>
                            </Form>
                          </Panel.Content>
                        )}
                      </Loader>
                    </Panel>
                  </MarginSpace>
                }
              >
                <SecondaryButton
                  onClick={model.openPopoverForAddingCatalog}
                  data-open-adding-effort-definition-catalog-test
                  data-add-catalog-e2e-test
                >
                  {translate('addNewCatalog')}
                </SecondaryButton>
              </Popover>
            </Flex>
          </Page.Row>

          <Page.Row>
            <Table
              striped
              cols={[
                {
                  content: (
                    <Table.HeadingCell>{translate('name')}</Table.HeadingCell>
                  ),
                  width: '100%',
                },

                {
                  content: (
                    <Table.HeadingCell>
                      {translate('description')}
                    </Table.HeadingCell>
                  ),
                  width: '100%',
                },

                {
                  content: (
                    <Table.HeadingCell>
                      {translate('createdDateTime')}
                    </Table.HeadingCell>
                  ),
                  width: 22 * baseline,
                },

                {
                  content: (
                    <Table.HeadingCell
                      padding={{ vertical: 'sm', horizontal: 'zero' }}
                    />
                  ),
                  width: 10 * baseline,
                },
              ]}
              rows={model.catalogs}
            >
              {catalog => (
                <Table.Row
                  data-catalog-e2e-test={catalog.name}
                  data-catalog-description-e2e-test={catalog.description}
                >
                  <Table.Cell>
                    <BodyText>{catalog.name}</BodyText>
                  </Table.Cell>

                  <Table.Cell>
                    <BodyText>{catalog.description}</BodyText>
                  </Table.Cell>

                  <Table.Cell>
                    <BodyText>{catalog.createdAt}</BodyText>
                  </Table.Cell>

                  <Table.Cell
                    padding={{ vertical: 'sm', horizontal: 'zero' }}
                    alignTextTo="center"
                  >
                    <SemanticButton
                      inlineBlock
                      onClick={catalog.delete}
                      color="danger"
                      hoverColor="danger"
                      data-delete-catalog-test={catalog.id}
                      data-delete-catalog-e2e-test={catalog.id}
                    >
                      <Icon
                        name="circle-minus"
                        alt={translate('deleteCatalog')}
                        size="md"
                      />
                    </SemanticButton>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table>
          </Page.Row>
        </Div>
      )}
    </Loader>
  </Page>
);

export default EffortDefinitionCatalogs;
