import React from 'react';
import Element from '../Element';

function Td({ children, ...props }) {
  return (
    <Element tagName="td" {...props}>
      {children}
    </Element>
  );
}

export default Td;
