import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import DateInput from 'shared-between-front-ends/src/components/public/DateInput/DateInput';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Form from 'shared-between-front-ends/src/components/public/Form/Form';
import GoBackButton from 'shared-between-front-ends/src/components/public/GoBackButton/GoBackButton';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import MainHeading from 'shared-between-front-ends/src/components/public/MainHeading/MainHeading';
import NumberInput from 'shared-between-front-ends/src/components/public/NumberInput/NumberInput';
import Page from 'shared-between-front-ends/src/components/public/Page/Page';
import PrimaryButton from 'shared-between-front-ends/src/components/public/PrimaryButton/PrimaryButton';
import RadioButtonGroup from 'shared-between-front-ends/src/components/public/RadioButtonGroup/RadioButtonGroup';
import Subheading from 'shared-between-front-ends/src/components/public/Subheading/Subheading';
import TextAreaInput from 'shared-between-front-ends/src/components/public/TextAreaInput/TextAreaInput';
import TextInput from 'shared-between-front-ends/src/components/public/TextInput/TextInput';
import TileBag from 'shared-between-front-ends/src/components/public/TileBag/TileBag';
import withModel from 'shared-between-front-ends/src/decorators/withModel/withModel';
import SpinnerTentative from 'shared-between-front-ends/src/decorators/withSpinnerFor/SpinnerTentative/SpinnerTentative';
import Navigation from '../../Navigation/Navigation';
import WorkOrderColorInput from '../WorkOrderColorInput/WorkOrderColorInput';
import workOrderTranslations from '../workOrderTranslations';
import CreateWorkOrderModel from './CreateWorkOrderModel';

const translate = localTranslate(workOrderTranslations);

function CreateWorkOrder({
  address,
  billableHours,
  city,
  color,
  colorInputIsVisible,
  customerName,
  deadlineDate,
  description,
  isValid,
  link,
  name,
  postalCode,
  submit,
  type,
}) {
  return (
    <Page data-create-work-order-page-e2e-test>
      <Navigation />

      <Page.Row alignWithPanel margin={{ bottom: 'md' }}>
        <MainHeading>{translate('createWorkOrder')}</MainHeading>
      </Page.Row>

      <SpinnerTentative
        spinnerName="creating-work-order"
        data-create-work-order-spinner-test
      >
        <Form onSubmit={submit}>
          <Page.Row alignWithPanel>
            <Subheading>{translate('basicInformation')}</Subheading>
          </Page.Row>

          <Page.Row withPanel>
            <TileBag
              tiles={[
                <TextInput
                  label={translate('customerName')}
                  model={customerName}
                  autoFocus
                  data-work-order-customer-name-input-test
                  data-name-of-customer-e2e-test
                />,

                <TextInput
                  label={translate('workOrderName')}
                  model={name}
                  data-work-order-name-input-test
                  data-name-of-work-order-e2e-test
                />,

                <TextInput
                  label={translate('address')}
                  model={address}
                  data-address-input-test
                  data-address-e2e-test
                />,

                <TextInput
                  label={translate('postalCode')}
                  model={postalCode}
                  data-postal-code-input-test
                  data-postal-code-e2e-test
                />,

                <TextInput
                  label={translate('city')}
                  model={city}
                  data-city-input-test
                  data-city-e2e-test
                />,

                <NumberInput
                  label={translate('billableHours')}
                  model={billableHours}
                  data-billable-hours-input-test
                  data-billable-hours-e2e-test
                />,

                <WorkOrderColorInput
                  label={translate('color')}
                  model={color}
                  shown={colorInputIsVisible}
                  data-color-input-test
                  data-color-input-e2e-test={color.internalValue}
                />,
              ]}
            />

            <Gutter />

            <RadioButtonGroup
              label={translate('workOrderType')}
              model={type}
              data-type-select-input-test
            />
          </Page.Row>

          <Page.Row alignWithPanel>
            <Subheading>{translate('otherInformation')}</Subheading>
          </Page.Row>

          <Page.Row withPanel>
            <DateInput
              label={translate('deadlineDate')}
              model={deadlineDate}
              data-deadline-date-input-test
            />

            <Gutter />

            <TextInput
              label={translate('link')}
              model={link}
              data-link-input-test
            />

            <Gutter />

            <TextAreaInput
              label={translate('description')}
              model={description}
              data-description-input-test
              data-description-e2e-test
            />
          </Page.Row>

          <Page.Row alignWithPanel margin={false}>
            <Flex spaceBetween>
              <GoBackButton>{translate('backButton')}</GoBackButton>

              <PrimaryButton
                data-submit-button-test
                data-create-new-work-order-e2e-test
                type="submit"
                enabled={isValid}
              >
                {translate('createWorkOrderButton')}
              </PrimaryButton>
            </Flex>
          </Page.Row>
        </Form>
      </SpinnerTentative>
    </Page>
  );
}

export default decorate(
  withModel({
    Model: CreateWorkOrderModel,
    props: [
      {
        name: 'customerName',
        modelPath: 'customerName',
      },
      {
        name: 'name',
        modelPath: 'name',
      },
      {
        name: 'link',
        modelPath: 'link',
      },
      {
        name: 'description',
        modelPath: 'description',
      },
      {
        name: 'address',
        modelPath: 'address',
      },
      {
        name: 'postalCode',
        modelPath: 'postalCode',
      },
      {
        name: 'city',
        modelPath: 'city',
      },
      {
        name: 'deadlineDate',
        modelPath: 'deadlineDate',
      },
      {
        name: 'billableHours',
        modelPath: 'billableHours',
      },
      {
        name: 'color',
        modelPath: 'color',
      },
      {
        name: 'colorInputIsVisible',
        modelPath: 'colorInputIsVisible',
      },
      {
        name: 'type',
        modelPath: 'type',
      },
      {
        name: 'submit',
        modelPath: 'submit',
      },
      {
        name: 'isValid',
        modelPath: 'isValid',
      },
    ],
  }),
)(CreateWorkOrder);
