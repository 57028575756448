import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import MarginSpace from 'shared-between-front-ends/src/components/private/MarginSpace/MarginSpace';
import AttentionText from 'shared-between-front-ends/src/components/public/AttentionText/AttentionText';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Form from 'shared-between-front-ends/src/components/public/Form/Form';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import GutterBag from 'shared-between-front-ends/src/components/public/GutterBag/GutterBag';
import HeavyText from 'shared-between-front-ends/src/components/public/HeavyText/HeavyText';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import Link from 'shared-between-front-ends/src/components/public/Link/Link';
import Panel from 'shared-between-front-ends/src/components/public/Panel/Panel';
import Popover from 'shared-between-front-ends/src/components/public/Popover/Popover';
import SecondaryButton from 'shared-between-front-ends/src/components/public/SecondaryButton/SecondaryButton';
import TextInput from 'shared-between-front-ends/src/components/public/TextInput/TextInput';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import schedulerTranslations from '../../../../schedulerTranslations';

const translate = localTranslate(schedulerTranslations);

const AbsenceBudgetModal = ({
  activationModel,
  selectedBudgetMonth,
  unselectBudgetMonth,
}) => (
  <Popover
    activationModel={activationModel}
    activateOn="manual"
    activationId="selected-budget-month-popover"
    position="vertical center of screen"
    getContent={() => (
      <MarginSpace compact data-test>
        <Panel>
          <Panel.Header>
            <Flex centeredVertically>
              <HeavyText>
                {translate(`months[${selectedBudgetMonth.month - 1}]`)}
              </HeavyText>

              <Gutter size="xxs" />

              <HeavyText>{selectedBudgetMonth.year}</HeavyText>

              <Gutter flexItem size="xxs" />

              <Link color="white" onClick={unselectBudgetMonth}>
                <Icon size="md" name="xmark" />
              </Link>
            </Flex>
          </Panel.Header>

          <Panel.Content>
            <Form
              onSubmit={selectedBudgetMonth.submit}
              data-submit-absence-budget-test
            >
              <Flex centeredVertically>
                <BodyText flexItem>{translate('absenceBudgetHeader')}</BodyText>

                <Gutter size="sm" />

                <TextInput
                  flexItem
                  autoFocus
                  data-editable-budget-test
                  model={selectedBudgetMonth.budgetedAbsenceDayCount}
                />
              </Flex>

              <Gutter size="md" />

              <GutterBag
                centeredVertically
                spaceBetween
                contents={[
                  <Link onClick={unselectBudgetMonth} data-close-modal-test>
                    <Flex centeredVertically>
                      <AttentionText>{translate('cancel')}</AttentionText>
                    </Flex>
                  </Link>,

                  <SecondaryButton
                    type="submit"
                    enabled={
                      selectedBudgetMonth.budgetedAbsenceDayCount.isValid
                    }
                    data-submit-form-test
                  >
                    {translate('save')}
                  </SecondaryButton>,
                ]}
              />
            </Form>
          </Panel.Content>
        </Panel>
      </MarginSpace>
    )}
  />
);

export default decorate(observer)(AbsenceBudgetModal);
