import find from 'lodash/fp/find';
import withExposedConfiguration from '../../test-utils/withExposedConfiguration';

export default withExposedConfiguration(setError => {
  const withErrorHandlingUnless = withExposedConfiguration((...unlesses) => {
    const getReasonToBypassErrorReporting = error =>
      find(unless => unless(error), unlesses);

    return toBeDecorated => async (...parameters) => {
      let returnValue;

      try {
        returnValue = await toBeDecorated(...parameters);
      } catch (error) {
        if (!getReasonToBypassErrorReporting(error)) {
          setError(error);
        }

        throw error;
      }

      if (returnValue) {
        const callWasUnsuccessful = returnValue.callWasSuccessful === false;

        const callError = returnValue.error || {
          message: 'Back-end call was unsuccessful',
        };

        const reasonToBypassErrorReporting = getReasonToBypassErrorReporting(
          callError,
        );

        if (callWasUnsuccessful && !reasonToBypassErrorReporting) {
          setError(callError);

          throw callError;
        }

        if (callWasUnsuccessful && reasonToBypassErrorReporting) {
          returnValue.reasonToBypassErrorReporting = reasonToBypassErrorReporting;
        }
      }

      return returnValue;
    };
  });

  const withErrorHandling = withErrorHandlingUnless();

  withErrorHandling.unless = withExposedConfiguration(withErrorHandlingUnless);

  return withErrorHandling;
});
