export default {
  en: {
    noSelectedValue: 'Select a value',
    unknownSelection: 'Unknown selection: "{unknownSelectionName}"',
  },
  fi: {
    noSelectedValue: 'Valitse arvo',
    unknownSelection: 'Epäkelpo valinta: "{unknownSelectionName}"',
  },
  pl: {
    noSelectedValue: 'Select a value',
    unknownSelection: 'Unknown selection: "{unknownSelectionName}"',
  },
  se: {
    noSelectedValue: 'Välj ett värde',
    unknownSelection: 'Okänt val: "{unknownSelectionName}"',
  },
};
