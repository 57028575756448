import { observer } from 'mobx-react';
import React from 'react';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import HeavyText from 'shared-between-front-ends/src/components/public/HeavyText/HeavyText';
import MainHeading from 'shared-between-front-ends/src/components/public/MainHeading/MainHeading';
import Map from 'shared-between-front-ends/src/components/public/Map/Map';
import Page from 'shared-between-front-ends/src/components/public/Page/Page';
import PrimaryButton from 'shared-between-front-ends/src/components/public/PrimaryButton/PrimaryButton';
import SecondaryButton from 'shared-between-front-ends/src/components/public/SecondaryButton/SecondaryButton';
import Subheading from 'shared-between-front-ends/src/components/public/Subheading/Subheading';
import ToggleGroup from 'shared-between-front-ends/src/components/public/ToggleGroup/ToggleGroup';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import Navigation from '../../../Navigation/Navigation';
import teamTranslations from '../../teamTranslations';

const translate = localTranslate(teamTranslations);

const TeamEffortCatalogMaintenance = ({ model }) => (
  <Page
    loadingUnless={() => model.lazyDto.promiseStatus.fulfilled}
    segmented
    data-team-effort-catalogs-test
    data-team-expected-efforts-page-e2e-test
  >
    <Page.Header>
      <Navigation />
    </Page.Header>

    <Page.Body padding={false}>
      {() => (
        <>
          <Page.Row padding={{ top: 'md' }} alignWithPanel>
            <MainHeading>
              {translate('teamEffortDefinitionCatalogsHeading', {
                teamName: model.teamName,
              })}
            </MainHeading>
          </Page.Row>

          <Page.Row alignWithPanel>
            <Flex>
              <ToggleGroup
                values={[
                  {
                    label: translate('openTeamDetailTab'),
                    value: 'teamDetailTab',
                  },
                  {
                    label: translate('openTeamEffortCatalogMaintenanceTab'),
                    value: 'teamEffortCatalogsTab',
                  },
                ]}
                value="teamEffortCatalogsTab"
                onChange={model.openTeamDetailTab}
                data-team-maintenance-tabs-test
              />

              <Div flexItem />
            </Flex>
          </Page.Row>

          <Page.Row withPanel>
            <Subheading>{translate('selectedTeamCatalogs')}</Subheading>

            <Gutter size="sm" />

            <Map
              items={model.selectedCatalogs}
              placeholder={<HeavyText>{translate('noCatalogs')}</HeavyText>}
            >
              {catalog => (
                <Flex centeredVertically striped padding={{ size: 'xs' }}>
                  <HeavyText>{catalog.name}</HeavyText>

                  <Gutter flexItem size="md" />

                  <SecondaryButton
                    onClick={catalog.isSelectedInput.setValueToFalse}
                    size="sm"
                    data-deselect-catalog-test={catalog.id}
                  >
                    {translate('removeCatalogFromTeam')}
                  </SecondaryButton>
                </Flex>
              )}
            </Map>
          </Page.Row>

          <Page.Row withPanel>
            <Subheading>{translate('nonSelectedTeamCatalogs')}</Subheading>

            <Gutter size="sm" />

            <Map
              items={model.nonSelectedCatalogs}
              placeholder={<HeavyText>{translate('noCatalogs')}</HeavyText>}
            >
              {catalog => (
                <Flex centeredVertically striped padding={{ size: 'xs' }}>
                  <HeavyText>{catalog.name}</HeavyText>

                  <Gutter flexItem size="md" />

                  <SecondaryButton
                    onClick={catalog.isSelectedInput.setValueToTrue}
                    size="sm"
                    data-select-catalog-test={catalog.id}
                    data-add-team-catalog-e2e-test={catalog.name}
                  >
                    {translate('addCatalogForTeam')}
                  </SecondaryButton>
                </Flex>
              )}
            </Map>
          </Page.Row>
        </>
      )}
    </Page.Body>

    <Page.Footer padding={{ size: 'sm' }} backgroundColor="white">
      {() => (
        <Page.Row alignWithPanel showOverflow margin={false}>
          <Flex centeredVertically>
            <SecondaryButton
              onClick={model.goBack}
              boxShadow={{ size: 'lg' }}
              data-go-back-test
            >
              {translate('backButton')}
            </SecondaryButton>

            <Gutter flexItem size="md" />

            <PrimaryButton
              onClick={model.submit}
              boxShadow={{ size: 'lg' }}
              data-submit-test
              data-submit-e2e-test
            >
              {translate('submitTeamCatalogs')}
            </PrimaryButton>
          </Flex>
        </Page.Row>
      )}
    </Page.Footer>
  </Page>
);

export default observer(TeamEffortCatalogMaintenance);
