export default {
  en: {
    today: 'Today',
    tomorrow: 'Tomorrow',
    description: 'Description',
    otherResources: 'Other people at location',
    info: 'Info',
    link: 'Link',
    refresh: 'Refresh',
    issuedEfforts: 'Issued efforts',
    manualEfforts: 'Manual efforts',
    navigateToEffortEntries: 'Log effort',
    actualEffortEntries: 'Actual',
    addManualEffort: 'Add effort',
    addManualEffortShort: 'Add',
    submitEffortEntries: 'Submit',
    selectCatalog: 'Select catalog:',
    selectCatalogItem: 'Select item:',
    cancel: 'Cancel',
    comment: 'Comments',
    effortDescription: 'Effort description',
    close: 'Close',
    including: 'Includes',
    excluding: 'Excludes',
    durationPerUnit: 'Duration per unit (h)',
    noEfforts:
      'There are no added efforts for the work order. You can add efforts through "Add effort" button.',
    pastFutureDelimiter: 'Previous',
    scrollToFutureAppointments: 'Today',
    olderAppointmentsAreNotShown: 'Older appointments are not shown',
    furtherAppointmentsAreNotShown: 'Further appointments are not shown',
    showPrevious7Days: 'Show previous 7 days',
    showPreviousDay: 'Show previous day',
    showNext7Days: 'Show next 7 days',
    participants: 'Participants',
    defaultParticipantsGotSaved: 'Default participants got saved',
    storeDefaultParticipants: 'Store default',
    save: 'Save',
    editWorkOrderDescription: 'Edit',
    workOrderDescriptionUpdateSuccess: 'Description updated',
    selfSchedule: 'Add appointment',
    teamWorkOrdersAreCropped:
      'Number of lines are cropped to {maximumNumberOfShownTeamWorkOrders}.',
    hasActualEffortEntriesForDay: 'This day contains effort entries.',
    hasActualEffortEntries:
      'This work order contains effort entries but not for today.',
    addNewParticipation: 'Add new participation',
    selectParticipants: 'Select participants',
    cancelNewParticipation: 'Cancel',
    submitNewParticipation: 'Add',
    setParticipantsAsDefault: 'Set participants as default',
    participations: 'Participations',
    reportUpdated: 'Report updated',
    reportUpdatedText:
      'There has been an update to the report. Please reload the page to get the latest.',
    reloadPage: 'Reload the page',
    submitAndClose: 'Submit & Close',
  },
  fi: {
    today: 'Tänään',
    tomorrow: 'Huomenna',
    description: 'Lisätietoja',
    link: 'Linkki',
    refresh: 'Päivitä',
    info: 'Huomioitavaa',
    otherResources: 'Muut tiimiläiset paikalla',
    issuedEfforts: 'Tilatut suoritteet',
    manualEfforts: 'Lisätyt suoritteet',
    navigateToEffortEntries: 'Kirjaa suoritteita',
    actualEffortEntries: 'Toteutuneet',
    addManualEffort: 'Lisää suorite',
    addManualEffortShort: 'Lisää',
    submitEffortEntries: 'Tallenna',
    selectCatalog: 'Valitse yksikkölista:',
    selectCatalogItem: 'Valitse suorite:',
    cancel: 'Peruuta',
    comment: 'Kommentit',
    effortDescription: 'Suoritteen kuvaus',
    close: 'Sulje',
    including: 'Sisältää',
    excluding: 'Ei sisällä',
    durationPerUnit: 'Tuotantopalkkion kesto (h)',
    noEfforts:
      'Työtilaukselle ei ole lisätty suoritteita. Voit lisätä suoritteita “Lisää suorite” -napin kautta.',
    pastFutureDelimiter: 'Aikaisemmat',
    scrollToFutureAppointments: 'Tänään',
    olderAppointmentsAreNotShown: 'Vanhempia tapaamisia ei näytetä',
    furtherAppointmentsAreNotShown: 'Myöhempiä tapaamisia ei näytetä',
    showPrevious7Days: 'Näytä edelliset 7 päivää',
    showPreviousDay: 'Näytä edeltävä päivä',
    showNext7Days: 'Näytä seuraavat 7 päivää',
    participants: 'Osallistuneet henkilöt',
    defaultParticipantsGotSaved: 'Oletusosallistujat tallennettu',
    storeDefaultParticipants: 'Aseta oletus',
    save: 'Tallenna',
    editWorkOrderDescription: 'Muokkaa',
    workOrderDescriptionUpdateSuccess: 'Lisätiedot päivitetty',
    selfSchedule: 'Lisää aikataulutus',
    teamWorkOrdersAreCropped:
      'Enintään {maximumNumberOfShownTeamWorkOrders} riviä näytetään. Tarkenna hakua.',
    hasActualEffortEntriesForDay: 'Tälle päivälle on raportoitu suoritteita.',
    hasActualEffortEntries:
      'Tälle työtilaukselle on raportoitu suoritteita, mutta ei tälle päivälle.',
    addNewParticipation: 'Lisää uusi kokoonpano',
    selectParticipants: 'Valitse osallistujat',
    cancelNewParticipation: 'Peruuta',
    submitNewParticipation: 'Lisää',
    setParticipantsAsDefault: 'Aseta valitut oletukseksi',
    participations: 'Kokoonpanot',
    reportUpdated: 'Raportissa muutoksia',
    reportUpdatedText:
      'Toinen käyttäjä on muokannut raporttia. Ole hyvä ja päivitä sivu nähdäksesi muutokset.',
    reloadPage: 'Päivitä sivu',
    submitAndClose: 'Tallenna & Sulje',
  },
  pl: {
    today: 'Dzisiaj',
    tomorrow: 'Jutro',
    description: 'Opis',
    link: 'Link',
    refresh: 'Odśwież',
    info: 'Info',
    otherResources: 'Inne osoby w lokalizacji',
    issuedEfforts: 'Issued efforts',
    manualEfforts: 'Manual efforts',
    navigateToEffortEntries: 'Log effort',
    actualEffortEntries: 'Actual',
    addManualEffort: 'Add effort',
    addManualEffortShort: 'Add',
    submitEffortEntries: 'Submit',
    selectCatalog: 'Select catalog:',
    selectCatalogItem: 'Select item:',
    cancel: 'Cancel',
    comment: 'Comments',
    effortDescription: 'Effort description',
    close: 'Close',
    including: 'Includes',
    excluding: 'Excludes',
    durationPerUnit: 'Duration per unit (h)',
    noEfforts:
      'There are no added efforts for the work order. You can add efforts through "Add effort" button.',
    pastFutureDelimiter: 'Previous',
    scrollToFutureAppointments: 'Today',
    olderAppointmentsAreNotShown: 'Older appointments are not shown',
    furtherAppointmentsAreNotShown: 'Further appointments are not shown',
    showPrevious7Days: 'Show previous 7 days',
    showPreviousDay: 'Show previous day',
    showNext7Days: 'Show next week',
    participants: 'Participants',
    defaultParticipantsGotSaved: 'Default participants got saved',
    storeDefaultParticipants: 'Store default',
    save: 'Save',
    editWorkOrderDescription: 'Edit',
    workOrderDescriptionUpdateSuccess: 'Description updated',
    selfSchedule: 'Add appointment',
    teamWorkOrdersAreCropped:
      'Number of lines are cropped to {maximumNumberOfShownTeamWorkOrders}.',
    hasActualEffortEntriesForDay: 'This day contains effort entries.',
    hasActualEffortEntries:
      'This work order contains effort entries but not for today.',
    addNewParticipation: 'Add new participation',
    selectParticipants: 'Select participants',
    cancelNewParticipation: 'Cancel',
    submitNewParticipation: 'Add',
    setParticipantsAsDefault: 'Set participants as default',
    participations: 'Participations',
  },
  se: {
    today: 'Idag',
    tomorrow: 'Imorgon',
    description: 'Beskrivning',
    otherResources: 'Annan personal på plats',
    info: 'Info',
    link: 'Länk',
    refresh: 'Uppdatera',
    issuedEfforts: 'Utfärdade AO',
    manualEfforts: 'Manuella AO',
    navigateToEffortEntries: 'Logga beskrivning',
    actualEffortEntries: 'Verklig',
    addManualEffort: 'Lägg till beskrivning',
    addManualEffortShort: 'Lägg till',
    submitEffortEntries: 'Skicka in',
    selectCatalog: 'Välj arbetsmoment:',
    selectCatalogItem: 'Välj objekt:',
    cancel: 'Avbryt',
    comment: 'Kommentar',
    effortDescription: 'Arbetslogg',
    close: 'Stäng',
    including: 'Omfattar',
    excluding: 'Omfattar inte',
    durationPerUnit: 'Varaktighet per AO (h)',
    noEfforts:
      'Det finns inga beskrivningar för arbetsordern. Du kan lägga till beskrivning genom knappen "Lägg till beskrivning".',
    pastFutureDelimiter: 'Tidigare',
    scrollToFutureAppointments: 'I dag',
    olderAppointmentsAreNotShown: 'Äldre möten visas inte',
    furtherAppointmentsAreNotShown: 'Ytterligare möten visas inte',
    showPrevious7Days: 'Visa de senaste 7 dagarna',
    showPreviousDay: 'Visa föregående dag',
    showNext7Days: 'Visa nästa vecka',
    participants: 'kollega',
    defaultParticipantsGotSaved: 'Standarddeltagare sparades',
    storeDefaultParticipants: 'Spara standard kollega',
    save: 'Spara',
    editWorkOrderDescription: 'Ändra',
    workOrderDescriptionUpdateSuccess: 'Beskrivning uppdaterad',
    selfSchedule: 'Lägg till AO',
    teamWorkOrdersAreCropped:
      'Antal rader beskärs till {maximumNumberOfShownTeamWorkOrders}.',
    hasActualEffortEntriesForDay: 'Denna dag innehåller arbetsbeskrivningar.',
    hasActualEffortEntries:
      'Denna AO innehåller arbetsbeskrivningar men inte för idag.',
    addNewParticipation: 'Lägg till en kollega',
    selectParticipants: 'Välj kollega',
    cancelNewParticipation: 'Avbryt',
    submitNewParticipation: 'Lägg till',
    setParticipantsAsDefault: 'Sätt kollega som standard',
    participations: 'Kollega',
    reportUpdated: 'Rapport uppdaterad',
    reportUpdatedText:
      'Det har skett en uppdatering av rapporten. Ladda om sidan för att få det senaste.',
    reloadPage: 'Ladda om sidan',
    submitAndClose: 'Skicka in & Stäng',
  },
};
