import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';

import { first } from 'shared-between-everything/src/functionalProgramming';

import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import AdditionalButton from 'shared-between-front-ends/src/components/public/AdditionalButton/AdditionalButton';
import AttentionText from 'shared-between-front-ends/src/components/public/AttentionText/AttentionText';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import CircleButton from 'shared-between-front-ends/src/components/public/CircleButton/CircleButton';
import { typographyBaseline } from 'shared-between-front-ends/src/components/public/constants';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import HeavyText from 'shared-between-front-ends/src/components/public/HeavyText/HeavyText';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import Map from 'shared-between-front-ends/src/components/public/Map/Map';
import RouteLink from 'shared-between-front-ends/src/components/public/RouteLink/RouteLink';
import Table from 'shared-between-front-ends/src/components/public/Table/Table';
import ToggleGroup from 'shared-between-front-ends/src/components/public/ToggleGroup/ToggleGroup';
import withModel from 'shared-between-front-ends/src/decorators/withModel/withModel';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import RoutingModel from 'shared-between-front-ends/src/models/RoutingModel/RoutingModel';
import SessionModel from 'shared-between-front-ends/src/models/SessionModel/SessionModel';
import schedulerTranslations from '../../schedulerTranslations';
import TeamModel from '../../TeamModel/TeamModel';
import AbsenceSchedulerModel from '../AbsenceSchedulerModel/AbsenceSchedulerModel';
import AbsenceBudget from './AbsenceBudget/AbsenceBudget';
import SchedulerDateRangeActions from './SchedulerDateRangeActions/SchedulerDateRangeActions';
import SchedulerTableHeadingCell from './SchedulerTableHeadingCell/SchedulerTableHeadingCell';

const translate = localTranslate(schedulerTranslations);

const SchedulerTableHeader = ({
  absenceWorkOrderTypes,
  budgetMonths,
  changeViewToScheduler,
  daysOfSelectedDateRange,
  districtId,
  goToNextMonth,
  goToPreviousMonth,
  yearStringOfSelectedDateRange,
  monthsOfSelectedDateRange,
  setNumberOfWeeksShown,
  showDayOfMonthString,
  showDayOfWeekString,
  showNumberOfWeeksOptions,
  showWeekNumberLabels,
  teamId,
  teamName,
  userRights,
  weeksInView,
}) => {
  const amountOfColumnsForRowHeader = 1;
  const amountOfColumnsForLeaveBalances = absenceWorkOrderTypes.length;

  const totalAmountOfColumns =
    amountOfColumnsForRowHeader +
    amountOfColumnsForLeaveBalances +
    daysOfSelectedDateRange.length;

  return (
    <React.Fragment>
      <AbsenceBudget
        budgetMonths={budgetMonths}
        goToNextMonth={goToNextMonth}
        goToPreviousMonth={goToPreviousMonth}
        daysOfSelectedDateRange={daysOfSelectedDateRange}
      />

      <Table.Row>
        <SchedulerTableHeadingCell
          padding={false}
          colSpan={totalAmountOfColumns}
        >
          <Gutter backgroundColor="eltelGrey" size="xxs" />
        </SchedulerTableHeadingCell>
      </Table.Row>

      <Table.Row>
        <SchedulerTableHeadingCell
          padding={{ horizontal: 'md', top: 'sm' }}
          backgroundColor="grey2"
        >
          <Flex spaceBetween pileVertically>
            <AttentionText>{teamName}</AttentionText>

            <Gutter size="xs" />

            <Flex centeredVertically>
              <RouteLink
                shown={userRights.maintainResources}
                routeName="district-team-create-resource"
                pathParameters={{ districtId, teamId }}
                block
                data-create-resource-test
              >
                <CircleButton>
                  <Icon name="plus" size="sm" />
                </CircleButton>
              </RouteLink>

              <Gutter size="xs" />

              <BodyText size="3xs">{translate('createTeamResource')}</BodyText>
            </Flex>
          </Flex>
        </SchedulerTableHeadingCell>

        <SchedulerTableHeadingCell
          padding={false}
          colSpan={amountOfColumnsForLeaveBalances}
        />

        <SchedulerTableHeadingCell
          padding={{ horizontal: 'xxs' }}
          colSpan={daysOfSelectedDateRange.length}
        >
          <Flex
            occupyVerticalSpace
            alignBottom
            spaceBetween
            hideOverflow
            pileVerticallyTo="md"
          >
            <AttentionText size="3xs" singleLine>
              {yearStringOfSelectedDateRange}
            </AttentionText>

            <Gutter flexItem />

            <Div>
              <AdditionalButton
                onClick={changeViewToScheduler}
                size="sm"
                data-change-view-to-scheduler-test
                data-change-view-to-scheduler-e2e-test
              >
                {translate('changeViewToScheduler')}
              </AdditionalButton>
            </Div>

            <Gutter />

            <SchedulerDateRangeActions />

            <Gutter />

            <Div>
              <ToggleGroup
                label={`${translate('weeks')}:`}
                values={showNumberOfWeeksOptions}
                value={weeksInView}
                onChange={setNumberOfWeeksShown}
                flexItem
                data-number-of-weeks-test
                data-weeks-in-view-e2e-test={weeksInView}
              />
            </Div>
          </Flex>
        </SchedulerTableHeadingCell>
      </Table.Row>

      <Table.Row>
        <SchedulerTableHeadingCell backgroundColor="grey2" />

        <SchedulerTableHeadingCell colSpan={amountOfColumnsForLeaveBalances} />

        <Map items={monthsOfSelectedDateRange}>
          {({ month, numberOfDays }, { itemIsFirst }) => (
            <SchedulerTableHeadingCell
              padding={
                showDayOfMonthString || itemIsFirst
                  ? { horizontal: 'xxs' }
                  : false
              }
              colSpan={numberOfDays}
              hideOverflow
            >
              <AttentionText size="3xs" singleLine>
                {month}
              </AttentionText>
            </SchedulerTableHeadingCell>
          )}
        </Map>
      </Table.Row>

      <Table.Row
        data-absence-scheduler-starting-from-e2e-test={
          first(daysOfSelectedDateRange).date
        }
      >
        <SchedulerTableHeadingCell backgroundColor="grey2" />

        <SchedulerTableHeadingCell
          padding={{ horizontal: 'xxs' }}
          colSpan={amountOfColumnsForLeaveBalances}
        >
          <HeavyText singleLine color="action" size="3xs">
            {translate('leaveBalances')}
          </HeavyText>
        </SchedulerTableHeadingCell>

        <Map items={daysOfSelectedDateRange}>
          {({ isFirstDayOfWeek, weekNumber }) =>
            isFirstDayOfWeek && (
              <SchedulerTableHeadingCell
                padding={{ horizontal: 'xxs' }}
                colSpan={daysOfSelectedDateRange.length / weeksInView}
              >
                <Flex centeredVertically>
                  <AttentionText size="3xs">
                    {showWeekNumberLabels && translate('week')} {weekNumber}
                  </AttentionText>
                </Flex>
              </SchedulerTableHeadingCell>
            )
          }
        </Map>
      </Table.Row>

      <Table.Row>
        <SchedulerTableHeadingCell backgroundColor="grey2" />

        <Map items={absenceWorkOrderTypes}>
          {workOrderType => (
            <LeaveBalanceHeaderCell stripeColor={workOrderType.color}>
              {translate(`shortBalanceHeaders.${workOrderType.type}`)}
            </LeaveBalanceHeaderCell>
          )}
        </Map>

        <Map items={daysOfSelectedDateRange}>
          {({ dayOfMonthString, dayOfWeekString, isToday }) =>
            showDayOfMonthString ? (
              <SchedulerTableHeadingCell padding={{ horizontal: 'xxs' }}>
                <Flex centeredVertically>
                  <BodyText>
                    {showDayOfWeekString && dayOfWeekString} {dayOfMonthString}
                  </BodyText>

                  {isToday && (
                    <Icon
                      name="circle"
                      color="action"
                      size="xs"
                      padding={{ left: '3xs' }}
                    />
                  )}
                </Flex>
              </SchedulerTableHeadingCell>
            ) : (
              <SchedulerTableHeadingCell padding={{ left: 0, right: 0 }}>
                {isToday && (
                  <Flex centeredHorizontally>
                    <Icon name="circle" color="action" size="xs" />
                  </Flex>
                )}
              </SchedulerTableHeadingCell>
            )
          }
        </Map>
      </Table.Row>
    </React.Fragment>
  );
};

export default decorate(
  withModel(
    {
      Model: AbsenceSchedulerModel,
      props: [
        {
          name: 'daysOfSelectedDateRange',
          modelPath: 'daysOfSelectedDateRange',
        },
        { name: 'weeksInView', modelPath: 'weeksInView' },
        { name: 'setNumberOfWeeksShown', modelPath: 'setNumberOfWeeksShown' },
        {
          name: 'yearStringOfSelectedDateRange',
          modelPath: 'yearStringOfSelectedDateRange',
        },
        {
          name: 'showNumberOfWeeksOptions',
          modelPath: 'showNumberOfWeeksOptions',
        },
      ],
    },
    {
      Model: RoutingModel,
      props: [
        { name: 'teamId', modelPath: 'pathParameters.teamId' },
        { name: 'districtId', modelPath: 'pathParameters.districtId' },
      ],
    },
    {
      Model: TeamModel,
      props: [{ name: 'teamName', modelPath: 'name' }],
    },
    {
      Model: SessionModel,
      props: [{ name: 'userRights', modelPath: 'userRights' }],
    },
  ),
)(SchedulerTableHeader);

const LeaveBalanceHeaderCell = ({ children, stripeColor }) => (
  <SchedulerTableHeadingCell verticalAlignToBottom padding={{ size: 'zero' }}>
    <Div>
      <BodyText margin={{ left: 'xxs' }} singleLine color="action" size="4xs">
        {children}
      </BodyText>

      <Div
        backgroundColor={stripeColor}
        style={{ height: typographyBaseline }}
      />
    </Div>
  </SchedulerTableHeadingCell>
);
