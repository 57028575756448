import defaultEmptyTo from 'shared-between-everything/src/doings/defaultEmptyTo/defaultEmptyTo';

import InputModelBaseClass from '../../../InputModelBaseClass';

export default class FileInputModel extends InputModelBaseClass {
  constructor({ ...rest } = {}) {
    super({
      inboundFormatters: [defaultEmptyTo(null)],
      outboundFormatters: [defaultEmptyTo('')],
      ...rest,
    });
  }
}
