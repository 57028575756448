import React from 'react';
import Span from 'shared-between-front-ends/src/components/private/Element/Span/Span';
import measurementUnits from 'shared-between-front-ends/src/components/public/MeasurementAmountInput/measurementUnits';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';

const MeasurementUnit = ({ measurementUnitId, ...props }) => (
  <Span {...props}>
    {localTranslate(measurementUnits[measurementUnitId].translations)('metric')}
  </Span>
);

export default MeasurementUnit;
