export default {
  en: {
    add: 'Add',
  },
  fi: {
    add: 'Lisää',
  },
  pl: {
    add: 'Dodaj',
  },
  se: {
    add: 'Lägg till',
  },
};
