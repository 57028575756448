import { observer } from 'mobx-react';
import React from 'react';
import { noop } from 'shared-between-everything/src/functionalProgramming';
import localTranslate from '../../doings/localTranslate/localTranslate';
import ErrorMessage from './ErrorMessage/ErrorMessage';
import MainHeading from '../../components/public/MainHeading/MainHeading';
import Page from '../../components/public/Page/Page';
import errorTranslations from './errorTranslations';

const translate = localTranslate(errorTranslations);

const ErrorTentative = ({
  errorStateModel: { errorMessage },
  getNavigation = noop,
  children,
}) => {
  const content = translate('message');

  return errorMessage ? (
    <Page>
      {getNavigation()}

      <Page.Row alignWithPanel>
        <MainHeading color="error">{translate('heading')}</MainHeading>
      </Page.Row>

      <Page.Row withPanel margin={false}>
        <ErrorMessage errorMessage={errorMessage}>{content}</ErrorMessage>
      </Page.Row>
    </Page>
  ) : (
    children
  );
};

export default observer(ErrorTentative);
