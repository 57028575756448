import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import { first } from 'shared-between-everything/src/functionalProgramming';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import AdditionalButton from 'shared-between-front-ends/src/components/public/AdditionalButton/AdditionalButton';
import AttentionText from 'shared-between-front-ends/src/components/public/AttentionText/AttentionText';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import CircleButton from 'shared-between-front-ends/src/components/public/CircleButton/CircleButton';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import Map from 'shared-between-front-ends/src/components/public/Map/Map';
import RouteLink from 'shared-between-front-ends/src/components/public/RouteLink/RouteLink';
import Table from 'shared-between-front-ends/src/components/public/Table/Table';
import ToggleGroup from 'shared-between-front-ends/src/components/public/ToggleGroup/ToggleGroup';
import withModel from 'shared-between-front-ends/src/decorators/withModel/withModel';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import RoutingModel from 'shared-between-front-ends/src/models/RoutingModel/RoutingModel';
import schedulerTranslations from '../../schedulerTranslations';
import TeamModel from '../../TeamModel/TeamModel';
import SchedulerModel from '../SchedulerModel/SchedulerModel';
import SchedulerDateRangeActions from './SchedulerDateRangeActions/SchedulerDateRangeActions';
import SchedulerTableHeadingCell from './SchedulerTableHeadingCell/SchedulerTableHeadingCell';

const translate = localTranslate(schedulerTranslations);

const SchedulerTableHeader = ({
  allowChangingViewToAbsenceScheduler,
  changeViewToAbsenceScheduler,
  daysOfSelectedDateRange,
  districtId,
  yearStringOfSelectedDateRange,
  sendNotificationEmails,
  setNumberOfWeeksShown,
  showDayOfMonthString,
  showDayOfWeekString,
  showNumberOfWeeksOptions,
  showWeekendAsSelectable,
  showWeekends,
  showWeekendsOptions,
  showWeekNumberLabels,
  teamId,
  teamName,
  userRights,
  weekendsAreShown,
  weeksInView,
  monthsOfSelectedDateRange,
}) => {
  return (
    <React.Fragment>
      <Table.Row>
        <SchedulerTableHeadingCell
          padding={{ horizontal: 'md', vertical: 'xxs' }}
          backgroundColor="grey2"
        >
          <AttentionText
            singleLine
            hidden={userRights.maintainTeams}
            data-team-name-test
          >
            {teamName}
          </AttentionText>

          <RouteLink
            shown={userRights.maintainTeams}
            pathParameters={{
              districtId,
              teamId,
            }}
            routeName="district-team-scheduler/team-maintenance"
            data-open-team-maintenance-test
            data-open-team-e2e-test
          >
            <Flex centeredVertically>
              <AttentionText singleLine>{teamName}</AttentionText>

              <Gutter size="sm" flexItem />

              <CircleButton>
                <Icon name="pen" size="sm" />
              </CircleButton>
            </Flex>
          </RouteLink>
        </SchedulerTableHeadingCell>

        <SchedulerTableHeadingCell
          colSpan={daysOfSelectedDateRange.length}
          padding={{ left: 'xs', right: 'xxs', vertical: 'xxs' }}
        >
          <Flex
            alignBottom
            occupyVerticalSpace
            spaceBetween
            hideOverflow
            pileVerticallyTo="md"
          >
            <AttentionText size="3xs" singleLine>
              {yearStringOfSelectedDateRange}
            </AttentionText>

            <Gutter flexItem />

            {allowChangingViewToAbsenceScheduler && (
              <Div>
                <AdditionalButton
                  onClick={changeViewToAbsenceScheduler}
                  size="sm"
                  data-change-view-to-absence-scheduler-test
                  data-change-view-to-absence-scheduler-e2e-test
                >
                  {translate('changeViewToAbsenceScheduler')}
                </AdditionalButton>
              </Div>
            )}

            {allowChangingViewToAbsenceScheduler && <Gutter />}

            <SchedulerDateRangeActions />

            <Gutter />

            <Div inlineBlock>
              <ToggleGroup
                label={`${translate('weeks')}:`}
                values={showNumberOfWeeksOptions}
                value={weeksInView}
                onChange={setNumberOfWeeksShown}
                flexItem
                data-number-of-weeks-test
              >
                {item => (
                  <ToggleGroup.Item
                    {...item}
                    data-show-number-of-weeks-e2e-test={item.value}
                  />
                )}
              </ToggleGroup>
            </Div>

            <Gutter />

            <Div inlineBlock>
              <ToggleGroup
                label={`${translate('showWeekends')}:`}
                values={showWeekendsOptions}
                value={weekendsAreShown}
                onChange={showWeekends}
                flexItem
                data-selecting-weekends-test
                data-showing-of-weekends-can-be-toggled-e2e-test={
                  showWeekendAsSelectable
                }
              >
                {item => (
                  <ToggleGroup.Item
                    {...item}
                    disabled={!showWeekendAsSelectable}
                    data-show-weekends-e2e-test={item.value}
                  />
                )}
              </ToggleGroup>
            </Div>
          </Flex>
        </SchedulerTableHeadingCell>
      </Table.Row>

      <Table.Row>
        <SchedulerTableHeadingCell backgroundColor="grey2" />

        <Map items={monthsOfSelectedDateRange}>
          {({ month, numberOfDays }, { itemIsFirst }) => (
            <SchedulerTableHeadingCell
              padding={
                showDayOfMonthString || itemIsFirst
                  ? { horizontal: 'xs' }
                  : false
              }
              colSpan={numberOfDays}
              hideOverflow
            >
              <AttentionText size="3xs" singleLine>
                {month}
              </AttentionText>
            </SchedulerTableHeadingCell>
          )}
        </Map>
      </Table.Row>

      <Table.Row>
        <SchedulerTableHeadingCell
          padding={{ horizontal: 'md' }}
          backgroundColor="grey2"
          data-weeks-in-view-e2e-test={weeksInView}
        >
          <Flex>
            <RouteLink
              shown={userRights.maintainResources}
              routeName="district-team-create-resource"
              pathParameters={{
                districtId,
                teamId,
              }}
              block
              title={translate('createTeam')}
              data-create-resource-test
            >
              <CircleButton>
                <Icon name="plus" size="sm" />
              </CircleButton>
            </RouteLink>

            <Gutter size="xs" />

            <CircleButton
              onClick={sendNotificationEmails}
              shown={userRights.doScheduling}
              title={translate('sendNotificationEmails')}
              data-send-email-notifications-test
              data-send-email-notifications-e2e-test={
                userRights.doScheduling ? 'shown' : 'hidden'
              }
            >
              <Icon name="envelope" size="sm" />
            </CircleButton>
          </Flex>
        </SchedulerTableHeadingCell>

        <Map items={daysOfSelectedDateRange}>
          {({ isFirstDayOfWeek, weekNumber }, { itemIsFirst }) =>
            isFirstDayOfWeek && (
              <SchedulerTableHeadingCell
                padding={
                  showDayOfMonthString || itemIsFirst
                    ? { horizontal: 'xs' }
                    : false
                }
                colSpan={daysOfSelectedDateRange.length / weeksInView}
              >
                <AttentionText size="3xs">
                  {showWeekNumberLabels && translate('week')} {weekNumber}
                </AttentionText>
              </SchedulerTableHeadingCell>
            )
          }
        </Map>
      </Table.Row>

      <Table.Row
        data-scheduler-starting-from-e2e-test={
          first(daysOfSelectedDateRange).date
        }
      >
        <SchedulerTableHeadingCell backgroundColor="grey2" />

        {toDayHeadingCells(
          daysOfSelectedDateRange,
          showDayOfWeekString,
          showDayOfMonthString,
        )}
      </Table.Row>
    </React.Fragment>
  );
};

export default decorate(
  withModel(
    {
      Model: SchedulerModel,
      props: [
        {
          name: 'daysOfSelectedDateRange',
          modelPath: 'daysOfSelectedDateRange',
        },
        { name: 'showWeekends', modelPath: 'showWeekends' },
        { name: 'weekendsAreShown', modelPath: 'weekendsAreShown' },
        { name: 'weeksInView', modelPath: 'weeksInView' },
        { name: 'setNumberOfWeeksShown', modelPath: 'setNumberOfWeeksShown' },
        {
          name: 'yearStringOfSelectedDateRange',
          modelPath: 'yearStringOfSelectedDateRange',
        },
        {
          name: 'showNumberOfWeeksOptions',
          modelPath: 'showNumberOfWeeksOptions',
        },
        {
          name: 'showWeekendsOptions',
          modelPath: 'showWeekendsOptions',
        },
      ],
    },
    {
      Model: RoutingModel,
      props: [
        { name: 'teamId', modelPath: 'pathParameters.teamId' },
        { name: 'districtId', modelPath: 'pathParameters.districtId' },
      ],
    },
    {
      Model: TeamModel,
      props: [{ name: 'teamName', modelPath: 'name' }],
    },
  ),
)(SchedulerTableHeader);

const toDayHeadingCells = (
  daysOfSelectedDateRange,
  showDayOfWeekString,
  showDayOfMonthString,
) => (
  <Map items={daysOfSelectedDateRange}>
    {({ dayOfWeekString, dayOfMonthString, compact, isToday }) =>
      showDayOfMonthString ? (
        <SchedulerTableHeadingCell padding={{ horizontal: 'xs' }}>
          {!compact && (
            <Flex centeredVertically>
              <BodyText>
                {showDayOfWeekString && dayOfWeekString} {dayOfMonthString}
              </BodyText>

              {isToday && (
                <Icon
                  name="circle"
                  color="action"
                  size="xs"
                  padding={{ left: 'xs' }}
                />
              )}
            </Flex>
          )}
        </SchedulerTableHeadingCell>
      ) : (
        <SchedulerTableHeadingCell padding={false}>
          {isToday && (
            <Flex centeredHorizontally>
              <Icon name="circle" color="action" size="xs" />
            </Flex>
          )}
        </SchedulerTableHeadingCell>
      )
    }
  </Map>
);
