import defaultEmptyTo from 'shared-between-everything/src/doings/defaultEmptyTo/defaultEmptyTo';

import InputModelBaseClass from '../../../InputModelBaseClass';
import emailAddressValidator from '../../../validators/emailAddress/emailAddressValidator';

export default class EmailInputModel extends InputModelBaseClass {
  constructor({ validators = [], ...rest } = {}) {
    super({
      inboundFormatters: [defaultEmptyTo(null)],
      outboundFormatters: [defaultEmptyTo('')],
      validators: [emailAddressValidator, ...validators],
      ...rest,
    });
  }
}
