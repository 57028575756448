import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';
import callForSubmitEffortEntriesInputMapper from './callForSubmitEffortEntriesInputMapper/callForSubmitEffortEntriesInputMapper';

// to bypass the error handling for this case
export const conditionCheckError = error =>
  error === 'ConditionalCheckFailedException';

export default getAuthenticatedCall({
  apiCallConfig: {
    path:
      '/api/resource-dashboard/{resourceId}/appointment/{appointmentId}/submit-work-order-report',

    method: 'POST',

    inputMapper: callForSubmitEffortEntriesInputMapper,
  },
  decoratorConfig: {
    reportErrorsUnless: [conditionCheckError],
  },
});
