import React from 'react';
import Element from '../Element';

const Select = ({ children, value, onChange, ...props }) => {
  const onNativeChange = ({ target: { value } }) =>
    onChange(value === '' ? null : value);

  return (
    <Element
      tagName="select"
      value={value === null ? '' : value}
      onChange={onNativeChange}
      {...props}
    >
      {children}
    </Element>
  );
};

export default Select;
