import { flow, map, toPairs } from './functionalProgramming';

export const contentTypes = {
  jpeg: Symbol.for('jpeg'),
  png: Symbol.for('png'),
  json: Symbol.for('json'),
  wav: Symbol.for('wav'),
  urlEncoded: Symbol.for('urlEncoded'),
};

export const contentTypeStringsByContentType = {
  [contentTypes.jpeg]: 'image/jpeg',
  [contentTypes.png]: 'image/png',
  [contentTypes.json]: 'application/json',
  [contentTypes.wav]: 'audio/wav',
  [contentTypes.urlEncoded]: 'application/x-www-form-urlencoded',
};

export const contentTypesByContentTypeString = {
  'image/jpeg': contentTypes.jpeg,
  'image/png': contentTypes.png,
  'application/json': contentTypes.json,
  'audio/wav': contentTypes.wav,
  'application/x-www-form-urlencoded': contentTypes.urlEncoded,
};

export const fileExtensionsByContentType = {
  [contentTypes.jpeg]: '.jpg',
  [contentTypes.png]: '.png',
  [contentTypes.json]: '.json',
  [contentTypes.wav]: '.wav',
  [contentTypes.urlEncoded]: '',
};

const toContentTypeAndFileExtension = ([contentType, contentTypeSymbol]) => ({
  fileExtension: fileExtensionsByContentType[contentTypeSymbol],
  contentType,
});

export const binaryContentTypes = ['image/jpeg', 'image/png', 'audio/wav'];

export const listOfSupportedContentTypesAndExtensions = flow(
  toPairs,
  map(toContentTypeAndFileExtension),
)(contentTypesByContentTypeString);
