import withExposedConfiguration from 'shared-between-everything/src/test-utils/withExposedConfiguration';

const whenObserved = withExposedConfiguration(
  triggerWhenPropertyBecomesObserved => (Class, methodName, oldDescriptor) => {
    if (!Class.__whenObserved) {
      Class.__whenObserved = [];
    }

    Class.__whenObserved.push({
      methodName,
      triggerWhenPropertyBecomesObserved,
    });

    const { value, initializer, ...newDescriptor } = oldDescriptor;

    if (initializer) {
      newDescriptor.initializer = function () {
        const decorated = initializer.call(this);

        decorated.__configuration = [triggerWhenPropertyBecomesObserved];
        decorated.__unconfiguredFunction = whenObserved;

        return decorated;
      };
    } else {
      const decorated = value;

      decorated.__configuration = [triggerWhenPropertyBecomesObserved];
      decorated.__unconfiguredFunction = whenObserved;

      newDescriptor.value = decorated;
    }

    return newDescriptor;
  },
);

export default whenObserved;
