import compact from 'lodash/fp/compact';
import React from 'react';
import Div from '../../private/Element/Div/Div';
import BodyText from '../BodyText/BodyText';
import Link from '../Link/Link';
import Map from '../Map/Map';
import Panel from '../Panel/Panel';
import SeparatorLine from '../SeparatorLine/SeparatorLine';

import styles from './DropdownList.module.scss';

const horizontalPadding = 'md';
const DropdownList = ({ items, children, ...props }) => {
  const compactedItems = compact(items);

  return (
    <Panel {...props} className={styles.dropdownList}>
      <Map
        items={compactedItems}
        separator={
          <Div data-separator-test padding={{ horizontal: horizontalPadding }}>
            <SeparatorLine horizontal />
          </Div>
        }
      >
        {children}
      </Map>
    </Panel>
  );
};

const DropdownListLink = ({ children, ...props }) => (
  <Link
    block
    padding={{ horizontal: horizontalPadding, vertical: 'sm' }}
    defaultColors
    {...props}
  >
    <BodyText>{children}</BodyText>
  </Link>
);

DropdownList.Link = DropdownListLink;

export default DropdownList;
