import find from 'lodash/fp/find';
import getModel from '../../decorators/withModel/getModel';
import RoutingModel from '../../models/RoutingModel/RoutingModel';
import SessionModel from '../../models/SessionModel/SessionModel';
import hasUserRight from './hasUserRight/hasUserRight';

export const dependencies = {
  sessionModel: getModel(SessionModel),
  routingModel: getModel(RoutingModel),
  hasUserRight,
};

export default routeName => {
  const route = find({ name: routeName }, dependencies.routingModel.routes);

  if (!route.loginIsRequired) {
    return true;
  }

  if (!dependencies.sessionModel.userIsLoggedIn) {
    return false;
  }

  return (
    !route.requiredUserRight ||
    dependencies.hasUserRight(route.requiredUserRight)
  );
};
