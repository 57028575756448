import defaultEmptyTo from 'shared-between-everything/src/doings/defaultEmptyTo/defaultEmptyTo';
import trimStart from 'lodash/fp/trimStart';

import InputModelBaseClass from '../../../InputModelBaseClass';

export default class TextInputModel extends InputModelBaseClass {
  constructor({ ...rest } = {}) {
    super({
      inboundFormatters: [trimStart, defaultEmptyTo(null)],
      outboundFormatters: [defaultEmptyTo('')],
      ...rest,
    });
  }
}
