import find from 'lodash/fp/find';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import { matchPath } from 'react-router';
import queryString from 'query-string';
import pipeline from 'shared-between-everything/src/doings/pipeline/pipeline';
import {
  filter,
  isEmpty,
  toPairs,
} from 'shared-between-everything/src/functionalProgramming';

export default routes => url => {
  const { url: path, query } = queryString.parseUrl(url);

  const matchRoute = matchRouteFor(path);

  const findMatchingRouteFromRoutes = flow(
    map(matchRoute),
    findFirstMatchingRoute,
  );

  const targetRoute = findMatchingRouteFromRoutes(routes);

  if (!targetRoute) {
    return null;
  }

  throwOnMissingPathParameters(targetRoute);

  return { ...targetRoute, queryParameters: { ...query } };
};

const findFirstMatchingRoute = find({ isMatching: true });

const matchRouteFor = path => route => {
  const matchingRoute = matchPath(path, {
    path: route.path,
    sensitive: true,
    exact: true,
  });

  return matchingRoute
    ? { ...route, pathParameters: matchingRoute.params, isMatching: true }
    : { ...route, pathParameters: {}, isMatching: false };
};

const throwOnMissingPathParameters = targetRoute => {
  const nullPathParameters = pipeline(
    targetRoute.pathParameters,
    toPairs,
    filter(([_, pathParameterValue]) => pathParameterValue === 'null'),
  );

  if (!isEmpty(nullPathParameters)) {
    const missingPathParametersString = nullPathParameters
      .map(([pathParameterName]) => pathParameterName)
      .join('", "');

    throw new Error(
      `Tried to access route "${targetRoute.name}" without "${missingPathParametersString}" as path parameter.`,
    );
  }
};
