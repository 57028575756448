import moment from 'moment-timezone';
import formatToDate from 'shared-between-everything/src/date-time-abstractions/formatToDate/formatToDate';
import { pipeline } from 'shared-between-everything/src/functionalProgramming';
import localTranslate from '../../doings/localTranslate/localTranslate';
import dateTranslations from '../dateTranslations';

const translate = localTranslate(dateTranslations);

export default date =>
  pipeline(
    date,
    formatToDate,
    date => moment(date).isoWeekday() - 1,
    dayNumber => translate(`shortWeekdays.${dayNumber}`),
  );
