import castArray from 'lodash/fp/castArray';
import callApiFor from 'shared-between-everything/src/doings/callApiFor/callApiFor';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import { identity } from 'shared-between-everything/src/functionalProgramming';
import withExposedConfiguration from 'shared-between-everything/src/test-utils/withExposedConfiguration';
import withErrorHandling from '../../decorators/withErrorHandling/withErrorHandling';
import withSpinnerFor from '../../decorators/withSpinnerFor/withSpinnerFor';

export const dependencies = {
  callApiFor,
};

export default withExposedConfiguration(
  ({
    apiCallConfig: {
      path,
      method,
      inputMapper,
      outputMapper,
      staticParameters,
      contentType,
    },
    decoratorConfig: {
      spinnerName,
      showSpinnerInstantly = false,
      reportErrorsUnless,
    } = {},
  }) => {
    const apiCall = dependencies.callApiFor({
      path,
      method,
      inputMapper,
      outputMapper,
      staticParameters,
      contentType,
    });

    return decorate(
      spinnerName
        ? withSpinnerFor(spinnerName, { showInstantly: showSpinnerInstantly })
        : identity,
      reportErrorsUnless
        ? withErrorHandling.unless(...castArray(reportErrorsUnless))
        : withErrorHandling,
    )(apiCall);
  },
);
