export default {
  en: {
    selectFile: 'Select file:',
    browse: 'Browse...',
    add: 'Add',
    cancel: 'Cancel',
    addNewCatalog: 'Add new catalog',
    writeDescription: 'Write description',
    name: 'Name',
    description: 'Description',
    createdDateTime: 'Created at',
    status: 'Status',
    catalogs: 'Catalogs',
    confirmDelete: 'Are you sure that you want to delete the catalog?',
    deleteSuccess: 'Catalog deleted successfully.',
    deleteCatalog: 'Delete catalog',
  },
  fi: {
    selectFile: 'Valitse tiedosto:',
    browse: 'Selaa...',
    add: 'Lisää',
    cancel: 'Peruuta',
    addNewCatalog: 'Lisää uusi yksikkölista',
    writeDescription: 'Kirjoita kuvaus',
    name: 'Nimi',
    description: 'Kuvaus',
    createdDateTime: 'Lisäyspäivämäärä',
    status: 'Status',
    catalogs: 'Yksikkölistat',
    confirmDelete: 'Oletko varma että haluat poistaa yksikkölistan?',
    deleteSuccess: 'Yksikkölista poistettu.',
    deleteCatalog: 'Poista yksikkölista',
  },
  pl: {
    selectFile: 'Select file:',
    browse: 'Browse...',
    add: 'Add',
    cancel: 'Cancel',
    addNewCatalog: 'Add new catalog',
    writeDescription: 'Write description',
    name: 'Name',
    description: 'Description',
    createdDateTime: 'Created at',
    status: 'Status',
    catalogs: 'Catalogs',
    confirmDelete: 'Are you sure that you want to delete the catalog?',
    deleteSuccess: 'Catalog deleted successfully.',
    deleteCatalog: 'Delete catalog',
  },
  se: {
    selectFile: 'Välj fil:',
    browse: 'Bläddra...',
    add: 'Lägg till',
    cancel: 'Avbryt',
    addNewCatalog: 'Lägg till nytt arbetsmoment',
    writeDescription: 'Skriv beskrivning',
    name: 'Namn',
    description: 'Beskrivning',
    createdDateTime: 'Skapad den',
    status: 'Status',
    catalogs: 'Arbetsmoment',
    confirmDelete: 'Är du säker på att du vill ta bort arbetsmomentet?',
    deleteSuccess: 'Arbetsmoment borttagen.',
    deleteCatalog: 'Ta bort arbetsmoment',
  },
};
