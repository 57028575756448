import React from 'react';

import Heading from '../Heading/Heading';

function Subheading({ tagName = 'h2', children, ...props }) {
  return (
    <Heading tagName={tagName} size="sm" color="black" {...props}>
      {children}
    </Heading>
  );
}

export default Subheading;
