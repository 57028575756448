import { observer } from 'mobx-react';
import React from 'react';

import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import { constant } from 'shared-between-everything/src/functionalProgramming';
import Div from '../../../private/Element/Div/Div';

const ShowWhenActivated = ({
  activationId,
  activationModel,
  content,
  getContent = constant(content),
  ...props
}) => {
  if (!activationModel.isActivated(activationId)) {
    return null;
  }

  return <Div {...props}>{getContent()}</Div>;
};

export default decorate(observer)(ShowWhenActivated);
