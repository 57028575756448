import React, { useEffect, useRef } from 'react';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';

export default ({ active, scrollIntoView, ...props }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (active) {
      scrollIntoView(ref.current);
    }
  });

  return <Div {...props} forwardedRef={ref} />;
};
