import { computed } from 'mobx';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import NotificationsModel from 'shared-between-front-ends/src/components/public/Notifications/NotificationsModel';
import TextInputModel from 'shared-between-front-ends/src/components/public/TextInput/TextInputModel';
import getModel from 'shared-between-front-ends/src/decorators/withModel/getModel';
import RoutingModel from 'shared-between-front-ends/src/models/RoutingModel/RoutingModel';
import createDistrictTranslations from './createDistrictTranslations';

const translate = localTranslate(createDistrictTranslations);

export default class CreateDistrictModel {
  dependencies = {};

  constructor(
    getEveryInputModelIsValid = require('shared-between-front-ends/src/doings/getEveryInputModelIsValid/getEveryInputModelIsValid')
      .default,
    submitDistrict = require('./submitDistrict/submitDistrict').default,
    routingModel = getModel(RoutingModel),
    notificationsModel = getModel(NotificationsModel),
  ) {
    this.dependencies.getEveryInputModelIsValid = getEveryInputModelIsValid;
    this.dependencies.submitDistrict = submitDistrict;
    this.dependencies.routingModel = routingModel;
    this.dependencies.notificationsModel = notificationsModel;
  }

  name = new TextInputModel({ required: true });
  nameOfManager = new TextInputModel();

  submit = async () => {
    await this.dependencies.submitDistrict({
      name: this.name.value,
      nameOfManager: this.nameOfManager.value,
    });

    this.dependencies.notificationsModel.setSuccess(translate('createSuccess'));

    this.name.clear();
    this.nameOfManager.clear();

    this.dependencies.routingModel.goBack();
  };

  @computed
  get isValid() {
    return this.dependencies.getEveryInputModelIsValid(this.name);
  }
}
