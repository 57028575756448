import injectInstance from './injectInstance';

export const models = new Map();

const getModel = Model => {
  if (models.has(Model)) {
    return models.get(Model);
  }

  const model = injectInstance(Model);

  if (Model.isSingleton !== false) {
    models.set(Model, model);
  }

  return model;
};

export default getModel;
