export default {
  en: {
    logIn: 'Log In',
    logOut: 'Log Out',
  },
  fi: {
    logIn: 'Kirjaudu',
    logOut: 'Kirjaudu Ulos',
  },
  pl: {
    logIn: 'Zaloguj się',
    logOut: 'Wyloguj się',
  },
  se: {
    logIn: 'Logga in',
    logOut: 'Logga ut',
  },
};
