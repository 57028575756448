import React from 'react';

import AttentionText from '../../AttentionText/AttentionText';
import SemanticButton from '../../SemanticButton/SemanticButton';

const Toggle = ({ onClick, label, value, checked, disabled, ...props }) => (
  <SemanticButton
    disabled={disabled}
    onClick={onClick}
    {...getColorProps(disabled, checked)}
    {...props}
  >
    <AttentionText singleLine size="3xs">
      {label}
    </AttentionText>
  </SemanticButton>
);

const getColorProps = (disabled, checked) => {
  if (checked) {
    return {
      color: 'white',
      hoverColor: 'white',
      backgroundColor: 'eltelGrey',
      backgroundHoverColor: 'eltelGrey',
    };
  }

  if (disabled) {
    return {
      color: 'inherit',
      hoverColor: 'white',
      backgroundColor: 'transparent',
      backgroundHoverColor: 'transparent',
    };
  }

  return {
    color: 'inherit',
    hoverColor: 'white',
    backgroundColor: 'transparent',
    backgroundHoverColor: 'action',
  };
};

export default Toggle;
