import getRandomId from 'shared-between-everything/src/doings/getRandomId/getRandomId';
import { pipeline } from 'shared-between-everything/src/functionalProgramming';
import when from '../../decorators/when/when';
import whenRouteChangesTo from '../../decorators/whenRouteChangesTo/whenRouteChangesTo';
import getModel from '../../decorators/withModel/getModel';
import browserStorageImport from '../../doings/browserStorage/browserStorage';
import redirectToImport from '../../doings/redirectTo/redirectTo';
import RoutingModel from '../../models/RoutingModel/RoutingModel';
import applicationIsStarted from './applicationIsStarted/applicationIsStarted';
import SessionModel from '../../models/SessionModel/SessionModel';
import setErrorImport from '../ErrorTentative/setError';
import callForStartOfSessionImport from './callForStartOfSession/callForStartOfSession';
import errorIsAboutInvalidAuthorizationCode from './callForStartOfSession/errorIsAboutInvalidAuthorizationCode/errorIsAboutInvalidAuthorizationCode';
import callForTokenRefreshImport from './callForTokenRefresh/callForTokenRefresh';
import leaveToLoginUrlFor from './leaveToLoginUrlFor/leaveToLoginUrlFor';

export default class LoginModel {
  dependencies = {};

  constructor(
    routingModel = getModel(RoutingModel),
    callForStartOfSession = callForStartOfSessionImport,
    sessionModel = getModel(SessionModel),
    environmentVariables = process.env,
    redirectTo = redirectToImport,
    callForTokenRefresh = callForTokenRefreshImport,
    browserStorage = browserStorageImport,
    getRandomString = getRandomId,
    setError = setErrorImport,
  ) {
    this.dependencies.routingModel = routingModel;
    this.dependencies.callForStartOfSession = callForStartOfSession;
    this.dependencies.sessionModel = sessionModel;
    this.dependencies.environmentVariables = environmentVariables;
    this.dependencies.redirectTo = redirectTo;
    this.dependencies.callForTokenRefresh = callForTokenRefresh;
    this.dependencies.browserStorage = browserStorage;
    this.dependencies.getRandomString = getRandomString;
    this.dependencies.setError = setError;

    this._leaveToLoginUrl = leaveToLoginUrlFor({
      environmentVariables,
      redirectTo,
      routingModel,
      browserStorage,
      getRandomString,
      sessionStartUrl: this.sessionStartUrl,
    });
  }

  get sessionStartUrl() {
    return `${this.dependencies.routingModel.origin}/session-start`;
  }

  _storeReturnRoute() {
    this.dependencies.browserStorage.set(
      'returnPath',
      this.dependencies.routingModel.path,
    );

    const {
      queryParameters,
      internalRoute: { name, pathParameters },
    } = this.dependencies.routingModel;

    const returnRoute = {
      name,
      pathParameters,
      queryParameters,
    };

    this.dependencies.browserStorage.set('returnRoute', returnRoute);
  }

  leaveToLogin = () => {
    this._storeReturnRoute();

    this._leaveToLoginUrl({ quiet: false });
  };

  @when(applicationIsStarted, true)
  leaveToTryForExistingLogin = () => {
    if (this.dependencies.routingModel.routeName === 'session-start') {
      return;
    }

    this._storeReturnRoute();

    this._leaveToLoginUrl({ quiet: true });
  };

  @whenRouteChangesTo('session-start')
  onReturnFromLogin = async () => {
    const { queryParameters } = this.dependencies.routingModel;

    const { codeChallengeVerifier } = pipeline(
      queryParameters.state,
      atob,
      JSON.parse,
    );

    const returnPath = this.dependencies.browserStorage.get('returnPath');

    if (this.dependencies.sessionModel.userIsLoggedIn) {
      this.dependencies.routingModel.setRouteByPath(returnPath);

      return;
    }

    const authorizationCode = queryParameters.code;

    if (!authorizationCode) {
      this.dependencies.sessionModel.setSession(null);
      this.dependencies.routingModel.setRouteByPath(returnPath);

      return;
    }

    const {
      reasonToBypassErrorReporting,
      response: session,
    } = await this.dependencies.callForStartOfSession({
      authorizationCode,
      codeChallengeVerifier,
      redirectUrl: this.sessionStartUrl,
    });

    if (reasonToBypassErrorReporting === errorIsAboutInvalidAuthorizationCode) {
      const amountOfRetries = this._getAmountOfRetries();

      const tooManyRetries = amountOfRetries > 3;

      return tooManyRetries
        ? this._failWithErrorAboutTooManyRetries(returnPath)
        : this._retryAuthentication(returnPath);
    }

    this._clearRetries();

    this.dependencies.sessionModel.setSession(session);

    this._scheduleRefresh({
      accessToken: session.accessToken,
      refreshToken: session.refreshToken,
    });

    this.dependencies.routingModel.setRouteByPath(returnPath);
  };

  _getAmountOfRetries = () => {
    return (
      this.dependencies.browserStorage.get(
        'amountOfRetriesForAuthentication',
      ) || 0
    );
  };

  _increaseAmountOfRetries = () => {
    const amountOfRetries = this._getAmountOfRetries();

    this.dependencies.browserStorage.set(
      'amountOfRetriesForAuthentication',
      amountOfRetries + 1,
    );
  };

  _clearRetries = () => {
    this.dependencies.browserStorage.remove('amountOfRetriesForAuthentication');
  };

  _failWithErrorAboutTooManyRetries = returnPath => {
    this._clearRetries();

    this.dependencies.sessionModel.setSession(null);
    this.dependencies.routingModel.setRouteByPath(returnPath);

    this.dependencies.setError({
      code: 'authentication-retry-failed',
      message:
        'Authentication failed. Clear your browser history and try again.',
    });
  };

  _retryAuthentication = returnPath => {
    this._increaseAmountOfRetries();

    this.dependencies.redirectTo({
      url: returnPath,
    });
  };

  _refreshAllTokens = async ({ refreshToken: oldRefreshToken }) => {
    const { response: tokens } = await this.dependencies.callForTokenRefresh({
      refreshToken: oldRefreshToken.value,
    });

    this.dependencies.sessionModel.setTokens(tokens);

    const newAccessToken = tokens.accessToken;

    this._scheduleRefresh({
      accessToken: newAccessToken,
      refreshToken: oldRefreshToken,
    });
  };

  _scheduleRefresh = ({ accessToken, refreshToken }) => {
    const millisecondsBeforeTokensExpire =
      (accessToken.expiresInSeconds - 60) * 1000;

    setTimeout(() => {
      this._refreshAllTokens({ refreshToken });
    }, millisecondsBeforeTokensExpire);
  };
}
