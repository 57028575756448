const dependencies = {
  getEnvironmentVariable: require('shared-between-everything/src/doings/getEnvironmentVariable/getEnvironmentVariable')
    .default,
};

export { dependencies };

export default () =>
  dependencies.getEnvironmentVariable('NODE_ENV') === 'test' &&
  dependencies.getEnvironmentVariable('INTEGRATION_TEST_RUNNING') !== 'true';
