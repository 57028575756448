import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Div from '../../private/Element/Div/Div';
import Option from '../../private/Element/Option/Option';
import Select from '../../private/Element/Select/Select';
import InputWrapper from '../../private/InputWrapper/InputWrapper';
import Icon from '../Icon/Icon';
import Map from '../Map/Map';
import styles from './SelectionInput.module.scss';

const SelectionInput = ({
  model,
  label,
  autoFocus,
  selectionInputName,
  ...props
}) => (
  <InputWrapper
    label={label}
    errorText={model.validationTranslationKey}
    {...props}
  >
    <Div relative>
      <Select
        border={{
          size: 'sm',
          color: 'grey5',
          radius: { size: 'zero' },
        }}
        occupyHorizontalSpace
        padding={{ size: 'xs' }}
        value={model.outboundValue}
        onChange={model.setInboundValue}
        autoFocus={autoFocus}
        className={styles.selectionInput}
        data-select-e2e-test={selectionInputName}
      >
        <Map items={model.valueOptions}>
          {({ id, name, props }) => (
            <Option value={id} {...props}>
              {name}
            </Option>
          )}
        </Map>
      </Select>

      <Icon
        size="sm"
        className={styles.selectionInput_icon}
        margin={{ right: 'xs' }}
        absolutePosition="my center right to center right of parent"
        name="chevron-down"
      />
    </Div>
  </InputWrapper>
);

export default decorate(observer)(SelectionInput);
