import withExposedConfiguration from 'shared-between-everything/src/test-utils/withExposedConfiguration';
import ConfirmationDialogModel from '../../App/ConfirmationDialog/ConfirmationDialogModel';
import NotificationsModel from '../../components/public/Notifications/NotificationsModel';
import getModel from '../../decorators/withModel/getModel';

export const dependencies = {
  confirmationDialogModel: getModel(ConfirmationDialogModel),
  notificationsModel: getModel(NotificationsModel),
};

export default withExposedConfiguration(
  ({
    call,
    showSuccessMessage = true,
    confirmationMessage,
    successMessage,
  }) => async callParameters => {
    const callWasConfirmed = await dependencies.confirmationDialogModel.getConfirmation(
      confirmationMessage,
    );

    if (callWasConfirmed) {
      const response = await call(callParameters);

      if (showSuccessMessage) {
        dependencies.notificationsModel.setSuccess(successMessage);
      }

      return { callWasDone: true, ...response };
    }

    return { callWasDone: false };
  },
);
