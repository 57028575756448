import times from 'lodash/fp/times';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import { baseline } from 'shared-between-front-ends/src/components/public/constants';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import Map from 'shared-between-front-ends/src/components/public/Map/Map';
import Page from 'shared-between-front-ends/src/components/public/Page/Page';
import SemanticButton from 'shared-between-front-ends/src/components/public/SemanticButton/SemanticButton';
import StickyTable from 'shared-between-front-ends/src/components/public/Table/StickyTable';
import withModel from 'shared-between-front-ends/src/decorators/withModel/withModel';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import Navigation from '../../Navigation/Navigation';
import styles from '../Scheduler/Scheduler.module.scss';
import schedulerTranslations from '../schedulerTranslations';
import AbsenceSchedulerModel from './AbsenceSchedulerModel/AbsenceSchedulerModel';
import AbsenceWorkOrder from './AbsenceWorkOrder/AbsenceWorkOrder';
import ResourceRow from './ResourceRow/ResourceRow';
import AbsenceBudgetModal from './SchedulerTableHeader/AbsenceBudget/AbsenceBudgetModal/AbsenceBudgetModal';
import SchedulerTableHeader from './SchedulerTableHeader/SchedulerTableHeader';

const translate = localTranslate(schedulerTranslations);

const AbsenceScheduler = ({ absenceSchedulerModel }) => {
  const {
    absenceWorkOrderTypes,
    absenceWorkOrders,
    buttonForClearingWorkOrderSelectionIsEnabled,
    changeViewToScheduler,
    clearWorkOrderSelection,
    daysOfSelectedDateRange,
    districtId,
    leaveDaysByResourceId,
    resourceRows,
    resourcesById,
    showDayOfMonthString,
    showDayOfWeekString,
    showWeekNumberLabels,
    teamId,

    dependencies: { activationModel },

    absenceBudgetModel: {
      budgetMonths,
      goToNextMonth,
      goToPreviousMonth,
      selectedBudgetMonth,
      unselectBudgetMonth,
    },

    monthsOfSelectedDateRange,

    userRights,
  } = absenceSchedulerModel;

  return (
    <Page data-absence-scheduler-e2e-test={teamId}>
      <Navigation margin={false} />

      <Div>
        <Flex relative>
          <AbsenceBudgetModal
            activationModel={activationModel}
            selectedBudgetMonth={selectedBudgetMonth}
            unselectBudgetMonth={unselectBudgetMonth}
          />

          <StickyTable
            flexItem={{ width: '100%' }}
            occupyVerticalSpace
            className={styles.scheduler_table}
            headers={[
              <SchedulerTableHeader
                budgetMonths={budgetMonths}
                goToNextMonth={goToNextMonth}
                goToPreviousMonth={goToPreviousMonth}
                showDayOfWeekString={showDayOfWeekString}
                showDayOfMonthString={showDayOfMonthString}
                showWeekNumberLabels={showWeekNumberLabels}
                changeViewToScheduler={changeViewToScheduler}
                monthsOfSelectedDateRange={monthsOfSelectedDateRange}
                absenceWorkOrderTypes={absenceWorkOrderTypes}
                data-table-header-test
              />,
            ]}
            rows={resourceRows}
            cols={getTableCols(daysOfSelectedDateRange, absenceWorkOrderTypes)}
          >
            {({ days, resourceId }) => (
              <ResourceRow
                key={resourceId}
                days={days}
                districtId={districtId}
                teamId={teamId}
                resource={resourcesById.get(resourceId) || {}}
                leaveDays={leaveDaysByResourceId[resourceId] || {}}
                absenceWorkOrderTypes={absenceWorkOrderTypes}
              />
            )}
          </StickyTable>

          <Div flexItem={{ width: '240px' }}>
            <Div
              backgroundColor="lightGrey"
              className={styles.scheduler_sticky}
            >
              <Div padding={{ size: 'sm' }}>
                <BodyText singleLine>
                  {translate('absenceWorkOrderListHeader')}
                </BodyText>
              </Div>

              <Div padding={{ vertical: 'xs', horizontal: 'sm' }}>
                <SemanticButton
                  onClick={clearWorkOrderSelection}
                  color="action"
                  hoverColor="actionHover"
                  enabled={buttonForClearingWorkOrderSelectionIsEnabled}
                  data-clearing-work-order-selection-test
                >
                  <Icon name="calendar-check" size="md" />
                </SemanticButton>
              </Div>

              <Map items={absenceWorkOrders} separator={<Gutter size="xxs" />}>
                {absenceWorkOrder => (
                  <AbsenceWorkOrder
                    userRights={userRights}
                    districtId={districtId}
                    teamId={teamId}
                    absenceWorkOrder={absenceWorkOrder}
                  />
                )}
              </Map>
            </Div>
          </Div>
        </Flex>
      </Div>
    </Page>
  );
};

const getTableCols = (daysOfSelectedDateRange, absenceWorkOrderTypes) => [
  {
    width: 54 * (baseline / 2),
  },
  ...times(
    () => ({
      width: 9 * (baseline / 2),
    }),
    absenceWorkOrderTypes.length,
  ),
  ...times(
    () => ({
      width: '100%',
    }),
    daysOfSelectedDateRange.length,
  ),
];

export default decorate(
  withModel({
    Model: AbsenceSchedulerModel,
    props: [
      {
        name: 'absenceSchedulerModel',
      },
    ],
  }),
)(AbsenceScheduler);
