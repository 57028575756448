import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import Map from 'shared-between-front-ends/src/components/public/Map/Map';
import WorkOrderCard from './WorkOrderCard/WorkOrderCard';

const WorkOrderList = ({
  workOrders,
  districtId,
  teamId,
  userRights,
  ...props
}) => {
  return (
    <Div data-work-order-list-e2e-test={workOrders.length} {...props}>
      <Map items={workOrders} separator={<Gutter size="3xs" />}>
        {workOrder => (
          <WorkOrderCard
            userRights={userRights}
            workOrder={workOrder}
            districtId={districtId}
            teamId={teamId}
          />
        )}
      </Map>
    </Div>
  );
};

export default decorate(observer)(WorkOrderList);
