import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Div from '../../private/Element/Div/Div';
import BodyText from '../BodyText/BodyText';
import Flex from '../Flex/Flex';
import Gutter from '../Gutter/Gutter';
import Map from '../Map/Map';
import SeparatorLine from '../SeparatorLine/SeparatorLine';
import Toggle from './Toggle/Toggle';

function ToggleGroup({
  value: currentValue,
  values,
  onChange,
  label,
  occupyHorizontalSpace,
  children,
  ...props
}) {
  return (
    <Flex centeredVertically {...props}>
      {label && (
        <Div>
          <BodyText size="3xs">{label}</BodyText>

          <Gutter size="xs" />
        </Div>
      )}

      <Div
        flexItem
        border={{ radius: { size: 'lg' }, color: 'grey6', size: 'sm' }}
        backgroundColor="white"
        hideOverflow
      >
        <Flex>
          <Map
            items={values}
            separator={<SeparatorLine backgroundColor="grey80" />}
          >
            {(item, { itemIsFirst, itemIsLast }) => {
              const padding = {
                left: itemIsFirst ? 'sm' : 'xs',
                right: itemIsLast ? 'sm' : 'xs',
                vertical: 'xxs',
              };

              const props = {
                value: item.value,
                checked: item.value === currentValue,
                onClick: () => onChange(item.value),
                disabled: item.value === currentValue,
                label: item.label,
                padding,
                'data-item-e2e-test': item.value,
                ...item.props,
              };

              return children ? children(props) : <Toggle {...props} />;
            }}
          </Map>
        </Flex>
      </Div>
    </Flex>
  );
}

const DecoratedToggleGroup = decorate(observer)(ToggleGroup);

DecoratedToggleGroup.Item = Toggle;

export default DecoratedToggleGroup;
