import React from 'react';
import { baseline } from 'shared-between-front-ends/src/components/public/constants';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import HeavyText from 'shared-between-front-ends/src/components/public/HeavyText/HeavyText';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';

const IconAndLabel = ({ iconName, children, ...props }) => (
  <Flex centeredVertically {...props}>
    <Icon style={{ width: 3 * baseline }} name={iconName} size="md" />

    <Gutter size="sm" />

    <HeavyText>{children}</HeavyText>
  </Flex>
);

export default IconAndLabel;
