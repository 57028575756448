import {
  curry,
  negate as not,
  pipeline,
  dropRightWhile,
} from '../../functionalProgramming';

export default curry((until, values) =>
  pipeline(values, dropRightWhile(not(until))),
);
