import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';

import withModel from '../../../decorators/withModel/withModel';
import RoutingModel from '../../../models/RoutingModel/RoutingModel';
import Link from '../Link/Link';

function RouteLink({
  getUrl,
  routeName,
  pathParameters,
  queryParameters,
  setRouteTo,
  children,
  ...props
}) {
  const onClick = () =>
    setRouteTo({ name: routeName, pathParameters, queryParameters });

  return (
    <Link
      onClick={onClick}
      to={getUrl(routeName, pathParameters, queryParameters)}
      {...props}
      data-link-e2e-test={routeName}
    >
      {children}
    </Link>
  );
}

export default decorate(
  withModel({
    Model: RoutingModel,
    props: [
      {
        name: 'getUrl',
        modelPath: 'getUrl',
      },
      {
        name: 'setRouteTo',
        modelPath: 'setRouteTo',
      },
    ],
  }),
)(RouteLink);
