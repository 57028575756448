import bodyParametersInputMapper from 'shared-between-everything/src/doings/bodyParametersInputMapper/bodyParametersInputMapper';
import getCall from '../../../doings/getCall/getCall';

export default getCall({
  apiCallConfig: {
    path: '/api/session/refresh',
    method: 'POST',
    inputMapper: bodyParametersInputMapper,
  },
  decoratorConfig: {
    spinnerName: 'refreshing-session',
    showSpinnerInstantly: false,
  },
});
