import { computed } from 'mobx';
import {
  getOr,
  matches,
} from 'shared-between-everything/src/functionalProgramming';
import localTranslate from '../../../doings/localTranslate/localTranslate';
import InputModelBaseClass from '../../../InputModelBaseClass';
import selectionInputTranslations from './selectionInputTranslations';

const translate = localTranslate(selectionInputTranslations);

export default class SelectionInputModel extends InputModelBaseClass {
  @computed
  get valueOptions() {
    return [
      { id: null, name: translate('noSelectedValue') },
      ...this._getValueOptions(),
    ];
  }

  @computed
  get internalValue() {
    if (!this._internalValue) {
      return this._internalValue;
    }

    return (
      this.valueOptions.find(matches({ id: this._internalValue.id })) || null
    );
  }

  constructor({ getValueOptions, ...rest }) {
    super({
      inboundFormatters: [
        valueOptionId =>
          valueOptionId
            ? this.valueOptions.find(matches({ id: valueOptionId }))
            : null,
      ],

      outboundFormatters: [getOr(null, 'id')],

      ...rest,
    });

    this._getValueOptions = getValueOptions;
  }
}
