import defaultEmptyTo from 'shared-between-everything/src/doings/defaultEmptyTo/defaultEmptyTo';
import InputModelBaseClass from '../../../InputModelBaseClass';

export default class ColorInputModel extends InputModelBaseClass {
  constructor(parameterObject) {
    super({
      inboundFormatters: [],
      outboundFormatters: [defaultEmptyTo('#ffffff')],
      ...parameterObject,
    });
  }
}
