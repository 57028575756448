import InputModelBaseClass from '../../../InputModelBaseClass';

export default class BooleanInputModel extends InputModelBaseClass {
  constructor({ defaultValue = null, ...rest } = {}) {
    super({
      defaultValue,
      ...rest,
    });
  }

  setValueToTrue = () => {
    this.setValue(true);
  };

  setValueToFalse = () => {
    this.setValue(false);
  };

  toggle = () => {
    this.setValue(!this.value);
  };
}
