import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import Address from 'shared-between-front-ends/src/components/public/Address/Address';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import HeavyText from 'shared-between-front-ends/src/components/public/HeavyText/HeavyText';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import Popover from 'shared-between-front-ends/src/components/public/Popover/Popover';
import RouteLink from 'shared-between-front-ends/src/components/public/RouteLink/RouteLink';
import SemanticButton from 'shared-between-front-ends/src/components/public/SemanticButton/SemanticButton';
import SensuelContentBox from '../../../../../../shared/SensuelContentBox/SensuelContentBox';
import StripedBox from '../../../../../../shared/StripedBox/StripedBox';

const WorkOrderCard = ({
  districtId,
  teamId,
  userRights,
  workOrder: {
    address,
    postalCode,
    city,
    backgroundColor,
    erpId,
    highlight,
    id: workOrderId,
    name,
    selectForScheduling,
    type,
    border,
  },
}) => (
  <Popover
    data-work-order-popover-test={workOrderId}
    activationId={`work-order-popover-for-${workOrderId}`}
    content={
      <StripedBox color={backgroundColor} padding={{ right: 'xlg' }} width="md">
        <Div padding={{ size: 'xs' }}>
          <HeavyText>{name}</HeavyText>

          <BodyText size="3xs" block>
            {erpId}
          </BodyText>

          <BodyText size="3xs" block>
            <Address
              streetAddress={address}
              postalCode={postalCode}
              city={city}
            />
          </BodyText>
        </Div>
      </StripedBox>
    }
  >
    {
      <SensuelContentBox
        border={border}
        draggableFor={'appointment-scheduling'}
        draggableValue={{ workOrderId }}
        data-work-order-box-test
        leftArea={{
          backgroundColor,
          highlight: highlight,
          'data-work-order-box-left-area-test': true,
          padding: { size: 'zero' },
          content: (
            <SemanticButton
              block
              onClick={selectForScheduling}
              padding={{ horizontal: 'sm' }}
              draggable={false}
              occupyVerticalSpace
              color="white"
              hoverColor="grey2"
              data-select-work-order-for-scheduling-test={workOrderId}
              data-select-work-order-for-scheduling-e2e-test={workOrderId}
            >
              <Flex centered occupyVerticalSpace width="3xs">
                <Icon
                  name={highlight ? 'calendar-check' : 'calendar'}
                  size="md"
                />
              </Flex>
            </SemanticButton>
          ),
        }}
        rightArea={{
          backgroundColor: 'white',
          padding: { size: 'zero' },
          content: (
            <Div>
              {userRights.createWorkOrdersOfAllTypes ? (
                <RouteLink
                  draggable={false}
                  block
                  routeName="district-team-update-work-order-from-scheduler"
                  padding={{
                    vertical: 'xxs',
                    horizontal: 'sm',
                  }}
                  pathParameters={{
                    districtId,
                    workOrderId,
                    teamId,
                  }}
                  data-update-work-order-test
                  data-open-work-order-e2e-test={workOrderId}
                  data-open-work-order-by-name-e2e-test={name}
                  data-work-order-e2e-test={workOrderId}
                  data-color-e2e-test={backgroundColor}
                >
                  {type === 'workOrder' ? (
                    <Div>
                      <BodyText singleLine block>
                        {name}
                      </BodyText>

                      <BodyText singleLine block size="3xs">
                        {erpId}
                      </BodyText>
                    </Div>
                  ) : (
                    <BodyText
                      occupyVerticalSpace
                      block
                      padding={{ vertical: 'xs' }}
                    >
                      <Flex centeredVertically>{name}</Flex>
                    </BodyText>
                  )}
                </RouteLink>
              ) : (
                <Div>
                  {type === 'workOrder' ? (
                    <RouteLink
                      draggable={false}
                      block
                      routeName="district-team-update-work-order-from-scheduler"
                      padding={{
                        vertical: 'xxs',
                        horizontal: 'sm',
                      }}
                      pathParameters={{
                        districtId,
                        workOrderId,
                        teamId,
                      }}
                      data-update-work-order-test
                      data-open-work-order-e2e-test={workOrderId}
                      data-open-work-order-by-name-e2e-test={name}
                      data-work-order-e2e-test={workOrderId}
                      data-color-e2e-test={backgroundColor}
                    >
                      <Div data-not-updatable-work-order-type>
                        <BodyText singleLine block>
                          {name}
                        </BodyText>

                        <BodyText singleLine block size="3xs">
                          {erpId}
                        </BodyText>
                      </Div>
                    </RouteLink>
                  ) : (
                    <Div
                      data-not-update-work-order-test
                      padding={{
                        vertical: 'xxs',
                        horizontal: 'sm',
                      }}
                    >
                      <BodyText
                        occupyVerticalSpace
                        block
                        padding={{ vertical: 'xs' }}
                      >
                        <Flex centeredVertically>{name}</Flex>
                      </BodyText>
                    </Div>
                  )}
                </Div>
              )}
            </Div>
          ),
        }}
      />
    }
  </Popover>
);

export default decorate(observer)(WorkOrderCard);
