export default {
  en: {
    userRightRequired: 'User right "{missingUserRight}" is required',
    goToFrontPage: 'To front-page',
  },
  fi: {
    userRightRequired: 'Käyttäjäoikeus "{missingUserRight}" vaaditaan',
    goToFrontPage: 'Etusivulle',
  },
  pl: {
    userRightRequired: 'Prawo użytkownika "{missingUserRight}" są wymagane',
    goToFrontPage: 'Idź na główną stronę',
  },
  se: {
    userRightRequired: 'Användarrättigheter "{missingUserRight}" behövs',
    goToFrontPage: 'Till startsidan',
  },
};
