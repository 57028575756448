import map from 'lodash/fp/map';
import range from 'lodash/fp/range';
import { action, computed, observable } from 'mobx';
import moment from 'moment-timezone';
import formatToYearAndMonth from 'shared-between-everything/src/date-time-abstractions/formatToYearAndMonth/formatToYearAndMonth';
import pipeline from 'shared-between-everything/src/doings/pipeline/pipeline';

import {
  countBy,
  filter,
  get,
  toNumber,
  toString,
} from 'shared-between-everything/src/functionalProgramming';
import getWorkOrderTypeValueObject from 'shared-between-everything/src/getWorkOrderTypeValueObject';
import TextInputModel from 'shared-between-front-ends/src/components/public/TextInput/TextInputModel';
import hasOnlyDigits from 'shared-between-front-ends/src/validators/hasOnlyDigits/hasOnlyDigits';

export default class AbsenceBudgetModel {
  dependencies = {};

  constructor(
    getAbsenceBudgets,
    createAbsenceBudget,
    absenceSchedulerModel,
    activationModel,
  ) {
    this.getAbsenceBudgets = getAbsenceBudgets;
    this.createAbsenceBudget = createAbsenceBudget;
    this.absenceSchedulerModel = absenceSchedulerModel;
    this.activationModel = activationModel;
  }

  @computed
  get leaveDaysByYearAndMonth() {
    return pipeline(
      [...this.absenceSchedulerModel.appointmentsById.values()],
      filter(toAppointmentsFromRecentFullMonths),
      filter(toCountedInAbsenceBudget),
      countBy(get('yearAndMonth')),
    );
  }

  @observable
  _startDateTimeString = moment().startOf('month').format();

  @observable _budgetedAbsenceDaysByYearAndMonth = {};
  @action
  _setAbsenceBudget = absenceBudget => {
    this._budgetedAbsenceDaysByYearAndMonth = absenceBudget;
  };

  refresh = async () => {
    const { response: absenceBudget } = await this.getAbsenceBudgets({
      teamId: this.absenceSchedulerModel.teamId,
    });

    this._setAbsenceBudget(absenceBudget);
  };

  @observable
  selectedBudgetMonth = null;

  @action
  setSelectedBudgetMonth = budgetMonth => {
    const initialBudgetedAbsenceDays = toString(
      budgetMonth.budgetedAbsenceDayCount,
    );
    const budgetedAbsenceDaysInputModel = new TextInputModel({
      defaultValue: initialBudgetedAbsenceDays,
      validators: [hasOnlyDigits],
    });

    this.selectedBudgetMonth = {
      ...budgetMonth,
      budgetedAbsenceDayCount: budgetedAbsenceDaysInputModel,
      submit: async () => {
        await this.createAbsenceBudget(
          toAbsenceBudgetObject(
            this.selectedBudgetMonth,
            this.absenceSchedulerModel.teamId,
          ),
        );

        this.refresh();

        this.unselectBudgetMonth();
      },
    };

    this.activationModel.activate('selected-budget-month-popover', {
      showOverlay: true,
    });
  };

  @action
  unselectBudgetMonth = () => {
    this.selectedBudgetMonth = null;

    this.activationModel.deactivate('selected-budget-month-popover');
  };

  @computed
  get budgetMonths() {
    const numberOfShownMonths = 5;

    const toStartDateTimePlusMonths = toDateTimePlusMonthsFor(
      this._startDateTimeString,
    );

    const toBudgetMonth = ([year, month]) => {
      const budgetMonth = {
        month,
        year,
        actualAbsenceDayCount: this._getActualAbsenceDays(year, month),
        budgetedAbsenceDayCount: this._getBudgetedAbsenceDays(year, month),
      };

      return {
        ...budgetMonth,
        select: () => this.setSelectedBudgetMonth(budgetMonth),
      };
    };

    return pipeline(
      numberOfShownMonths,
      range(0),
      map(toStartDateTimePlusMonths),
      map(toBudgetMonth),
    );
  }

  _getActualAbsenceDays = (year, month) => {
    return this.leaveDaysByYearAndMonth[`${year}-${month}`] || 0;
  };

  _getBudgetedAbsenceDays = (year, month) => {
    return this._budgetedAbsenceDaysByYearAndMonth[`${year}-${month}`] || 0;
  };

  _goToMonth = monthBias => () => {
    this._startDateTimeString = moment(this._startDateTimeString)
      .add(monthBias, 'month')
      .format();
  };

  @action
  goToNextMonth = this._goToMonth(1);

  @action
  goToPreviousMonth = this._goToMonth(-1);
}

const toDateTimePlusMonthsFor = dateTime => months => {
  const [year, month] = moment(dateTime).add(months, 'months').toArray();

  return [year, month + 1];
};

const toAppointmentsFromRecentFullMonths = appointment =>
  moment(appointment.startDateTime).isSameOrAfter(
    moment().add(-1, 'months').startOf('month'),
  );

const toCountedInAbsenceBudget = ({ workOrderType }) =>
  getWorkOrderTypeValueObject(workOrderType).isCountedInAbsenceBudget;

const toAbsenceBudgetObject = (currentSelectedBudgetMonth, teamId) => {
  const absenceBudget = toNumber(
    currentSelectedBudgetMonth.budgetedAbsenceDayCount.value,
  );

  return {
    teamId,
    yearAndMonth: formatToYearAndMonth(
      `${currentSelectedBudgetMonth.year}-${currentSelectedBudgetMonth.month}`,
    ),
    absenceBudget,
  };
};
