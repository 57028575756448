import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import some from 'lodash/fp/some';
import React from 'react';

import wrapEachComponentWithKey from '../../../doings/wrapEachComponentWithKey/wrapEachComponentWithKey';
import BodyText from '../BodyText/BodyText';
import Heading from '../Heading/Heading';
import Map from '../Map/Map';
import styles from './Table.module.scss';
import TableCell from './TableCell/TableCell';
import TableHeadingCell from './TableHeadingCell/TableHeadingCell';
import TableRow from './TableRow/TableRow';

const renderHeaderRowUsing = cols => (
  <TableRow>
    <Map items={cols}>{get('content')}</Map>
  </TableRow>
);

function Table({
  heading,
  cols = [],
  headers = some('content', cols) ? [renderHeaderRowUsing(cols)] : [],
  footers = [],
  rows,
  striped,
  className,
  children: childrenCallback,
  ...props
}) {
  return (
    <BodyText
      tagName="div"
      data-indicators-e2e-test={rows.length > 0 ? 'non-empty' : 'empty'}
      className={[
        styles.table,
        { [styles.table__striped]: striped },
        className,
      ]}
      {...props}
    >
      {heading && (
        <Heading size="xs" block useDefaultBottomMargin color="black">
          {heading}
        </Heading>
      )}

      <table className={styles.table_table}>
        <colgroup>
          <Map items={cols}>
            {({ width, className }) => (
              <col width={width} className={className} />
            )}
          </Map>
        </colgroup>

        {!isEmpty(headers) && (
          <thead>{wrapEachComponentWithKey(headers)}</thead>
        )}

        <tbody>
          <Map items={rows}>{childrenCallback}</Map>
        </tbody>

        {!isEmpty(footers) && (
          <tfoot>{wrapEachComponentWithKey(footers)}</tfoot>
        )}
      </table>
    </BodyText>
  );
}

Table.Cell = TableCell;
Table.HeadingCell = TableHeadingCell;
Table.Row = TableRow;

export default Table;
