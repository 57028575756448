export default {
  en: {
    duplicateId: 'Already exists',
  },
  fi: {
    duplicateId: 'Sähköpostiosoite on jo olemassa',
  },
  pl: {
    duplicateId: 'Użytkownik/adres email już istnieje',
  },
  se: {
    duplicateId: 'Finns redan',
  },
};
