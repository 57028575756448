import React from 'react';
import reactDom from 'react-dom';

import getElement from './getElement';

export const dependencies = { reactDom, getElement };

export default (Feature, htmlId, props) => {
  const targetElement = dependencies.getElement(htmlId);

  if (targetElement) {
    dependencies.reactDom.render(<Feature {...props} />, targetElement);
  }
};
