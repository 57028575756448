export default {
  en: {
    createDistrictButton: 'Create district',
    backButton: 'Cancel',
    districtName: 'Name',
    districtManager: 'District manager',
    createDistrict: 'Create district',
    createSuccess: 'Created successfully',
  },
  fi: {
    createDistrictButton: 'Luo uusi alue',
    backButton: 'Peruuta',
    districtName: 'Nimi',
    districtManager: 'Aluepäällikkö',
    createDistrict: 'Luo uusi alue',
    createSuccess: 'Luotu onnistuneesti',
  },
  pl: {
    createDistrictButton: 'Utwórz dział',
    backButton: 'Anuluj',
    districtName: 'Nazwa',
    districtManager: 'Kierownik działu',
    createDistrict: 'Utwórz dział',
    createSuccess: 'Utworzono pomyślnie',
  },
  se: {
    createDistrictButton: 'Skapa distrikt',
    backButton: 'Avbryt',
    districtName: 'Namn',
    districtManager: 'Distriktschef',
    createDistrict: 'Skapa distrikt',
    createSuccess: 'Skapad',
  },
};
