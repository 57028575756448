import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import Map from 'shared-between-front-ends/src/components/public/Map/Map';
import Page from 'shared-between-front-ends/src/components/public/Page/Page';
import RouteLink from 'shared-between-front-ends/src/components/public/RouteLink/RouteLink';
import SecondaryButton from 'shared-between-front-ends/src/components/public/SecondaryButton/SecondaryButton';
import withModel from 'shared-between-front-ends/src/decorators/withModel/withModel';
import SpinnerTentative from 'shared-between-front-ends/src/decorators/withSpinnerFor/SpinnerTentative/SpinnerTentative';
import SessionModel from 'shared-between-front-ends/src/models/SessionModel/SessionModel';
import Panel from 'shared-between-front-ends/src/components/public/Panel/Panel';
import Navigation from '../../Navigation/Navigation';
import District from './District/District';
import DistrictsModel from './DistrictsModel';
import districtsTranslations from './districtsTranslations';

const translate = localTranslate(districtsTranslations);

const Districts = ({ districts, userRights }) => (
  <Page backgroundColor="white" data-districts-page-e2e-test>
    <Navigation margin={false} />

    <SpinnerTentative spinnerName={['getting-districts', 'loading-teams']}>
      {userRights.maintainDistricts && (
        <Page.Row alignWithPanel data-district-maintenance-test>
          <Flex alignRight>
            <RouteLink
              routeName="create-district"
              block
              data-create-district-test
              data-create-district-button-e2e-test
            >
              <SecondaryButton clickableAppearance>
                {translate('createNewDistrict')}
              </SecondaryButton>
            </RouteLink>
          </Flex>
        </Page.Row>
      )}

      <Page.Row>
        <Panel transparent showOverflow>
          <Panel.Content data-list-of-districts-e2e-test>
            <Map items={districts} separator={<Gutter />}>
              {district => (
                <District
                  data-district-test={district.id}
                  data-district-e2e-test={district.name}
                  data-name-of-district-manager-e2e-test={
                    district.nameOfManager
                  }
                  district={district}
                  userRights={userRights}
                />
              )}
            </Map>
          </Panel.Content>
        </Panel>
      </Page.Row>
    </SpinnerTentative>
  </Page>
);

export default decorate(
  withModel(
    {
      Model: DistrictsModel,
      props: [{ name: 'districts', modelPath: 'districts' }],
    },
    {
      Model: SessionModel,
      props: [
        {
          name: 'userRights',
          modelPath: 'userRights',
        },
      ],
    },
  ),
)(Districts);
