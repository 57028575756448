import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Form from 'shared-between-front-ends/src/components/public/Form/Form';
import GoBackButton from 'shared-between-front-ends/src/components/public/GoBackButton/GoBackButton';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import MainHeading from 'shared-between-front-ends/src/components/public/MainHeading/MainHeading';
import MultiTextInput from 'shared-between-front-ends/src/components/public/MultiTextInput/MultiTextInput';
import Page from 'shared-between-front-ends/src/components/public/Page/Page';
import PrimaryButton from 'shared-between-front-ends/src/components/public/PrimaryButton/PrimaryButton';
import TextInput from 'shared-between-front-ends/src/components/public/TextInput/TextInput';
import TileBag from 'shared-between-front-ends/src/components/public/TileBag/TileBag';
import ToggleGroup from 'shared-between-front-ends/src/components/public/ToggleGroup/ToggleGroup';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import Navigation from '../../Navigation/Navigation';
import teamTranslations from '../teamTranslations';

const translate = localTranslate(teamTranslations);

const UpdateTeam = ({ model }) => (
  <Form onSubmit={model.submit} data-submit-form-test>
    <Page
      loadingUnless={() => model.team.promiseStatus.fulfilled}
      segmented
      data-team-details-page-test
      data-team-maintenance-page-e2e-test
    >
      <Page.Header>
        <Navigation />
      </Page.Header>

      <Page.Body>
        {() => (
          <>
            <Page.Row alignWithPanel margin={{ bottom: 'md' }}>
              <MainHeading>{translate('team')}</MainHeading>

              <Gutter size="md" />

              <Flex>
                <ToggleGroup
                  values={[
                    {
                      label: translate('openTeamDetailTab'),
                      value: 'teamDetailTab',
                    },
                    {
                      label: translate('openTeamEffortCatalogMaintenanceTab'),
                      value: 'teamEffortCatalogsTab',
                      props: { 'data-open-expected-efforts-e2e-test': true },
                    },
                  ]}
                  value="teamDetailTab"
                  onChange={model.openTeamEffortCatalogMaintenance}
                  data-team-maintenance-tabs-test
                />

                <Div flexItem />
              </Flex>
            </Page.Row>

            <Page.Row withPanel>
              <TileBag
                tiles={[
                  <TextInput
                    label={translate('teamName')}
                    model={model.name}
                    autoFocus
                    data-team-name-input-test
                  />,

                  <TextInput
                    label={translate('teamManager')}
                    model={model.nameOfManager}
                    data-name-of-manager-input-test
                  />,
                ]}
              />

              <Gutter />

              <TileBag
                tiles={[
                  <MultiTextInput
                    label={translate('costCentres')}
                    model={model.costCentres}
                    data-cost-centres-input-test
                  />,

                  <MultiTextInput
                    label={translate('departments')}
                    model={model.departments}
                    data-departments-input-test
                  />,

                  <MultiTextInput
                    label={translate('foremen')}
                    model={model.namesOfForemen}
                    data-names-of-foremen-input-test
                  />,
                ]}
              />
            </Page.Row>
          </>
        )}
      </Page.Body>

      <Page.Footer padding={{ size: 'sm' }} backgroundColor="white">
        {() => (
          <Page.Row alignWithPanel showOverflow margin={false}>
            <Flex centeredVertically>
              <GoBackButton boxShadow={{ size: 'lg' }}>
                {translate('backButton')}
              </GoBackButton>

              <Gutter size="sm" flexItem />

              <PrimaryButton
                type="submit"
                enabled={model.isValid}
                boxShadow={{ size: 'lg' }}
                data-submit-button-test
              >
                {translate('updateTeamButton')}
              </PrimaryButton>
            </Flex>
          </Page.Row>
        )}
      </Page.Footer>
    </Page>
  </Form>
);

export default decorate(observer)(UpdateTeam);
