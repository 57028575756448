import { action, computed, observable, runInAction } from 'mobx';

import addHours from 'shared-between-everything/src/date-time-abstractions/addHours/addHours';
import formatToDate from 'shared-between-everything/src/date-time-abstractions/formatToDate/formatToDate';
import formatToYearAndShortMonth from 'shared-between-everything/src/date-time-abstractions/formatToYearAndShortMonth/formatToYearAndShortMonth';
import getDifferenceInHours from 'shared-between-everything/src/date-time-abstractions/getDifferenceInHours/getDifferenceInHours';
import isWeekend from 'shared-between-everything/src/date-time-abstractions/isWeekend/isWeekend';
import setHours from 'shared-between-everything/src/date-time-abstractions/setHours/setHours';
import setMinutes from 'shared-between-everything/src/date-time-abstractions/setMinutes/setMinutes';
import setSeconds from 'shared-between-everything/src/date-time-abstractions/setSeconds/setSeconds';
import { pipeline } from 'shared-between-everything/src/functionalProgramming';
import getWorkOrderTypeValueObject from 'shared-between-everything/src/getWorkOrderTypeValueObject';
import HourInputModel from 'shared-between-front-ends/src/components/public/HourInput/HourInputModel';
import TextInputModel from 'shared-between-front-ends/src/components/public/TextInput/TextInputModel';
import when from 'shared-between-front-ends/src/decorators/when/when';
import withDebounce from 'shared-between-front-ends/src/decorators/withDebounce/withDebounce';
import observed from 'shared-between-front-ends/src/observed';

export default class AppointmentModel {
  constructor(
    userRights,
    createAppointment,
    callForModifyAppointment,
    workOrdersById,
    schedulerModel,
    activationModel,
    routingModel,
    callForCancelAppointment,
    appointmentData,
  ) {
    this.userRights = userRights;
    this.createAppointment = createAppointment;
    this.callForModifyAppointment = callForModifyAppointment;
    this.workOrdersById = workOrdersById;
    this.schedulerModel = schedulerModel;
    this.activationModel = activationModel;
    this.routingModel = routingModel;
    this.callForCancelAppointment = callForCancelAppointment;

    this.id = appointmentData.id;
    this._isObfuscated = appointmentData.obfuscated;

    this.teamId = appointmentData.teamId;
    this.workOrderId = appointmentData.workOrderId;

    this.info = new TextInputModel({
      defaultValue: appointmentData.info,
      readOnly: !userRights.doScheduling,
    });

    this.duration = new HourInputModel({
      readOnly: !userRights.doScheduling,
      defaultValue: getDifferenceInHours(
        appointmentData.startDateTime,
        appointmentData.endDateTime,
      ),
    });

    runInAction(() => {
      this._resourceId = appointmentData.resourceId;

      this.startDateTime = appointmentData.startDateTime;
    });
  }

  @when(observed('duration.value', 'info.value'))
  @withDebounce(500)
  debouncedUpdate = () => {
    this.update();
  };

  update = () => {
    this.callForModifyAppointment({
      id: this.id,
      teamId: this.teamId,
      workOrderId: this.workOrderId,
      resourceId: this.resourceId,
      startDateTime: this.dateTimeRange.start,
      endDateTime: this.dateTimeRange.end,
      info: this.info.value,
    });
  };

  @computed
  get detailsAreVisible() {
    if (
      isWeekend(this.startDateTime) &&
      !this.schedulerModel.weekendsAreShown
    ) {
      return false;
    }

    if (
      getWorkOrderTypeValueObject(this.workOrderType).appointmentsAreSimplified
    ) {
      return false;
    }

    return this.schedulerModel.weeksInView < 4;
  }

  @computed
  get isObfuscated() {
    return !this.workOrder || this._isObfuscated;
  }

  @computed
  get border() {
    return this.workOrder.border;
  }

  @computed
  get backgroundColor() {
    return this.workOrder.backgroundColor;
  }

  @computed
  get color() {
    return this.workOrder.color || 'danger';
  }

  @observable startDateTime;

  @computed
  get dateTimeRange() {
    return {
      start: this.startDateTime,
      end: addHours(this.duration.value, this.startDateTime),
    };
  }

  @observable deleted = false;

  @computed
  get resourceId() {
    return this.deleted ? null : this._resourceId;
  }

  @computed
  get date() {
    return this.deleted ? null : formatToDate(this.dateTimeRange.start);
  }

  @computed
  get yearAndMonth() {
    return formatToYearAndShortMonth(this.dateTimeRange.start);
  }

  @computed
  get resourceIdAndDate() {
    return `${this.resourceId}/${this.date}`;
  }

  @observable _resourceId;

  @computed
  get workOrder() {
    return this.workOrdersById.get(this.workOrderId);
  }

  @computed
  get workOrderName() {
    return this.workOrder && this.workOrder.name;
  }

  @computed
  get titleForScheduler() {
    const tooManyWeeksInView = this.schedulerModel.weeksInView > 4;

    if (tooManyWeeksInView) {
      return null;
    }

    if (
      isWeekend(this.startDateTime) &&
      !this.schedulerModel.weekendsAreShown
    ) {
      return null;
    }

    if (
      getWorkOrderTypeValueObject(this.workOrderType).appointmentsAreSimplified
    ) {
      return this.workOrderName;
    }

    return this.duration.outboundValue;
  }

  @computed
  get titleForAbsenceScheduler() {
    const tooManyWeeksInView = this.schedulerModel.weeksInView > 4;

    if (tooManyWeeksInView) {
      return null;
    }

    return this.workOrderName;
  }

  @computed
  get workOrderErpId() {
    return this.workOrder && this.workOrder.erpId;
  }

  @computed
  get workOrderType() {
    return this.workOrder && this.workOrder.type;
  }

  @computed
  get workOrderLink() {
    return this.workOrder.link;
  }

  @computed
  get isRelevantToAbsenceScheduling() {
    return !!getWorkOrderTypeValueObject(this.workOrderType).absenceScheduling;
  }

  @computed
  get _appointmentIsRelatedToSelectedWorkOrder() {
    return this.workOrderId === this.schedulerModel.selectedWorkOrderId;
  }

  @computed
  get disabled() {
    const workOrderIsSelected =
      this.schedulerModel.selectedWorkOrderId !== null;

    if (!workOrderIsSelected) {
      return false;
    }

    return !this._appointmentIsRelatedToSelectedWorkOrder;
  }

  createInBackend = () => {
    this.createAppointment({
      pathParameters: {
        teamId: this.teamId,
      },
      bodyParameters: {
        startDateTime: this.dateTimeRange.start,
        endDateTime: this.dateTimeRange.end,
        id: this.id,
        resourceId: this.resourceId,
        workOrderId: this.workOrderId,
        teamId: this.teamId,
        info: this.info.value,
      },
    });
  };

  navigateToUpdateWorkOrder = () => {
    this.routingModel.setRouteTo({
      name: 'district-team-update-work-order-from-scheduler',
      pathParameters: {
        districtId: this.schedulerModel.districtId,
        teamId: this.teamId,
        workOrderId: this.workOrderId,
      },
    });
  };

  select = () => {
    if (this._appointmentIsRelatedToSelectedWorkOrder) {
      this.delete();

      return;
    }

    if (!!this.schedulerModel.selectedWorkOrderId) {
      this.schedulerModel.scheduleWorkOrderToGridCell({
        workOrderId: this.schedulerModel.selectedWorkOrderId,
        resourceId: this.resourceId,
        date: this.date,
      });

      return;
    }

    if (this.routingModel.routeName !== 'district-team-scheduler') {
      return;
    }

    this.activationModel.activate(`popover-for-appointment-${this.id}`, {
      showOverlay: true,
    });
  };

  @action
  delete = () => {
    this.activationModel.deactivateAll();

    this.callForCancelAppointment({
      appointmentId: this.id,
    });

    this.deleted = true;
  };

  @action
  relocate = async ({ resourceId, date }) => {
    const startDateTime = pipeline(
      date,
      setHours(8),
      setMinutes(0),
      setSeconds(0),
    );

    this._resourceId = resourceId;
    this.startDateTime = startDateTime;

    await this.update();
  };

  @computed
  get isDraggable() {
    return this.userRights.doScheduling;
  }

  selectWorkOrder = () => {
    this.activationModel.deactivateAll();

    this.workOrder.selectForScheduling();
    this.schedulerModel.setSelectedHours(this.duration.value);
  };

  get _workOrderTypeValueObject() {
    return getWorkOrderTypeValueObject(this.workOrderType);
  }

  @computed
  get workOrderDoneStatusCanBeUpdated() {
    return (
      this._workOrderTypeValueObject.canBeDone &&
      !!this.schedulerModel.userRights.maintainWorkOrders
    );
  }

  @computed
  get workOrderUrgencyCanBeUpdated() {
    return (
      this._workOrderTypeValueObject.canBeUrgent &&
      !!this.schedulerModel.userRights.maintainWorkOrders
    );
  }
}
