import { pipeline } from 'shared-between-everything/src/functionalProgramming';
import getModel from '../../decorators/withModel/getModel';
import RoutingModel from '../../models/RoutingModel/RoutingModel';
import callToReportFatalErrorImport from './callToReportFatalError/callToReportFatalError';
import ErrorStateModel from './ErrorStateModel';

export const dependencies = {
  errorStateModel: getModel(ErrorStateModel),
  logError: require('./logError').default,
  getErrorStringFromOneOfKnownErrors: require('./getErrorStringFromOneOfKnownErrors')
    .default,
  callToReportFatalError: callToReportFatalErrorImport,
  routingModel: getModel(RoutingModel),
};

export default error => {
  dependencies.logError(error);

  const errorString = dependencies.getErrorStringFromOneOfKnownErrors(error);

  dependencies.errorStateModel.setErrorMessage(errorString);

  dependencies.callToReportFatalError({
    source: dependencies.routingModel.origin,
    error: cloneAsSerializable(error),
    route: dependencies.routingModel.externalRoute,
  });
};

const cloneAsSerializable = error =>
  pipeline(
    Object.getOwnPropertyNames(error),
    propertyNames => JSON.stringify(error, propertyNames),
    JSON.parse,
  );
