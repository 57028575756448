import { computed, untracked } from 'mobx';

import {
  filter,
  get,
  map,
  pipeline,
  sortBy,
} from 'shared-between-everything/src/functionalProgramming';

import BooleanInputModel from 'shared-between-front-ends/src/components/public/CheckboxInput/BooleanInputModel';
import NotificationsModel from 'shared-between-front-ends/src/components/public/Notifications/NotificationsModel';
import getModel from 'shared-between-front-ends/src/decorators/withModel/getModel';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import RoutingModel from 'shared-between-front-ends/src/models/RoutingModel/RoutingModel';
import teamTranslations from '../../teamTranslations';
import withLaziness from '../withLaziness/withLaziness';
import callForDtoImport from './callForDto/callForDto';
import callForUpdateOfCatalogsImport from './callForUpdateOfCatalogs/callForUpdateOfCatalogs';

const translate = localTranslate(teamTranslations);

export default class TeamEffortCatalogMaintenanceModel {
  static isSingleton = false;

  dependencies = {};

  constructor({
    callForDto = callForDtoImport,
    callForUpdateOfCatalogs = callForUpdateOfCatalogsImport,
    routingModel = getModel(RoutingModel),
    notificationsModel = getModel(NotificationsModel),
  } = {}) {
    this.dependencies.callForDto = callForDto;
    this.dependencies.callForUpdateOfCatalogs = callForUpdateOfCatalogs;
    this.dependencies.routingModel = routingModel;
    this.dependencies.notificationsModel = notificationsModel;
  }

  lazyDto = withLaziness(() =>
    this.dependencies.callForDto({
      teamId: this.dependencies.routingModel.pathParameters.teamId,
    }),
  );

  @computed
  get _allCatalogs() {
    return pipeline(
      this.lazyDto.promiseStatus.value.response.effortDefinitionCatalogs,

      map(({ id, isSelectedForTeam, name }) => ({
        id,

        name,

        isSelectedInput: untracked(
          () =>
            new BooleanInputModel({
              initialInternalValue: isSelectedForTeam,
            }),
        ),
      })),
    );
  }

  @computed
  get selectedCatalogs() {
    return pipeline(
      this._allCatalogs,
      filter({ isSelectedInput: { internalValue: true } }),
      sortBy('name'),
    );
  }

  @computed
  get nonSelectedCatalogs() {
    return pipeline(
      this._allCatalogs,
      filter({ isSelectedInput: { internalValue: false } }),
      sortBy('name'),
    );
  }

  @computed
  get teamName() {
    return this.lazyDto.promiseStatus.value.response.team.name;
  }

  submit = async () => {
    await this.dependencies.callForUpdateOfCatalogs({
      teamId: this.dependencies.routingModel.pathParameters.teamId,
      catalogIds: this.selectedCatalogs.map(get('id')),
    });

    this.dependencies.notificationsModel.setSuccess(
      translate('teamCatalogUpdateSuccess'),

      {
        'data-team-catalog-update-success-notification-test': true,
        'data-notification-for-successful-update-e2e-test': true,
      },
    );
  };

  openTeamDetailTab = () => {
    this.dependencies.routingModel.goBack();
  };

  goBack = () => {
    this.dependencies.routingModel.goBack();
  };
}
