export default unconfiguredFunction => {
  const exposedFunction = (...configuration) => {
    const configuredFunction = unconfiguredFunction(...configuration);

    configuredFunction.__configuration = configuration;

    configuredFunction.__unconfiguredFunction = exposedFunction;

    return configuredFunction;
  };

  return exposedFunction;
};
