import castArray from 'lodash/fp/castArray';
import find from 'lodash/fp/find';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';

import Div from '../../private/Element/Div/Div';
import InputWrapper from '../../private/InputWrapper/InputWrapper';
import BodyText from '../BodyText/BodyText';
import GutterBag from '../GutterBag/GutterBag';
import RadioButton from '../RadioButton/RadioButton';

function RadioButtonGroup({
  model = {},
  value = model.outboundValue,
  values = model.values,
  onChange = model.setValue,
  readOnly = model.readOnly,
  errorText = model.validationTranslationKey,
  name,
  label,
  ...props
}) {
  const contents = (readOnly
    ? renderSingleSelectedValue(value)
    : renderAllRadioButtons(value, onChange, name))(values);

  const internalMargin = {
    top: label ? 'xs' : 'zero',
    bottom: errorText ? 'xs' : 'zero',
  };

  return (
    <InputWrapper label={label} errorText={errorText} {...props}>
      <Div margin={internalMargin}>
        <GutterBag gutterSize="xs" contents={contents} />
      </Div>
    </InputWrapper>
  );
}

RadioButtonGroup.RadioButton = RadioButton;

export const NotObserverRadioButtonGroup = RadioButtonGroup;
export default decorate(observer)(RadioButtonGroup);

const renderSingleSelectedValue = selectedValue =>
  flow(
    find({ value: selectedValue }),
    ({ label }) => <BodyText block>{label}</BodyText>,
    castArray,
  );

const createRadio = (currentlySelectedValue, onChange, name) => ({
  value: valueOfThisRadioButton,
  label,
}) => (
  <RadioButton
    value={valueOfThisRadioButton}
    checked={valueOfThisRadioButton === currentlySelectedValue}
    onChange={() => onChange(valueOfThisRadioButton)}
    label={label}
    name={name}
  />
);

const renderAllRadioButtons = flow(createRadio, map);
