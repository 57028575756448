import React from 'react';
import Element from '../Element';

function Div({ children, ...props }) {
  return (
    <Element tagName="div" {...props}>
      {children}
    </Element>
  );
}

export default Div;
