import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';

const MinimalAppointment = ({ appointment, ...props }) => (
  <Flex
    centered
    backgroundColor={appointment.backgroundColor}
    padding={{ horizontal: appointment.titleForScheduler ? 'sm' : 'zero' }}
    {...props}
  >
    <BodyText color="white" singleLine>
      {appointment.titleForScheduler && appointment.titleForScheduler}

      {!appointment.titleForScheduler && <span>&nbsp;</span>}
    </BodyText>
  </Flex>
);

export default decorate(observer)(MinimalAppointment);
