import { observer } from 'mobx-react';
import React from 'react';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import MarginSpace from 'shared-between-front-ends/src/components/private/MarginSpace/MarginSpace';
import AttentionText from 'shared-between-front-ends/src/components/public/AttentionText/AttentionText';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import HeavyText from 'shared-between-front-ends/src/components/public/HeavyText/HeavyText';
import Map from 'shared-between-front-ends/src/components/public/Map/Map';
import Panel from 'shared-between-front-ends/src/components/public/Panel/Panel';
import SecondaryButton from 'shared-between-front-ends/src/components/public/SecondaryButton/SecondaryButton';
import SemanticButton from 'shared-between-front-ends/src/components/public/SemanticButton/SemanticButton';
import SeparatorLine from 'shared-between-front-ends/src/components/public/SeparatorLine/SeparatorLine';
import TextInput from 'shared-between-front-ends/src/components/public/TextInput/TextInput';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import StripedBox from '../../../shared/StripedBox/StripedBox';
import resourceDashboardTranslations from '../resourceDashboardTranslations';

const translate = localTranslate(resourceDashboardTranslations);

const PopoverContentForSelfScheduling = ({ day }) => (
  <MarginSpace
    compact
    data-popover-for-self-scheduling-test
    data-pop-up-for-self-scheduling-e2e-test
  >
    <Panel>
      <Flex pileVertically height="xlgOrViewport">
        <Panel.Heading
          iconName="calendar"
          headerText={`${translate('selfSchedule')} - ${day.dateString}`}
        />

        <Panel.Content
          padding={{
            bottom: false,
            size: 'md',
          }}
        >
          <TextInput
            model={day.teamWorkOrderSearchInput}
            data-team-work-order-search-string-test
          />
        </Panel.Content>

        <Panel.Content flexItem scrollOverflow>
          <Map items={day.teamWorkOrders} separator={<Gutter size="sm" />}>
            {({ workOrder, selfSchedule }) => (
              <SemanticButton
                onClick={() => {
                  selfSchedule(day.date);
                }}
                occupyHorizontalSpace
                data-team-work-order-test={workOrder.id}
                data-create-appointment-test={workOrder.id}
                data-team-work-order-name-test={workOrder.name}
                data-team-work-order-erp-id-test={workOrder.erpId}
                data-schedule-work-order-e2e-test={workOrder.id}
              >
                <StripedBox color={workOrder.color}>
                  <Div padding={{ size: 'sm' }}>
                    <HeavyText>{workOrder.name}</HeavyText>

                    <BodyText size="3xs" block>
                      {workOrder.erpId}
                    </BodyText>
                  </Div>
                </StripedBox>
              </SemanticButton>
            )}
          </Map>

          <Flex
            shown={day.teamWorkOrdersAreCropped}
            centeredVertically
            margin={{ top: 'md' }}
          >
            <SeparatorLine flexItem horizontal />

            <Gutter size="xs" />

            <AttentionText color="grey50">
              {translate(
                'teamWorkOrdersAreCropped',

                {
                  maximumNumberOfShownTeamWorkOrders:
                    day.maximumNumberOfShownTeamWorkOrders,
                },
              )}
            </AttentionText>

            <Gutter size="xs" />

            <SeparatorLine flexItem horizontal />
          </Flex>
        </Panel.Content>

        <Panel.Content>
          <Flex>
            <Gutter flexItem />
            <SecondaryButton
              onClick={day.cancelSelfScheduling}
              data-cancel-self-scheduling-test
            >
              {translate('cancel')}
            </SecondaryButton>
          </Flex>
        </Panel.Content>
      </Flex>
    </Panel>
  </MarginSpace>
);

export default observer(PopoverContentForSelfScheduling);
