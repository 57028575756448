import identity from 'lodash/fp/identity';
import ConfirmationDialogModel from '../../App/ConfirmationDialog/ConfirmationDialogModel';
import NotificationsModel from '../../components/public/Notifications/NotificationsModel';
import getModel from '../../decorators/withModel/getModel';
import getAuthenticatedCall from '../getAuthenticatedCall/getAuthenticatedCall';

export const dependencies = {
  getAuthenticatedCall,
  confirmationDialogModel: getModel(ConfirmationDialogModel),
  notificationsModel: getModel(NotificationsModel),
};

export default async ({
  path,
  spinnerName,
  confirmationMessage,
  confirmationNotRequired = false,
  showSuccessMessage = true,
  successMessage,
  responseMapper = identity,
}) => {
  const deleteIsConfirmed = confirmationNotRequired
    ? true
    : await dependencies.confirmationDialogModel.getConfirmation(
        confirmationMessage,
      );

  if (deleteIsConfirmed) {
    const callForDelete = dependencies.getAuthenticatedCall({
      apiCallConfig: {
        path,
        method: 'DELETE',
      },
      decoratorConfig: { spinnerName, responseMapper },
    });

    await callForDelete();

    if (showSuccessMessage) {
      dependencies.notificationsModel.setSuccess(successMessage);
    }

    return { deletionWasDone: true };
  }

  return { deletionWasDone: false };
};
