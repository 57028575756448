import { action, computed, observable } from 'mobx';
import withDebounce from 'shared-between-front-ends/src/decorators/withDebounce/withDebounce';
import getForMapsAndObjects from 'shared-between-front-ends/src/doings/nested-map-and-object/getForMapsAndObjects/getForMapsAndObjects';

export default class GridCellModel {
  constructor(
    scheduleWorkOrderToGridCell,
    schedulerModel,
    resource,
    day,
    gridData,
    appointmentsById,
  ) {
    this.schedulerModel = schedulerModel;
    this.resource = resource;
    this.day = day;
    this.scheduleWorkOrderToGridCell = scheduleWorkOrderToGridCell;
    this.gridData = gridData;
    this.appointmentsById = appointmentsById;

    this.resourceIdAndDate = `${resource.id}/${day.date}`;
    this.resourceId = resource.id;
  }

  @computed
  get _appointments() {
    return getForMapsAndObjects(
      this.gridData,
      `resources.${this.resourceId}.days.${this.day.date}.appointments`,
    );
  }

  @computed
  get appointments() {
    return this._appointments ? [...this._appointments.values()] : [];
  }

  @computed
  get backgroundColor() {
    const isWeekendOrNationalHoliday =
      this.day.isWeekend || this.day.isNationalHoliday;

    const shouldHighlight =
      !!this.schedulerModel.selectedWorkOrderId && !isWeekendOrNationalHoliday;

    if (shouldHighlight) {
      return 'highlight';
    }

    return isWeekendOrNationalHoliday ? 'lightGrey' : 'lightSuccess';
  }

  @computed
  get hasClickableAppearance() {
    return !!this.schedulerModel.selectedWorkOrderId;
  }

  @computed
  get compact() {
    return !this.schedulerModel.weekendsAreShown && this.day.isWeekend;
  }

  @action
  selectForScheduling = () => {
    if (!this.schedulerModel.selectedWorkOrderId) {
      return;
    }

    this.scheduleWorkOrderToGridCell({
      workOrderId: this.schedulerModel.selectedWorkOrderId,
      resourceId: this.resourceId,
      date: this.day.date,
      durationHours: this.schedulerModel.selectedHours,
    });
  };

  drop = ({ appointmentId, workOrderId }) => {
    if (appointmentId) {
      const appointment = this.appointmentsById.get(appointmentId);

      appointment.relocate({
        resourceId: this.resource.id,
        date: this.day.date,
      });
    }

    if (workOrderId) {
      this.scheduleWorkOrderToGridCell({
        workOrderId,
        resourceId: this.resource.id,
        date: this.day.date,
      });
    }
  };

  @observable draggedOver = false;
  @withDebounce(100)
  @action
  setDraggedOver = draggedOver => {
    this.draggedOver = draggedOver;
  };
}
