import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';
import noop from 'lodash/fp/noop';
import createAbsenceBudgetInputMapper from './createAbsenceBudgetInputMapper';

export default getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/teams/{teamId}/absence-budgets',
    method: 'POST',
    inputMapper: createAbsenceBudgetInputMapper,
  },
  decoratorConfig: {
    spinnerName: 'creating-absence-budget',
    showSpinnerInstantly: false,
    stubificationFunction: noop,
  },
});
