import { action, observable } from 'mobx';

export default toBeDecorated => (...args) => {
  const value = toBeDecorated(...args);

  if (!isPromise(value)) {
    return observable({
      pending: false,
      value,
    });
  }

  const observableObject = observable({ pending: true, value: null });

  value.then(
    action(asyncValue => {
      observableObject.pending = false;
      observableObject.value = asyncValue;
    }),
  );

  return observableObject;
};

const isPromise = value => !!value && value.constructor === Promise;
