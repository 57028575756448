import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import { get } from 'shared-between-everything/src/functionalProgramming';
import InputWrapper from 'shared-between-front-ends/src/components/private/InputWrapper/InputWrapper';
import GutterBag from 'shared-between-front-ends/src/components/public/GutterBag/GutterBag';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import Link from 'shared-between-front-ends/src/components/public/Link/Link';
import workOrderColors from 'shared-between-everything/src/workOrderColors';

function WorkOrderColorInput({ label, model, flexItem, ...props }) {
  return (
    <InputWrapper
      label={label}
      errorText={model.errorText}
      flexItem={flexItem}
      padding={false}
      {...props}
    >
      <GutterBag contents={workOrderColors.map(get('value'))} gutterSize="xxs">
        {color => (
          <Link
            block
            width="3xs"
            height="3xs"
            backgroundColor={color}
            onClick={() => model.setInternalValue(color)}
            data-work-order-color-test={color}
            data-color-e2e-test={color}
          >
            <Icon
              shown={color === model.internalValue}
              color="white"
              name="check"
            />
          </Link>
        )}
      </GutterBag>
    </InputWrapper>
  );
}

export default decorate(observer)(WorkOrderColorInput);
