import { computed } from 'mobx';
import {
  filter,
  isOneOf,
  join,
  map,
  pipeline,
  sortBy,
  toLower,
} from 'shared-between-everything/src/functionalProgramming';
import BooleanInputModel from 'shared-between-front-ends/src/components/public/CheckboxInput/BooleanInputModel';
import MeasurementAmountInputModel from 'shared-between-front-ends/src/components/public/MeasurementAmountInput/MeasurementAmountInputModel';
import TextInputModel from 'shared-between-front-ends/src/components/public/TextInput/TextInputModel';

export default class ParticipationModel {
  constructor({
    participation,
    expectedEffortDefinition,
    teamResources,
    effortDefinitionCatalogItem,
    interactiveEffortModel,
    initiallyExpanded,
    justCreated,
  }) {
    this.justCreated = justCreated;
    this._participation = participation;

    this.actualEffortInput = new MeasurementAmountInputModel({
      initialInternalValue: participation.effort.amount,
      measurementUnitId: expectedEffortDefinition.effort.measurementUnitId,
    });

    this.comment = new TextInputModel({
      initialInternalValue: participation.comment,
    });

    this._participants = participation.participants;
    this._teamResources = teamResources;

    this._expectedEffortDefinition = expectedEffortDefinition;
    this._effortDefinitionCatalogItem = effortDefinitionCatalogItem;
    this._interactiveEffortModel = interactiveEffortModel;

    this.participationIsExpanded = new BooleanInputModel({
      initialInternalValue: initiallyExpanded,
    });
  }

  get participationId() {
    return pipeline(this.participatingResourceIds, join('-'));
  }

  @computed
  get roundedTotalDuration() {
    const totalDuration =
      this.effortAmount * this._effortDefinitionCatalogItem.duration.amount;

    return {
      amount: Math.round(totalDuration * 100) / 100,
      measurementUnitId: 'hour',
    };
  }

  get effortAmount() {
    return this._participation.effort.amount || 0;
  }

  @computed
  get expectedEffort() {
    return this._expectedEffortDefinition.effort;
  }

  get effortEntryHighlightColor() {
    return this.actualEffortInput.internalValue !== null
      ? 'primary'
      : 'grey100';
  }

  get effortDefinitionCatalogItemId() {
    return this._effortDefinitionCatalogItem.id;
  }

  @computed
  get participantNames() {
    return pipeline(
      this._teamResources,

      filter(({ resource }) =>
        isOneOf(this.participatingResourceIds, resource.id),
      ),

      map(
        ({ resource: { firstName, lastName } }) => `${firstName} ${lastName}`,
      ),

      sortBy(toLower),

      join(', '),
    );
  }

  @computed
  get participatingResourceIds() {
    return pipeline(this._participants, map('resourceId'));
  }
}
