import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import {
  matches,
  toLower,
  none,
} from 'shared-between-everything/src/functionalProgramming';

import noDuplicateIdsValidatorTranslations from './noDuplicateIdsValidatorTranslations';

const translate = localTranslate(noDuplicateIdsValidatorTranslations);

export default thingsWithId => id =>
  none(
    matches({ id: toLower(id) }),
    thingsWithId.map(({ id }) => ({ id: toLower(id) })),
  )
    ? null
    : translate('duplicateId');
