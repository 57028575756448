export default {
  en: {
    ok: 'Ok',
    cancel: 'Cancel',
  },
  fi: {
    ok: 'Jatka',
    cancel: 'Peruuta',
  },
  pl: {
    ok: 'Ok',
    cancel: 'Anuluj',
  },
  se: {
    ok: 'Ok',
    cancel: 'Avbryt',
  },
};
