import React from 'react';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import MarginSpace from 'shared-between-front-ends/src/components/private/MarginSpace/MarginSpace';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import HeavyText from 'shared-between-front-ends/src/components/public/HeavyText/HeavyText';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import Panel from 'shared-between-front-ends/src/components/public/Panel/Panel';
import Popover from 'shared-between-front-ends/src/components/public/Popover/Popover';
import PrimaryButton from 'shared-between-front-ends/src/components/public/PrimaryButton/PrimaryButton';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import MeasurementAmount from '../../MeasurementAmount/MeasurementAmount';
import resourceDashboardTranslations from '../../resourceDashboardTranslations';

const translate = localTranslate(resourceDashboardTranslations);

export default ({
  expectedEffortDefinition,
  effortDefinitionCatalogItem,
  effortDescriptionPopoverActivationId,
  closeEffortDescriptionPopover,
  effortAmountTotal,
  roundedDurationPerUnit,
  roundedTotalDuration,
  expectedEffortIsShown,
  descriptionIsShown,
  includingIsShown,
  excludingIsShown,
}) => (
  <Flex centeredVertically data-expected-effort-test>
    <BodyText
      width="md"
      size="3xs"
      data-effort-entry-name-e2e-test={effortDefinitionCatalogItem.name}
    >
      {effortDefinitionCatalogItem.name}
    </BodyText>

    <Gutter size="xs" />

    <Popover
      activationId={effortDescriptionPopoverActivationId}
      activateOn="click"
      position="vertical center of screen"
      data-effort-description-popover-test={expectedEffortDefinition.id}
      content={
        <MarginSpace compact>
          <Panel>
            <Flex
              centeredVertically
              color="white"
              backgroundColor="modalHeader"
              padding={{ size: 'sm' }}
            >
              <Icon size="md" name="circle-info" compact />

              <Gutter size="sm" />

              <BodyText>{translate('effortDescription')}</BodyText>
            </Flex>
            <Panel.Content padding={{ size: 'lg' }}>
              <HeavyText size="xs">
                {effortDefinitionCatalogItem.name}
              </HeavyText>

              <Div
                shown={descriptionIsShown}
                data-show-expected-effort-definition-description-test={
                  effortDefinitionCatalogItem.id
                }
              >
                <Gutter size="sm" />

                <BodyText>{effortDefinitionCatalogItem.description}</BodyText>
              </Div>

              <Div
                shown={includingIsShown}
                data-show-expected-effort-definition-including-test={
                  effortDefinitionCatalogItem.id
                }
              >
                <Gutter size="sm" />

                <HeavyText>{translate('including')}</HeavyText>

                <Gutter size="xs" />

                <BodyText>{effortDefinitionCatalogItem.including}</BodyText>
              </Div>

              <Div
                shown={excludingIsShown}
                data-show-expected-effort-definition-excluding-test={
                  effortDefinitionCatalogItem.id
                }
              >
                <Gutter size="sm" />

                <HeavyText>{translate('excluding')}</HeavyText>

                <Gutter size="xs" />

                <BodyText>{effortDefinitionCatalogItem.excluding}</BodyText>
              </Div>

              <Gutter size="sm" />

              <Div>
                <HeavyText>{translate('durationPerUnit')}</HeavyText>

                <Gutter size="xs" />

                <BodyText>
                  <MeasurementAmount value={roundedDurationPerUnit} />
                </BodyText>
              </Div>

              <Flex centered>
                <PrimaryButton
                  onClick={closeEffortDescriptionPopover}
                  data-close-effort-description-test
                >
                  {translate('close')}
                </PrimaryButton>
              </Flex>
            </Panel.Content>
          </Panel>
        </MarginSpace>
      }
    >
      <Icon
        name="circle-info"
        size="md"
        color="action"
        alt={effortDefinitionCatalogItem.description}
        data-open-effort-description-test={expectedEffortDefinition.id}
      />
    </Popover>

    <Gutter size="xs" flexItem />

    <BodyText data-effort-entry-amount-e2e-test={effortAmountTotal}>
      <MeasurementAmount
        value={{
          amount: effortAmountTotal,
          measurementUnitId: expectedEffortDefinition.effort.measurementUnitId,
        }}
        metricIsShown={!expectedEffortIsShown}
      />
    </BodyText>

    <BodyText shown={expectedEffortIsShown}>/</BodyText>

    <HeavyText shown={expectedEffortIsShown} alignTextTo="right">
      <MeasurementAmount value={expectedEffortDefinition.effort} />
    </HeavyText>

    <Gutter size="xs" />

    <HeavyText width="xs" alignTextTo="right">
      <MeasurementAmount value={roundedTotalDuration} />
    </HeavyText>
  </Flex>
);
