import { action, observable } from 'mobx';

export default class {
  @observable
  currentlySpinning = [];

  @action.bound
  setNamedSpinnerToSpin(spinnerName) {
    this.currentlySpinning.push(spinnerName);
  }

  @action.bound
  setNamedSpinnerToNotSpin(spinnerName) {
    const toBeRemovedIndex = this.currentlySpinning.indexOf(spinnerName);
    if (toBeRemovedIndex === -1) {
      throw new Error(
        'Tried to set spinner with name "' +
          spinnerName +
          '" as not spin, but only spinners present were: "' +
          this.currentlySpinning.join('", "') +
          '"',
      );
    }

    this.currentlySpinning.splice(toBeRemovedIndex, 1);
  }
}
