import noop from 'lodash/fp/noop';
import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Div from '../../private/Element/Div/Div';
import CircleButton from '../CircleButton/CircleButton';
import { baseline } from '../constants';
import Flex from '../Flex/Flex';
import Gutter from '../Gutter/Gutter';
import HeavyText from '../HeavyText/HeavyText';
import Icon from '../Icon/Icon';

function HourInput({
  model,
  paddingSize = 'zero',
  readOnly = model.readOnly,
  setValueFor = model.setValueFor || noop,
  ...props
}) {
  return readOnly ? (
    <HeavyText>{model.value}</HeavyText>
  ) : (
    <Div data-hour-input-controls-test {...props}>
      <Flex centered>
        <CircleButton
          sizeBaselines={4}
          onClick={model.minus}
          data-minus-button-test
        >
          <Icon name="minus" size="sm" />
        </CircleButton>

        <Gutter size="sm" />

        <HeavyText alignTextTo="center" style={{ width: 3 * baseline }} block>
          {model.value}
        </HeavyText>

        <Gutter size="sm" />

        <CircleButton
          sizeBaselines={4}
          onClick={model.plus}
          data-plus-button-test
        >
          <Icon name="plus" size="sm" />
        </CircleButton>
      </Flex>

      <Gutter size="xs" />

      <Flex centered>
        <CircleButton
          sizeBaselines={5}
          onClick={setValueFor(2)}
          data-set-to-two-hours-test
        >
          <HeavyText>2</HeavyText>
        </CircleButton>

        <Gutter size="sm" />

        <CircleButton
          sizeBaselines={5}
          onClick={setValueFor(4)}
          data-set-to-four-hours-test
        >
          <HeavyText>4</HeavyText>
        </CircleButton>

        <Gutter size="sm" />

        <CircleButton
          sizeBaselines={5}
          onClick={setValueFor(10)}
          data-set-to-eight-hours-test
        >
          <HeavyText>10</HeavyText>
        </CircleButton>
      </Flex>
    </Div>
  );
}

export default decorate(observer)(HourInput);
