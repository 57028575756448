import chunk from 'lodash/fp/chunk';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import { PropTypes } from 'prop-types';
import React from 'react';

import addSeparator from '../../../doings/addSeparator/addSeparator';
import wrapEachComponentWithKey from '../../../doings/wrapEachComponentWithKey/wrapEachComponentWithKey';
import Div from '../../private/Element/Div/Div';
import Gutter from '../Gutter/Gutter';
import Tile from './Tile/Tile';
import styles from './TileBag.module.scss';

function TileBag({ tiles, gutterSize = 'md', ...props }) {
  return (
    <Div className={styles.tileBag} {...props}>
      {getTilesArrangedInChunksForDifferentScreenSizes(gutterSize)(tiles)}
    </Div>
  );
}

TileBag.Tile = Tile;

TileBag.propTypes = {
  tiles: PropTypes.array.isRequired,
  gutterLines: PropTypes.bool,
};

export default TileBag;

const getTilesArrangedInChunksForDifferentScreenSizes = gutterSize =>
  flow(
    createTiles,
    createMobileChunks(gutterSize),
    createTabletChunks(gutterSize),
    createDesktopChunks(gutterSize),
  );

const createTiles = map(content => <Tile>{content}</Tile>);

const createMobileChunks = gutterSize =>
  flow(
    chunk(2),
    map(separateWithMobileChunkGutterWhenNeeded(gutterSize)),
    map(wrapEachComponentWithKey),
  );

const separateWithMobileChunkGutterWhenNeeded = gutterSize => ([
  firstTile,
  existingLastTile,
]) => {
  const lastTile = existingLastTile || <Tile />;

  return [
    firstTile,
    getMobileChunkGutter(existingLastTile, gutterSize),
    lastTile,
  ];
};

const getMobileChunkGutter = (lastTile, gutterSize) => (
  <Gutter
    responsiveHidden={lastTile ? undefined : { thinnerThan: 'sm' }}
    size={gutterSize}
  />
);

const createTabletChunks = gutterSize =>
  flow(
    map(createTabletChunk),
    chunk(2),
    map(separateWithTabletChunkGutterWhenNeeded(gutterSize)),
    map(wrapEachComponentWithKey),
  );

const separateWithTabletChunkGutterWhenNeeded = gutterSize => ([
  firstMobileChunk,
  existingLastMobileChunk,
]) => {
  const lastMobileChunk =
    existingLastMobileChunk || getEmptyTabletChunk(gutterSize);

  return [
    firstMobileChunk,
    getTabletChunkGutter(existingLastMobileChunk, gutterSize),
    lastMobileChunk,
  ];
};

const getEmptyTabletChunk = gutterSize =>
  flow(
    wrapEachComponentWithKey,
    createTabletChunk,
  )(getEmptyMobileChunk(gutterSize));

const getEmptyMobileChunk = gutterSize => [
  <Tile />,
  <Gutter hidden size={gutterSize} />,
  <Tile />,
];

const getTabletChunkGutter = (lastMobileChunk, gutterSize) => (
  <Gutter
    responsiveHidden={lastMobileChunk ? undefined : { thinnerThan: 'lg' }}
    size={gutterSize}
  />
);

const createTabletChunk = tiles => (
  <div className={styles.tileBag_tabletChunk}>{tiles}</div>
);

const createDesktopChunks = gutterSize =>
  flow(
    map(createDesktopChunk),
    addSeparator(<Gutter size={gutterSize} />),
    wrapEachComponentWithKey,
  );

const createDesktopChunk = tabletChunks => (
  <div className={styles.tileBag_desktopChunk}>{tabletChunks}</div>
);
