import { action, observable } from 'mobx';

export default class {
  @observable
  errorMessage = '';

  @action.bound
  setErrorMessage(errorMessage) {
    this.errorMessage = errorMessage;
  }
}
