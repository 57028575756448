import bodyParametersInputMapper from 'shared-between-everything/src/doings/bodyParametersInputMapper/bodyParametersInputMapper';
import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';

export default getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/scheduler/cancel-appointment',
    method: 'POST',
    inputMapper: bodyParametersInputMapper,
  },
  decoratorConfig: {
    spinnerName: 'deleting-appointment',
    showSpinnerInstantly: false,
  },
});
