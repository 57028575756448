import React from 'react';
import Div from '../../private/Element/Div/Div';

import styles from './SeparatorLine.module.scss';

const SeparatorLine = ({ className, horizontal, size = 'zero', ...props }) => {
  const margin = { [horizontal ? 'vertical' : 'horizontal']: size };

  return (
    <Div
      className={[
        styles.separatorLine,
        !!horizontal
          ? styles.separatorLine__horizontal
          : styles.separatorLine__vertical,
        className,
      ]}
      backgroundColor="grey20"
      margin={margin}
      {...props}
    />
  );
};

export default SeparatorLine;
