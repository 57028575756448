import React from 'react';
import AttentionText from '../../../AttentionText/AttentionText';
import SemanticButton from '../../../SemanticButton/SemanticButton';

const Toggle = ({ disabled, children, ...props }) => (
  <SemanticButton
    {...getColorProps(disabled)}
    padding={{
      vertical: 'xxs',
      horizontal: 'xs',
    }}
    disabled={disabled}
    {...props}
  >
    <AttentionText singleLine size="3xs">
      {children}
    </AttentionText>
  </SemanticButton>
);

export default Toggle;

const getColorProps = disabled => {
  if (disabled) {
    return {
      color: 'inherit',
      hoverColor: 'white',
      backgroundColor: 'eltelGrey',
      backgroundHoverColor: 'eltelGrey',
    };
  }

  return {
    color: 'inherit',
    hoverColor: 'white',
    backgroundColor: 'transparent',
    backgroundHoverColor: 'action',
  };
};
