import React from 'react';

import pipeline from 'shared-between-everything/src/doings/pipeline/pipeline';
import compact from 'lodash/fp/compact';
import join from 'lodash/fp/join';
import BodyText from '../BodyText/BodyText';

const compactJoin = (delimiter, strings) =>
  pipeline(strings, compact, join(delimiter));

const getAddressString = (streetAddress, postalCode, city) =>
  compactJoin(', ', [streetAddress, compactJoin(' ', [postalCode, city])]);

const Address = ({ streetAddress, postalCode, city, ...props }) => (
  <BodyText {...props}>
    {getAddressString(streetAddress, postalCode, city)}
  </BodyText>
);

export default Address;
