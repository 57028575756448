import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import Link from 'shared-between-front-ends/src/components/public/Link/Link';
import MainHeading from 'shared-between-front-ends/src/components/public/MainHeading/MainHeading';
import Page from 'shared-between-front-ends/src/components/public/Page/Page';
import Panel from 'shared-between-front-ends/src/components/public/Panel/Panel';
import PrimaryButton from 'shared-between-front-ends/src/components/public/PrimaryButton/PrimaryButton';
import RouteLink from 'shared-between-front-ends/src/components/public/RouteLink/RouteLink';
import Table from 'shared-between-front-ends/src/components/public/Table/Table';
import withModel from 'shared-between-front-ends/src/decorators/withModel/withModel';
import SpinnerTentative from 'shared-between-front-ends/src/decorators/withSpinnerFor/SpinnerTentative/SpinnerTentative';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import RoutingModel from 'shared-between-front-ends/src/models/RoutingModel/RoutingModel';
import Navigation from '../../Navigation/Navigation';

import WorkOrderModel from '../WorkOrderModel';
import workOrderTranslations from '../workOrderTranslations';

const translate = localTranslate(workOrderTranslations);

function WorkOrderRoster({ workOrders, districtId, teamId }) {
  return (
    <Page data-work-order-roster-e2e-test>
      <Navigation />

      <Page.Row alignWithPanel>
        <MainHeading>{translate('workOrders')}</MainHeading>
      </Page.Row>

      <Page.Row alignWithPanel>
        <Flex alignRight>
          <RouteLink
            routeName="district-team-create-work-order"
            pathParameters={{ districtId, teamId }}
            data-create-work-order-test
          >
            <PrimaryButton data-create-work-order-button-e2e-test>
              {translate('createWorkOrderButton')}
            </PrimaryButton>
          </RouteLink>
        </Flex>
      </Page.Row>

      <Page.Row>
        <SpinnerTentative
          spinnerName={['loading-work-orders', 'deleting-work-order']}
        >
          <Panel>
            <Table
              striped
              cols={[
                {
                  content: (
                    <Table.HeadingCell>
                      {translate('customerName')}
                    </Table.HeadingCell>
                  ),
                  width: '100%',
                },
                {
                  content: (
                    <Table.HeadingCell>
                      {translate('workOrderName')}
                    </Table.HeadingCell>
                  ),
                  width: '100%',
                },
                {
                  content: (
                    <Table.HeadingCell>
                      {translate('address')}
                    </Table.HeadingCell>
                  ),
                  width: '100%',
                },
                {
                  content: (
                    <Table.HeadingCell>{translate('link')}</Table.HeadingCell>
                  ),
                  width: '100%',
                },
                {
                  content: (
                    <Table.HeadingCell>
                      {translate('description')}
                    </Table.HeadingCell>
                  ),
                  width: '100%',
                },
                {
                  content: <Table.HeadingCell />,
                  width: '100%',
                },
              ]}
              rows={workOrders}
            >
              {({
                customerName,
                name,
                address,
                postalCode,
                city,
                link,
                description,
                update,
                delete: doDelete,
              }) => (
                <Table.Row data-work-order-roster-row-e2e-test={name}>
                  <Table.Cell>{customerName}</Table.Cell>

                  <Table.Cell>{name}</Table.Cell>

                  <Table.Cell>
                    {address}, {postalCode} {city}
                  </Table.Cell>

                  <Table.Cell>{link}</Table.Cell>

                  <Table.Cell>{description}</Table.Cell>

                  <Table.Cell alignTextTo="right">
                    <Link onClick={update} data-update-work-order-test>
                      <Icon size="md" name="pen" color="secondary" />
                    </Link>

                    <Gutter size="xs" inlineBlock />

                    <Link
                      onClick={doDelete}
                      data-delete-work-order-test
                      data-delete-roster-row-e2e-test={name}
                    >
                      <Icon size="md" name="circle-minus" color="danger" />
                    </Link>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table>
          </Panel>
        </SpinnerTentative>
      </Page.Row>
    </Page>
  );
}

export default decorate(
  withModel(
    {
      Model: WorkOrderModel,
      props: [{ name: 'workOrders', modelPath: 'workOrders' }],
    },
    {
      Model: RoutingModel,
      props: [
        { name: 'districtId', modelPath: 'pathParameters.districtId' },
        { name: 'teamId', modelPath: 'pathParameters.teamId' },
      ],
    },
  ),
)(WorkOrderRoster);
