import React from 'react';

import Heading from '../Heading/Heading';

function MainHeading({ tagName = 'h1', children, ...props }) {
  return (
    <Heading tagName={tagName} {...props}>
      {children}
    </Heading>
  );
}

export default MainHeading;
