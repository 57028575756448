import moment from 'moment-timezone';
import { curry, pipeline } from '../../functionalProgramming';

export default curry((start, end) =>
  pipeline(
    moment(end).diff(start, 'minutes'),
    differenceInMinutes => differenceInMinutes / 60,
    roundToDecimals(1),
  ),
);

const roundToDecimals = amountOfDecimals => number =>
  Math.round(number * Math.pow(10, amountOfDecimals)) /
  Math.pow(10, amountOfDecimals);
