import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Div from '../../components/private/Element/Div/Div';
import withModel from '../../decorators/withModel/withModel';
import styles from './Overlay.module.scss';
import OverlayModel from './OverlayModel';

const Overlay = ({ visible, close, className, ...props }) => (
  <Div
    shown={visible}
    onClick={close}
    backgroundColor="black"
    className={[styles.overlay, className]}
    {...props}
  />
);

export default decorate(
  withModel({
    Model: OverlayModel,
    props: [
      {
        name: 'visible',
        modelPath: 'visible',
      },
      {
        name: 'close',
        modelPath: 'close',
      },
    ],
  }),
)(Overlay);
