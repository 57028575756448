import localTranslate from '../../doings/localTranslate/localTranslate';
import { isNumber } from 'shared-between-everything/src/functionalProgramming';
import validatorTranslations from '../validatorTranslations';

const translate = localTranslate(validatorTranslations);

export default value => {
  if (!value || (isNumber(value) && value >= 0)) {
    return null;
  }

  return translate('isPositiveNumber');
};
