export const dependencies = {
  getEnvironmentIsUnitTesting: require('./getEnvironmentIsUnitTesting').default,
};

export default error => {
  if (dependencies.getEnvironmentIsUnitTesting()) {
    throw error;
  }

  console.error(error);
};
