import { onBecomeObserved, onBecomeUnobserved, when, autorun } from 'mobx';

import setTimeoutWithThis from '../../doings/setTimeoutWithThis/setTimeoutWithThis';
import ApplicationModel from '../../models/ApplicationModel/ApplicationModel';
import getModel from '../withModel/getModel';

export const dependencies = {
  setTimeoutWithThis,
};

export default model => {
  if (model.__autorunWhileObserved) {
    const applicationModel = getModel(ApplicationModel);

    when(
      () => applicationModel.started,
      () => {
        model.__autorunWhileObserved.forEach(
          ({ methodName, triggerWhenPropertyBecomesObserved }) => {
            let stopAutorun;

            onBecomeObserved(model, triggerWhenPropertyBecomesObserved, () => {
              stopAutorun = autorun(model[methodName].bind(model));
            });

            onBecomeUnobserved(model, triggerWhenPropertyBecomesObserved, () =>
              stopAutorun(),
            );
          },
        );
      },
    );
  }
};
