import { flow as mobxFlow, observable } from 'mobx';
import moment from 'moment-timezone';
import { forEach } from 'shared-between-everything/src/functionalProgramming';

export default class DataPopulationModel {
  @observable
  resourcesById = new Map();

  @observable
  workOrdersById = new Map();

  @observable
  appointmentsById = new Map();

  constructor(
    getAppointments,
    callForResourcesInTeam,
    getWorkOrders,
    createWorkOrder,
    createAppointment,
    createResource,
    sessionModel,
  ) {
    this.getAppointments = getAppointments;
    this.callForResourcesInTeam = callForResourcesInTeam;
    this.getWorkOrders = getWorkOrders;
    this.createWorkOrder = createWorkOrder;
    this.createAppointment = createAppointment;
    this.createResource = createResource;
    this.sessionModel = sessionModel;
  }

  refresh = mobxFlow(function* ({ teamId }) {
    const getAppointmentsPromise = this.getAppointments({
      pathParameters: { teamId },
      queryParameters: {
        startDateTime: moment()
          .add(-1, 'month')
          .startOf('month')
          .utc()
          .format(),
        endDateTime: moment().add(1, 'year').utc().format(),
      },
    });

    const callForResourcesInTeamPromise = this.callForResourcesInTeam({
      teamId,
    });

    const getWorkOrdersPromise = this.getWorkOrders({
      teamId,
    });

    const responses = yield Promise.all([
      getAppointmentsPromise,
      callForResourcesInTeamPromise,
      getWorkOrdersPromise,
    ]);

    this.resourcesById.clear();
    this.workOrdersById.clear();
    this.appointmentsById.clear();

    const [
      { response: appointments },
      { response: resources },
      { response: workOrders },
    ] = responses;

    this.populateWorkOrdersById(workOrders);

    this.populateResourceById(resources);

    forEach(this.createAppointment, appointments);
  });

  populateResourceById(resources) {
    resources.forEach(resourceData => {
      const resourceModel = this.createResource({
        resourceData,
      });

      this.resourcesById.set(resourceData.id, resourceModel);
    });
  }

  populateWorkOrdersById(workOrders) {
    workOrders.forEach(workOrderData => {
      const workOrderModel = this.createWorkOrder({
        workOrderData,
      });

      this.workOrdersById.set(workOrderData.id, workOrderModel);
    });
  }
}
