import storage from 'local-storage-fallback';

export const dependencies = { storage };

export default {
  set: (key, value) => {
    dependencies.storage.setItem(key, JSON.stringify(value));
  },

  get: key => {
    const value = dependencies.storage.getItem(key);

    if (typeof value === 'undefined') {
      return null;
    }

    return JSON.parse(value);
  },

  remove: key => {
    dependencies.storage.removeItem(key);
  },
};
