import {
  fromPairs,
  pipeline,
  values,
  map,
} from 'shared-between-everything/src/functionalProgramming';
import * as userRights from 'shared-between-everything/src/userRightValueObjects';

const getUserRightTranslations = language =>
  pipeline(
    userRights,
    values,
    map(
      ({
        type,
        translations: {
          [language]: { name: userRightNameTranslation },
        },
      }) => [type, userRightNameTranslation],
    ),
    fromPairs,
  );

export default {
  en: {
    emailAddress: 'Email',
    createNewUser: 'Create new user',
    userRights: getUserRightTranslations('en'),
  },
  fi: {
    emailAddress: 'Käyttäjätunniste',
    createNewUser: 'Lisää uusi',
    userRights: getUserRightTranslations('fi'),
  },
  se: {
    emailAddress: 'Email',
    createNewUser: 'Skapa ny användare',
    userRights: getUserRightTranslations('se'),
  },
  pl: {
    emailAddress: 'User ID',
    createNewUser: 'Utwórz nowego użytkownika',
    userRights: getUserRightTranslations('pl'),
  },
};
