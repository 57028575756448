import localTranslate from '../../doings/localTranslate/localTranslate';
import withExposedConfiguration from 'shared-between-everything/src/test-utils/withExposedConfiguration';
import validatorTranslations from '../validatorTranslations';

const translate = localTranslate(validatorTranslations);

export default withExposedConfiguration(length => value => {
  if (!value || value.length === 0) {
    return null;
  }

  return value.length >= length ? null : translate('minLength', { length });
});
