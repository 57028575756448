import moment from 'moment-timezone';

export default dateTime => {
  if (dateTime === null) {
    return null;
  }

  if (!dateTime) {
    return moment(dateTime).utc().format('YYYY-MM-DD');
  }

  if (dateTime.match(/^\d{4}-\d{2}-\d{2}$/)) {
    return moment(dateTime).format('YYYY-MM-DD');
  }

  return moment(dateTime).utc().format('YYYY-MM-DD');
};
