import identity from 'lodash/fp/identity';
import map from 'lodash/fp/map';
import { observer } from 'mobx-react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import pipeline from 'shared-between-everything/src/doings/pipeline/pipeline';
import { isEmpty } from 'shared-between-everything/src/functionalProgramming';
import addSeparator from '../../../doings/addSeparator/addSeparator';
import wrapEachComponentWithKey from '../../../doings/wrapEachComponentWithKey/wrapEachComponentWithKey';

const mapThatShowsRunningIndex = map.convert({ cap: false });

const Map = ({ items, children: toChild, separator, placeholder }) =>
  isEmpty(items)
    ? placeholder
    : pipeline(
        items,
        mapThatShowsRunningIndex((item, runningIndex) =>
          toChild(item, {
            itemIsFirst: runningIndex === 0,
            itemIsLast: runningIndex === items.length - 1,
          }),
        ),
        separator ? addSeparator(separator) : identity,
        wrapEachComponentWithKey,
      );

export default decorate(observer)(Map);
