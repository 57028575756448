import castArray from 'lodash/fp/castArray';
import withExposedConfiguration from 'shared-between-everything/src/test-utils/withExposedConfiguration';
import getCall from '../getCall/getCall';
import addAuthenticationHeadersInputMapper from './addAuthenticationHeadersInputMapper';

export const dependencies = {
  getCall,
};

export default withExposedConfiguration(
  ({
    apiCallConfig: { inputMapper = [], ...restApiCallConfig },
    decoratorConfig,
  }) => {
    const inputMappers = castArray(inputMapper);

    return dependencies.getCall({
      decoratorConfig,
      apiCallConfig: {
        ...restApiCallConfig,
        inputMapper: [...inputMappers, addAuthenticationHeadersInputMapper],
      },
    });
  },
);
