import castArray from 'lodash/fp/castArray';
import flow from 'lodash/fp/flow';
import identity from 'lodash/fp/identity';
import withExposedConfiguration from '../../test-utils/withExposedConfiguration';
import replaceTagsWithValues from '../replaceTagsWithValues/replaceTagsWithValues';

import callApi from './callApi';
import encodeToUrlEncoding from './encodeToUrlEncoding/encodeToUrlEncoding';

export const dependencies = { callApi };

export default withExposedConfiguration(
  ({
    path,
    method,
    inputMapper = [],
    outputMapper = identity,
    staticParameters: { headerParameters: staticHeaders } = {},
    contentType,
  }) => async (input = {}) => {
    const inputMappers = castArray(inputMapper);

    const {
      pathParameters,
      queryParameters,
      bodyParameters,
      headers: nonStaticHeaders,
    } = flow(...inputMappers)(input);

    const callParameters = {
      url:
        replaceTagsWithValues(path, pathParameters) +
        (queryParameters ? '?' + encodeToUrlEncoding(queryParameters) : ''),
      body: bodyParameters,
      method,
      headers: { ...staticHeaders, ...nonStaticHeaders },
      contentType,
    };

    const { callWasSuccessful, response, error } = await dependencies.callApi(
      callParameters,
    );

    if (!callWasSuccessful) {
      return {
        callWasSuccessful: false,
        error,
      };
    }

    const mappedResponse = outputMapper(response);

    return {
      callWasSuccessful: true,
      response: mappedResponse,
    };
  },
);
