import React from 'react';

function Empty({ children }) {
  return children;
}

export default toBeWrappeds =>
  toBeWrappeds.map((toBeWrapped, index) => (
    <Empty key={index}>{toBeWrapped}</Empty>
  ));
