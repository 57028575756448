import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';

import Div from '../../private/Element/Div/Div';
import InputWrapper from '../../private/InputWrapper/InputWrapper';
import BodyText from '../BodyText/BodyText';
import Flex from '../Flex/Flex';
import Gutter from '../Gutter/Gutter';
import Icon from '../Icon/Icon';
import styles from './CheckboxInput.module.scss';

function CheckboxInput({
  model = {},
  checked = model.outboundValue,
  onChange = model.setValue,
  errorText = model.validationTranslationKey,
  label,
  description,
  alignWithOtherInputs,
  ...props
}) {
  return (
    <InputWrapper
      label={alignWithOtherInputs ? '\u00A0' : ''}
      clickableAppearance
      {...props}
    >
      <Div relative>
        <Flex centeredVertically>
          <input
            className={styles.checkbox_hiddenCheckbox}
            type="checkbox"
            checked={checked}
            onChange={() => onChange(!checked)}
          />

          <Flex
            centered
            backgroundColor="white"
            className={styles.checkbox_fakeCheckbox}
            tabIndex="-1"
          >
            <Icon
              shown={checked}
              name="check"
              color="primary"
              size="sm"
              className={styles.checkbox_checkIcon}
            />
          </Flex>

          <Gutter size="xs" />

          <Div>
            <BodyText block size="3xs">
              {label}
            </BodyText>

            <BodyText block size="3xs" color="grey60">
              {description}
            </BodyText>

            <BodyText block size="3xs" color="error">
              {errorText}
            </BodyText>
          </Div>
        </Flex>
      </Div>
    </InputWrapper>
  );
}

export default decorate(observer)(CheckboxInput);
