import map from 'lodash/fp/map';
import values from 'lodash/fp/values';
import pipeline from 'shared-between-everything/src/doings/pipeline/pipeline';
import workOrderTypes from 'shared-between-everything/src/workOrderTypes';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';

const getNameTranslation = translations => localTranslate(translations)('name');

export default userHasRightToAllTypes =>
  userHasRightToAllTypes ? allWorkOrderTypes : limitedWorkOrderTypes;

const allWorkOrderTypes = pipeline(
  workOrderTypes,
  values,
  map(({ type: workOrderType, translations }) => ({
    label: getNameTranslation(translations),
    value: workOrderType,
  })),
);

const workOrder = workOrderTypes.workOrder;
const teamActivity = workOrderTypes.teamActivity;

const limitedWorkOrderTypes = [
  {
    label: getNameTranslation(workOrder.translations),
    value: workOrder.type,
  },
  {
    label: getNameTranslation(teamActivity.translations),
    value: teamActivity.type,
  },
];
