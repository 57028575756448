import React from 'react';
import { noop } from 'shared-between-everything/src/functionalProgramming';

import Element from '../../private/Element/Element';

function Form({ children, onSubmit = noop, ...props }) {
  const onNativeSubmit = e => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <Element tagName="form" onSubmit={onNativeSubmit} {...props}>
      {children}
    </Element>
  );
}

export default Form;
