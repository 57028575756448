import { observer } from 'mobx-react';
import React from 'react';

import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import RouteLink from 'shared-between-front-ends/src/components/public/RouteLink/RouteLink';
import Title from 'shared-between-front-ends/src/components/public/Title/Title';

const ResourceRowHeader = ({
  resource: { id: resourceId, backgroundColor, firstName, lastName },
  teamId,
  districtId,
}) => (
  <RouteLink
    block
    backgroundColor={backgroundColor}
    routeName="district-team-update-resource"
    pathParameters={{
      districtId,
      resourceId,
      teamId,
    }}
    padding={{
      vertical: 'xs',
      horizontal: 'md',
    }}
    occupyVerticalSpace
    data-update-resource-test
  >
    <Flex centeredVertically occupyVerticalSpace>
      <Title singleLine>
        {lastName}, {firstName}
      </Title>
    </Flex>
  </RouteLink>
);

export default decorate(observer)(ResourceRowHeader);
