import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Map from '../Map/Map';
import Toggle from './ToggleGroup/Toggle/Toggle';
import ToggleGroup from './ToggleGroup/ToggleGroup';

const RouteToggleGroup = ({ routingModel, routes, ...props }) => (
  <ToggleGroup {...props}>
    <Map items={routes}>
      {route => (
        <Toggle
          onClick={() => {
            routingModel.setRouteTo({
              name: route.routeName,
            });
          }}
          disabled={routingModel.routeName === route.routeName}
          {...route.props}
        >
          {route.label}
        </Toggle>
      )}
    </Map>
  </ToggleGroup>
);

export default decorate(observer)(RouteToggleGroup);
