import localTranslate from '../../doings/localTranslate/localTranslate';
import validatorTranslations from '../validatorTranslations';

const translate = localTranslate(validatorTranslations);

const hasOnlyDigits = value => {
  if (!value || value.match(/^[0-9]*$/)) {
    return null;
  }

  return translate('invalidInteger');
};

export default hasOnlyDigits;
