import flow from 'lodash/fp/flow';
import split from 'lodash/fp/split';
import React from 'react';

import addSeparator from '../../../doings/addSeparator/addSeparator';
import wrapEachComponentWithKey from '../../../doings/wrapEachComponentWithKey/wrapEachComponentWithKey';
import BodyText from '../../public/BodyText/BodyText';
import Element from '../Element/Element';
import Span from '../Element/Span/Span';
import styles from './TextArea.module.scss';

function TextArea({
  rows = 3,
  readOnly,
  value,
  onChange,
  className,
  ...props
}) {
  const onNativeChange = ({ target: { value } }) => onChange(value);

  return (
    <BodyText block>
      {!readOnly && (
        <Element
          tagName="textarea"
          onChange={onNativeChange}
          className={styles.textArea}
          backgroundColor="grey10"
          occupyHorizontalSpace
          padding={{ horizontal: 'sm', vertical: 'xs' }}
          rows={rows}
          value={value}
          {...props}
        />
      )}

      {readOnly && <Span>{convertLineBreaks(value)}</Span>}
    </BodyText>
  );
}

const convertLineBreaks = flow(
  split('\n'),
  addSeparator(<br />),
  wrapEachComponentWithKey,
);

export default TextArea;
