import React from 'react';
import SecondaryButton from '../SecondaryButton/SecondaryButton';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import withModel from '../../../decorators/withModel/withModel';
import RoutingModel from '../../../models/RoutingModel/RoutingModel';

function GoBackButton({ goingBackIsPossible, goBack, ...props }) {
  return (
    <SecondaryButton
      onClick={goBack}
      shown={goingBackIsPossible}
      {...props}
      data-go-back-button-test
    />
  );
}

export default decorate(
  withModel({
    Model: RoutingModel,
    props: [
      {
        name: 'goingBackIsPossible',
        modelPath: 'goingBackIsPossible',
      },
      {
        name: 'goBack',
        modelPath: 'goBack',
      },
    ],
  }),
)(GoBackButton);
