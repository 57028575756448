import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import EmailInput from 'shared-between-front-ends/src/components/public/EmailInput/EmailInput';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Form from 'shared-between-front-ends/src/components/public/Form/Form';
import GoBackButton from 'shared-between-front-ends/src/components/public/GoBackButton/GoBackButton';
import MainHeading from 'shared-between-front-ends/src/components/public/MainHeading/MainHeading';
import Page from 'shared-between-front-ends/src/components/public/Page/Page';
import PhoneNumberInput from 'shared-between-front-ends/src/components/public/PhoneNumberInput/PhoneNumberInput';
import PrimaryButton from 'shared-between-front-ends/src/components/public/PrimaryButton/PrimaryButton';
import RadioButtonGroup from 'shared-between-front-ends/src/components/public/RadioButtonGroup/RadioButtonGroup';
import TextInput from 'shared-between-front-ends/src/components/public/TextInput/TextInput';
import TileBag from 'shared-between-front-ends/src/components/public/TileBag/TileBag';
import withModel from 'shared-between-front-ends/src/decorators/withModel/withModel';
import SpinnerTentative from 'shared-between-front-ends/src/decorators/withSpinnerFor/SpinnerTentative/SpinnerTentative';
import Navigation from '../../../Navigation/Navigation';
import resourceTranslations from '../../../resource/resourceTranslations';
import UpdateTeamResourceModel from './UpdateTeamResourceModel';

const translate = localTranslate(resourceTranslations);

function UpdateTeamResource({
  erpId,
  firstName,
  lastName,
  phoneNumber,
  emailAddress,
  role,
  submit,
  isValid,
}) {
  return (
    <Page>
      <Navigation />

      <Page.Row alignWithPanel margin={{ bottom: 'md' }}>
        <MainHeading>{translate('resource')}</MainHeading>
      </Page.Row>

      <SpinnerTentative
        spinnerName={['getting-resource', 'updating-resource']}
        data-update-resource-spinner-test
      >
        <Form onSubmit={submit}>
          <Page.Row withPanel>
            <TileBag
              tiles={[
                <TextInput
                  label={translate('erpId')}
                  model={erpId}
                  autoFocus
                  data-erp-id-input-test
                />,

                <TextInput
                  label={translate('firstName')}
                  model={firstName}
                  data-first-name-input-test
                />,

                <TextInput
                  label={translate('lastName')}
                  model={lastName}
                  data-last-name-input-test
                />,

                <PhoneNumberInput
                  label={translate('phoneNumber')}
                  model={phoneNumber}
                  data-phone-number-input-test
                />,

                <EmailInput
                  label={translate('emailAddress')}
                  model={emailAddress}
                  data-email-address-input-test
                />,

                <RadioButtonGroup
                  model={role}
                  label={translate('role')}
                  data-radio-button-input-test
                />,
              ]}
            />
          </Page.Row>

          <Page.Row alignWithPanel margin={false}>
            <Flex spaceBetween>
              <GoBackButton>{translate('backButton')}</GoBackButton>

              <PrimaryButton
                data-submit-button-test
                type="submit"
                enabled={isValid}
              >
                {translate('updateResourceButton')}
              </PrimaryButton>
            </Flex>
          </Page.Row>
        </Form>
      </SpinnerTentative>
    </Page>
  );
}

export default decorate(
  withModel({
    Model: UpdateTeamResourceModel,
    props: [
      {
        name: 'erpId',
        modelPath: 'erpId',
      },
      {
        name: 'firstName',
        modelPath: 'firstName',
      },
      {
        name: 'lastName',
        modelPath: 'lastName',
      },
      {
        name: 'phoneNumber',
        modelPath: 'phoneNumber',
      },
      {
        name: 'emailAddress',
        modelPath: 'emailAddress',
      },
      {
        name: 'role',
        modelPath: 'role',
      },
      {
        name: 'submit',
        modelPath: 'submit',
      },
      {
        name: 'isValid',
        modelPath: 'isValid',
      },
    ],
  }),
)(UpdateTeamResource);
