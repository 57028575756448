import React from 'react';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import Map from 'shared-between-front-ends/src/components/public/Map/Map';
import Table from 'shared-between-front-ends/src/components/public/Table/Table';
import SchedulerTableCell from '../SchedulerTableCell/SchedulerTableCell';

import ResourceDayCell from './ResourceDayCell/ResourceDayCell';
import ResourceRowHeader from './ResourceRowHeader/ResourceRowHeader';

const ResourceRow = ({
  absenceWorkOrderTypes,
  days,
  districtId,
  teamId,
  resource,
  leaveDays,
}) => (
  <Table.Row key={resource.id}>
    <SchedulerTableCell>
      <ResourceRowHeader
        resource={resource}
        teamId={teamId}
        districtId={districtId}
      />
    </SchedulerTableCell>

    <Map items={absenceWorkOrderTypes}>
      {workOrderType => (
        <LeaveBalanceCell
          backgroundColor={workOrderType.absenceScheduling.valueBackgroundColor}
        >
          {leaveDays[workOrderType.type]}
        </LeaveBalanceCell>
      )}
    </Map>

    <Map items={days}>{day => <ResourceDayCell gridCell={day.gridCell} />}</Map>
  </Table.Row>
);

const LeaveBalanceCell = ({ children, ...props }) => (
  <SchedulerTableCell alignTextTo="center" {...props}>
    <BodyText>{children}</BodyText>
  </SchedulerTableCell>
);
export default ResourceRow;
