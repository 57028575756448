import {
  curry,
  flatMap,
  pipeline,
} from 'shared-between-everything/src/functionalProgramming';

export const reorderAbove = curry((where, what, items) =>
  pipeline(
    items,
    flatMap(item => {
      if (item === what) {
        return [];
      }

      if (item === where) {
        return [what, where];
      }

      return [item];
    }),
  ),
);

export const reorderBelow = curry((where, what, items) =>
  pipeline(
    items,
    flatMap(item => {
      if (item === what) {
        return [];
      }

      if (item === where) {
        return [where, what];
      }

      return [item];
    }),
  ),
);
