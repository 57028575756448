import React from 'react';

import {
  map,
  pipeline,
} from 'shared-between-everything/src/functionalProgramming';

import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import HeavyText from 'shared-between-front-ends/src/components/public/HeavyText/HeavyText';
import StripedBox from '../../../shared/StripedBox/StripedBox';

const SimplifiedAppointmentBox = ({
  normalizedItem: { workOrder, workOrderType, durationString },
}) => (
  <StripedBox color={workOrderType.color}>
    <Div
      color="black"
      padding={{ size: 'xs' }}
      style={{ backgroundColor: hexToRgba(workOrderType.color, 0.1) }}
    >
      <Flex spaceBetween>
        <BodyText>{workOrder.name}</BodyText>

        <Gutter />

        <HeavyText>{durationString}</HeavyText>
      </Flex>
    </Div>
  </StripedBox>
);

const hexToRgba = (hex, alpha) =>
  pipeline(
    hex.match(/\w\w/g),
    map(part => parseInt(part, 16)),
    ([r, g, b]) => `rgba(${r}, ${g}, ${b}, ${alpha})`,
  );

export default SimplifiedAppointmentBox;
