import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import Div from 'shared-between-front-ends/src/components/private/Element/Div/Div';
import BodyText from 'shared-between-front-ends/src/components/public/BodyText/BodyText';
import Flex from 'shared-between-front-ends/src/components/public/Flex/Flex';
import Gutter from 'shared-between-front-ends/src/components/public/Gutter/Gutter';
import HeavyText from 'shared-between-front-ends/src/components/public/HeavyText/HeavyText';
import Icon from 'shared-between-front-ends/src/components/public/Icon/Icon';
import Panel from 'shared-between-front-ends/src/components/public/Panel/Panel';
import SemanticButton from 'shared-between-front-ends/src/components/public/SemanticButton/SemanticButton';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import MeasurementAmount from '../../MeasurementAmount/MeasurementAmount';
import resourceDashboardTranslations from '../../resourceDashboardTranslations';

const translate = localTranslate(resourceDashboardTranslations);

const EffortDescription = ({
  isExpanded,
  effortDefinitionCatalogItem: { id, description, name, including, excluding },
  descriptionIsShown,
  includingIsShown,
  excludingIsShown,
  roundedDurationPerUnit,
  ...props
}) => (
  <Panel {...props}>
    <SemanticButton
      occupyHorizontalSpace
      onClick={isExpanded.toggle}
      color="action"
      data-toggle-expected-effort-definition-description-test={id}
    >
      <Panel.Header backgroundColor="white" color="action">
        <Flex centeredVertically>
          <Icon
            compact
            name={isExpanded.internalValue ? 'chevron-up' : 'chevron-down'}
            size="md"
          />

          <Gutter size="sm" />

          <HeavyText>{translate('effortDescription')}</HeavyText>
        </Flex>
      </Panel.Header>
    </SemanticButton>

    <Div
      backgroundColor="white"
      data-expected-effort-definition-expanded-content-for-description-test={id}
      shown={isExpanded.internalValue}
    >
      <Panel.Content>
        <Flex backgroundColor="white" centeredVertically>
          <Icon compact name="chevron-up" size="md" occupyContentSpaceButHide />

          <Gutter size="sm" />

          <Div>
            <HeavyText size="xs">{name}</HeavyText>

            <Div
              shown={descriptionIsShown}
              data-show-expected-effort-definition-description-test={id}
            >
              <Gutter shown={descriptionIsShown} size="sm" />

              <BodyText>{description}</BodyText>
            </Div>

            <Div
              shown={includingIsShown}
              data-show-expected-effort-definition-including-test={id}
            >
              <Gutter size="sm" />

              <HeavyText>{translate('including')}</HeavyText>

              <Gutter size="xs" />

              <BodyText>{including}</BodyText>
            </Div>

            <Div
              shown={excludingIsShown}
              data-show-expected-effort-definition-excluding-test={id}
            >
              <Gutter size="sm" />

              <HeavyText>{translate('excluding')}</HeavyText>

              <Gutter size="xs" />

              <BodyText>{excluding}</BodyText>
            </Div>

            <Gutter size="sm" />

            <Div>
              <HeavyText>{translate('durationPerUnit')}</HeavyText>

              <Gutter size="xs" />

              <BodyText>
                <MeasurementAmount value={roundedDurationPerUnit} />
              </BodyText>
            </Div>
          </Div>
        </Flex>
      </Panel.Content>
    </Div>
  </Panel>
);

export default decorate(observer)(EffortDescription);
