import { SHA256, enc as encodings } from 'crypto-js';

export default ({
  environmentVariables,
  redirectTo,
  getRandomString,
  sessionStartUrl,
}) => ({ quiet }) => {
  const {
    REACT_APP_OAUTH_CLIENT_ID: clientId,
    REACT_APP_OAUTH_TOKEN_AUDIENCE: audience,
    REACT_APP_OAUTH_AUTHORIZE_URL: authorizeUrl,
  } = environmentVariables;

  const randomString = getRandomString();
  const codeChallengeVerifier = btoa(randomString);
  const codeChallenge = getCodeChallenge(codeChallengeVerifier);

  const nonce = getRandomString();

  const state = getState({
    codeChallengeVerifier,
    nonce,
  });

  redirectTo({
    url: authorizeUrl,
    queryParameters: {
      response_type: 'code',
      client_id: clientId,
      redirect_uri: sessionStartUrl,
      scope: `openid offline_access api://${clientId}/openid`,
      state,
      audience,
      ...(quiet ? { prompt: 'none' } : {}),
      code_challenge_method: 'S256',
      code_challenge: codeChallenge,
    },
  });
};

const getState = ({ codeChallengeVerifier, nonce }) =>
  btoa(
    JSON.stringify({
      nonce,
      codeChallengeVerifier,
    }),
  );

const getCodeChallenge = verifier =>
  SHA256(verifier)
    .toString(encodings.Base64)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
