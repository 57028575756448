import { observer } from 'mobx-react';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import {
  flow,
  head,
  join,
  map,
} from 'shared-between-everything/src/functionalProgramming';
import Div from '../../private/Element/Div/Div';
import Element from '../../private/Element/Element';
import InputWrapper from '../../private/InputWrapper/InputWrapper';
import BodyText from '../BodyText/BodyText';
import Flex from '../Flex/Flex';
import Gutter from '../Gutter/Gutter';
import SecondaryButton from '../SecondaryButton/SecondaryButton';
import styles from './SelectFile.module.scss';

const SelectFile = ({
  label,
  model: { setValue, validationTranslationKey, outboundValue },
  children,
  inputProps,
  ...props
}) => {
  const onChange = async ({ target: { files: externalFiles = [] } }) => {
    const externalFile = head(externalFiles);

    if (!externalFile) {
      setValue(null);

      return;
    }

    const internalFile = await toInternalFile(externalFile);

    setValue(internalFile);
  };

  return (
    <InputWrapper label={label} errorText={validationTranslationKey} relative>
      <Flex centeredVertically hideOverflow>
        <Div>
          <Element
            className={styles.selectFile_input}
            absolutePosition="my-dimensions-to-match-parents"
            pushBehind
            occupyHorizontalSpace
            tagName="input"
            onChange={onChange}
            type="file"
            {...inputProps}
          />

          <SecondaryButton
            tagName="div"
            className={styles.selectFile_button}
            clickableAppearance
            {...props}
          >
            {children}
          </SecondaryButton>
        </Div>

        <Gutter />

        <BodyText singleLine>{outboundValue.fileName}</BodyText>
      </Flex>
    </InputWrapper>
  );
};

export default decorate(observer)(SelectFile);

const encodeToBase64 = flow(
  arrayBuffer => new Uint8Array(arrayBuffer),
  map(String.fromCharCode),
  join(''),
  btoa,
);

const toInternalFile = async file => {
  const contentArrayBuffer = await file.arrayBuffer();
  const contentAsBase64 = encodeToBase64(contentArrayBuffer);

  return {
    fileName: file.name,
    contentType: file.type,
    contentAsBase64,
  };
};
