import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import sortBy from 'lodash/fp/sortBy';

import { action, flow as mobxFlow, observable } from 'mobx';
import whenRouteChangesTo from 'shared-between-front-ends/src/decorators/whenRouteChangesTo/whenRouteChangesTo';
import getModel from 'shared-between-front-ends/src/decorators/withModel/getModel';
import browserStorageImport from 'shared-between-front-ends/src/doings/browserStorage/browserStorage';
import confirmAndCallFor from 'shared-between-front-ends/src/doings/confirmAndCallFor/confirmAndCallFor';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import RoutingModel from 'shared-between-front-ends/src/models/RoutingModel/RoutingModel';
import SessionModel from 'shared-between-front-ends/src/models/SessionModel/SessionModel';
import callForDeleteDistrict from './callForDeleteDistrict/callForDeleteDistrict';
import callForDeleteTeam from './callForDeleteTeam/callForDeleteTeam';
import DistrictModel from './DistrictModel';
import districtsTranslations from './districtsTranslations';

const translate = localTranslate(districtsTranslations);

export default class DistrictsModel {
  dependencies = {};

  constructor({
    callForDistricts = require('./callForDistricts/callForDistricts').default,
    routingModel = getModel(RoutingModel),
    confirmAndDeleteDistrict = confirmAndCallFor({
      call: callForDeleteDistrict,
      confirmationMessage: translate('deleteConfirmation'),
      successMessage: translate('deleteSuccess'),
    }),
    sessionModel = getModel(SessionModel),
    browserStorage = browserStorageImport,
    confirmAndDeleteTeam = confirmAndCallFor({
      call: callForDeleteTeam,
      confirmationMessage: translate('deleteTeamConfirmation'),
      successMessage: translate('deleteTeamSuccess'),
    }),
  } = {}) {
    this.dependencies.callForDistricts = callForDistricts;
    this.dependencies.routingModel = routingModel;
    this.dependencies.confirmAndDeleteDistrict = confirmAndDeleteDistrict;
    this.dependencies.sessionModel = sessionModel;
    this.dependencies.browserStorage = browserStorage;
    this.dependencies.confirmAndDeleteTeam = confirmAndDeleteTeam;
  }

  @observable districts = [];
  @action
  setDistricts = districts => {
    this.districts = districts;
  };

  @whenRouteChangesTo('districts')
  loadDistricts = mobxFlow(function* () {
    const { response: districts } = yield this.dependencies.callForDistricts();

    const toDistrictModel = toDistrictModelFor(
      this.dependencies.routingModel,
      this.dependencies.confirmAndDeleteDistrict,
      this.refresh,
      this.dependencies.sessionModel,
      this.dependencies.browserStorage,
      this.dependencies.confirmAndDeleteTeam,
    );

    const sortedDistricts = flow(
      map(toDistrictModel),
      sortBy('name'),
    )(districts);

    this.setDistricts(sortedDistricts);
  });

  refresh = () => {
    this.loadDistricts();
  };
}

const toDistrictModelFor = (
  routingModel,
  confirmAndDeleteDistrict,
  onDeletionFinished,
  sessionModel,
  browserStorage,
  confirmAndDeleteTeam,
) => districtData =>
  new DistrictModel(
    districtData,
    routingModel,
    confirmAndDeleteDistrict,
    onDeletionFinished,
    sessionModel,
    browserStorage,
    confirmAndDeleteTeam,
  );
