import {
  action,
  computed,
  observable,
  reaction,
  runInAction,
  untracked,
} from 'mobx';

import getRandomIdImport from 'shared-between-everything/src/doings/getRandomId/getRandomId';

import {
  endsWith,
  filter,
  flow,
  get,
  getOr,
  identity,
  includes,
  isEmpty,
  map,
  maxBy,
  pipeline,
  sortBy,
  toLower,
  uniqBy,
} from 'shared-between-everything/src/functionalProgramming';

import MeasurementAmountInputModel from 'shared-between-front-ends/src/components/public/MeasurementAmountInput/MeasurementAmountInputModel';
import NotificationsModel from 'shared-between-front-ends/src/components/public/Notifications/NotificationsModel';
import SelectionInputModel from 'shared-between-front-ends/src/components/public/SelectionInput/SelectionInputModel';
import TextInputModel from 'shared-between-front-ends/src/components/public/TextInput/TextInputModel';
import getModel from 'shared-between-front-ends/src/decorators/withModel/getModel';
import localTranslate from 'shared-between-front-ends/src/doings/localTranslate/localTranslate';
import RoutingModel from 'shared-between-front-ends/src/models/RoutingModel/RoutingModel';
import getWorkOrderUpdateTabNavigationRoutes from '../../getWorkOrderUpdateTabNavigationRoutes';
import updateWorkOrderExpectedEffortsTranslations from '../updateWorkOrderExpectedEffortsTranslations';
import callForExpectedEffortsImport from './callForExpectedEfforts/callForExpectedEfforts';
import callForUpdateOfExpectedEffortsImport from './callForUpdateOfExpectedEfforts/callForUpdateOfExpectedEfforts';

const translate = localTranslate(updateWorkOrderExpectedEffortsTranslations);

export default class UpdateWorkOrderExpectedEffortsModel {
  dependencies = {};

  constructor({
    routingModel = getModel(RoutingModel),
    notificationsModel = getModel(NotificationsModel),
    callForExpectedEfforts = callForExpectedEffortsImport,
    callForUpdateOfExpectedEfforts = callForUpdateOfExpectedEffortsImport,
    getRandomId = getRandomIdImport,
  } = {}) {
    this.dependencies.routingModel = routingModel;
    this.dependencies.notificationsModel = notificationsModel;
    this.dependencies.callForExpectedEfforts = callForExpectedEfforts;
    this.dependencies.callForUpdateOfExpectedEfforts = callForUpdateOfExpectedEfforts;
    this.dependencies.getRandomId = getRandomId;

    reaction(
      () => pipeline(routingModel.routeName, endsWith('/expected-efforts')),

      routeIsRelevant => routeIsRelevant && this.refresh(),

      { fireImmediately: true },
    );
  }

  catalogInput = new SelectionInputModel({
    getValueOptions: () =>
      pipeline(
        this._rawExpectedEfforts,
        uniqBy('catalog.id'),
        map(joined => ({ id: joined.catalog.id, name: joined.catalog.name })),
        sortBy('name'),
      ),
  });

  expectedEffortSearchInput = new TextInputModel();

  @observable loading = true;
  @action
  setLoading = loading => {
    this.loading = loading;
  };

  @observable _rawExpectedEfforts = [];
  @action
  setExpectedEfforts = expectedEfforts => {
    this._rawExpectedEfforts = expectedEfforts;
  };

  @computed
  get _expectedEfforts() {
    if (!this.catalogInput.internalValue) {
      return [];
    }

    return pipeline(
      this._rawExpectedEfforts,
      filter({ catalog: { id: this.catalogInput.internalValue.id } }),
      map(joined => ({
        ...joined,

        expectedEffortDefinition:
          joined.expectedEffortDefinition ||
          observable({
            id: this.dependencies.getRandomId(),
            type: 'unexpected',

            effort: {
              amount: 0,
              measurementUnitId: joined.catalogItem.effort.measurementUnitId,
            },
          }),
      })),
    );
  }

  @computed
  get issuedExpectedEfforts() {
    return pipeline(
      this._expectedEfforts,
      filter({ expectedEffortDefinition: { type: 'issued' } }),
      map(toDeissuable),
      map(toHaveEffortInput),
      sortBy('catalogItem.name'),
    );
  }

  @computed
  get manualExpectedEfforts() {
    return pipeline(
      this._expectedEfforts,
      filter({ expectedEffortDefinition: { type: 'manual' } }),
      map(toIssuable),
      sortBy('catalogItem.name'),
    );
  }

  @computed
  get unexpectedEfforts() {
    return pipeline(
      this._expectedEfforts,
      filter({ expectedEffortDefinition: { type: 'unexpected' } }),
      isEmpty(this.expectedEffortSearchInput.debouncedInternalValue)
        ? identity
        : filter(
            flow(
              get('catalogItem.name'),
              toLower,
              includes(
                toLower(this.expectedEffortSearchInput.debouncedInternalValue),
              ),
            ),
          ),
      map(toIssuable),
      sortBy('catalogItem.name'),
    );
  }

  @computed
  get tabRoutes() {
    return getWorkOrderUpdateTabNavigationRoutes(
      this.dependencies.routingModel,
    );
  }

  @computed
  get workOrderId() {
    return this.dependencies.routingModel.pathParameters.workOrderId;
  }

  refresh = async () => {
    this.setLoading(true);

    const {
      response: expectedEfforts,
    } = await this.dependencies.callForExpectedEfforts({
      workOrderId: this.workOrderId,
    });

    this.setExpectedEfforts(expectedEfforts);

    const preselectedCatalogId = pipeline(
      expectedEfforts,
      maxBy('expectedEffortDefinition.modifiedDateTime'),
      getOr(null, 'catalog.id'),
    );

    this.catalogInput.setInboundValue(preselectedCatalogId);

    this.setLoading(false);
  };

  submit = async () => {
    await this.dependencies.callForUpdateOfExpectedEfforts({
      workOrderId: this.workOrderId,
      expectedEfforts: this._submittableExpectedEfforts,
    });

    this.dependencies.notificationsModel.setSuccess(translate('updateDone'), {
      'data-notification-for-successful-update-e2e-test': true,
    });
  };

  @computed
  get _submittableExpectedEfforts() {
    return [...this.issuedExpectedEfforts, ...this.manualExpectedEfforts].map(
      ({ catalogItem, effortInput, expectedEffortDefinition }) => ({
        id: expectedEffortDefinition.id,
        type: expectedEffortDefinition.type,
        effortDefinitionCatalogItemId: catalogItem.id,
        effort: {
          amount: effortInput
            ? effortInput.internalValue || 0
            : expectedEffortDefinition.effort.amount,

          measurementUnitId: catalogItem.effort.measurementUnitId,
        },
      }),
    );
  }
}

const toIssuable = joined => ({
  doIssue: () =>
    runInAction(() => {
      joined.expectedEffortDefinition.type = 'issued';
    }),

  ...joined,
});

const toDeissuable = joined => ({
  doDeissue: () =>
    runInAction(() => {
      joined.expectedEffortDefinition.type = 'manual';
    }),

  ...joined,
});

const toHaveEffortInput = joined => ({
  effortInput: untracked(
    () =>
      new MeasurementAmountInputModel({
        measurementUnitId: joined.catalogItem.effort.measurementUnitId,
        initialInternalValue: joined.expectedEffortDefinition.effort.amount,
      }),
  ),

  ...joined,
});
