import intersection from 'lodash/fp/intersection';
import isArray from 'lodash/fp/isArray';
import React from 'react';
import decorate from 'shared-between-everything/src/doings/decorate/decorate';
import { isFunction } from 'shared-between-everything/src/functionalProgramming';
import Div from '../../../components/private/Element/Div/Div';

import Flex from '../../../components/public/Flex/Flex';
import Spinner from '../../../components/public/Spinner/Spinner';
import withModel from '../../withModel/withModel';
import SpinningModel from './SpinningModel';

function SpinnerTentative({
  spinnerName = 'default-spinner-name',
  currentlySpinning,
  children = null,
  ...props
}) {
  const targetSpinners = !isArray(spinnerName) ? [spinnerName] : spinnerName;

  const showLoading =
    intersection(targetSpinners, currentlySpinning).length > 0;

  return (
    <Div data-spinner-name-test={targetSpinners.join(', ')}>
      {showLoading ? (
        <Flex centered {...props}>
          <Spinner />
        </Flex>
      ) : isFunction(children) ? (
        children()
      ) : (
        children
      )}
    </Div>
  );
}

export default decorate(
  withModel({
    Model: SpinningModel,
    props: [
      {
        name: 'currentlySpinning',
        modelPath: 'currentlySpinning',
      },
    ],
  }),
)(SpinnerTentative);
