const when = (triggerWhen, fireImmediately = false) => (
  Class,
  methodName,
  oldDescriptor,
) => {
  if (!Class.__when) {
    Class.__when = [];
  }

  Class.__when.push({
    methodName,
    triggerWhen,
    fireImmediately,
  });

  const { value, initializer, ...newDescriptor } = oldDescriptor;

  if (initializer) {
    newDescriptor.initializer = function () {
      const decorated = initializer.call(this);

      decorated.__configuration = [triggerWhen, fireImmediately];
      decorated.__unconfiguredFunction = when;

      return decorated;
    };
  } else {
    const decorated = value;

    decorated.__configuration = [triggerWhen, fireImmediately];
    decorated.__unconfiguredFunction = when;

    newDescriptor.value = decorated;
  }

  return newDescriptor;
};

export default when;
