import React from 'react';

import Table from 'shared-between-front-ends/src/components/public/Table/Table';

export default ({ backgroundColor = 'white', children, ...props }) => (
  <Table.HeadingCell
    padding={{ vertical: 'xs', horizontal: 'md' }}
    backgroundColor={backgroundColor}
    {...props}
  >
    {children}
  </Table.HeadingCell>
);
