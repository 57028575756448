import parameterInputMapperFor from 'shared-between-everything/src/doings/parameterInputMapperFor/parameterInputMapperFor';
import getAuthenticatedCall from 'shared-between-front-ends/src/doings/getAuthenticatedCall/getAuthenticatedCall';

export default getAuthenticatedCall({
  apiCallConfig: {
    path: '/api/modify-team/{teamId}',
    method: 'POST',
    inputMapper: parameterInputMapperFor({
      pathParameters: {
        teamId: 'id',
      },

      bodyParameters: {
        districtId: 'districtId',
        name: 'name',
        nameOfManager: 'nameOfManager',
        costCentres: 'costCentres',
        departments: 'departments',
        namesOfForemen: 'namesOfForemen',
      },
    }),
  },
  decoratorConfig: {
    spinnerName: 'updating-team',
    showSpinnerInstantly: true,
  },
});
